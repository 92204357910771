import { PropsWithChildren, useState } from "react";
import { useTranslate } from "../i18translate/Hooks";
import { I18 } from "../languages/I18";
import notificationManager from "../utils/NotificationManager";


interface DragAndDropProps {
    onfileDroped: (files: any) => void;
    formatsAccepting?: [string];
    maxCount?: number;
    isDisabled?: boolean;
    className?: string;
}
const DragAndDrop = (props: PropsWithChildren<DragAndDropProps>) => {
    const { onfileDroped, formatsAccepting = [".json"], maxCount = 1, isDisabled = false, className } = props;

    const { t } = useTranslate();

    const [isfileHover, setIsfileHover] = useState<boolean>(false);

    const dragOverHandler = (e: any) => {
        if (isDisabled)
            return;
        e.preventDefault();
        e.stopPropagation();
    }
    const dropHandler = (e: any) => {
        if (isDisabled)
            return;
        e.preventDefault();
        e.stopPropagation();
        setIsfileHover(false)
        const files = [...e.dataTransfer.files];
        if (maxCount < files.length) {
            notificationManager.error(t(I18.drag_and_drop_maximium_count_exceeded_notification_message, maxCount.toString()));
            return;
        }
        if (files.some((file) => !formatsAccepting.some((format: string) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
            notificationManager.error(t(I18.drag_and_drop_format_mismatch_notification_message, formatsAccepting.join(', ')));
            return;
        }
        if (files && files.length) {
            onfileDroped(files);
        }
    }
    const dragEnterHandler = () => setIsfileHover(true);
    const dragLeaveHandler = () => setIsfileHover(false);

    return (
        <div
            className={`drag-and-drop ${!isDisabled && isfileHover ? "filehover" : ""} ${className ? className : ""}`}
            onDragOver={dragOverHandler}
            onDrop={dropHandler}
            onDragEnter={dragEnterHandler}
            onDragLeave={dragLeaveHandler}
            // eslint-disable-next-line
            role={"dragAndDrop"}>
            {props.children}
        </div>
    )
}

export default DragAndDrop