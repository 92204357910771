import { PropsWithChildren } from 'react';
import Button from '../../../components/Form/Button';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';

interface InlineEditPopupProps {
    fieldId: string;
    onUpdateClick?: (fieldId: string) => void;
    onCancelClick?: () => void;
}

function InlineEditPopup(props: PropsWithChildren<InlineEditPopupProps>) {
    const { fieldId, onCancelClick, onUpdateClick } = props;

    const { t } = useTranslate();

    const handleCancelClick = () => {
        if (onCancelClick)
            onCancelClick();
    }

    const handleUpdateClick = () => {
        if (onUpdateClick)
            onUpdateClick(fieldId);
    }

    return (
        <div className='inline-edit-popup'>
            <div className='triangle'></div>
            <div className='inline-edit-content'>
                {props.children}
            </div>
            <div className='inline-edit-footer'>
                <Button size='xsmall' type='discreet' onClick={handleCancelClick}>
                    {t(I18.cancel)}
                </Button>
                <Button size='xsmall' type='primary-black' onClick={handleUpdateClick}>
                    {t(I18.update)}
                </Button>
            </div>
        </div>
    )
}

export default InlineEditPopup