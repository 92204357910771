import { HomeAction, HomeActionType } from './actionTypes';

export const showOptionModal = (): HomeAction  => {
    return {
        type: HomeActionType.HOME_OPTION_MODAL_SHOW
    }
}

export const hideOptionModal = (): HomeAction  => {
    return {
        type: HomeActionType.HOME_OPTION_MODAL_HIDE
    }
}