import { ConfigurationJSONModel } from '../model/ConfigurationJSONModel';
import { ConfigurationModel } from '../model/ConfigurationModel';
import { projectPageAPI } from './APIService';

const baseUrl = "configurations";

export const configurationApi = projectPageAPI.injectEndpoints({
    endpoints: (builder) => ({
        getConfigurationByProjectIdAndConfigurationId: builder.mutation<ConfigurationModel, { projectId: string, configurationId: string }>({
            query: ({ projectId, configurationId }) => `${baseUrl}/${projectId}/${configurationId}`,
        }),
        addConfiguration: builder.mutation<ConfigurationModel, Partial<ConfigurationModel>>({
            query: (data) => (
                {
                    url: `${baseUrl}`,
                    method: 'POST',
                    body: data
                }
            ),
            invalidatesTags: (result, error, { projectID }) => ["Configurations", { type: "Projects", id: projectID }]
        }),
        updateConfiguration: builder.mutation<ConfigurationModel, Partial<ConfigurationModel>>({
            query: (data) => (
                {
                    url: `${baseUrl}/${data.configurationID}`,
                    method: 'PUT',
                    body: data
                }
            ),
            invalidatesTags: (result, error, { configurationID }) => [{ type: "Configurations", id: configurationID }]
        }),
        updateConfigurationJson: builder.mutation<ConfigurationJSONModel, { projectId: string, configurationId: string, data: ConfigurationJSONModel }>({
            query: ({ projectId, configurationId, data }) => (
                {
                    url: `${baseUrl}?projectID=${projectId}&configurationID=${configurationId}`,
                    method: 'PUT',
                    body: data
                }
            )
        }),
        deleteconfiguration: builder.mutation<ConfigurationModel, Partial<ConfigurationModel>>({
            query: (data) => {
                const { projectID, configurationID } = data;
                return {
                    url: `${baseUrl}`,
                    params: { projectID, configurationID },
                    method: 'DELETE'
                };
            },
            invalidatesTags: (result, error, { projectID }) => ["Configurations", { type: "Projects", id: projectID }]
        })
    }),
});

export const {
    useGetConfigurationByProjectIdAndConfigurationIdMutation,
    useAddConfigurationMutation,
    useUpdateConfigurationMutation,
    useUpdateConfigurationJsonMutation,
    useDeleteconfigurationMutation,
} = configurationApi;