import HarmonicIcon from "../../../components/HarmonicIcon";
import Icon from "../../../components/Icon";
import '../styles/systemcomponentModalstyle.scss';

interface SystemComponentModalItemProps {
    icon: string;
    text: string;
    iconType?: "CommonUX" | "Harmonic";
    onClick?: () => void;
}

function SystemComponentModalItem(props: SystemComponentModalItemProps) {
    const { icon, text, iconType = "CommonUX", onClick } = props
    const handleClick = () => {
        if (onClick)
            onClick();
    }

    return (
        <li>
            <div className="modal-item" onClick={handleClick}>
                <span className="icon-left">
                    {
                        iconType === "CommonUX"
                            ? <Icon size="small" name={icon}></Icon>
                            : <HarmonicIcon size="small" name={icon} />
                    }
                </span>
                <div className="label-text">
                    {text}
                </div>
            </div>
        </li>
    )
}

export default SystemComponentModalItem;
