import Button from "../../../components/Form/Button";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { NavigationPath } from "../../../utils/Navigation";
import OptionModal from "./OptionModal";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import { HomeAction } from "../store/actionTypes";
import { showOptionModal } from "../store/actions";

function Banner() {
  const { t } = useTranslate();
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch<HomeAction>>();
  const onClickEstimateHarmonics = () => {
    auth.isAuthenticated
      ? navigate(NavigationPath.Project)
      : dispatch(showOptionModal());
  };

  return (
    <>
      <OptionModal />
      <section id="banner" className="banner">
        <div className="container">
          <div className="banner-content">
            <p className="brand-title-font">
              <span className="brand-title-bar">—</span>
              <br />
              {t(I18.app_brandname)}
            </p>
            {/* <img alt='title' src={bannerBrandName} className='brand-title'></img> */}
            <article>
              <p className="body-2-regular">
                {t(I18.home_harmonics_description)}
              </p>
            </article>
            <Button
              type="primary-red"
              size="large"
              onClick={onClickEstimateHarmonics}
            >
              {t(I18.home_estimate_harmonics)}
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
