import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import './styles/layout.scss'
import { useAppSelector } from '../store/hooks'
import { appInsights } from '../core/appInsights'
import { useAuth } from 'react-oidc-context'

export default function MainLayout() {

    const { cookiePolicy } = useAppSelector(store => store.main);
    const auth = useAuth();

    useEffect(()=>{
    const analyticsValue = localStorage.getItem('analytics');
    const allanalyticsValue = localStorage.getItem('allanalytics');
    const enableTracking = analyticsValue === 'true' || allanalyticsValue === 'true';
       
    if(enableTracking && appInsights && !auth.isLoading && auth.isAuthenticated){
        appInsights.config.disableTelemetry = false; 
    }
    else if(appInsights){
        appInsights.config.disableTelemetry = true; 
    }
    },[cookiePolicy, auth.isLoading]);

    return (
        <div className='layout'>
             <div>
                <Header />
                <Outlet />
             </div>
                <Footer />
        </div>
    )
}
