import { ITranslate } from "../models/ITranslate";
import { TranslationConfig } from "../models/TranslationConfig";

export class Translate implements ITranslate {
    private _currentLanguage: string = "";
    private _fallbackLanguage: string = "en-US";
    private _languages: string[] = [];
    private _translations: any = {};
    private static _instance: Translate;
    private _currentLanguageJson: object = {};
    private _fallbackLanguageJson: object = {};

    private constructor(config?: TranslationConfig) {
        if (!config)
            return;

        this._currentLanguageJson = config.currentLanguageJson;
        this._fallbackLanguageJson = config.fallbackLanguageJson;
        this._currentLanguage = config.currentLanguage;
        this._fallbackLanguage = config.fallbackLanguage;
        //set translations
        this._translations[this._currentLanguage] = this._currentLanguageJson;
        this._translations[this._fallbackLanguage] = this._fallbackLanguageJson;

        this.setLanguages(config.languages || []);
    }

    getCurrentLanguageJson = (): object => {
        return this._currentLanguageJson;
    }

    getFallbackLanguageJson = (): object => {
        return this._fallbackLanguageJson;
    }

    getCurrentLanguage = (): string => {
        return this._currentLanguage;
    }

    getFallbackLanguage = (): string => {
        return this._fallbackLanguage;
    }

    getLanguages = (): string[] => {
        return this._languages;
    }

    private setLanguages = (languages: string[]) => {
        this._languages = languages;
    }

    getTranslations = (): any => {
        return this._translations;
    }

    changeCurrentLangugage = (languageName: string, languageJson: object) => {
        if (!languageName || languageName === "" || !languageJson)
            throw new Error("Language name or json is missing");

        //remove existing language
        this._translations[this._currentLanguage] = undefined;

        this._currentLanguage = languageName;
        this._currentLanguageJson = languageJson;
        this._translations[this._currentLanguage] = this._currentLanguageJson;
    }

    t = (key: string, ...value: string[]): string => {
        let fallBackLang: string = this._translations[this._fallbackLanguage] && this._translations[this._fallbackLanguage][key] ?
            this._translations[this._fallbackLanguage][key] :
            key;
        let result: string = this._translations[this._currentLanguage] && this._translations[this._currentLanguage][key] ?
            this._translations[this._currentLanguage][key] : fallBackLang;

        if (value)
            for (let i = 0; i < value.length; i++) {
                result = result.replaceAll(`{${i}}`, value[i]);
            }

        return result;
    }

    public static getInstance(config?: TranslationConfig): Translate {
        if (this._instance)
            return this._instance
        else {
            this._instance = new Translate(config);
            return this._instance;
        }
    }
}