import '../styles/login.scss';
import { useAuth } from 'react-oidc-context';
import { NavigationPath } from '../utils/Navigation';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslate } from '../i18translate/Hooks';
import { I18 } from '../languages/I18';

function Logout() {
    const auth = useAuth();
    const { t } = useTranslate();
    const { pathname } = useLocation();
    const isUnAuthorized = pathname.includes(NavigationPath.Unauthorized);
    const isSessionTimeOut = pathname.includes(NavigationPath.SessionTimeOut);

    useEffect(() => {
        if (auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator)
            auth.removeUser();
    }, [auth.isAuthenticated, auth.isLoading, auth.activeNavigator, auth])

    if (auth.error) {
        console.error("Auth Error: ", auth.error?.message)
        return <Navigate to={NavigationPath.Home} />
    }

    if (!auth.isAuthenticated && !auth.isLoading && isUnAuthorized)
        return <Navigate to={`/${NavigationPath.Unauthorized}`} />

    if (!auth.isAuthenticated && !auth.isLoading && isSessionTimeOut)
        return <Navigate to={`/${NavigationPath.SessionTimeOut}`} />

    if (!auth.isAuthenticated && !auth.isLoading)
        return <Navigate to={NavigationPath.Home} />

    return (
        <div className='container'>
            <div className='login'>
                <h4 className='title abb-cursor'>{t(I18.login_redirect_signout)}</h4>
                <span className='description'>{t(I18.login_waiting_message)}</span>
            </div>
        </div>
    )
}

export default Logout