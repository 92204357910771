import { CookiePolicyAction, CookiePolicyActionTypes } from "./actionTypes";
import { CookiePolicyModel, CookiePolicyStoreModel } from "../models/CookiePolicyModel";

export const initialState: CookiePolicyStoreModel = {
    cookiePolicy:{
        analytics: false,
        preference: false,
        processdata: false,
        thirdParty: false
    } as CookiePolicyModel
}

const cookiePolicyReducer = (state: CookiePolicyStoreModel, action: CookiePolicyAction ):CookiePolicyStoreModel => {
    state = state ? state : initialState;

    switch (action.type) {
        case CookiePolicyActionTypes.COOKIE_POLICY_UPDATE:
            return {
                ...state,
                cookiePolicy: action.payload
            }

        default:
            return state;
    }
}

export default cookiePolicyReducer;