import { useState, Dispatch, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { hideSystemComponentModal, hideMotorControlCenterModal, addChildComponent, updateComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import CommonModalFooter from './CommonModalFooter';
import { SystemComponent } from '../../models/SystemComponent';
import { ComponentType } from '../../models/ComponentType';
import { getSelectedComponent } from "../../../../utils/Common";
import { MotorControlCenter } from '../../models/MotorControlCenter';
import { cloneDeep } from 'lodash';
import MotorControlCenterFormFields from '../../models/FormFields/MotorControlCenterFormFields';
import Input from '../../../../components/Form/Input';
import Icon from '../../../../components/Icon';
import ModalDialog from '../../../../components/ModalDialog';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

function MotorControlCenterModalPopup() {
    const [formState, setFormState] = useState<any>({
        parentComponent: ComponentType.Transformer
    });

    const [selectedComponent, setSelectedComponent] = useState<SystemComponent>();
    const { isMotorControlCenterEdit, isShowMotorControlCenterModal, selectedComponentNodeId, configurationTree } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const idRef = useRef<any>();

    const { t } = useTranslate();

    const handleCloseMotorControlCenterModal = () => {
        dispatch(hideMotorControlCenterModal());
        dispatch(hideSystemComponentModal());
    }

    const handleCancelMotorControlCenterModal = () => {
        dispatch(hideMotorControlCenterModal());
    }

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };
        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(MotorControlCenterFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    useEffect(() => {
        if (isShowMotorControlCenterModal) {
            if (isMotorControlCenterEdit) {
                let selectedMCC = getSelectedComponent(configurationTree, selectedComponentNodeId);
                if (selectedMCC) {
                    setSelectedComponent(selectedMCC);
                    let mccData = selectedMCC.componentData as MotorControlCenter;
                    let fields = { ...formState };
                    fields[MotorControlCenterFormFields.id] = mccData.id;
                    fields[MotorControlCenterFormFields.motorControlCenterName] = mccData.motorControlCenterName;
                    if (mccData) {
                        setFormState({
                            ...fields
                        });
                    }
                }
            }
            else {
                let fields = { ...formState };
                fields[MotorControlCenterFormFields.id] = '';
                fields[MotorControlCenterFormFields.motorControlCenterName] = '';
                setFormState({
                    ...fields,
                });
            }
        }
        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowMotorControlCenterModal, isMotorControlCenterEdit, configurationTree, selectedComponentNodeId])

    const handleAdd = () => {
        if (!validateForm())
            return;
        let MotorControlCenterData: MotorControlCenter = new MotorControlCenter(
            formState[MotorControlCenterFormFields.id],
            formState[MotorControlCenterFormFields.motorControlCenterName]
        )
        let mcc: SystemComponent = {
            componentType: ComponentType.MCC,
            componentData: MotorControlCenterData
        };
        if (selectedComponentNodeId) {
            dispatch(addChildComponent(selectedComponentNodeId, mcc));
        }
        dispatch(hideMotorControlCenterModal());
        dispatch(hideSystemComponentModal());
    }

    const handleUpdate = () => {
        if (!validateForm())
            return;
        if (selectedComponent) {
            let component = cloneDeep(selectedComponent);
            let motorControlCenterData = component.componentData as MotorControlCenter;
            if (motorControlCenterData) {
                motorControlCenterData.id = formState[MotorControlCenterFormFields.id];
                motorControlCenterData.motorControlCenterName = formState[MotorControlCenterFormFields.motorControlCenterName];
                dispatch(updateComponent(component));
            }
        }
        dispatch(hideMotorControlCenterModal());
    }

    const validateForm = (): boolean => {
        return idRef.current.validate();
    }

    return (
        <ModalDialog
            open={isShowMotorControlCenterModal}
            onModalClose={handleCloseMotorControlCenterModal}
            showBackButton={true}
            onBack={handleCancelMotorControlCenterModal}
            modalTitle={isMotorControlCenterEdit ? t(I18.configuration_page_motor_control_center_modal_popup_edit_title) : t(I18.configuration_page_motor_control_center_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup">
            <Icon slot='title-icon' name="sld-1" ></Icon>
            <div className="modal-content">
                <Input
                    className="input-style first-child"
                    size="medium"
                    labelText={t(I18.configuration_page_label_id_tag)}
                    optionalText={t(I18.configuration_page_optional_text_id_tag)}
                    value={formState[MotorControlCenterFormFields.id]}
                    onInputChange={(e) => { handleChange(e, MotorControlCenterFormFields.id); }}
                    heMaxLength={10}
                    ref={idRef}>
                </Input>
                <Input
                    className="input-style"
                    size="medium"
                    labelText={t(I18.configuration_page_label_mcc_name)}
                    optionalText={t(I18.configuration_page_optional_text_mcc_name)}
                    value={formState[MotorControlCenterFormFields.motorControlCenterName]}
                    onInputChange={(e) => { handleChange(e, MotorControlCenterFormFields.motorControlCenterName); }}>
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={isMotorControlCenterEdit}
                    onCancelClick={handleCloseMotorControlCenterModal}
                    onActionButtonClick={handleAdd}
                    onUpdateClick={handleUpdate} />
            </div>
        </ModalDialog>
    )
}
export default MotorControlCenterModalPopup;
