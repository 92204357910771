import LoadDetailsItem, { LoadDetailstemProps } from "./LoadDetailsItem";
import '../styles/loaddetails.scss';
import { LoadDetails, LoadDetailsResultModel } from "../models/LoadDetailsResultModel";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
interface LoadDetailsCardProps {
    loadDetailsResult?: LoadDetailsResultModel;
    loading?: boolean;
}

function LoadDetailsCard(props: LoadDetailsCardProps) {

    const { t } = useTranslate();

    const { loadDetailsResult, loading = false } = props;
    const getLoadDetailItems = (result?: LoadDetailsResultModel): LoadDetailstemProps[] => {
        let loadDetailstems: LoadDetailstemProps[] = [];

        if (!result)
            result = getDefaultLoadDetailValues();

        loadDetailstems.push({
            title: `${t(I18.configuration_page_label_drives)}, ${t(I18.configuration_page_label_harmonic_current)}`,
            Amps: result.driveHarmonicCurrent
        });
        loadDetailstems.push({
            title: `${t(I18.configuration_page_label_drives)}, ${t(I18.configuration_page_label_fundamental_current)}`,
            Amps: result.driveFundamentalCurrent,
        });
        [
            { title: `${t(I18.configuration_page_label_drives)}, ${t(I18.configuration_page_label_total_power)}`, result: result.drivesTotal },
            { title: `${t(I18.configuration_page_label_linear_loads)}, ${t(I18.configuration_page_label_total_power)}`, result: result.linearLoadTotal },
            { title: t(I18.configuration_page_label_total), result: result.total }
        ].forEach((item) => {
            loadDetailstems.push({
                title: item.title,
                Amps: item.result.amps,
                kVA: item.result.kva,
                kW: item.result.kw,
                PF: item.result.pf
            })
        });
        loadDetailstems.push({
            title: t(I18.configuration_page_label_percentage_of_rated_source),
            Amps: result.percentageRated.amps,
            kVA: result.percentageRated.kva ? result.percentageRated.kva : undefined,
            kW: result.percentageRated.kw ? result.percentageRated.kw : undefined
        });
        return loadDetailstems;
    }
    const getDefaultLoadDetailValues = (): LoadDetailsResultModel => {
        const loadDetail: LoadDetails = {
            amps: 0,
            kva: 0,
            kw: 0,
            pf: 0
        }
        return {
            driveFundamentalCurrent: 0,
            driveHarmonicCurrent: 0,
            drivesTotal: loadDetail,
            linearLoadTotal: loadDetail,
            total: loadDetail,
            percentageRated: loadDetail
        }
    }
    return (
        <div className='card load-details-card'>
            <ul className="ul-style">
                {getLoadDetailItems(loadDetailsResult).map((item) =>
                (<LoadDetailsItem
                    key={item.title} loading={loading} {...item} />
                ))}
            </ul>
        </div>
    )
}
export default LoadDetailsCard
