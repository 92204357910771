import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CountryModel } from "../models/CountryModel";
import { IndustryModel } from "../models/IndustryModel";

// Replace it with the REACT_APP_API_URL when backend API is ready
const baseUrl = `/mockAPIData`;

export const commonApi = createApi({
    reducerPath: "commonApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    //keeping data in cache for 1 hour = 3600 seconds.
    keepUnusedDataFor: 3600,
    tagTypes: ['Country', 'Industry'],
    endpoints: (builder) => ({
        getAllCoutries: builder.query<[CountryModel], void>({
            query: () => "country.json",
            providesTags: () => ["Country"]
        }),
        getAllIndustries: builder.query<[IndustryModel], void>({
            query: () => "industry.json",
            providesTags: () => ["Industry"]
        })
    }),
});

export const { useGetAllCoutriesQuery, useGetAllIndustriesQuery, } = commonApi;