import { ActiveHarmonicFilterResultModel } from "../models/ActiveHarmonicFilterResultModel";
import { SystemComponent } from "../models/SystemComponent";
import { ProjectSharedModel } from '../../../models/ProjectSharedModel';
import { ConfigurationSharedModel } from '../../../models/ConfigurationSharedModel';

export enum ConfigurationActionTypes {
    RESET_CONFIGURATION = "RESET_CONFIGURATION",
    CONFIGURATION_TREE_ADD_CHILD = "CONFIGURATION_TREE_ADD_CHILD",
    CONFIGURATION_TREE_UPDATE = "CONFIGURATION_TREE_UPDATE",
    CONFIGURATION_TREE_DELETE_CHILD = "CONFIGURATION_TREE_DELETE_CHILD",
    CONFIGURATION_TREE_CREATE_TREE = "CONFIGURATION_TREE_CREATE_TREE",
    PROJECT_UPDATE = "PROJECT_UPDATE",
    CONFIGURATION_UPDATE = "CONFIGURATION_UPDATE",
    SYSTEM_COMPONENT_MODAL_SHOW = "SYSTEM_COMPONENT_MODAL_SHOW",
    SYSTEM_COMPONENT_MODAL_HIDE = "SYSTEM_COMPONENT_MODAL_HIDE",
    CONFIGURATION_SELECT_COMPONENT = "CONFIGURATION_SELECT_COMPONENT",
    CONFIGURATION_SELECT_PCC = "CONFIGURATION_SELECT_PCC",
    CONFIGURATION_SELECT_POWERSOURCE = "CONFIGURATION_SELECT_POWERSOURCE",
    CONFIGURATION_SELECT_UNIT = "CONFIGURATION_SELECT_UNIT",
    CONFIGURATION_SELECT_STANDARD = "CONFIGURATION_SELECT_STANDARD ",
    USER_TRANSFORMER_MODAL_SHOW = "USER_TRANSFORMER_MODAL_SHOW",
    USER_TRANSFORMER_MODAL_HIDE = "USER_TRANSFORMER_MODAL_HIDE",
    NON_LINEAR_LOADS_MODAL_SHOW = "NON_LINEAR_LOADS_MODAL_SHOW",
    NON_LINEAR_LOADS_MODAL_HIDE = "NON_LINEAR_LOADS_MODAL_HIDE",
    EDIT_POWER_GRID_MODAL_SHOW = "EDIT_POWER_GRID_MODAL_SHOW",
    EDIT_POWER_GRID_MODAL_HIDE = "EDIT_POWER_GRID_MODAL_HIDE",
    EDIT_LV_GENSET_MODAL_SHOW = "EDIT_LV_GENSET_MODAL_SHOW",
    EDIT_LV_GENSET_MODAL_HIDE = "EDIT_LV_GENSET_MODAL_HIDE",
    ACTIVE_HARMONIC_FILTER_MODAL_SHOW = "ACTIVE_HARMONIC_FILTER_MODAL_SHOW",
    ACTIVE_HARMONIC_FILTER_MODAL_HIDE = "ACTIVE_HARMONIC_FILTER_MODAL_HIDE",
    MOTOR_CONTROL_CENTER_MODAL_SHOW = "MOTOR_CONTROL_CENTER_MODAL_SHOW",
    MOTOR_CONTROL_CENTER_MODAL_HIDE = " MOTOR_CONTROL_CENTER_MODAL_HIDE",
    LINEAR_LOAD_MODAL_SHOW = "LINEAR_LOAD_MODAL_SHOW",
    LINEAR_LOAD_MODAL_HIDE = " LINEAR_LOAD_MODAL_HIDE",
    DISABLE_CREATE_REPORT_BUTTON = "DISABLE_CREATE_REPORT_BUTTON ",
    ENABLE_CREATE_REPORT_BUTTON = "ENABLE_CREATE_REPORT_BUTTON ",
    UPDATE_ACTIVE_HARMONIC_FILTER_RESULT = "UPDATE_ACTIVE_HARMONIC_FILTER_RESULT",
    ADD_ASSUMPTIONNOTES_MODEL_SHOW = "ADD_ASSUMPTIONOTES_MODEL_SHOW",
    ADD_ASSUMPTIONNOTES_MODEL_HIDE = "ADD_ASSUMPTIONOTES_MODEL_HIDE",
    ADD_ASSUMPTIONNOTE = "ADD_ASSUMPTIONNOTE",
    REMOVE_ASSUMPTIONNOTE = "REMOVE_ASSUMPTIONNOTE",
    UPDATE_ASSUMPTIONNOTE = "UPDATE_ASSUMPTIONNOTE",
    EXPORT_CONFIGURATION_MODAL_SHOW = "EXPORT_CONFIGURATION_MODAL_SHOW",
    EXPORT_CONFIGURATION_MODAL_HIDE = "EXPORT_CONFIGURATION_MODAL_HIDE",
    IMPORT_CONFIGURATION_MODAL_SHOW = "IMPORT_CONFIGURATION_MODAL_SHOW",
    IMPORT_CONFIGURATION_MODAL_HIDE = "IMPORT_CONFIGURATION_MODAL_HIDE",
}

interface ResetConfiguration {
    type: ConfigurationActionTypes.RESET_CONFIGURATION;
}

interface AddChild {
    type: ConfigurationActionTypes.CONFIGURATION_TREE_ADD_CHILD;
    parentNodeId: string;
    payload: SystemComponent;
}
interface UpdateTree {
    type: ConfigurationActionTypes.CONFIGURATION_TREE_UPDATE;
    payload: SystemComponent;
}

interface DeleteChild {
    type: ConfigurationActionTypes.CONFIGURATION_TREE_DELETE_CHILD;
    payload: SystemComponent;
}

interface CreateTree {
    type: ConfigurationActionTypes.CONFIGURATION_TREE_CREATE_TREE;
    payload: SystemComponent;
}

interface ProjectUpdate {
    type: ConfigurationActionTypes.PROJECT_UPDATE;
    payload: ProjectSharedModel;
}

interface ConfigurationUpdate {
    type: ConfigurationActionTypes.CONFIGURATION_UPDATE;
    payload: ConfigurationSharedModel;
}

interface ShowSystemComponentModal {
    type: ConfigurationActionTypes.SYSTEM_COMPONENT_MODAL_SHOW;
}

interface HideSystemComponentModal {
    type: ConfigurationActionTypes.SYSTEM_COMPONENT_MODAL_HIDE;
}

interface SelectComponent {
    type: ConfigurationActionTypes.CONFIGURATION_SELECT_COMPONENT;
    payload: string
}

interface SelectPCC {
    type: ConfigurationActionTypes.CONFIGURATION_SELECT_PCC;
    payload: string;
}

interface SelectedPowerSource {
    type: ConfigurationActionTypes.CONFIGURATION_SELECT_POWERSOURCE;
    payload: number;
}
interface ShowUserTransformerModal {
    type: ConfigurationActionTypes.USER_TRANSFORMER_MODAL_SHOW;
    payload: { isEdit: boolean }
}

interface HideUserTransformerModal {
    type: ConfigurationActionTypes.USER_TRANSFORMER_MODAL_HIDE;
}

interface ShowNonlinearLoadsModal {
    type: ConfigurationActionTypes.NON_LINEAR_LOADS_MODAL_SHOW;
    payload: { isEdit: boolean }
}

interface HideNonlinearLoadsModal {
    type: ConfigurationActionTypes.NON_LINEAR_LOADS_MODAL_HIDE;
}
interface ShowEditPowerGridModal {
    type: ConfigurationActionTypes.EDIT_POWER_GRID_MODAL_SHOW;
}

interface HideEditPowerGridModal {
    type: ConfigurationActionTypes.EDIT_POWER_GRID_MODAL_HIDE;
}

interface ShowEditLvGensetModal {
    type: ConfigurationActionTypes.EDIT_LV_GENSET_MODAL_SHOW;
}

interface HideEditLvGensetModal {
    type: ConfigurationActionTypes.EDIT_LV_GENSET_MODAL_HIDE;
}

interface SelectUnit {
    type: ConfigurationActionTypes.CONFIGURATION_SELECT_UNIT;
    payload: number;
}

interface SelectStandard {
    type: ConfigurationActionTypes.CONFIGURATION_SELECT_STANDARD;
    payload: number;
}

interface ShowActiveHarmonicFilterModal {
    payload: any;
    type: ConfigurationActionTypes.ACTIVE_HARMONIC_FILTER_MODAL_SHOW;
}

interface HideActiveHarmonicFilterModal {
    type: ConfigurationActionTypes.ACTIVE_HARMONIC_FILTER_MODAL_HIDE;
}

interface ShowMotorControlCenterModal {
    payload: any;
    type: ConfigurationActionTypes.MOTOR_CONTROL_CENTER_MODAL_SHOW;
}

interface HideMotorControlCenterModal {
    type: ConfigurationActionTypes.MOTOR_CONTROL_CENTER_MODAL_HIDE;
}

interface ShowLinearLoadModal {
    payload: any;
    type: ConfigurationActionTypes.LINEAR_LOAD_MODAL_SHOW;
}

interface HideLinearLoadModal {
    type: ConfigurationActionTypes.LINEAR_LOAD_MODAL_HIDE;
}

interface DisableCreateReportButton {
    type: ConfigurationActionTypes.DISABLE_CREATE_REPORT_BUTTON;
}

interface EnableCreateReportButton {
    type: ConfigurationActionTypes.ENABLE_CREATE_REPORT_BUTTON;
}

interface UpdateAHFResult {
    type: ConfigurationActionTypes.UPDATE_ACTIVE_HARMONIC_FILTER_RESULT;
    payload: ActiveHarmonicFilterResultModel;
}

interface ShowAssumptionNotesModel {
    type: ConfigurationActionTypes.ADD_ASSUMPTIONNOTES_MODEL_SHOW
}

interface HideAssumptionNotesModel {
    type: ConfigurationActionTypes.ADD_ASSUMPTIONNOTES_MODEL_HIDE
}

interface AddAssumptionNotes {
    type: ConfigurationActionTypes.ADD_ASSUMPTIONNOTE
    payload: string[]
}

interface RemoveAssumptionNotes {
    type: ConfigurationActionTypes.REMOVE_ASSUMPTIONNOTE
    payload: string
}

interface UpdateAssumptionNotes {
    type: ConfigurationActionTypes.UPDATE_ASSUMPTIONNOTE
    payload: string
}

interface ShowExportConfigurationModal {
    type: ConfigurationActionTypes.EXPORT_CONFIGURATION_MODAL_SHOW;
}

interface HideExportConfigurationModal {
    type: ConfigurationActionTypes.EXPORT_CONFIGURATION_MODAL_HIDE;
}

interface ShowImportConfigurationModal {
    type: ConfigurationActionTypes.IMPORT_CONFIGURATION_MODAL_SHOW;
}

interface HideImportConfigurationModal {
    type: ConfigurationActionTypes.IMPORT_CONFIGURATION_MODAL_HIDE;
}

export type ConfigurationAction =
    ResetConfiguration
    | AddChild
    | UpdateTree
    | DeleteChild
    | CreateTree
    | ProjectUpdate
    | ConfigurationUpdate
    | ShowSystemComponentModal
    | HideSystemComponentModal
    | SelectComponent
    | SelectPCC
    | SelectedPowerSource
    | SelectUnit
    | SelectStandard
    | ShowUserTransformerModal
    | HideUserTransformerModal
    | ShowNonlinearLoadsModal
    | HideNonlinearLoadsModal
    | ShowEditPowerGridModal
    | HideEditPowerGridModal
    | ShowEditLvGensetModal
    | HideEditLvGensetModal
    | ShowActiveHarmonicFilterModal
    | HideActiveHarmonicFilterModal
    | ShowMotorControlCenterModal
    | HideMotorControlCenterModal
    | ShowLinearLoadModal
    | HideLinearLoadModal
    | DisableCreateReportButton
    | EnableCreateReportButton
    | UpdateAHFResult
    | ShowAssumptionNotesModel
    | HideAssumptionNotesModel
    | AddAssumptionNotes
    | RemoveAssumptionNotes
    | UpdateAssumptionNotes
    | ShowExportConfigurationModal
    | HideExportConfigurationModal
    | ShowImportConfigurationModal
    | HideImportConfigurationModal;



