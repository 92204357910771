import { clone } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import Input from "../../../../components/Form/Input";
import ToggleSwitch from "../../../../components/Form/ToggleSwitch";
import HarmonicIcon from "../../../../components/HarmonicIcon";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import { ActiveHarmonicFilter } from "../../models/ActiveHarmonicFilter";
import ActiveHarmonicFilterFormFields from "../../models/FormFields/ActiveHarmonicFilterFormFields";
import { UnitSymbols } from "../../models/UnitSymbols";
import ComponentValue from "../ComponentValue";
interface CardStripActiveHarmonicFilterPorps {
    data: ActiveHarmonicFilter;
    onUpdate?: (data: ActiveHarmonicFilter) => void;
}

function CardStripActiveHarmonicFilter(props: CardStripActiveHarmonicFilterPorps) {
    const { data, onUpdate } = props;
    const [cardStripState, setCardStripState] = useState<any>({ ...data });
    const idRef = useRef<any>();
    const totalRatedCurrentRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
    }
    useEffect(() => {
        setCardStripState({ ...data });
    }, [data]);

    const handleOnUpdate = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name] = detail.value;
        setCardStripState({ ...state });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === ActiveHarmonicFilterFormFields.id && !idRef.current.validate())
            return false;
        if (editFiledId === ActiveHarmonicFilterFormFields.totalRatedCurrent && !totalRatedCurrentRef.current.validate())
            return false;
        let updatedData = clone(data);

        if (editFiledId !== 'id') {
            (updatedData as any)[editFiledId] = parseFloat(cardStripState[editFiledId]);
        } else {
            (updatedData as any)[editFiledId] = cardStripState[editFiledId];
        }

        onUpdate(updatedData);
        return true;
    }

    const handleCancel = () => {
        setCardStripState({ ...data });
    }

    const handleAHFToggleSwitch = ({ detail }: any) => {
        if (detail.value === undefined)
            return;

        if (!onUpdate)
            return true;

        let updatedData = clone(data);
        updatedData.isDisable = !detail.value;
        onUpdate(updatedData);
    }

    const { t } = useTranslate();

    return (
        <>
            <HarmonicIcon className="component-icon" size="small" name="ahf" />
            <ToggleSwitch size="medium" type="primary-black" showicon checked={!data.isDisable} onToggleChange={handleAHFToggleSwitch} ></ToggleSwitch>
            <span className="body-1-regular">
                {t(I18.configuration_page_card_strip_active_harmonic_filter_title)}
            </span>
            {
                data.id &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_id_tag)}
                    value={data.id}
                    editable
                    fieldId={ActiveHarmonicFilterFormFields.id}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}>
                    <Input
                        placeholder="0"
                        inputType='text'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_id_tag_inline_edit_popup)}
                        value={cardStripState.id}
                        onInputChange={(e) => { handleOnUpdate(e, ActiveHarmonicFilterFormFields.id); }}
                        heMaxLength={10}
                        ref={idRef}>
                    </Input>
                </ComponentValue>
            }
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_current)}
                value={`${data.totalRatedCurrent} ${UnitSymbols.Current}`}
                editable
                fieldId={ActiveHarmonicFilterFormFields.totalRatedCurrent}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}>
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_rated_current, UnitSymbols.Current)}
                    suffixText={UnitSymbols.Current}
                    required
                    value={cardStripState.totalRatedCurrent}
                    onInputChange={(e) => { handleOnUpdate(e, ActiveHarmonicFilterFormFields.totalRatedCurrent); }}
                    heMinValue={1}
                    heMaxValue={1000}
                    heDecimalPrecision={1}
                    ref={totalRatedCurrentRef}>
                </Input>
            </ComponentValue>
        </>
    )
}
export default memo(CardStripActiveHarmonicFilter)