import { ReportConfigurationModel } from "../models/ReportHeaderModel";
import { EmailModel } from "../models/EmailModel";

export enum ReportActionTypes {
    REPORT_UPDATE_HEADER = "REPORT_UPDATE_HEADER",
    EMAIL_MODAL = "EMAIL_MODAL"
}

export interface UpdateHeader {
    type: ReportActionTypes.REPORT_UPDATE_HEADER;
    payload: ReportConfigurationModel;
}

export interface ShowEmailModal {
    type: ReportActionTypes.EMAIL_MODAL;
    payload: EmailModel
}


export type ReportAction = UpdateHeader | ShowEmailModal;