import { ConfigurationModalPayloadModel, DeleteModalPayloadModel, ProjectModalPayloadModel } from "../model/ModalPayloadModel";

export enum ProjectActionType {
    PROJECT_MODAL = "PROJECT_MODAL",
    DELETE_PROJECT_MODAL = "DELETE_PROJECT_MODAL",
    CONFIGURATION_MODAL = "CONFIGURATION_MODAL"
}

interface ProjectModal {
    type: ProjectActionType.PROJECT_MODAL;
    payload: ProjectModalPayloadModel
}

interface ConfigurationModal {
    type: ProjectActionType.CONFIGURATION_MODAL;
    payload: ConfigurationModalPayloadModel
}

interface DeleteProjectModal {
    type: ProjectActionType.DELETE_PROJECT_MODAL;
    payload: DeleteModalPayloadModel
}

export type ProjectAction = ProjectModal | ConfigurationModal | DeleteProjectModal;