import { User } from "oidc-client-ts";
import AuthConfig from "./AuthConfig";

//Auth manager to be used useAuth hook can not be used or if Outside the AuthProvider.
//This AuthManager is totally dependent on the session 
export class AuthManager {
    private static _storageKey: string = `oidc.user:${AuthConfig.authority}:${AuthConfig.client_id}`;

    public static getUser(): User | undefined {
        const oidcStorage = localStorage.getItem(this._storageKey);
        if (!oidcStorage) {
            return undefined;
        }
        return User.fromStorageString(oidcStorage);
    }

    public static isAuthenticated(): boolean {
        const user = this.getUser();
        return !!user && !!user.access_token && (user.expired !== undefined && !user.expired);
    }
}