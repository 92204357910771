import { cloneDeep } from "lodash";
import { generateUid } from "../../../utils/Common";
import { ConfigurationStoreModel } from "../models/ConfigurationStoreModel";
import { SystemComponent } from "../models/SystemComponent";

export const updateComponent = (state: ConfigurationStoreModel, component: SystemComponent): SystemComponent => {
    let configTree = cloneDeep(state.configurationTree);

    if (component.nodeId === configTree.nodeId) {
        //* Here allowing to update systemConponent because it's the main object. so the whole object will get new reference. 
        configTree = component;
    } else {
        configTree.childComponents?.forEach(updateChildComponents(component));
    }

    return configTree;
}

const updateChildComponents = (data: SystemComponent) => (systemComponent: SystemComponent) => {
    if (systemComponent.nodeId === data.nodeId) {
        //* updating only componentData because data could be clone and might not be referencing the same object
        //* and in this case updating systemComponent would not work. 
        //* anyway only componentData is editable rest is related to tree data. 
        systemComponent.componentData = data.componentData;
        return true;
    } else if (systemComponent.childComponents) {
        systemComponent.childComponents.some(updateChildComponents(data));
    }
}

export const addChildComponent = (state: ConfigurationStoreModel, parentNodeId: string, component: SystemComponent): SystemComponent => {
    let configTree = cloneDeep(state.configurationTree);
    addChild(parentNodeId, component)(configTree);
    return configTree;
}

const addChild = (parentNodeId: string, component: SystemComponent) => (parentComponent: SystemComponent): string => {
    let newNodeId = parentNodeId;
    if (parentComponent.nodeId === parentNodeId) {
        if (!parentComponent.childComponents)
            parentComponent.childComponents = [];

        component.nodeId = generateUid();
        parentComponent.childComponents.push(component);
        newNodeId = component.nodeId;
        return newNodeId;
    } else if (parentComponent.childComponents) {
        parentComponent.childComponents.some((childComponent) => {
            let nodeId = addChild(newNodeId, component)(childComponent);
            if (nodeId !== parentNodeId) {
                newNodeId = nodeId;
                return true;
            }
            return false;
        }
        );
    }
    return newNodeId;
}

export const deleteComponent = (state: ConfigurationStoreModel, component: SystemComponent): SystemComponent => {
    let configTree = cloneDeep(state.configurationTree);
    configTree.childComponents?.some(deleteChildComponents(component, configTree));
    return configTree;
}

const deleteChildComponents = (data: SystemComponent, parentComponent: SystemComponent) => (systemComponent: SystemComponent) => {
    if (systemComponent.nodeId === data.nodeId) {
        let childComponents = parentComponent.childComponents?.filter((item) => item.nodeId !== data.nodeId);
        parentComponent.childComponents = childComponents;
        return true;
    } else if (systemComponent.childComponents) {
        systemComponent.childComponents.some(deleteChildComponents(data, systemComponent));
    }
}

export const addSelectedAssumptionNotes = (state: ConfigurationStoreModel, payload: string[]): string[] => {
    return [...payload]
}

export const filterAssumptionNotes = (state: ConfigurationStoreModel, payload: string): string[] => {
    return (state.assumptionNotes.filter((item) => (item !== payload)))
}

export const updateAssumptionNotes = (state: ConfigurationStoreModel, payload: string): string[] => {
    return ([...state.assumptionNotes, payload])
}

