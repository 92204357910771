import React from "react";

interface ReportTextProps {
    loading: boolean;
}
function ReportText(props: ReportTextProps) {
    const { loading } = props;
    return (
        <article>
            <p className={`${loading ? 'skeleton' : ''}`}>
                Harmonics are 'electrical pollutions' caused by non-linear loads.
                Harmonic estimator tool will help you choose the right drives and
                components with harmonic mitigation that will meet the recommended standards.
            </p>
        </article>
    );
}
export default ReportText