import { useState } from 'react';
import '../styles/icons.scss';
import '../styles/narrowmenu.scss';
import Button from './Form/Button';
import Icon from './Icon';
import NarrowMenuContainer from './NarrowMenuContainer';
import { NarrowMenuItemProps } from './NarrowMenuItem';
import OutsideClickHandler from "./OutsideClickHandler";

interface NarrowMenuProps {
    className?: string;
    activeClassName?: string;
    menuItems?: NarrowMenuItemProps[];
    disabled?: boolean;
}

//Narrow Menu, menu items design is inspired from common ux menu.
function NarrowMenu(props: NarrowMenuProps) {
    const { className, activeClassName, menuItems, disabled = false } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClickMenu = () => {
        if (disabled)
            return;

        setIsOpen(!isOpen);
    }

    const handleOnClickMenuItem = (menuItem: NarrowMenuItemProps) => {
        if (menuItem.onClick)
            menuItem.onClick(menuItem.value);
        setIsOpen(!isOpen);
    }

    return (
        <OutsideClickHandler onClickOutside={() => { setIsOpen(false) }}>
            <div className={`narrow-menu ${className ? className : ''} ${isOpen && activeClassName ? activeClassName : ''}`}>
                <Button type='discreet' size='small' className={isOpen ? 'active' : ''} onClick={handleOnClickMenu} disabled={disabled} >
                    <Icon slot='icon' name="menu-narrow"></Icon>
                </Button>
                <NarrowMenuContainer menuItems={menuItems} isShow={isOpen} onClickMenuItem={handleOnClickMenuItem} ></NarrowMenuContainer>
            </div>
        </OutsideClickHandler>
    );
}

export default NarrowMenu;
