import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationPath } from '../../../utils/Navigation';
import { ResultSummaryRequestPropModel } from '../models/ResultSummaryRequestPropModel';
import ReportPages from './ReportPages'

function PrintReport(props: ResultSummaryRequestPropModel) {
    const navigate = useNavigate()
    const { projectId, configurationId } = useParams();

    useEffect(() => {
        hasReportLoaded()
        .then(() => {
            window.print();
            navigate(`/${NavigationPath.Report}/${projectId}/${configurationId}`);
        })
    });
    //this will check if the report script has been executed successfully or not.
    const hasReportLoaded = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            function checkForComponent() {
                if(document.getElementById("report-harmonic-table"))
                return resolve();
                setTimeout(checkForComponent, 300);
            }
            checkForComponent();
        })
    }
    return (
        <div id="report-page-root-container-print" className="container">
            <ReportPages resultSummaryRequest={props.resultSummaryRequest} />
        </div>
    )
}

export default PrintReport