
/** Navigation path enum */
export enum NavigationPath {
    Home = "/",
    Project = "project",
    Configuration = "configuration",
    Report = "report",
    ReportPrint = "printReport",
    Info = "info",
    VersionHistory = "versionHistory",
    Login = "login",
    Logout = "logout",
    Unauthorized = "unauthorized",
    SessionTimeOut = "sessionExpired",
    LoginCallback = "login/callback",
    NotFound="notFound",
    DownloadExcelTool="downloadExcelTool",
    Legal = "legal"
}
