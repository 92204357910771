import { CommonuxTabItem } from "@abb-ux/commonux-web-components-react";
import { JSX } from "@abb-ux/commonux-web-components/dist/types";
import { PropsWithChildren } from "react";

interface TabItemProps extends JSX.CommonuxTabItem {
  className?: string;
  id?: string;
}

const TabItem = (props: PropsWithChildren<TabItemProps>) => {
  return <CommonuxTabItem {...props}>{props.children}</CommonuxTabItem>;
};

export default TabItem;
