import { ActiveHarmonicFilter } from "./ActiveHarmonicFilter";
import { LinearLoad } from "./LinearLoad";
import { MotorControlCenter } from "./MotorControlCenter";
import { NonLinearDriveLoad } from "./NonLinearDriveLoad";

export class ConfigurationLoads {
    driveLoadList?: NonLinearDriveLoad[];
    linearLoadList?: LinearLoad[];
    filterList?: ActiveHarmonicFilter[];
    motorControlCenterList?: MotorControlCenter[];

    //this is to create map for property and it's type. 
    //created this because all the property here is array type and there is no way to get it's type.
    //* If any changes made in properties, it must be updated here as well
    private getPropertyMap(): any {
        return {
            NonLinearDriveLoad: "driveLoadList",
            LinearLoad: "linearLoadList",
            ActiveHarmonicFilter: "filterList",
            MotorControlCenter: "motorControlCenterList"
        }
    }

    /**
     * This will add data to respective array propertied of this class
     * @param load data to be pushed into array.
     */
    public pushLoad(load: any) {
        //get's in which property load to be pushed. eg driveLoadList or linearLoadList
        let propertyName = this.getPropertyMap()[load.constructor.name] as keyof ConfigurationLoads; 
        
        // if the property is blank then initialize it with default array.
        if (this[propertyName] === undefined)
            (this[propertyName] as any) = [];

        let propertyObject: any = this[propertyName];
        propertyObject.push(load);
        this[propertyName] = propertyObject;
    }
}