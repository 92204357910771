import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { hideSystemComponentModal, hideActiveHarmonicFilterModal, updateComponent, addChildComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import CommonModalFooter from './CommonModalFooter';
import { SystemComponent } from '../../models/SystemComponent';
import { ComponentType } from '../../models/ComponentType';
import { getSelectedComponent } from "../../../../utils/Common";
import { ActiveHarmonicFilter } from '../../models/ActiveHarmonicFilter';
import { cloneDeep } from 'lodash';
import HarmonicIcon from '../../../../components/HarmonicIcon';
import ActiveHarmonicFilterFormFields from '../../models/FormFields/ActiveHarmonicFilterFormFields';
import Input from '../../../../components/Form/Input';
import { UnitSymbols } from '../../models/UnitSymbols';
import ModalDialog from '../../../../components/ModalDialog';
import { I18 } from '../../../../languages/I18';
import { useTranslate } from '../../../../i18translate/Hooks';

function ActiveHarmonicFilterModalPopup() {

    const [formState, setFormState] = useState<any>({
        parentComponent: ComponentType.Transformer
    });

    const [selectedComponent, setSelectedComponent] = useState<SystemComponent>();
    const { isActiveHarmonicFilterEdit, isShowActiveHarmonicFilterModal, selectedComponentNodeId, configurationTree } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const idRef = useRef<any>();
    const totalRatedCurrentRef = useRef<any>();
    const { t } = useTranslate();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        size: "medium",
        heIsNumberOnly: true,
        heIsRequired: true,
    }

    const handleCloseActiveHarmonicModal = () => {
        dispatch(hideActiveHarmonicFilterModal());
        dispatch(hideSystemComponentModal());
    }

    const handleCancelActiveHarmonicModal = () => {
        dispatch(hideActiveHarmonicFilterModal());
    }

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };
        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(ActiveHarmonicFilterFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    useEffect(() => {
        if (isShowActiveHarmonicFilterModal) {
            if (isActiveHarmonicFilterEdit) {
                let selectedActiveHarmonicFilter = getSelectedComponent(configurationTree, selectedComponentNodeId);
                if (selectedActiveHarmonicFilter) {
                    setSelectedComponent(selectedActiveHarmonicFilter);
                    let activeHarmonicData = selectedActiveHarmonicFilter.componentData as ActiveHarmonicFilter;
                    let fields = { ...formState };
                    fields[ActiveHarmonicFilterFormFields.id] = activeHarmonicData.id;
                    fields[ActiveHarmonicFilterFormFields.totalRatedCurrent] = activeHarmonicData.totalRatedCurrent;
                    if (activeHarmonicData) {
                        setFormState({
                            ...fields
                        });
                    }
                }
            }
            else {
                let fields = { ...formState };
                fields[ActiveHarmonicFilterFormFields.id] = '';
                fields[ActiveHarmonicFilterFormFields.totalRatedCurrent] = '';
                setFormState({
                    ...fields
                });
            }
        }
        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowActiveHarmonicFilterModal, isActiveHarmonicFilterEdit, configurationTree, selectedComponentNodeId])

    const handleAdd = () => {
        if (!validateForm())
            return;
        let activeHarmonicData: ActiveHarmonicFilter = new ActiveHarmonicFilter(
            formState[ActiveHarmonicFilterFormFields.id],
            formState[ActiveHarmonicFilterFormFields.totalRatedCurrent]
        )
        let activefilter: SystemComponent = {
            componentType: ComponentType.ActiveHarmonicFilter,
            componentData: activeHarmonicData
        };
        if (selectedComponentNodeId) {
            dispatch(addChildComponent(selectedComponentNodeId, activefilter));
        }
        dispatch(hideActiveHarmonicFilterModal());
        dispatch(hideSystemComponentModal());
    }

    const handleUpdate = () => {
        if (!validateForm())
            return;
        if (selectedComponent) {
            let component = cloneDeep(selectedComponent);
            let activeHarmonicData = component.componentData as ActiveHarmonicFilter;
            if (activeHarmonicData) {
                activeHarmonicData.id = formState[ActiveHarmonicFilterFormFields.id];
                activeHarmonicData.totalRatedCurrent = parseFloat(formState[ActiveHarmonicFilterFormFields.totalRatedCurrent]);
                dispatch(updateComponent(component));
            }
        }
        dispatch(hideActiveHarmonicFilterModal());
    }

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = idRef.current.validate()
            & totalRatedCurrentRef.current.validate()
        return !!isValid;
    }

    return (
        <ModalDialog
            open={isShowActiveHarmonicFilterModal}
            onModalClose={handleCloseActiveHarmonicModal}
            showBackButton={true}
            onBack={handleCancelActiveHarmonicModal}
            modalTitle={isActiveHarmonicFilterEdit ? t(I18.configuration_page_active_harmonic_filter_modal_popup_edit_title) : t(I18.configuration_page_active_harmonic_filter_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup">
            <div slot="title-icon">
                <HarmonicIcon size="small" name="ahf" />
            </div>
            <div className="modal-content">
                <Input
                    className="input-style first-child"
                    size="medium"
                    labelText={t(I18.configuration_page_label_id_tag)}
                    optionalText={t(I18.configuration_page_optional_text_id_tag)}
                    value={formState[ActiveHarmonicFilterFormFields.id]}
                    onInputChange={(e) => { handleChange(e, "id"); }}
                    heMaxLength={10}
                    ref={idRef} >
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_total_rated_current)}
                    optionalText={t(I18.configuration_page_optional_text_total_rated_current, UnitSymbols.Current)}
                    suffixText={UnitSymbols.Current}
                    size="medium"
                    value={formState[ActiveHarmonicFilterFormFields.totalRatedCurrent]}
                    onInputChange={(e) => { handleChange(e, "totalRatedCurrent"); }}
                    heMinValue={1}
                    heMaxValue={1000}
                    heDecimalPrecision={1}
                    ref={totalRatedCurrentRef}>
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={isActiveHarmonicFilterEdit}
                    onCancelClick={handleCancelActiveHarmonicModal}
                    onActionButtonClick={handleAdd}
                    onUpdateClick={handleUpdate} />
            </div>
        </ModalDialog>
    )
}
export default ActiveHarmonicFilterModalPopup;