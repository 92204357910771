import { useGetVersionHistoryQuery } from "../services/VersionLicenseAndDiscliamerService";
import ReactMarkdown from 'react-markdown';

const VersionHistorycard = () => {
  const { data: versionHistoryData, isLoading: dataIsLoading, isSuccess: dataIsSuccess } = useGetVersionHistoryQuery();

  return (
    <div className={`${dataIsLoading ? 'skeleton' : "info-card"}`}>
      {
        dataIsSuccess &&
        <ReactMarkdown children={versionHistoryData.value} />
      }
    </div>
  );
};

export default VersionHistorycard;
