import { ReportConfigurationModel } from "../models/ReportHeaderModel";
import { ReportActionTypes, UpdateHeader } from "./actionTypes";

export const updatetReportHeader = (reportHeader: ReportConfigurationModel): UpdateHeader => {
    return {
        type: ReportActionTypes.REPORT_UPDATE_HEADER,
        payload: reportHeader
    };
}

export const showEmailModal = () => {
    return {
        type: ReportActionTypes.EMAIL_MODAL,
        payload: {
            modalState: true
        }
    }
}

export const hideEmailModal = () => {
    return {
        type: ReportActionTypes.EMAIL_MODAL,
        payload: {
            modalState: false
        }
    }
}