import { Dispatch, memo } from "react";
import { useDispatch } from "react-redux";
import Radio from "../../../../components/Form/Radio";
import { SystemComponent } from "../../models/SystemComponent";
import { selectPCC } from "../../store/actions";
import { ConfigurationAction } from "../../store/actionTypes";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";

interface PCCProps {
    id?: string;
    systemComponent: SystemComponent;
    isPCCSelected: boolean;
}

function PCCSelectComponent(props: React.PropsWithChildren<PCCProps>) {

    const { id, isPCCSelected } = props;
    const {t} = useTranslate();
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();

    const handlePCCChange = () => {
        const pccNodeId: string = id ? id : "";
        dispatch(selectPCC(pccNodeId));
    }
    return (
        <>
            <div className={`pcc ${isPCCSelected ? 'selected' : ''}`} onClick={handlePCCChange}>
                <span className="body-1-regular">{t(I18.configuration_page_pcc_label)}</span>
                <Radio
                    size="small"
                    type="primary-red"
                    name="radioPCC"
                    value={id} >
                </Radio>
            </div>
        </>

    )
}
export default memo(PCCSelectComponent);



