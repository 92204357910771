import SetTitle from '../../components/SetTitle';
import Banner from './components/Banner';
import Card from './components/Card';
import './styles/style.scss';
import { useGetArticalsQuery, useGetCaseStudyQuery } from './services/ArticalAndCaseStudyService';
import { useTranslate } from '../../i18translate/Hooks';
import { I18 } from '../../languages/I18';

function Home() {
    const { data: articalData, isLoading: articalIsLoading, isSuccess: articalIsSuccess, isError: articalIsError } = useGetArticalsQuery(undefined);
    const { data: caseStudyData, isLoading: caseStudyIsLoading, isSuccess: caseStudyIsSuccess, isError: caseStudyIsError } = useGetCaseStudyQuery(undefined);
    const { t } = useTranslate();
    const image = { id: '', contentType: '', fileName: 'pulp-and-paper-drives-header.png', url: 'pulp-and-paper-drives-header.png' };
    const description = 'Lorem ipsum dolor sit amet, consectetur adip is cing elit dolor sit. Lorem ipsum dolor sit amet, consectetur adip is cing elit dolor.'

    return (
        <>
            <SetTitle title='Home' />
            <Banner />
            <div className='container'>
                <section className='articles'>
                    <h5>{t(I18.home_title_article_tools)}</h5>
                    <div className='tools-cards'>
                        {
                            articalIsSuccess && (
                                articalData?.map((articals) => <Card key={articals.id} type={articals.type} title={articals.title} link={articals.link} description={articals.description} ></Card>))
                        }
                        {
                            (articalIsLoading || articalIsError) &&
                            Array.from({ length: 3 }, (_, i) => i++).map(item =>
                                <Card key={item} type='Article' title='Long Cable' link='' description={description} isLoading={true} />)
                        }
                    </div>
                    <h5>{t(I18.home_title_case_studies)}</h5>
                    <div className='case-study-cards'>
                        {
                            caseStudyIsSuccess &&
                            (caseStudyData?.map((caseStudy, index) => <Card key={caseStudy.id} type={caseStudy.type} title={caseStudy.title} link={caseStudy.link} description={caseStudy.description} imageMedia={caseStudy.imageMedia}></Card>))
                        }
                        {
                            (caseStudyIsLoading || caseStudyIsError) &&
                            Array.from({ length: 4 }, (_, i) => i++).map(item =>
                                <Card key={item} type='CASE' title='Cable length' link='' description={description} imageMedia={image} isLoading={true} />)
                        }
                    </div>
                </section>
            </div>
        </>
    )
}

export default Home
