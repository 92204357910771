import { clone } from "lodash";
import { getFormatedComponentData } from "../utils/SystemComponentUtils";
import { ActiveHarmonicFilter } from "./ActiveHarmonicFilter";
import { ComponentType } from "./ComponentType";
import { ConfigurationLoads } from "./ConfigurationLoads";
import { ConfigurationStrategy } from "./ConfigurationStrategy";
import { LinearLoad } from "./LinearLoad";
import { MotorControlCenter } from "./MotorControlCenter";
import { NonLinearDriveLoad } from "./NonLinearDriveLoad";
import { LVGenset } from "./PowerSource";
import { SystemComponent } from "./SystemComponent";
import { Transformer } from "./Transfomer";

export class Load implements ConfigurationStrategy {
    public load: ConfigurationLoads;

    public constructor() {
        this.load = new ConfigurationLoads()
    }

    //this method will implemented by child classes
    getComponentData(): LVGenset | Transformer | MotorControlCenter {
        throw new Error("Method not implemented.");
    }

    addChildComponent(load: any): void {
        this.load.pushLoad(load);
    }

    getChildComponents(): SystemComponent[] | undefined {
        if (!this.load)
            return undefined;

        let childComponents: SystemComponent[] = [];

        let tempLoad = new ConfigurationLoads();

        if (typeof this.load.pushLoad !== 'function') {
            Object.assign(tempLoad, this.load)
            this.load = clone(tempLoad);
        }
        else
            tempLoad = clone(this.load);

        if (tempLoad.driveLoadList && tempLoad.driveLoadList.length > 0)
            tempLoad.driveLoadList.forEach((driveLoad) => {
                childComponents.push(getFormatedComponentData(ComponentType.NonLinearDriveLoad, Object.assign(new NonLinearDriveLoad(), driveLoad)));
            })

        if (tempLoad.linearLoadList && tempLoad.linearLoadList.length > 0)
            tempLoad.linearLoadList.forEach((linearLoad) => {
                childComponents.push(getFormatedComponentData(ComponentType.LinearLoad, Object.assign(new LinearLoad(), linearLoad)));
            })

        if (tempLoad.filterList && tempLoad.filterList.length > 0)
            tempLoad.filterList.forEach((ahf) => {
                childComponents.push(getFormatedComponentData(ComponentType.ActiveHarmonicFilter, Object.assign(new ActiveHarmonicFilter(), ahf)));
            })

        if (tempLoad.motorControlCenterList && tempLoad.motorControlCenterList.length > 0)
            tempLoad.motorControlCenterList.forEach((mcc) => {
                childComponents.push(getFormatedComponentData(ComponentType.ActiveHarmonicFilter, Object.assign(new ActiveHarmonicFilter(), mcc.getComponentData(), mcc.getChildComponents())));
            })

        return childComponents;
    }
}