import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ContentfulAPIModel } from "../models/contentfulAPIModel";

const baseUrlEnv = process.env.REACT_APP_API_URL;
const baseUrl = `${baseUrlEnv}/`;

export const VersionLicenseAndDiscliamerAPI = createApi({
    reducerPath: 'versionLicenseAndDiscliamer',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getVersionHistory: builder.query<ContentfulAPIModel, void>({
            query: () => `versionhistory`
        }),
        getSoftwareLicenses: builder.query<ContentfulAPIModel, void>({
            query: () => `softwarelicenses`
        }),
        getDisclaimer: builder.query<ContentfulAPIModel, void>({
            query: () => `disclaimer`
        })
    })
});

export const { useGetVersionHistoryQuery, useGetSoftwareLicensesQuery, useGetDisclaimerQuery } = VersionLicenseAndDiscliamerAPI;
