import { getIconMapSVG } from '../utils/iconNames';
import '../styles/harmonicicon.scss';

interface HarmonicIconProps {
    name: string,
    size: "small" | "medium" | "large",
    className?: string;
}

function HarmonicIcon(props: HarmonicIconProps) {
    const { name, size, className = "" } = props;
    const iconFullName = getIconMapSVG(name);
    const classNotFound = iconFullName === undefined ? "not-found" : '';
    return (
        <div className={`${className} harmonic-icon`}>
            <span className={`${size} ${classNotFound}`}>
                <img src={require(`../assets/icons/${iconFullName}.svg`)} alt="NA" />
            </span>
        </div>
    )
}

export default HarmonicIcon