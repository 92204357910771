import { PropsWithChildren } from 'react';
import { CommonuxIcon } from '@abb-ux/commonux-web-components-react';
import { JSX } from '@abb-ux/commonux-web-components/dist/types';
import { getIconName } from '../utils/iconNames';

interface IconProps extends JSX.CommonuxIcon {
    slot?: string;
    className?: string;
    //make it false if wanted to used the specified icon name
    formatIconName?: boolean;
}

function Icon(props: PropsWithChildren<IconProps>) {
    const { size = "small", name, formatIconName = true } = props;
    const iconNameInFormat = getIconName(name, size, formatIconName)
    return (
        <CommonuxIcon {...props} name={iconNameInFormat} >
            {props.children}
        </CommonuxIcon>
    )
}

export default Icon