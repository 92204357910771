import '../styles/login.scss';
import { useAuth } from 'react-oidc-context';
import { NavigationPath } from '../utils/Navigation';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslate } from '../i18translate/Hooks';
import { I18 } from '../languages/I18';

function SessionTimeOut() {
    const auth = useAuth();
    const { t } = useTranslate();

    useEffect(() => {
        if (auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator)
            auth.removeUser();
    }, [auth.isAuthenticated, auth.isLoading, auth.activeNavigator, auth])

    if (auth.error) {
        console.error("Session Timeout Error: ", auth.error?.message)
        return <Navigate to={NavigationPath.Home} />
    }

    if (auth.isAuthenticated && !auth.isLoading)
        return <Navigate to={`/${NavigationPath.Logout}`} />

    return (
        <div className='container'>
            <div className='login'>
                <h4 className='title abb-cursor'>{t(I18.login_session_timeout_title)}</h4>
                <span className='description' dangerouslySetInnerHTML={{__html: t(I18.login_session_timeout_message)}}></span>
            </div>
        </div>
    )
}

export default SessionTimeOut