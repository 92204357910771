import { Translate } from "./translate/Translate";
import { TranslationAction } from "./models/TranslationAction";
import { TranslationState } from "./models/TranslationState";

//Reducers
export function translateReducer(state: TranslationState, action: TranslationAction): TranslationState {
    const initTranslate = Translate.getInstance();
    switch (action.type) {
        case "CHANGE_LANGUAGE": {
            initTranslate.changeCurrentLangugage(action.language, action.languageJson);
            return { ...state, language: action.language, languageJson: action.languageJson }
        }

        default:
            throw new Error(`Unhandled action: ${action.type}`);
    }
}