import { PropsWithChildren } from 'react';
import { CommonuxModal } from '@abb-ux/commonux-web-components-react';
import { JSX } from '@abb-ux/commonux-web-components/dist/types';

interface ModalDialogProps extends JSX.CommonuxModal {
    slot?: string;
    className?: string;
    type?: "default" | "discreet";
    onBack?: (event: any) => void;
}

function ModalDialog(props: PropsWithChildren<ModalDialogProps>) {
    const { onBack, type = "default" } = props;

    const handleModalBack = (event: CustomEvent<any>) => {
        if (!onBack)
            return;

        onBack(event);
    }

    return (
        <CommonuxModal {...props} onModalBack={handleModalBack} type={type} >
            {props.children}
        </CommonuxModal>
    )
}

export default ModalDialog