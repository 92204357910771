import '../styles/tooltip.scss';

interface TooltipProps {
    label: string;
    children?: React.ReactNode;
    position?: "top" | "bottom" | "right" | "left";

}

function ToolTip(props: TooltipProps) {
    const { label, children, position = "top" } = props;

    return (
        <div>
            <div className="tooltip" > {children}
                <div className={`tooltiptext ${position}`}>
                    {label}
                    <div className='triangle'></div>
                </div>
            </div>
        </div>
    );
}
export default ToolTip;
