import '../styles/icons.scss';
import '../styles/narrowmenu.scss';
import NarrowMenuItem, { NarrowMenuItemProps } from './NarrowMenuItem';

interface NarrowMenuContainerProps {
    isShow: boolean;
    menuItems?: NarrowMenuItemProps[];
    onClickMenuItem?: (menuItem: NarrowMenuItemProps) => void; 
}

//Narrow Menu, container, menu items design is inspired from common ux menu.
function NarrowMenuContainer(props: NarrowMenuContainerProps) {
    const { menuItems, isShow, onClickMenuItem } = props;
    
    const handleOnClickMenuItem = (menuItem: NarrowMenuItemProps) => {
        if (onClickMenuItem)
            onClickMenuItem(menuItem);
    }

    return (
        <div className={`menu-container ${isShow ? 'show' : ''}`}>
            <ul className='content'>
                {menuItems && menuItems.map(
                    (item) => (
                        <NarrowMenuItem key={item.value} value={item.value} label={item.label} onClick={() => handleOnClickMenuItem(item)} />
                    ))}
            </ul>
            <div className='triangle'></div>
        </div>
    );
}

export default NarrowMenuContainer;
