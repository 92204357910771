import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/Form/Button";
import Input from "../../../components/Form/Input";
import Icon from "../../../components/Icon";
import LabelSeparator from "../../../components/LabelSeparator";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import { useAppSelector } from "../../../store/hooks";
import { isStringEmpty } from "../../../utils/Common";
import {
  removeAssumptionNote,
  showAddAssumptionnoteslModal,
  updateAssumptionNote,
} from "../store/actions";
import { ConfigurationAction } from "../store/actionTypes";
import "../styles/assumptionnotes.scss";

const AssumptionNotesCard = () => {
  const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
  const handleShowAssumptionNotePopup = () => {
    dispatch(showAddAssumptionnoteslModal());
  };
  const { assumptionNotes } = useAppSelector(
    (storeState) => storeState.configuration
  );
  const deleteHandler = (
    item: string,
    e: React.MouseEvent<any, MouseEvent>
  ) => {
    dispatch(removeAssumptionNote(item));
  };
  const [customNotes, setCustomNotes] = useState<string>("");
  const [isAddCutomNotesEnabled, setIsAddCutomNotesEnabled] = useState<boolean>(false);

  useEffect(() => {
    setIsAddCutomNotesEnabled(!isStringEmpty(customNotes)  && !assumptionNotes.includes(customNotes))
  }, [customNotes])

  const handleInput = (e: any) => {
    setCustomNotes(e.detail.value);
  };

  const addHandler = () => {
    dispatch(updateAssumptionNote(customNotes));
    setCustomNotes("");
  };

  const cancelHandler = () => {
    setCustomNotes("");
  };

  const { t } = useTranslate()

  return (
    <div className="card assumption-notes-card">
      <div className="assumption-notes">
        <div className="select-note">
          <label className="assumption-notes-label">
            {t(I18.configuration_page_assumption_notes_card_title)}
          </label>
          <Button
            size="small"
            type="secondary"
            className="select-list-button"
            onClick={handleShowAssumptionNotePopup}
          >
            <Icon name="plus" size="small" slot="icon"></Icon>
            {t(I18.configuration_page_assumption_notes_card_select_from_the_list_button_text)}
          </Button>
        </div>
        <LabelSeparator separatorText="or" />
        <Input
          size="xlarge"
          labelText={t(I18.configuration_page_assumption_notes_card_label)}
          placeholder={t(I18.configuration_page_assumption_notes_card_placeholder)}
          optionalText={t(I18.configuration_page_assumption_notes_card_optional_text)}
          value={customNotes}
          onInputInput={handleInput}
        ></Input>
        <div className="button-section">
          <Button type="tertiary" size="small" onClick={cancelHandler}>
            {t(I18.cancel)}
          </Button>
          <Button type="primary-black" size="small" onClick={addHandler} disabled={!isAddCutomNotesEnabled}>
            {t(I18.add)}
          </Button>
        </div>
        {assumptionNotes.length >= 1 && (
          <div className="slected-notes">
            {assumptionNotes.map((item) => (
              <div className="list" key={item}>
                <label>{item}</label>
                <Button
                  className="delete-button"
                  size="xsmall"
                  type="discreet"
                  onClick={(e) => deleteHandler(item, e)}
                >
                  <Icon name="close" size="small"></Icon>
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssumptionNotesCard;
