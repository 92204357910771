import { HomeStoreModel } from "../models/HomeStoreModel";
import {HomeAction, HomeActionType} from './actionTypes'

export const initialState: HomeStoreModel = {
    isShowOptionModal: false,
    articles: []
}

const homeReducer = (state: HomeStoreModel | undefined, action: HomeAction): HomeStoreModel => {
    state = state ? state : initialState;
    switch (action.type) {
        case HomeActionType.HOME_OPTION_MODAL_SHOW:
            return {
                ...state,
                isShowOptionModal: true
            };
        case HomeActionType.HOME_OPTION_MODAL_HIDE:
            return {
                ...state,
                isShowOptionModal: false
            };

        default:
            return state;
    }
}

export default homeReducer;