import { CommonuxTabGroup } from "@abb-ux/commonux-web-components-react";
import { JSX } from "@abb-ux/commonux-web-components/dist/types";
import { PropsWithChildren } from "react";

interface TabGroupProps extends JSX.CommonuxTabGroup {
  className?: string;
  id?: string;
}

const TabGroup = (props: PropsWithChildren<TabGroupProps>) => {
  return <CommonuxTabGroup {...props}>{props.children}</CommonuxTabGroup>;
};

export default TabGroup;
