import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { hideEditPowerGridModal, updateComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import { getSelectedComponent, getSelectOptionArray } from "../../../../utils/Common";
import '../../styles/editpowergridmodalstyle.scss';
import { PowerGridFrequencyOptions, PowerGrid } from "../../models/PowerSource";
import { SystemComponent } from '../../models/SystemComponent';
import { cloneDeep } from 'lodash';
import CommonModalFooter from './CommonModalFooter';
import HarmonicIcon from '../../../../components/HarmonicIcon';
import PowerGridFormFields, { powergridRadioOptions } from '../../models/FormFields/PowerGridFormFields';
import Input from '../../../../components/Form/Input';
import { UnitSymbols } from '../../models/UnitSymbols';
import Select from '../../../../components/Form/Select';
import Radio from '../../../../components/Form/Radio';
import RadioGroup from '../../../../components/Form/RadioGroup';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import ModalDialog from '../../../../components/ModalDialog';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

function PowerGridModalPopup() {
    const [formState, setFormState] = useState<any>({});
    const [selectedComponent, setSelectedComponent] = useState<SystemComponent>();
    const { isShowEditPowerGridModal, selectedComponentNodeId, configurationTree } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const ratedVoltageRef = useRef<any>();
    const mvascRef = useRef<any>();
    const iscRef = useRef<any>();
    const feederRRef = useRef<any>();
    const feederXRef = useRef<any>();

    const { t } = useTranslate();

    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "medium",
        heIsNumberOnly: true,
        heIsRequired: true,
        required: true,
    }

    const handleCloseEditPowerGridModal = () => {
        dispatch(hideEditPowerGridModal());
    }

    useEffect(() => {
        if (isShowEditPowerGridModal) {
            let selectedPowergrid = getSelectedComponent(configurationTree, selectedComponentNodeId);
            if (selectedPowergrid) {
                setSelectedComponent(selectedPowergrid);
                let gridData = selectedPowergrid.componentData as PowerGrid;
                let fields = { ...formState };
                fields[PowerGridFormFields.powergridRadioSelection] = gridData.powergridRadioSelection ? gridData.powergridRadioSelection : powergridRadioOptions.mvasc;
                fields[PowerGridFormFields.mvasc] = gridData.mvasc ? gridData.mvasc.toString() : "400";
                fields[PowerGridFormFields.frequency] = gridData.frequency.toString();
                fields[PowerGridFormFields.ratedVoltage] = gridData.ratedVoltage.toString();
                fields[PowerGridFormFields.isc] = gridData.isc ? gridData.isc.toString() : 20000;
                fields[PowerGridFormFields.resistance] = gridData.resistance ? gridData.resistance.toString() : 0.05;
                fields[PowerGridFormFields.inductance] = gridData.inductance ? gridData.inductance.toString() : 0.5;

                if (gridData) {
                    setFormState({
                        ...fields
                    });
                }
            }
        }
        else {
            let fields = { ...formState };
            fields[PowerGridFormFields.isc] = 20000;
            fields[PowerGridFormFields.resistance] = 0.05;
            fields[PowerGridFormFields.inductance] = 0.5;
            setFormState({
                ...fields
            });
        }
        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowEditPowerGridModal, configurationTree, selectedComponentNodeId])

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };

        //added this because select is trigerring change event before it is fully rendered
        if (!fields.hasOwnProperty(name))
            return;

        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const handleUpdate = () => {
        if (validateForm() && selectedComponent) {
            let component = cloneDeep(selectedComponent);
            let gridData = component.componentData as PowerGrid;
            if (gridData) {
                gridData.frequency = parseInt(formState[PowerGridFormFields.frequency]);
                gridData.ratedVoltage = parseFloat(formState[PowerGridFormFields.ratedVoltage]);

                gridData.powergridRadioSelection = formState[PowerGridFormFields.powergridRadioSelection];
                gridData.mvasc = formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.mvasc ?
                    parseFloat(formState[PowerGridFormFields.mvasc]) : undefined;
                gridData.isc = formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.isc ?
                    parseFloat(formState[PowerGridFormFields.isc]) : undefined;
                gridData.resistance = formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.feederrandx ?
                    parseFloat(formState[PowerGridFormFields.resistance]) : undefined;
                gridData.inductance = formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.feederrandx ?
                    parseFloat(formState[PowerGridFormFields.inductance]) : undefined;

                dispatch(updateComponent(component));
            }
            dispatch(hideEditPowerGridModal());
        }
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(PowerGridFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = ratedVoltageRef.current.validate();

        switch (formState[PowerGridFormFields.powergridRadioSelection]) {
            case powergridRadioOptions.mvasc:
                isValid = isValid & mvascRef.current.validate()
                break;
            case powergridRadioOptions.isc:
                isValid = isValid & iscRef.current.validate()
                break;
            case powergridRadioOptions.feederrandx:
                isValid = isValid & feederRRef.current.validate()
                isValid = isValid & feederXRef.current.validate()
                break;
        }

        return !!isValid;
    }

    return (
        <ModalDialog
            open={isShowEditPowerGridModal}
            onModalClose={handleCloseEditPowerGridModal}
            showBackButton={true}
            onBack={handleCloseEditPowerGridModal}
            modalTitle={t(I18.configuration_page_powergrid_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup">
            <div slot="title-icon">
                <HarmonicIcon size="small" name="power-grid" />
            </div>
            <div className="modal-content">
                <SelectContainer
                    className="select-style first-child"
                    required
                    size="medium"
                    labelText={t(I18.configuration_page_label_frequency)}
                    optionalText={t(I18.configuration_page_optional_text_frequency, UnitSymbols.Frequency)}
                    suffixText={UnitSymbols.Frequency}
                >
                    <Select position="main" slot="main"
                        value={formState[PowerGridFormFields.frequency]}
                        optionText={formState[PowerGridFormFields.frequency]}
                        onSelectChange={(e) => { handleChange(e, PowerGridFormFields.frequency); }}>
                        <div slot="options-main">
                            {getSelectOptionArray(PowerGridFrequencyOptions).map((item) =>
                                <SelectOption
                                    key={item.value.key}
                                    data-value={item.value.key}
                                    selected={formState[PowerGridFormFields.frequency] && item.value.key.toString() === formState[PowerGridFormFields.frequency]}
                                    optionText={item.value.value} >
                                </SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_voltage)}
                    optionalText={t(I18.configuration_page_optional_text_voltage, UnitSymbols.VoltagekV)}
                    size="medium"
                    suffixText={UnitSymbols.VoltagekV}
                    value={formState[PowerGridFormFields.ratedVoltage]}
                    onInputChange={(e) => { handleChange(e, PowerGridFormFields.ratedVoltage); }}
                    heMinValue={0.1}
                    heMaxValue={500}
                    heDecimalPrecision={2}
                    ref={ratedVoltageRef}>
                </Input>
                <div className="label-style">{t(I18.configuration_page_select_one_of_the_following_text)}</div>
                <div className="radiobtn-style" >
                    <RadioGroup onRadioChange={(e) => { handleChange(e, PowerGridFormFields.powergridRadioSelection); }} value={formState[PowerGridFormFields.powergridRadioSelection]}>
                        <Radio size="small" name="radioPowergridRadioSelection" id="MVAsc" value={powergridRadioOptions.mvasc}>
                            <span slot='label'>
                                {t(I18.configuration_page_label_mvasc)}
                            </span>
                        </Radio>
                        <Radio size="small" name="radioPowergridRadioSelection" id="Isc" value={powergridRadioOptions.isc}>
                            <span slot='label'>
                                {t(I18.configuration_page_label_isc)}
                            </span>
                        </Radio>
                        <Radio size="small" name="radioPowergridRadioSelection" id="FeederRandX" value={powergridRadioOptions.feederrandx}>
                            <span slot='label'>
                                {t(I18.configuration_page_label_feeder_r_and_x)}
                            </span>
                        </Radio>
                    </RadioGroup>
                </div>
                {formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.mvasc &&
                    <div>
                        <Input
                            {...commonInputProps}
                            labelText={t(I18.configuration_page_label_mvasc)}
                            optionalText={t(I18.configuration_page_optional_text_mvasc)}
                            value={formState[PowerGridFormFields.mvasc]}
                            onInputChange={(e) => { handleChange(e, PowerGridFormFields.mvasc); }}
                            heDecimalPrecision={3}
                            heMinValue={0.001}
                            heMaxValue={1000}
                            ref={mvascRef}>
                        </Input>
                    </div>
                }
                {formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.isc &&
                    <div>
                        <Input
                            {...commonInputProps}
                            labelText={t(I18.configuration_page_label_isc)}
                            optionalText={t(I18.configuration_page_optional_text_isc, UnitSymbols.Current)}
                            value={formState[PowerGridFormFields.isc]}
                            onInputChange={(e) => { handleChange(e, PowerGridFormFields.isc); }}
                            suffixText='A'
                            heDecimalPrecision={0}
                            heMinValue={10}
                            heMaxValue={100000}
                            ref={iscRef}>
                        </Input>
                    </div>
                }
                {formState[PowerGridFormFields.powergridRadioSelection] === powergridRadioOptions.feederrandx &&
                    <div className="feederRandX-style" >
                        <Input
                            {...commonInputProps}
                            labelText={t(I18.configuration_page_label_feeder_r)}
                            optionalText={t(I18.configuration_page_optional_text_feeder_r, UnitSymbols.ResistanceOhm)}
                            value={formState[PowerGridFormFields.resistance]}
                            onInputChange={(e) => { handleChange(e, PowerGridFormFields.resistance); }}
                            suffixText='Ω'
                            heDecimalPrecision={4}
                            heMinValue={0.0001}
                            heMaxValue={10000}
                            ref={feederRRef}>
                        </Input>
                        <Input {...commonInputProps}
                            labelText={t(I18.configuration_page_label_feeder_x)}
                            optionalText={t(I18.configuration_page_optional_text_feeder_x, UnitSymbols.ResistanceOhm)}
                            value={formState[PowerGridFormFields.inductance]}
                            onInputChange={(e) => { handleChange(e, PowerGridFormFields.inductance); }}
                            suffixText='Ω'
                            heDecimalPrecision={4}
                            heMinValue={0.0001}
                            heMaxValue={10000}
                            ref={feederXRef}>
                        </Input>
                    </div>
                }
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={true}
                    onCancelClick={handleCloseEditPowerGridModal}
                    onActionButtonClick={handleUpdate}
                    onUpdateClick={handleUpdate} />
            </div>
        </ModalDialog>
    )
}
export default PowerGridModalPopup;
