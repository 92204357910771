/**
 * Get full name of the scg icon 
 * @param iconName name of the icon without any prefix or suffix
 * @param iconSize size of the icon small for 16, medium for 24 and large for 32
 * @returns return full name of the svg icon with prefix and suffix
 */
export function getIconMapSVG(iconName: string, iconSize: "small" | "medium" | "large" = "small"): string {
    const sizeMap: { key: string, value: string }[] = [
        { key: "small", value: "16" },
        { key: "medium", value: "24" },
        { key: "large", value: "32" },
    ]
    const size = sizeMap.find(item => item.key === iconSize)?.value;
    return `abb_he_${iconName}_${size}`
}
/**
 * 
 * @param name name of the icon without any prefix or suffix
 * @param size size of the icon small for 16, medium for 24 and large for 32 pixels
 * @param formatIconName if name is already in correct format value is fasle 
 * @returns returns the icon name in <icon_name>-<size_in_px> format
 */
export const getIconName = (name: string | undefined, size: string | undefined, formatIconName: boolean): string | undefined => {
    if (!name || !formatIconName)
        return name;

    switch (size) {
        case "small":
            return `${name}-16`;
        case "medium":
            return `${name}-24`;
        case "large":
            return `${name}-32`;
        default:
            return name;
    }
}