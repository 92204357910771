import { useNavigate } from "react-router-dom";
import { isStringEmpty } from "../utils/Common";
import Button from "./Form/Button";
import Icon from "./Icon";

interface WindowBackNavigationProps {
  navigateTo?: string;
}

const WindowBackNavigation = (props: WindowBackNavigationProps) => {
  const { navigateTo } = props
  const navigate = useNavigate()
  const handleBackButtonClick = () => {
    if (!isStringEmpty(navigateTo)){
      navigate(navigateTo ?? "");
      return;
    }

    window.history.back();
  }
  return (
    <Button size='small' type='discreet' onClick={handleBackButtonClick}>
      <Icon slot='icon' size='small' name='left' />
    </Button>
  );
};

export default WindowBackNavigation;
