import { CommonuxBrandplate } from '@abb-ux/commonux-web-components-react';
import { JSX } from '@abb-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface BrandPlateProps extends JSX.CommonuxBrandplate {
    className?: string;
    slot?: string;
}

function BrandPlate(props: PropsWithChildren<BrandPlateProps>) {
    return (
        <CommonuxBrandplate {...props}>
            {props.children}
        </CommonuxBrandplate>
    )
}

export default BrandPlate