import { forIn } from "lodash";
import { SelectOptionKeyValuePair } from "../models/SelectOptionKeyValuePair";
import { SystemComponent } from '../pages/Configuration/models/SystemComponent';
import { getComponentByNodeId } from "../pages/Configuration/utils/SystemComponentUtils";
import { getCountryForTimezone } from 'countries-and-timezones';

/**
 * 
 * @param input Number to be rounded off
 * @param fractionDigits Number of digit after decimal point
 * @returns Returns a string representing a number in fixed-point notation
 */
export function roundOff(value: number, fractionDigits: number = 0): string {
    let result = value.toString();
    if (fractionDigits === 0) {
        if ((Math.abs(value) > 0) && (Math.abs(value) < 1)) {
            // 3 decimals
            result = value.toFixed(3);
        }
        else if ((Math.abs(value) >= 1) && (Math.abs(value) < 10)) {
            // 2 decimals
            result = value.toFixed(2);
        }
        else if ((Math.abs(value) >= 10) && (Math.abs(value) < 100)) {
            // 1 decimals
            result = value.toFixed(1);
        }
        else if (Math.abs(value) >= 100) {
            // 0 decimals
            result = value.toFixed(0);
        }
    }
    else {
        result = value.toFixed(fractionDigits);
    }
    return result;
}


/**
 * Return rounded value upto 2 decimal places only if decimal exist
 * @param value to be rounded off 
 * @returns rounded value
 */
export function roundOffTo2Decimal(value: number): number {
    return roundOffByDecimalPrecision(value, 2);
}

/**
 * Return rounded value upto specified decimal places only if decimal exist
 * @param value to be rounded off 
 * @param decimalPrecision decimal places for rounding
 * @returns rounded value.
 */
export function roundOffByDecimalPrecision(value: number, decimalPrecision: number) {
    const roundingConstant = Math.pow(10, decimalPrecision);
    return Math.round((value + Number.EPSILON) * roundingConstant) / roundingConstant;
}

/**
 * 
 * @param input Input string to be checked.
 * @returns If string is empty return true else false  
 */
export function isStringEmpty(input?: string): boolean {
    return !input || input.length === 0;
}

/**
 * This function will convert select options object to key value pair  
 * @param selectOptionObject select options to be converted to array
 * @returns Array of key value pair object.
 */
export function getSelectOptionArray(selectOptionObject: any): SelectOptionKeyValuePair[] {
    let keyValuePairs: SelectOptionKeyValuePair[] = [];
    forIn(selectOptionObject, (value, key) => keyValuePairs.push({
        key,
        value
    }));
    return keyValuePairs;
}

/**
 * This function with get enum if query matches either key or value.
 * @param enumObject Enum to be searched from
 * @param query Key or value to find
 * @returns Enum key as string
 */
export function getEnumKey(enumObject: any, query: string): string {
    forIn(enumObject, (value, key) => {
        if (query === value || query === key) {
            return key;
        }
    });
    return "";
}

/**
 * This function with get enum if query matches either key or value.
 * @param enumObject Enum to be searched from
 * @param query Key or value to find
 * @returns Enum Value as string
 */
export function getEnumValue(enumObject: any, query: string): string {
    forIn(enumObject, (value, key) => {
        if (query === value || query === key) {
            return value;
        }
    });
    return "";
}

/**
 * This function with get the selected component using component id
 * @param component Parent component 
 * @param selectedComponentNodeId  id of the selected component
 * @returns selected component
 * TODO Replace this with SystemComponent utils getComponentByNodeId
 */
export function getSelectedComponent(component: SystemComponent, selectedComponentNodeId: string | undefined) {
    return getComponentByNodeId(selectedComponentNodeId || "", component)
}

/**
 * This function with get the selected component using component id
 * @param component Parent component 
 * @param selectedComponentNodeId  id of the selected component
 * @returns selected component
 * TODO Need some refactoring here. Suggested to make high order function and then use it for recursiveness. 
 */
export function getSelectedComponentParent(component: SystemComponent, parentComponent?: SystemComponent, selectedComponentNodeId?: string) {
    let systemComponent;
    if (component && selectedComponentNodeId) {
        if (component.nodeId === selectedComponentNodeId) {
            return parentComponent;
        } else if (component.childComponents) {
            component.childComponents.some((item) => systemComponent = getSelectedComponentParent(item, component, selectedComponentNodeId) as SystemComponent);
        }
    }
    return systemComponent;
}

/**
 * This function will generate 10 character long uid using time stamp and random function.
 * @returns 10 character uid 
 */
export function generateUid(): string {
    let timestamp = Date.now().toString().slice(6);
    let crypt = global.crypto;
    let intArray = new Uint8Array(1);
    let multiplier = crypt.getRandomValues(intArray)[0];
    let randomValue = multiplier > 1 ? (parseInt(timestamp) * multiplier) : parseInt(timestamp); //multiplier should not be 0 other wise random will be zero
    return randomValue.toString().padEnd(10, "0");
}

export function preventPageRefresh() {
    const handlePageRefresh = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return event.returnValue = '';
    }
    window.addEventListener('beforeunload', handlePageRefresh, { capture: true });
    return () => {
        window.removeEventListener('beforeunload', handlePageRefresh, { capture: true });
        console.log("beforeunload end")
    }
}

export function getLocalDate(): Date {
    let currentUTCDate = new Date();
    return new Date(currentUTCDate.getTime() - currentUTCDate.getTimezoneOffset() * 60 * 1000);
}

export function getDateFormatyyyyMMdd(date?: Date): string {
    let currentDate = new Date();
    if (date)
        currentDate = date;

    let yyyy = currentDate.getFullYear();
    let MM = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let dd = currentDate.getDate().toString().padStart(2, "0");

    return `${yyyy}${MM}${dd}`;
}

export function getDateFormatyyyyMMddHHmmss(date?: Date): string {
    let currentDate = new Date();
    if (date)
        currentDate = date;

    let yyyyMMdd = getDateFormatyyyyMMdd(date);
    let HH = currentDate.getHours().toString().padStart(2, "0");
    let mm = currentDate.getMinutes().toString().padStart(2, "0");
    let ss = currentDate.getSeconds().toString().padStart(2, "0");

    return `${yyyyMMdd}${HH}${mm}${ss}`;
}

export function getLocalDateFromUTC(utcDate: Date): Date {
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
}

/**
 * This function will convert a string to its shorform  
 * @param maxCharacters maximum number of characters shortform can have
 * @param value string which needs to be coverted
 * @param suffixText suffix will attach at the end of shortform
 * @returns shortform of the given string.
 */
export const getShortForm = (maxCharacters: number, value: string, suffixText?: string) => {
    let trimmedString: string = value
    if (trimmedString.length > maxCharacters) {
        trimmedString = trimmedString.substring(0, maxCharacters);
        trimmedString += "...";
        if (suffixText)
            trimmedString += ` ${suffixText}`;

    }
    return trimmedString;

}

/**
 * Get Current Country Information
 */
export const GetCurrentCountry = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = getCountryForTimezone(tz);
  return {
    id: country?.id,
    idLowerCase: country?.id.toLocaleLowerCase(),
    country: country?.name,
  };
};
