import { Dispatch } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { NavigationPath } from "../../../utils/Navigation";
import { hideOptionModal } from "../store/actions";
import { HomeAction } from "../store/actionTypes";
import OptionModalItem from "./OptionModalItem";
import ModalDialog from "../../../components/ModalDialog";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";

const OptionModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const isShowOptionModal = useAppSelector(
    (state) => state.home.isShowOptionModal
  );
  const dispatch = useDispatch<Dispatch<HomeAction>>();

  const handleClickLogin = () => {
    dispatch(hideOptionModal());
    navigate(NavigationPath.Login);
  };

  const handleCloseOptionModal = () => {
    dispatch(hideOptionModal());
  };

  return (
    <ModalDialog
      open={isShowOptionModal}
      onModalClose={handleCloseOptionModal}
      modalTitle={t(I18.home_estimate_harmonics_modal_title)}
      showHeaderSeparator
    >
      <div className="option-modal">
        <ul>
          <OptionModalItem
            icon="key"
            text={t(I18.home_quick_estimate)}
            onClick={handleClickLogin}
            additionalText={t(I18.home_quick_estimate_additional_text)}
          />
          <li>
            <span className="description-regular gray-font">{t(I18.home_download_excel)}</span>
            <Link to={NavigationPath.DownloadExcelTool} onClick={handleCloseOptionModal} className="description-regular black-font">{t(I18.click_here)}</Link>
          </li>
        </ul>
      </div>
    </ModalDialog>
  );
};

export default OptionModal;
