import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthManager } from "../../../authServices/AuthManager";
import { getDateFormatyyyyMMdd } from "../../../utils/Common";
import { ReportRequestModel } from "../models/ReportRequestModel";
import { EmailRequestModel } from "../models/EmailRequestModel";

const baseUrlEnv = process.env.REACT_APP_API_URL;
const baseUrl = `${baseUrlEnv}`;

export const reportAPI = createApi({
    reducerPath: 'reportAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${AuthManager.getUser()?.access_token}`);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getReportHtml: builder.mutation<any, Partial<ReportRequestModel>>({
            query: (data: ReportRequestModel) => (
                {
                    url: 'report/json',
                    method: 'POST',
                    body: data
                }
            )
        }),
        getReportPdf: builder.mutation<any, Partial<ReportRequestModel>>({
            queryFn: async (data: ReportRequestModel, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `report/pdf`,
                    body: data,
                    method: 'POST',
                    responseHandler: ((response: any) => response.blob())
                });
                if (!result.data)
                    return { data: null };
                let anchor = document.createElement('a');
                let url = URL || webkitURL;
                let pdf = url.createObjectURL(result.data as any);
                anchor.href = pdf;
                anchor.target = "_blank";
                anchor.download = `HarmonicEstimatorReport_${data.reportConfiguration?.projectName || ""}_${getDateFormatyyyyMMdd(data.reportConfiguration?.date)}.pdf`
                anchor.click();
                return { data: { Success: true } };
            }
        }),
        sendEmail: builder.mutation<any, Partial<EmailRequestModel>>({
            query: (data: EmailRequestModel) => (
                {
                    url: `email/report`,
                    method: "POST",
                    body: data,
                })
        }),
    })
});

export const { useGetReportHtmlMutation, useGetReportPdfMutation, useSendEmailMutation } = reportAPI;