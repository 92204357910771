import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import { useAppSelector } from "../../../store/hooks";
import { ResultSummaryModel } from "../models/ResultSummaryModel";
import SummaryCardItem, { SummaryCardItemProps } from "./SummaryCardItem";
interface SummaryCardProps {
    title: string;
    //listItem: SummaryCardItemProps[];
    resultSummary?: ResultSummaryModel;
    loading: boolean;
    subTitle?: string;
}

function SummaryCard(props: SummaryCardProps) {

    const { t } = useTranslate();
    const {selectedStandard} = useAppSelector(store => store.configuration)

    const { title, loading, subTitle =  t(I18.configuration_page_summary_card_sub_title, selectedStandard.toString()), resultSummary } = props;

    const currentTDDToolTip = t(I18.configuration_page_summary_card_current_tdd_tool_tip);
    const VoltageTHDToolTip = t(I18.configuration_page_summary_card_voltage_thd_tool_tip);

    const getListItems = (result?: ResultSummaryModel): SummaryCardItemProps[] => {
        let summaryCardItems: SummaryCardItemProps[] = [];
        if (result) {
            //Current TDD
            summaryCardItems.push({
                title: t(I18.configuration_page_summary_card_current_tdd_title),
                toolTip: currentTDDToolTip,
                value: result.currentTDD,
                limit: result.currentTDDLimit,
                unitSymbol: "%",
                loading: loading
            });
            //Voltage THD
            summaryCardItems.push({
                title: t(I18.configuration_page_summary_card_voltage_thd_title),
                toolTip: VoltageTHDToolTip,
                value: result.voltageTHD,
                limit: result.voltageTHDLimit,
                unitSymbol: "%",
                loading: loading
            });
            //Current Isc
            summaryCardItems.push({
                title: t(I18.configuration_page_summary_card_isc_i_ratio_title),
                value: result.currentIscToIloadRatio,
                loading: loading
            });

            return summaryCardItems;
        }

        return [
            {
                title: t(I18.configuration_page_summary_card_current_tdd_title),
                toolTip: currentTDDToolTip,
                limit: 1,
                unitSymbol: "%",
                loading: loading
            },
            {
                title: t(I18.configuration_page_summary_card_voltage_thd_title),
                toolTip: VoltageTHDToolTip,
                limit: 1,
                unitSymbol: "%",
                loading: loading
            },
            {
                title: t(I18.configuration_page_summary_card_isc_i_ratio_title),
                loading: loading
            }
        ]
    }

    return (
        <div className='card summary-card'>
            <h6>
                <span className={`${loading ? 'skeleton' : ''}`}> {title}</span>
            </h6>
            <span className={`disclaimer description-regular ${loading ? 'skeleton' : ''}`}>
                {subTitle}
            </span>
            <ul>
                {getListItems(resultSummary).map((item) =>
                (<SummaryCardItem
                    key={item.title} {...item} />
                ))}
            </ul>
        </div>
    )
}

export default SummaryCard
