import React from 'react';
import '../../styles/tree.scss';

function TreeItem(props: React.PropsWithChildren<any>) {
    return (
        <ul className='tree-ul'>
            {React.Children.map(props.children, (child, i) =>
                <li key={i} className='tree-li'>
                    {child}
                </li>
            )}
        </ul>
    )
}

export default TreeItem