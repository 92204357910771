import { Dispatch, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import {
  addAssumptionNote,
  hideAddAssumptionnoteslModal,
} from "../../store/actions";
import { ConfigurationAction } from "../../store/actionTypes";
import CommonModalFooter from "./CommonModalFooter";
import { CommonuxCheckboxCustomEvent } from "@abb-ux/commonux-web-components";
import ModalDialog from "../../../../components/ModalDialog";
import Checkbox from "../../../../components/Form/Checkbox";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import { useGetAssumptionNotesQuery } from "../../services/ContentfulAPIService";


const AssumptionNotesModalPopup = () => {
  const { isShowAssumptionNoteModal: isAssumptionNoteModel, assumptionNotes, selectedStandard } =
    useAppSelector((storeState) => storeState.configuration);
  const dispatch = useDispatch<Dispatch<ConfigurationAction>>();

  const handleCloseAssumptionNoteModel = () => {
    dispatch(hideAddAssumptionnoteslModal());
  };

  const { data: assumptionNotesData, isLoading: dataIsLoading, isSuccess: dataIsSuccess } = useGetAssumptionNotesQuery();

  const [selectedNotes, setSelectedNotes] = useState<string[]>(assumptionNotes);
  const { t } = useTranslate();

  const handleCheckBox = (e: CommonuxCheckboxCustomEvent<any>) => {
    if (e.detail.checked) {
      setSelectedNotes([
        ...selectedNotes, e.detail.value]);
    }
    if (!e.detail.checked) {
      setSelectedNotes(
        selectedNotes.filter((element) => element !== e.detail.value)
      );
    }
  };
  const handleAdd = () => {
    dispatch(addAssumptionNote(selectedNotes));
    dispatch(hideAddAssumptionnoteslModal());
  };

  return (
    <ModalDialog
      open={isAssumptionNoteModel}
      onModalClose={handleCloseAssumptionNoteModel}
      showBackButton={false}
      onModalBack={handleCloseAssumptionNoteModel}
      modalTitle={t(I18.configuration_page_assumption_notes_modal_popup_title, selectedStandard.toString())}
      showFooterSeparator={true}
      className="commonux-modal-popup"
    >
      <div className="modal-content">
        <h6>{t(I18.configuration_page_assumption_notes_modal_popup_header)}</h6>
        <div className="checkbox-style" >
          {dataIsLoading &&
            <>
              {Array.from({ length: 6 }, (_, i) => <span className="skeleton" key={i}>.</span>)}
            </>
          }
          {dataIsSuccess && assumptionNotesData?.map((item, index) => (
            <Checkbox
              checked={initialCheckBoxCondition(item.name)}
              onCheckboxChange={handleCheckBox}
              className="test"
              type="primary-black"
              size="small"
              key={item.name}
              value={item.name}
            >
              {item.name}
            </Checkbox>
          ))}
        </div>
      </div>
      <div slot="footer">
        <CommonModalFooter
          isEdit={false}
          onCancelClick={handleCloseAssumptionNoteModel}
          onActionButtonClick={handleAdd}
        />
      </div>
    </ModalDialog>
  );

  function initialCheckBoxCondition(item: string): boolean | undefined {
    const value = selectedNotes.find((element) => element === item);
    return value ? true : false;
  }
};

export default AssumptionNotesModalPopup;
