import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Icon from '../../../components/Icon';
import LeftPane from '../../../components/LeftPane'
import LeftPaneItem from '../../../components/LeftPaneItem'
import NarrowMenuContainer from '../../../components/NarrowMenuContainer';
import { NarrowMenuItemProps } from '../../../components/NarrowMenuItem';
import { useTranslate } from '../../../i18translate/Hooks'
import { I18 } from '../../../languages/I18';
import { isStringEmpty } from '../../../utils/Common';
import { NavigationPath } from '../../../utils/Navigation';
import NotificationManager from '../../../utils/NotificationManager';
import { ProjectModel } from '../model/ProjectModel';
import { useAddProjectMutation, useGetAllProjectsQuery } from '../services/ProjectAPIServices';
import { showDeleteProjectModal, showProjectModal } from '../store/actions';
import ProjectDashedButton from './ProjectDashedButton'

function ProjectListSection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslate();

    const { data, isLoading, isSuccess, fulfilledTimeStamp } = useGetAllProjectsQuery();
    const { projectId } = useParams();
    const [addProject] = useAddProjectMutation();
    const[numberOfProjects, setNumberOfProjects] = useState<number>(data ? data.length : 0);
    const previousNumberOfProjectsRef = useRef<number>();
    const [isShowNarrowMenu, setIsShowNarrowMenu] = useState(true)

    useEffect(() => {
        setNumberOfProjects(data ? data.length : 0);
        previousNumberOfProjectsRef.current = numberOfProjects
        //In case no data available and projectId passed then remove projectId from url
        if (data && data.length === 0 && (!isStringEmpty(projectId)))
            navigate(`/${NavigationPath.Project}`);

        //In case no projectId passed or Selected Project is deleted or new Project is created then select the top project by default
        if (data && data.length > 0 && data.length !== previousNumberOfProjectsRef.current)
            navigate(`/${NavigationPath.Project}/${data[0].projectID}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, fulfilledTimeStamp])

    const handleClickNewProject = () => dispatch(showProjectModal());

    const duplicateProject = (project: ProjectModel) => {
        let { projectID, userID, updatedDateTime, ...newProject } = project;
        addProject(newProject).unwrap()
            .then((data) => {
                NotificationManager.success(t(I18.project_page_new_project_modalpopup_success_notification_text));
            })
            .catch((error) => NotificationManager.error(error))
    }

    const handleSelectProject = (event: any, projectId: string | undefined) => navigate(`/${NavigationPath.Project}/${projectId}`);

    const handleContextMenuClick = () => {
        setIsShowNarrowMenu(true);
    }

    const handleNarrowMenuItemClick = (actionType: string, project: ProjectModel) => {
        if (actionType === "edit") {
            dispatch(showProjectModal(true, project));
            return;
        }

        if (actionType === "delete") {
            dispatch(showDeleteProjectModal(project.projectID));
            return;
        }

        if (actionType === "duplicate") {
            duplicateProject(project);
            return;
        }
    }

    const handleOnClickMenuItem = (menuItem: NarrowMenuItemProps) => {
        if (menuItem.onClick)
            menuItem.onClick(menuItem.value, menuItem.data);
        setIsShowNarrowMenu(!isShowNarrowMenu);
    }

    const getNarrowMenuItem = (project: ProjectModel): NarrowMenuItemProps[] => [
        { label: t(I18.edit), value: "edit", data: project, onClick: handleNarrowMenuItemClick },
        // { label: t(I18.duplicate), value: "duplicate", onClick: handleNarrowMenuItemClick },
        { label: t(I18.delete), value: "delete", data: project, onClick: handleNarrowMenuItemClick },
    ];

    return (
        <section id="section-project-list" className='section-project-list'>
            <ProjectDashedButton
                label={t(I18.project_new_project_button_label)}
                additionalText={t(I18.project_new_project_button_description)}
                iconName='plus'
                iconSize='small'
                onClick={handleClickNewProject}
            />
            <span className='body-1-regular'>{t(I18.project_all_project_label)}</span>
            {isLoading &&
                <LeftPane isLoading>
                    <LeftPaneItem labelText="Loading" isContextMenu={false} description="Please wait"></LeftPaneItem>
                </LeftPane>
            }
            {
                isSuccess && data &&
                <LeftPane key={fulfilledTimeStamp} selectedItemId={projectId}>
                    {
                        data.map((project) =>
                            <LeftPaneItem
                                key={project.projectID}
                                itemId={project.projectID}
                                labelText={project.projectName}
                                isContextMenu={true}
                                description={`${project.configurationCount} Configuration`}
                                onLeftPaneItemClick={(event) => handleSelectProject(event, project.projectID)}
                                onContextMenuClick={handleContextMenuClick}
                            >
                                <Icon slot='icon' name='menu-narrow' size='small'></Icon>
                                <div slot="context-data" className='narrow-menu'>
                                    <NarrowMenuContainer key={isShowNarrowMenu.toString()} menuItems={getNarrowMenuItem(project)} isShow={isShowNarrowMenu} onClickMenuItem={handleOnClickMenuItem} ></NarrowMenuContainer>
                                </div>
                            </LeftPaneItem>
                        )
                    }
                </LeftPane>
            }
        </section>
    )
}

export default ProjectListSection