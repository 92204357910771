import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Input from "../../../../components/Form/Input"
import ModalDialog from "../../../../components/ModalDialog"
import { useTranslate } from "../../../../i18translate/Hooks"
import { I18 } from "../../../../languages/I18"
import { useAppSelector } from "../../../../store/hooks"
import NotificationManager from "../../../../utils/NotificationManager"
import CommonModalFooter from "../../../Configuration/components/AddNewComponentModalPopup/CommonModalFooter"
import { ConfigurationModel } from "../../model/ConfigurationModel"
import { useAddConfigurationMutation, useUpdateConfigurationMutation } from "../../services/ConfigurationAPIServices"
import { hideConfigurationModal } from "../../store/actions"

const ConfigurationModalPopup = () => {
    const { configurationModal } = useAppSelector(store => store.project);
    const dispatch = useDispatch();
    const [addConfiguration, { isLoading: isConfigurationAdding }] = useAddConfigurationMutation();
    const [updateConfiguration, { isLoading: isConfigurationUpdating }] = useUpdateConfigurationMutation();
    const configurationNameRef = useRef<any>();
    const { t } = useTranslate();
    const { projectId = "" } = useParams();
    const isRename: boolean = !!configurationModal.isEdit && !!configurationModal.configuration;
    const defaultConfigName = "Untitled Configuration"
    const [configurationName, setConfigurationName] = useState<string>(defaultConfigName);

    useEffect(() => {
        if (isRename && configurationModal.configuration?.configurationName) {
            let config = { ...configurationModal.configuration }
            setConfigurationName(config.configurationName)
        }
        //**Validating here as a temporary fix for resolving commonux bug in validation (Validation error is still showing after the component is re-rendered)*/
        validateForm();
        return () => {
            setConfigurationName(defaultConfigName);
        }
    }, [isRename, configurationModal.configuration, configurationModal.modalState])

    const validateForm = (): boolean => configurationNameRef.current.validate()

    const actionButtonHandler = () => {
        if (!validateForm()) return;

        let configuration: ConfigurationModel = {
            configurationName: configurationName,
            projectID: projectId ?? "",
            revision: "",
            configuration: {
                projectID: projectId ?? ""
            }
        }
        addConfiguration(configuration).unwrap()
            .then((data) => {
                NotificationManager.success(
                    t(I18.project_page_create_new_configuration_modalpopup_success_notification_text));
                dispatch(hideConfigurationModal());
            })
            .catch((error) => NotificationManager.error(error))
    }

    const updateButtonHandler = () => {
        if (!validateForm()) return;

        let configuration: ConfigurationModel = {
            configurationName: configurationName,
            projectID: configurationModal.projectId ?? "",
            configurationID: isRename ? configurationModal.configuration?.configurationID ?? "" : "",
            revision: "",
            configuration: {
                projectID: configurationModal.projectId ?? "",
            }
        }
        updateConfiguration(configuration).unwrap()
            .then((data) => {
                NotificationManager.success(
                    t(I18.project_page_rename_configuration_modalpopup_success_notification_text));
                dispatch(hideConfigurationModal());
            })
            .catch((error) => NotificationManager.error(error))
    }

    const inputHandler = (e: any) => setConfigurationName(e.detail.value)
    const handleBackButtonClick = () => dispatch(hideConfigurationModal())

    return (
        <ModalDialog
            open={configurationModal.modalState}
            modalTitle={isRename ? t(I18.project_page_rename_configuration_modalpopup_title) : t(I18.project_page_create_new_configuration_modalpopup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
            onModalClose={handleBackButtonClick}
        >
            <div className="modal-content">
                <Input
                    labelText={t(I18.configuration_name)}
                    heIsRequired
                    ref={configurationNameRef}
                    value={configurationName}
                    heMaxLength={100}
                    onInputInput={inputHandler}
                    optionalText={isRename ? t(I18.optional_text_configuration_name_preferred) : t(I18.optioal_text_configuration_name)}
                    size="medium">
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    key={isConfigurationAdding || isConfigurationUpdating ? "loading" : "defualt"}
                    isEdit={isRename}
                    isLoading={isConfigurationUpdating || isConfigurationAdding}
                    actionButtonText={isConfigurationAdding ? t(I18.adding) : t(I18.add)}
                    onActionButtonClick={actionButtonHandler}
                    onUpdateClick={updateButtonHandler}
                    onCancelClick={handleBackButtonClick}
                />
            </div>
        </ModalDialog>
    )
}

export default ConfigurationModalPopup