import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { ArticleModel } from '../models/ArticleModel'

export interface CardProps extends ArticleModel {
    isLoading?: boolean;
}

const Card = (props: CardProps) => {
    const { type, title, link, description, imageMedia, isLoading = false } = props;
    const {t} = useTranslate();
    const OpenNewTab = (link: string) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
    return (
        <div className={`card`} onClick={() => OpenNewTab(link)}>
            {imageMedia &&
                <img alt={`${title}`} src={imageMedia.url} ></img>}
            <span className={`type ${isLoading ? "skelton" : ""}`}>{type}</span>
            <h6 className={`title ${isLoading ? "skelton" : ""}`}>{title}</h6>
            <article className={`content body-1-regular ${isLoading ? "skelton" : ""}`}>{description}</article>
            <span className={`content-link ${isLoading ? "skelton" : ""}`}>
                {t(I18.more)}...
            </span>
        </div>
    )
}

export default Card
