import Icon from "../../../components/Icon";

interface OptionModalItemProps {
  icon: string;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  additionalText?: string;
}

function OptionModalItem(props: OptionModalItemProps) {
  const { icon, text, disabled, onClick, additionalText } = props;
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <li>
      <div className={`option-item-wrapper ${disabled ? "disabled" : null}`}>
        <div className="option-item" onClick={handleClick}>
          <Icon size="medium" name={icon}></Icon>
          <div className="option-text-wrapper">
            <span className="body-1-medium"> {text}</span>
            <span className="description-regular gray-font">{additionalText}</span>
          </div>
        </div>
      </div>
    </li>
  );
}

export default OptionModalItem;
