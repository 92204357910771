import { generateUid, isStringEmpty } from "../../../utils/Common";
import { ComponentType } from "../models/ComponentType";
import { SystemComponent } from "../models/SystemComponent";

/**
 * Get parent transformer component even from within the MCC.
 * @param currentComponentNodeId Current component nodeId for which to get the parent Transformer component
 * @param configTree Complete config tree object
 * @returns Parent Transformer Component
 */
export function getParentTransformerComponent(currentComponentNodeId: string, configTree: SystemComponent): SystemComponent | undefined {
    if (!configTree.childComponents)
        return undefined;

    let parentTransformerComponent = configTree;

    const getParent = (currentNodeId: string, parentComponent: SystemComponent) => (component: SystemComponent): boolean => {
        if (currentNodeId === component.nodeId) {
            //if the searching using transformer/lvgenset component nodeId then current component will be transformer/lvgenset 
            //and it will be assigned as parentTransformerComponent. 
            //Else parentComponent will be asigned to parentTransformerComponent
            parentTransformerComponent = component.componentType === ComponentType.Transformer ||
                component.componentType === ComponentType.LVGenset ? component : parentComponent;
            return true;
        } else if (component.childComponents) {
            let prntComponent = component.componentType === ComponentType.MCC ? parentComponent : component;
            return component.childComponents.some(getParent(currentNodeId, prntComponent));
        }
        return false;
    };

    return getParent(currentComponentNodeId, configTree)(configTree) ? parentTransformerComponent : undefined;
}


/**
 * Get all components as child of transformer component eliminating MCC component by default and will take MCC's child Component at transfomer level.
 * @param currentComponentNodeId Current component nodeId for which to get the parent Transformer component
 * @param configTree Complete config tree object
 * @param excludingMCC if True will not include MCC but will include it's child components. By default true 
 * @returns Array of all the components at Transformer level.
 */
export function getAllComponentsTransformerLevel(currentComponentNodeId: string, configTree: SystemComponent, excludingMCC: boolean = true): SystemComponent[] | undefined {
    let parentTransformerComponent = getParentTransformerComponent(currentComponentNodeId, configTree);

    if (!parentTransformerComponent)
        return undefined;

    if (!parentTransformerComponent || !parentTransformerComponent.childComponents)
        return [];

    let componentList: SystemComponent[];
    if (excludingMCC) {
        componentList = [...parentTransformerComponent.childComponents.filter((item) => item.componentType !== ComponentType.MCC)];
        parentTransformerComponent.childComponents.filter((item) => item.componentType === ComponentType.MCC)?.forEach((item) => {
            if (item.childComponents)
                componentList = componentList.concat(item.childComponents);
        });
    }
    else
        componentList = [...parentTransformerComponent.childComponents];

    return componentList;
}


/**
 * Returns component by nodeId.
 * @param componentNodeId Parent component 
 * @param configTree  id of the selected component
 * @returns System Component
 */
export function getComponentByNodeId(componentNodeId: string, configTree: SystemComponent): SystemComponent | undefined {
    if (isStringEmpty(componentNodeId))
        return;

    let component = configTree;

    const getComponent = (currentComponentNodeId: string) => (systemComponent: SystemComponent): boolean => {
        if (currentComponentNodeId === systemComponent.nodeId) {
            component = systemComponent;
            return true;
        } else if (systemComponent.childComponents) {
            return systemComponent.childComponents.some(getComponent(currentComponentNodeId));
        }
        return false;
    };

    getComponent(componentNodeId)(configTree);

    return component;
}

export function getFormatedComponentData(componentType: ComponentType, componentData: any, childComponents?: SystemComponent[]): SystemComponent {
    return {
        nodeId: generateUid(),
        componentType,
        componentData,
        childComponents
    }
}
