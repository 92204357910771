import { cloneDeep } from "lodash";
import { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/Form/Button";
import Input from "../../../components/Form/Input"
import { useAppSelector } from "../../../store/hooks";
import ReportFormFields from "../models/ReportFormField/ReportFormFields";
import { updatetReportHeader } from "../store/actions";
import { ReportAction } from "../store/actionTypes";

function ReportSummarySection() {
    const { reportHeaderConfig } = useAppSelector(storeState => storeState.report);
    const [formState, setFormState] = useState<any>({ ...reportHeaderConfig });
    const emailRegex: RegExp = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
    const revisionHistoryRegex: RegExp = new RegExp(/^[a-zA-Z0-9@./-]+$/);
    const dispatch = useDispatch<Dispatch<ReportAction>>();
    const projectIdRef = useRef<any>();
    const projectNameRef = useRef<any>();
    const configurationNameRef = useRef<any>();
    const revisionHistoryRef = useRef<any>();
    const contactNameRef = useRef<any>();
    const emailRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-generate-report",
        inputType: 'text',
        size: "medium"
    }

    useEffect(() => {
        setFormState({ ...reportHeaderConfig });
    }, [reportHeaderConfig])

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };
        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const handleUpdate = () => {
        if (!validateForm())
            return;

        let fields = { ...formState };
        let reportHeaderData = cloneDeep(reportHeaderConfig);
        reportHeaderData.projectId = fields[ReportFormFields.projectId];
        reportHeaderData.projectName = fields[ReportFormFields.projectName];
        reportHeaderData.configurationName = fields[ReportFormFields.configurationName];
        reportHeaderData.revisionHistory = fields[ReportFormFields.revisionHistory];
        reportHeaderData.contactName = fields[ReportFormFields.contactName];
        reportHeaderData.email = fields[ReportFormFields.email];
        dispatch(updatetReportHeader(reportHeaderData));
    }

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = projectIdRef.current.validate()
            & projectNameRef.current.validate()
            & configurationNameRef.current.validate()
            & revisionHistoryRef.current.validate()
            & contactNameRef.current.validate()
            & emailRef.current.validate()

        return !!isValid;
    }

    return (
        <>
            <div className='report-summary result-summary'>
                <div className='card summary-card' >
                    <h6>Generate Report</h6>
                    <Input
                        className="top-margin"
                        inputType='text'
                        size="medium"
                        labelText="Project Id"
                        heMaxLength={100}
                        value={formState[ReportFormFields.projectId]}
                        onInputChange={(e) => { handleChange(e, "projectId"); }}
                        ref={projectIdRef}>
                    </Input>
                    <Input
                        {...commonInputProps}
                        labelText="Project Name"
                        heMaxLength={100}
                        value={formState[ReportFormFields.projectName]}
                        onInputChange={(e) => { handleChange(e, "projectName"); }}
                        ref={projectNameRef}>
                    </Input>
                    <Input
                        {...commonInputProps}
                        placeholder="Utility with tie open"
                        labelText="Configuration"
                        heMaxLength={100}
                        value={formState[ReportFormFields.configurationName]}
                        onInputChange={(e) => { handleChange(e, "configurationName"); }}
                        ref={configurationNameRef}>
                    </Input>
                    <div className='hr-style' />
                    <Input
                        {...commonInputProps}
                        placeholder="Version-0.01"
                        labelText="Revision History"
                        heMaxLength={40}
                        value={formState[ReportFormFields.revisionHistory]}
                        heErrorMessages={{ showErrorMessage: true, errors: { regExp: "Enter valid alphanumeric may include @/-." } }}
                        onInputChange={(e) => { handleChange(e, "revisionHistory"); }}
                        heRegExp={revisionHistoryRegex}
                        ref={revisionHistoryRef}>
                    </Input>
                    <div className='input-reprt-style'>
                        <Input
                            {...commonInputProps}
                            labelText="Contact"
                            heMaxLength={100}
                            value={formState[ReportFormFields.contactName]}
                            onInputChange={(e) => { handleChange(e, "contactName"); }}
                            ref={contactNameRef}>
                        </Input>
                        <Input
                            className='input-generate-report input-email-style'
                            inputType='text'
                            size="medium"
                            labelText="ABB Email"
                            heMaxLength={100}
                            heRegExp={emailRegex}
                            heErrorMessages={{ showErrorMessage: true, errors: { regExp: "Enter valid email" } }}
                            value={formState[ReportFormFields.email]}
                            onInputChange={(e) => { handleChange(e, "email"); }}
                            ref={emailRef}>
                        </Input>
                    </div>
                    <div className="report-style">
                        <Button
                            size="small"
                            type="primary-black"
                            onClick={handleUpdate} >
                            Update Report
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ReportSummarySection