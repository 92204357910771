import React, { ReactNode } from 'react'
import '../../styles/tree.scss';

interface TreeProps {
    parentNode?: ReactNode;
}

function Tree(props: React.PropsWithChildren<TreeProps>) {
    return (
        <div id="tree" className='tree'>
            {props.parentNode}
            {props.children}
        </div>
    )
}

export default Tree