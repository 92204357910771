import { getFormatedComponentData } from "../utils/SystemComponentUtils";
import { ActiveHarmonicFilter } from "./ActiveHarmonicFilter";
import { ComponentType } from "./ComponentType";
import { ConfigurationLoads } from "./ConfigurationLoads";
import { ConfigurationStrategy } from "./ConfigurationStrategy";
import { LinearLoad } from "./LinearLoad";
import { NonLinearDriveLoad } from "./NonLinearDriveLoad";
import { SystemComponent } from "./SystemComponent";

export class MotorControlCenter extends ConfigurationLoads implements ConfigurationStrategy {
    id?: string;
    motorControlCenterName?: string;

    public constructor(id?: string, motorControlCenterName?: string) {
        super();
        this.id = id;
        this.motorControlCenterName = motorControlCenterName;
    }

    getComponentData(): MotorControlCenter {
        const mcc = new MotorControlCenter();
        Object.assign(mcc, this);
        return mcc;
    }

    getChildComponents(): SystemComponent[] | undefined {
        if (!this.driveLoadList && !this.filterList && !this.linearLoadList)
            return undefined;

        let childComponents: SystemComponent[] = [];

        if (this.driveLoadList && this.driveLoadList.length > 0)
            this.driveLoadList.forEach((driveLoad) => {
                childComponents.push(getFormatedComponentData(ComponentType.NonLinearDriveLoad, Object.assign(new NonLinearDriveLoad(), driveLoad)));
            })

        if (this.linearLoadList && this.linearLoadList.length > 0)
            this.linearLoadList.forEach((linearLoad) => {
                childComponents.push(getFormatedComponentData(ComponentType.LinearLoad, Object.assign(new LinearLoad(), linearLoad)));
            })

        if (this.filterList && this.filterList.length > 0)
            this.filterList.forEach((ahf) => {
                childComponents.push(getFormatedComponentData(ComponentType.ActiveHarmonicFilter, Object.assign(new ActiveHarmonicFilter(), ahf)));
            })

        return childComponents;
    }

    addChildComponent(load: any): void {
        if (load.constructor.name !== MotorControlCenter.name)
            this.pushLoad(load);
    }
}