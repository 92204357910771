import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import AbbHeader from '../../components/AbbHeader';
import BrandPlate from '../../components/BrandPlate';
import Button from '../../components/Form/Button';
import Icon from '../../components/Icon';
import { useTranslate } from '../../i18translate/Hooks';
import { I18 } from '../../languages/I18';
import { NavigationPath } from '../../utils/Navigation';

function Header() {
    const { t } = useTranslate();
    const navigate = useNavigate();
    const auth = useAuth();

    const handleBrandPlateClick = () => navigate(NavigationPath.Home);
    const handleSignInClick = () => navigate(NavigationPath.Login);
    const handleSignOutClick = () => navigate(NavigationPath.Logout);

    return (
        <AbbHeader size='medium' className='header'>
            <BrandPlate slot="brandplate" productname={t(I18.app_brandname)} type="brand-color" onBrandplateClick={handleBrandPlateClick} ></BrandPlate>
            <div slot="global-controls">
                {/* Menu for authenticated user */}
                {auth.isAuthenticated &&
                    // css class globalControls-slot-container globalControls-container are from commonux
                    <div className='globalControls-slot-container globalControls-container'>
                        {/* <SearchBar size='small' clearButton type='discreet' ></SearchBar>
                        <div className='header-divider'></div> */}
                        <Button type='discreet' size='small' onClick={handleSignOutClick}>
                            <Icon slot='icon' size='small' name='user-in-circle'></Icon>
                            {/* <Icon slot='menu' size='small' name='down'></Icon> */}
                            {t(I18.app_header_signout)}
                        </Button>
                    </div>
                }
                {/* For unauthenticated user */}
                {!auth.isLoading && !auth.isAuthenticated && !auth.activeNavigator &&
                    <Button type='discreet' size='small' onClick={handleSignInClick} >
                        <Icon slot='icon' size='small' name='user-in-circle' />
                        {t(I18.app_header_signin)}
                    </Button>
                }
            </div>
        </AbbHeader>
    )
}

export default Header
