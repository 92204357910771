import { cloneDeep } from "lodash";
import store from "../../../store/store";
import { SystemComponent } from "./SystemComponent";
import { ComponentType } from "./ComponentType"
import { LVGenset, PowerGrid } from "./PowerSource";
import { ResultSummaryRequestModel } from "./ResultSummaryRequestModel";
import { Transformer } from "./Transfomer";
import { MotorControlCenter } from "./MotorControlCenter";
import { getComponentByNodeId } from "../utils/SystemComponentUtils";
import { PCCPointOptions } from "./PCCPointOptions";
import { getSelectOptionArray } from "../../../utils/Common";

/**
 * This function will get object data from tree's child nodes or from the passed default parameters and will add it to respective parent objects
 * @param defaultComponentTree System Component from which api object has to be created. By default undefined
 * @param defaultPCCPoint Default PCC point to set. By default undefined
 * @param defaultUnit Default Unit (SI/US) to set. By default undefined
 * @returns Final PowerGrid or LV Genset Object.
 */
export function getAPIRequestModel(defaultComponentTree?: SystemComponent, defaultPCCPoint?: number, defaultUnit?: number, defaultStandard?: number, defualtAssumptionNotes?: string[]): ResultSummaryRequestModel | undefined {
    let sysComponent: SystemComponent;
    let selectedPCCPoint: number;
    let selectedUnit: number;
    let selectedStandard: number
    let assumptionNotes: string[];
    //this is to allow creation of API requestmodel from provided data. 
    if (defaultComponentTree && defaultPCCPoint !== undefined && defaultUnit !== undefined && defaultStandard !== undefined && defualtAssumptionNotes !== undefined) {
        sysComponent = cloneDeep(defaultComponentTree);
        selectedPCCPoint = defaultPCCPoint;
        selectedUnit = defaultUnit;
        selectedStandard = defaultStandard;
        assumptionNotes = defualtAssumptionNotes;
    } else {
        const state = store.getState().configuration;
        sysComponent = cloneDeep(state.configurationTree);
        selectedPCCPoint = getPCCPoint(sysComponent, state.selectedPCCNodeId);
        selectedUnit = state.selectedUnit;
        selectedStandard = state.selectedStandard;
        assumptionNotes = state.assumptionNotes;
    }

    treeNodeToDataObject(sysComponent);

    let apiRequest: ResultSummaryRequestModel | undefined = undefined;
    if (sysComponent.componentType === ComponentType.PowerGrid || sysComponent.componentType === ComponentType.LVGenset) {
        apiRequest = new ResultSummaryRequestModel(sysComponent.componentData as PowerGrid || LVGenset, selectedUnit, selectedPCCPoint, selectedStandard, assumptionNotes);
    }

    return apiRequest;
}

//Recursive function to add child elements in parent. to create tree structure
const treeNodeToDataObject = (childComponent: SystemComponent, parentComponent?: SystemComponent) => {
    if (childComponent.childComponents && childComponent.childComponents.length > 0) {
        childComponent.childComponents.forEach((data) => treeNodeToDataObject(data, childComponent));
    }
    //if component doesn't have any more childnodes then asign childs to respective parent objects
    if (parentComponent)
        addChildComponentsToParent(parentComponent, childComponent)
}

const addChildComponentsToParent = (parentComponent: SystemComponent, childComponent: SystemComponent) => {
    if (!parentComponent)
        return;

    switch (parentComponent.componentType) {
        case ComponentType.PowerGrid:
        case ComponentType.Transformer:
        case ComponentType.LVGenset:
        case ComponentType.MCC:
            (parentComponent.componentData as Transformer || LVGenset || MotorControlCenter || PowerGrid).addChildComponent(childComponent.componentData);
            break;

        default:
            break;
    }
}

export const getPCCPoint = (configTree: SystemComponent, selectedPCCNodeId?: string): number => {
    const defaultPCCPoint: number = PCCPointOptions.PowerGrid.key as number;

    if (!selectedPCCNodeId)
        return defaultPCCPoint;

    //get ComponentType of the selectedPCCNodeId
    const pccComponentType = getComponentByNodeId(selectedPCCNodeId, configTree)?.componentType;

    if (!pccComponentType)
        return defaultPCCPoint;

    //get selected pcc point based on componentType 
    const selectedPCCPoint = getSelectOptionArray(PCCPointOptions).find(item => item.value.value === pccComponentType)?.value.key;

    return selectedPCCPoint ? selectedPCCPoint as number : defaultPCCPoint;
}


