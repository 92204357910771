import { SystemComponent } from "./SystemComponent";
import { ComponentType } from "./ComponentType"
import { LVGenset, PowerGrid } from "./PowerSource";
import { ResultSummaryRequestModel } from "./ResultSummaryRequestModel";
import { getSelectOptionArray } from "../../../utils/Common";
import { PCCPointOptions } from "./PCCPointOptions";

/**
 * This function will get System Component Config Tree data from given ResultSummaryRequestModel
 * @param configObject API Object that needs to be converted
 * @returns Final ConfigurationTree System Component.
 */
export function getConfigTreeModel(configObject: ResultSummaryRequestModel): SystemComponent | undefined {
    let powerSource: PowerGrid | LVGenset;
    let componentType: ComponentType;

    if (configObject.powergrid) {
        powerSource = new PowerGrid();
        //*Object.assign is done to convert the json object to class object so that it have access to all the class methods as well
        Object.assign(powerSource, configObject.powergrid);
        componentType = ComponentType.PowerGrid;
    }
    else {
        powerSource = new LVGenset();
        Object.assign(powerSource, configObject.generator);
        componentType = ComponentType.LVGenset;
    }

    let rootComponent: SystemComponent = {
        nodeId: "0",
        componentType: componentType,
        componentData: powerSource.getComponentData(),
        childComponents: powerSource.getChildComponents()
    };

    return rootComponent;
}

/**
 * Get componentNodeID which has PCC Point by the PCCPoint from settings   
 * @param configTree Configuration Tree of type SystemComponent 
 * @param pccPoint pccPoint from Setting 0 | 1 | 2
 * @returns nodeId of the PCC Component
 */
export function getComponentNodeIdByPCCPoint(configTree: SystemComponent, pccPoint: number): string | undefined {
    const pccComponentType = getSelectOptionArray(PCCPointOptions).find((item) => item.value.key === pccPoint)?.value.value;

    //PCC point is at the root PowerGrid or LV Genset
    if (!pccComponentType || configTree.componentType === pccComponentType)
        return configTree.nodeId;

    if (configTree.childComponents)
        return getPCCPointNodeId(configTree.childComponents, pccComponentType)

    return "0";
}

const getPCCPointNodeId = (childComponents: SystemComponent[], pccComponentType: string) => {
    const component = childComponents?.find((item) => item.componentType === pccComponentType)
    if (component)
        return component.nodeId;
    else
        childComponents.forEach((item) => {
            if (item.componentType === pccComponentType)
                return item.nodeId
            else
                item.childComponents && item.childComponents.length > 0 &&
                    getPCCPointNodeId(item.childComponents, pccComponentType)
        })
}