import { CommonuxHeader } from '@abb-ux/commonux-web-components-react';
import { JSX } from '@abb-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface AbbHeaderProps extends JSX.CommonuxHeader {
    className?: string;
}

function AbbHeader(props: PropsWithChildren<AbbHeaderProps>) {
    return (
        <CommonuxHeader {...props}>
            {props.children}
        </CommonuxHeader>
    )
}

export default AbbHeader