import { ConfigurationModel } from '../model/ConfigurationModel';
import { ProjectModel } from '../model/ProjectModel';
import { ProjectAction, ProjectActionType } from './actionTypes';

export const showProjectModal = (isEdit: boolean = false, project?: ProjectModel): ProjectAction => {
    return {
        type: ProjectActionType.PROJECT_MODAL,
        payload: {
            modalState: true,
            isEdit,
            project
        }
    }
}

export const hideProjectModal = (): ProjectAction => {
    return {
        type: ProjectActionType.PROJECT_MODAL,
        payload: {
            modalState: false,
            isEdit: false,
            project: undefined
        }
    }
}

export const showConfigurationModal = (projectId: string, isEdit: boolean = false, configuration?: ConfigurationModel): ProjectAction => {
    return {
        type: ProjectActionType.CONFIGURATION_MODAL,
        payload: {
            modalState: true,
            isEdit,
            projectId,
            configuration
        }
    }
}

export const hideConfigurationModal = (): ProjectAction => {
    return {
        type: ProjectActionType.CONFIGURATION_MODAL,
        payload: {
            modalState: false,
            isEdit: false,
            projectId: undefined,
            configuration: undefined
        }
    }
}

export const showDeleteProjectModal = (id?: string): ProjectAction => {
    return {
        type: ProjectActionType.DELETE_PROJECT_MODAL,
        payload: {
            modalState: true,
            projectId: id
        }
    }
}

export const hideDeleteProjectModal = (): ProjectAction => {
    return {
        type: ProjectActionType.DELETE_PROJECT_MODAL,
        payload: {
            modalState: false,
            projectId: undefined
        }
    }
}