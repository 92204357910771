import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { isStringEmpty } from "../../../utils/Common";
import { ProjectListmodel } from "../model/ProjectListModel";
import { useGetProjectByIdQuery } from "../services/ProjectAPIServices";
import ConfigurationSection from "./ConfigurationSection"
import ProjectInfo from "./ProjectInfo"

function ProjectDetailSection() {
  const { projectId } = useParams();
  const { data, isFetching, fulfilledTimeStamp, isError } = useGetProjectByIdQuery(projectId ? projectId : "", { skip: !projectId });
  const [project, setProject] = useState<ProjectListmodel | undefined>(undefined)

  useEffect(() => {
    if (isError || isStringEmpty(projectId)) {
      setProject(undefined);
      return;
    }
    setProject(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fulfilledTimeStamp, isError])

  return (
    <section id="section-project-detail" className="section-project-detail">
      <h5 className={`project-info-header ${isFetching ? 'skeleton' : ''}`}>{!isFetching && project ? project.projectName : "Please select a project to view detail"}</h5>
      <ProjectInfo
        isLoading={isFetching}
        company={project?.contact?.company ?? ""}
        country={project?.contact?.country ?? ""}
        industry={project?.contact?.industry ?? ""}
        contactPerson={project?.contact?.contactPerson ?? ""}
      />
      <ConfigurationSection configurations={project?.configuration} isLoading={isError} ></ConfigurationSection>
    </section>
  )
}

export default ProjectDetailSection