import { CommonuxLeftPaneItem } from "@abb-ux/commonux-web-components-react"
import { PropsWithChildren } from "react";
import { JSX } from '@abb-ux/commonux-web-components/dist/types';
import { getIconName } from "../utils/iconNames";

interface LeftPaneItemProps extends JSX.CommonuxLeftPaneItem {
    iconName?: string;
    iconSize?: "small" | "medium" | "large";
    isContextMenu?: boolean;
    formatIconName?: boolean;
    className?: string;
}
const LeftPaneItem = (props: PropsWithChildren<LeftPaneItemProps>) => {

    const leftPaneItemProps = { ...(({ contextMenu, ...rest } = { ...props }) => (rest))(), }
    const { iconName, iconSize = "small", isContextMenu = false, formatIconName = true, className } = props

    const iconNameInFormat = getIconName(iconName, iconSize, formatIconName)
    return <CommonuxLeftPaneItem {...leftPaneItemProps} icon-name={iconNameInFormat} context-menu={isContextMenu} className={className}>{props.children}</CommonuxLeftPaneItem>
}

export default LeftPaneItem
