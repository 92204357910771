import { ProjectStoreModel } from "../model/ProjectStoreModel";
import { ProjectAction, ProjectActionType } from './actionTypes'

export const initialState: ProjectStoreModel = {
    projectModal: { modalState: false, isEdit: false },
    configurationModal: { modalState: false, isEdit: false },
    projects: [],
    configurations: [],
    deleteProjectModal: { modalState: false }
}

const projectReducer = (state: ProjectStoreModel | undefined, action: ProjectAction): ProjectStoreModel => {
    state = state ? state : initialState;
    switch (action.type) {
        case ProjectActionType.PROJECT_MODAL:
            return {
                ...state,
                projectModal: action.payload
            };
        case ProjectActionType.CONFIGURATION_MODAL:
            return {
                ...state,
                configurationModal: action.payload
            };
        case ProjectActionType.DELETE_PROJECT_MODAL:
            return {
                ...state,
                deleteProjectModal: action.payload
            }

        default:
            return state;
    }
}

export default projectReducer;