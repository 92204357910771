import { SelectOptionKeyValuePair, SelectOptionValues } from "../../../models/SelectOptionKeyValuePair";
import { getSelectOptionArray } from "../../../utils/Common";
import { MagnitudeStandardUnit } from "./Units";

export class ABBDriveType {
    driveFamily: string;
    driveRating: string;
    mainsVoltage: number;

    public constructor(driveFamily?: string, driveRating?: string, mainsVoltage?: number) {
        this.driveFamily = driveFamily ? driveFamily : "";
        this.driveRating = driveRating ? driveRating : "";
        this.mainsVoltage = mainsVoltage ? mainsVoltage : 0;
    }
}

export class NonLinearDriveLoad {
    id: string;
    applicationName: string;
    drive: number;
    filter: number;
    power: MagnitudeStandardUnit;
    maximumLoad: number;
    quantity: number;
    length: MagnitudeStandardUnit;
    abbDrive: ABBDriveType;
    isDisabled: boolean = false;

    public constructor(
        id?: string,
        applicationName?: string,
        drive?: number,
        filter?: number,
        power?: MagnitudeStandardUnit,
        maximumLoad?: number,
        quantity?: number,
        length?: MagnitudeStandardUnit,
        abbDrive?: ABBDriveType
    ) {
        this.id = id ? id : "";
        this.applicationName = applicationName ? applicationName : "";
        this.drive = drive ? drive : 0;
        this.filter = filter ? filter : 0;
        this.power = power ? power : new MagnitudeStandardUnit();
        this.maximumLoad = maximumLoad ? maximumLoad : 0;
        this.quantity = quantity ? quantity : 0;
        this.length = length ? length : new MagnitudeStandardUnit();
        this.abbDrive = abbDrive ? abbDrive : new ABBDriveType();
    }
}

export class DriveLoadDriveOptions {
    public static SixPulseNoImpedance: SelectOptionValues = { key: 1, value: "6 Pulse w/ No Impedance" };
    public static SixPulseThreeImpedance: SelectOptionValues = { key: 2, value: "6 Pulse w/ 3% Impedance" };
    public static SixPulseFiveImpedance: SelectOptionValues = { key: 3, value: "6 Pulse w/ 5% Impedance" };
    public static TwelvePulse: SelectOptionValues = { key: 4, value: "12 Pulse Drive" };
    public static EighteenPulse: SelectOptionValues = { key: 5, value: "18 Pulse Drive" };
    public static ULH: SelectOptionValues = { key: 6, value: "ULH (AFE) Drive" };
    //removing it as the implementaiton is not availabe in the backend
    // public static CustomSystem: SelectOptionValues = { key: 6, value: "Custom System" };
}

export class DriveLoadFilterOptions {
    public static None: SelectOptionValues = { key: 1, value: "None" };
    public static OnePointFiveACReactor: SelectOptionValues = { key: 2, value: "1.5% AC Reactor" };
    public static TwoPointFiveACReactor: SelectOptionValues = { key: 3, value: "2.5% AC Reactor" };
    public static ThreeACReactor: SelectOptionValues = { key: 4, value: "3% AC Reactor" };
    public static FiveACReactor: SelectOptionValues = { key: 5, value: "5% AC Reactor" };
    public static SevenACReactor: SelectOptionValues = { key: 6, value: "7% AC Reactor" };
    public static TenACReactor: SelectOptionValues = { key: 7, value: "10% AC Reactor" };
    public static SevenTHDiPassiveFilter: SelectOptionValues = { key: 8, value: "7% THDi Passive Filter" };
    public static FiveTHDiPassiveFilter: SelectOptionValues = { key: 9, value: "5% THDi Passive Filter" };
}

export function getFilterOptionsForSelectedDriveOption(driveOption: number): SelectOptionKeyValuePair[] {
    let filterOptions = getSelectOptionArray(DriveLoadFilterOptions);
    switch (driveOption) {
        case DriveLoadDriveOptions.SixPulseNoImpedance.key:
        case DriveLoadDriveOptions.SixPulseThreeImpedance.key:
        case DriveLoadDriveOptions.SixPulseFiveImpedance.key:
            return filterOptions;

        case DriveLoadDriveOptions.TwelvePulse.key:
        case DriveLoadDriveOptions.EighteenPulse.key:
        case DriveLoadDriveOptions.ULH.key:
            //removing it as the implementaiton is not availabe in the backend
            //case DriveLoadDriveOptions.CustomSystem.key:
            return filterOptions.filter((item) => item.value.key === DriveLoadFilterOptions.None.key);

        default:
            return filterOptions;
    }
}