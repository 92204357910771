import { Dispatch, memo } from "react";
import { useDispatch } from "react-redux";
import HarmonicIcon from "../../../../components/HarmonicIcon";
import { useAppSelector } from "../../../../store/hooks";
import { getSelectOptionArray } from "../../../../utils/Common";
import { SystemComponent } from "../../models/SystemComponent";
import { getConfigTreeLVGensetDefaultValue, getConfigTreePowerGridDefaultValue } from "../../models/DefaultValues";
import { PowerGrid, LVGenset, PowerSourceOptions } from "../../models/PowerSource";
import { selectPCC, selectPowerSource, updateComponent } from "../../store/actions";
import { ConfigurationAction } from "../../store/actionTypes";
import CardStripPowerGrid from "./CardStripPowerGrid";
import CardStripLVGenset from "./CardStripLVGenset";
import Select from "../../../../components/Form/Select";
import SelectContainer from "../../../../components/Form/SelectContainer";
import SelectOption from "../../../../components/Form/SelectOption";
import Icon from "../../../../components/Icon";
import { ComponentType } from "../../models/ComponentType";

interface CardStripPowerSourcePorps {
    data?: PowerGrid | LVGenset;
    onUpdate?: (data: PowerGrid | LVGenset) => void;
}

function CardStripPowerSource(props: CardStripPowerSourcePorps) {
    const { data, onUpdate } = props;

    const { selectedPowerSource, configurationTree, project } = useAppSelector(state => state.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();

    const handlePowerSourceChange = ({ detail }: any) => {
        const powerSource = parseInt(detail.value);

        if (selectedPowerSource === powerSource)
            return;

        let configTree: SystemComponent | undefined = undefined;
        if (powerSource === PowerSourceOptions.PowerGrid.key) {
            const lvGensetComponent = configurationTree.componentType === ComponentType.LVGenset && configurationTree.childComponents && configurationTree.childComponents.length > 0 ?
                configurationTree : undefined;
            configTree = getConfigTreePowerGridDefaultValue(lvGensetComponent?.childComponents, project.contact?.country)
            dispatch(updateComponent(configTree));
        } else if (powerSource === PowerSourceOptions.LVGenset.key) {
            const transformerComponent = configurationTree.componentType === ComponentType.PowerGrid && configurationTree.childComponents && configurationTree.childComponents.length > 0 ?
                configurationTree.childComponents[0] : undefined;
            configTree = getConfigTreeLVGensetDefaultValue(transformerComponent?.childComponents, project.contact?.country);
            dispatch(updateComponent(configTree));
        }
        dispatch(selectPowerSource(powerSource));

        if (configTree)
            dispatch(selectPCC(configTree.nodeId ? configTree.nodeId : "0"))
    }

    const handleOnUpdateComponentValue = (updatedData: PowerGrid | LVGenset) => {
        if (!onUpdate)
            return;
        onUpdate(updatedData);
    }

    const getPowerGridValues = () => {
        const powerGrid = data as PowerGrid;
        return <CardStripPowerGrid data={powerGrid} onUpdate={handleOnUpdateComponentValue} />
    }

    const getLVGensetValues = () => {
        const lVgenset = data as LVGenset;
        return <CardStripLVGenset data={lVgenset} onUpdate={handleOnUpdateComponentValue} />
    }

    const powerSourceSelector =
        <SelectContainer key={selectedPowerSource} size="medium" className="power-source">
            <Select slot="main" onSelectChange={handlePowerSourceChange}>
                <div slot="options-main">
                    {getSelectOptionArray(PowerSourceOptions).map((item) =>
                        <SelectOption key={item.value.key} data-value={item.value.key} selected={item.value.key === selectedPowerSource} optionText={item.value.value} ></SelectOption>)
                    }
                </div>
            </Select>
        </SelectContainer>

    switch (selectedPowerSource) {
        case PowerSourceOptions.PowerGrid.key:
            return (
                <>
                    <HarmonicIcon className="component-icon" size="small" name="power-grid" />
                    {powerSourceSelector}
                    {getPowerGridValues()}
                </>
            );

        case PowerSourceOptions.LVGenset.key:
            return (
                <>
                    <Icon className="component-icon" size="medium" name="generator" />
                    {powerSourceSelector}
                    {getLVGensetValues()}
                </>
            );

        default:
            return <></>
    }
}

export default memo(CardStripPowerSource);