import { getLocalDate } from "../../../utils/Common";
import { ResultSummaryRequestModel } from "../../Configuration/models/ResultSummaryRequestModel";
import { ReportConfigurationModel } from "./ReportHeaderModel";

export class ReportRequestModel {
    reportConfiguration?: ReportConfigurationModel;
    configuration?: ResultSummaryRequestModel;

    constructor(reportHeaderConfiguration?: ReportConfigurationModel, configuration?: ResultSummaryRequestModel) {
        this.reportConfiguration = { ...reportHeaderConfiguration };
        this.configuration = configuration;
        if (this.reportConfiguration)
        {
            this.reportConfiguration.date = getLocalDate();
        }
    }
}