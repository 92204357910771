import { memo, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { generateUid, isStringEmpty } from "../../../utils/Common";
import "../../Configuration/styles/commonstyle.scss";
import { ReportRequestModel } from "../models/ReportRequestModel";
import { ResultSummaryRequestPropModel } from "../models/ResultSummaryRequestPropModel";
import { useGetReportHtmlMutation } from "../services/APIService";
import ReportText from "./ReportText";

const brandLogo = require('../../../assets/images/ABB-logo.svg');
const REPORTSCRIPTSRC = `reportui/static/js/reportui.js`;
const REPORTSTYLESRC = `reportui/static/css/reportui.css`;
const SCRIPTID = 'report-script';
const STYLEID = 'report-style';

function ReportPages(props: ResultSummaryRequestPropModel) {
    const { reportHeaderConfig } = useAppSelector(store => store.report);
    const { resultSummaryRequest } = props;
    const divRef = useRef<any>();
    const [getReportHtml] = useGetReportHtmlMutation();
    const [reportRequestData, setReportRequestData] = useState("")
    const [scriptVersion, setScriptVersion] = useState("");
    const [skeletonHtml, setSkeletonHtml] = useState("");

    useEffect(() => {
        //adding skeleton inside harmonics-report-main-container before api call
        if (isStringEmpty(skeletonHtml))
            setSkeletonHtml((divRef.current as HTMLDivElement).innerHTML);
        else
            (divRef.current as HTMLDivElement).innerHTML = skeletonHtml;

        if (!resultSummaryRequest)
            return;

        const reportRequest = new ReportRequestModel(reportHeaderConfig, resultSummaryRequest);
        let scrVer = getScriptVersion();

        if (reportRequest) {
            getReportHtml(reportRequest).unwrap()
                .then(data => {
                    setReportRequestData(JSON.stringify(data));
                    loadReportCssStyle(scrVer);
                    loadReportScript(scrVer);
                })
                .catch(error => {
                    console.error("Error in loading report. ", error);
                })
        }
        return () => {
            unLoadReportCssStyle();
            unLoadReportScript();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportHeaderConfig, resultSummaryRequest]);

    const getScriptVersion = (): string => {
        let newVesion = scriptVersion;

        if (!isStringEmpty(newVesion))
            return scriptVersion;

        newVesion = generateUid();
        setScriptVersion(newVesion);
        return newVesion;
    }

    const loadReportScript = (version: string) => {
        unLoadReportScript();
        let scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.id = SCRIPTID;
        scriptTag.src = `${REPORTSCRIPTSRC}?version=${version}`;
        let body = document.getElementsByTagName('body')[0];
        body.appendChild(scriptTag);
    }

    const unLoadReportScript = () => {
        const existingScript = document.getElementById(SCRIPTID)
        if (existingScript)
            existingScript.remove();
    }

    const loadReportCssStyle = (version: string) => {
        const existingScript = document.getElementById(STYLEID)
        if (existingScript)
            return;

        let linkTag = document.createElement('link');
        linkTag.id = STYLEID;
        linkTag.rel = "stylesheet"
        linkTag.href = `${REPORTSTYLESRC}?version=${version}`;
        let head = document.getElementsByTagName('head')[0];
        head.appendChild(linkTag);
    }

    const unLoadReportCssStyle = () => {
        const existingScript = document.getElementById(STYLEID)
        if (existingScript)
            existingScript.remove();
    }

    const ReportData = (n: number) => {
        let data: any[] = []
        for (let i = 0; i < n; i++) {
            data.push(<div key={i} className={`skeleton`}>
                <ReportText loading={false} />
            </div>);
        }
        return data;
    }

    return (
        <div id="report-container">
            <input id="report-data" name="report-data" type="hidden" value={reportRequestData}></input>
            <div id="harmonics-report-main-container" className={`report-container`} ref={divRef}>
                <div className={`report-skeleton`}>
                    <div className="page-style">
                        <div className="brand-logo-style">
                            <img alt='Logo' src={brandLogo}></img>
                        </div>
                        <p className={`eyebrow-regular brand-title`}>
                            Harmonic Estimator
                        </p>
                        <h4 className={`label-width skeleton`}>Royal Dutch Shell </h4>
                        <div className={`label-style skeleton`}>Utility with tie open</div>
                        {ReportData(10)}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ReportPages)