import { CommonuxRadio } from '@abb-ux/commonux-web-components-react'
import { JSX } from '@abb-ux/commonux-web-components/dist/types'
import { PropsWithChildren } from 'react';

interface RadioProps extends JSX.CommonuxRadio {
    className?: string;
    id?: string
}

function Radio(props: PropsWithChildren<RadioProps>) {
    return (
        <CommonuxRadio {...props}>
            {props.children}
        </CommonuxRadio>
    )
}

export default Radio