import { memo } from "react";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import { useAppSelector } from "../../../../store/hooks";
import { roundOffByDecimalPrecision } from "../../../../utils/Common";
import ComponentValue from "../ComponentValue";

function MinAHFRating() {
    const { activeHarmonicFilterResult } = useAppSelector(state => state.configuration);
    const minAHF: number = activeHarmonicFilterResult.minAHFRating || 0;

    const { t } = useTranslate();

    return (
        <>
            <ComponentValue
                labelText={t(I18.configuration_page_label_min_ahf_rating)}
                value={roundOffByDecimalPrecision(minAHF, 1).toString()}
                className="min-ahf-rating-style">
            </ComponentValue>
        </>
    );
}
export default memo(MinAHFRating);


