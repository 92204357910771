import LeftPane from "../../../components/LeftPane"
import LeftPaneItem from "../../../components/LeftPaneItem"
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import "../styles/leftpaneitem.scss";

interface ProjectInfoProps {
    isLoading?: boolean;
    contactPerson?: string;
    country?: string;
    industry?: string;
    company?: string;
}

function ProjectInfo(props: ProjectInfoProps) {
    const { t } = useTranslate();
    const { isLoading = false, contactPerson = "N/A", country = "N/A", industry = "N/A", company = "N/A" } = props
    return (
        <header id="header-project-info">
            <h6>{t(I18.project_info_project_information_label)}</h6>
            <LeftPane allignment="row" className={`project-information ${isLoading ? "skeleton" : ""}`} >
                <LeftPaneItem iconName="user" iconSize="large" labelText={t(I18.project_info_contact_person_label)} description={contactPerson}></LeftPaneItem>
                <LeftPaneItem iconName="location" iconSize="large" labelText={t(I18.project_info_country_label)} description={country}></LeftPaneItem>
                <LeftPaneItem iconName="factory" iconSize="large" labelText={t(I18.project_info_industry_label)} description={industry}></LeftPaneItem>
                <LeftPaneItem iconName="briefcase" iconSize="large" labelText={t(I18.project_info_company_label)} description={company}></LeftPaneItem>
            </LeftPane>
        </header >
    )
}

export default ProjectInfo