import { ImportValidationAPI } from './../pages/Configuration/services/ImportApiServices';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import { AnyAction, Dispatch, Middleware, configureStore } from '@reduxjs/toolkit';
import { configAPI } from '../pages/Configuration/services/APIService';
import { reportAPI } from '../pages/Report/services/APIService';
import { commonApi } from '../services/CommonAPIServices';

import { configurationJSONAPI } from '../pages/Configuration/services/ConfigurationAPIService';
import { projectPageAPI } from '../pages/Project/services/APIService';
import { apiQueryErrorLogger } from '../services/APIErrorMiddleware';
import { articalAndCaseStudyAPI } from '../pages/Home/services/ArticalAndCaseStudyService';

import { VersionLicenseAndDiscliamerAPI } from '../pages/Info/services/VersionLicenseAndDiscliamerService';
import { assumptionAPI } from '../pages/Configuration/services/ContentfulAPIService';
const environment: string = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : "prod";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return environment === 'local' || environment === 'dev' ?
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(
                logger as Middleware<any, any, Dispatch<AnyAction>>,
                configAPI.middleware,
                reportAPI.middleware,
                projectPageAPI.middleware,
                configurationJSONAPI.middleware,
                commonApi.middleware,
                articalAndCaseStudyAPI.middleware,
                apiQueryErrorLogger,
                VersionLicenseAndDiscliamerAPI.middleware,
                ImportValidationAPI.middleware,
                assumptionAPI.middleware
            ) :
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(
                configAPI.middleware,
                reportAPI.middleware,
                projectPageAPI.middleware,
                configurationJSONAPI.middleware,
                commonApi.middleware,
                articalAndCaseStudyAPI.middleware,
                apiQueryErrorLogger,
                VersionLicenseAndDiscliamerAPI.middleware,
                ImportValidationAPI.middleware,
                assumptionAPI.middleware
            )
    },
    devTools: environment === 'local' || environment === 'dev'
});

export default store;
