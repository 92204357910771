import { clone } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import Input from "../../../../components/Form/Input";
import Icon from "../../../../components/Icon";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import MotorControlCenterFormFields from "../../models/FormFields/MotorControlCenterFormFields";
import { MotorControlCenter } from "../../models/MotorControlCenter";
import ComponentValue from "../ComponentValue";
interface CardStripMotorControlCenterPorps {
    data: MotorControlCenter;
    onUpdate?: (data: MotorControlCenter) => void;
}

function CardStripMotorControlCenter(props: CardStripMotorControlCenterPorps) {
    const { data, onUpdate } = props;
    const [cardStripState, setCardStripState] = useState<any>({ ...data });
    const idRef = useRef<any>();

    useEffect(() => {
        setCardStripState({ ...data });
    }, [data]);

    const handleOnUpdate = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name] = detail.value;
        setCardStripState({ ...state });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === MotorControlCenterFormFields.id && !idRef.current.validate())
            return false;

        let updatedData = clone(data);

        if (editFiledId !== 'id') {
            (updatedData as any)[editFiledId] = parseFloat(cardStripState[editFiledId]);
        } else {
            (updatedData as any)[editFiledId] = cardStripState[editFiledId];
        }

        onUpdate(updatedData);
        return true;
    }

    const handleCancel = () => {
        setCardStripState({ ...data });
    }

    const { t } = useTranslate();

    return (
        <>
            <Icon className="component-icon" size="small" name="sld-1" />
            <span className="component-name body-1-regular">
                {t(I18.configuration_page_card_strip_motor_control_center_title)}
            </span>
            {
                data.id &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_id_tag)}
                    value={data.id}
                    editable
                    fieldId={MotorControlCenterFormFields.id}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}>
                    <Input
                        placeholder="0"
                        inputType='text'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_id_tag_inline_edit_popup)}
                        value={cardStripState.id}
                        onInputChange={(e) => { handleOnUpdate(e, MotorControlCenterFormFields.id); }}
                        heMaxLength={10}
                        ref={idRef}>
                    </Input>
                </ComponentValue>
            }
        </>
    )
}
export default memo(CardStripMotorControlCenter)