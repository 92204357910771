import Navigation from "../../components/Navigation";
import NavigationLink from "../../components/NavigationLink";
import { useTranslate } from "../../i18translate/Hooks";
import { I18 } from "../../languages/I18";
import { NavigationPath } from "../../utils/Navigation";
import PrivacyCookieBanner from "../../components/PrivacyCookieBanner";
import { useEffect, useLayoutEffect, useState } from "react";
import { GetCurrentCountry } from "../../utils/Common";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { cookiePolicyUpdate } from "../store/actions";
import { CookiePolicyModel } from "../models/CookiePolicyModel";
import { CookiePolicyAction } from "../store/actionTypes";

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [analyticCheckbox, setAnalyticCheckbox] = useState(false);
  const [preferencesCheckbox, setPreferencesCheckbox] = useState(false);
  const [processData, setProcessData] = useState(false);
  const [ thirParty, setThirdParty] =useState(false);
  const currentCountryId = GetCurrentCountry().id;
  const dispatch = useDispatch<Dispatch<CookiePolicyAction>>();

  const versionHistoryHandler = () => window.open(NavigationPath.Info+"/"+NavigationPath.VersionHistory, '_blank', 'noopener,noreferrer');
  const legalHandler = () =>  window.open(NavigationPath.Info+"/"+NavigationPath.Legal, '_blank', 'noopener,noreferrer'); 
  const { t } = useTranslate();

  const storedfModalValue = localStorage.getItem('hide_privacy_banner');
  const initialModalValue:boolean = storedfModalValue === 'true';
  const [showPrivacybanner, setShowPrivacybanner] = useState(initialModalValue); // to make it even clickable this set state is needed.

  useLayoutEffect(() => {
    const allanalyticsValue = localStorage.getItem('allanalytics');
    const analyticsValue = localStorage.getItem('analytics');
    const preferenceValue = localStorage.getItem('preferences');
    const processValue = localStorage.getItem('processData');
    const thirdPartyValue = localStorage.getItem('thirdParty');
    if (allanalyticsValue === 'true' ) {
      setAnalyticCheckbox(true);
      setPreferencesCheckbox(true);
      setProcessData(true);
      setThirdParty(true);
    } 
    if (analyticsValue === 'true') {
      setAnalyticCheckbox(true);
    }
    if (preferenceValue  === 'true') {
      setPreferencesCheckbox(true);
    }
    if(processValue === 'true'){
      setProcessData(true);
    }
    if(thirdPartyValue === 'true' ){
      setThirdParty(true);
    }
  }, []);

  const setPrivacyBannerValue = () => {
    localStorage.setItem('hide_privacy_banner', 'true');
    setIsModalOpen(false);
  };

  //to accept all the cookies.
  const setAllTheCheckBox = () => {
    setAnalyticCheckbox(true);
    setPreferencesCheckbox(true);
    //set process personal data and third party check box as true only if country is China
    if(currentCountryId === "CN"){
      setProcessData(true);
      setThirdParty(true);
    }
    setPrivacyBannerValue();
    localStorage.setItem('allanalytics', 'true');
    updateCoookiePolicy(true);
  };

  //refuse all the cookies.

  const refuseAllClick = () => {
    setAnalyticCheckbox(false);
    setPreferencesCheckbox(false);
    setProcessData(false);
    setThirdParty(false);
    setPrivacyBannerValue();
    localStorage.setItem('allanalytics', 'false');
    localStorage.setItem("analytics", "false");
    localStorage.setItem("preferences", "false");
    localStorage.setItem("processData", "false");
    localStorage.setItem("thirdParty", "false");
    updateCoookiePolicy(false);
  };

  const updateCoookiePolicy = (analytics: boolean) =>{
   let cookiePolicy: CookiePolicyModel = {
        analytics: analytics,
        preference: preferencesCheckbox,
        processdata: preferencesCheckbox,
        thirdParty: thirParty
  };
   dispatch(cookiePolicyUpdate(cookiePolicy));

  };

   const acceptSelectedClick = () => {
     if (analyticCheckbox && preferencesCheckbox && currentCountryId !== "CN") {
      setAllTheCheckBox();
    } 
    if(analyticCheckbox && preferencesCheckbox && processData 
      && thirParty && currentCountryId === 'CN'){
      setAllTheCheckBox();
    }
    if (analyticCheckbox) {
      localStorage.setItem('analytics', 'true');
      updateCoookiePolicy(true);
    }
    else{
      updateCoookiePolicy(false);
    }
    if(preferencesCheckbox) {
      localStorage.setItem('preferences', 'true');
    }
    if(processData){
      localStorage.setItem('processData', 'true');
    }
    if(thirParty){
      localStorage.setItem('thirdParty', 'true');
    }
    localStorage.setItem('allanalytics', 'false');
    setPrivacyBannerValue();
  };

  
   const handleAnalyticcheckvalue = (
    event: CustomEvent<any>, type: string
  ) => {
    console.log("event", event);
    if (type === 'analytics') {
      setAnalyticCheckbox(event.detail.checked);
      if(!event.detail.checked){ 
        localStorage.setItem("analytics", "false");
      }
    }
    if (type === 'preferences') {
      setPreferencesCheckbox(event.detail.checked);
      if(!event.detail.checked) localStorage.setItem("preferences", "false");
    }
     if (type === 'processData') {
      setProcessData(event.detail.checked);
      if(!event.detail.checked) localStorage.setItem("processData", "false");
    }
    if (type === 'thirdParty') {
      setThirdParty(event.detail.checked);
      if(!event.detail.checked) localStorage.setItem("thirdParty", "false");
    }
  };

  const onPrivacyBannerClick = () =>{
    setShowPrivacybanner(false);
    setIsModalOpen(true);
  }

  const onModalClose = () =>{
    setIsModalOpen(false);
    setShowPrivacybanner(false);
  }
  const OpenNewTab = (link: string) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
  return (
    <>
    <footer id="footer" className="container">
      <div className="footer button-regular">
        <label className="copyright">{t(I18.app_footer_copyright)}</label>
        <Navigation size="small" type="discreet" className="underline">
          <NavigationLink 
            label={t(I18.app_footer_provider_information)}
            onSelectedEvent={() => OpenNewTab("https://new.abb.com/provider-information")}
          />
          <NavigationLink 
            label={t(I18.app_footer_acceptable_use_policy)}
            onSelectedEvent={() => OpenNewTab("https://library.e.abb.com/public/09158efb31874fd2a7739e4f1e63d1cb/ABB%20Acceptable%20Use%20Policy%20March%202018.pdf?x-sign=J7ipRSrP215EEzLV41EbTFXfodoPSGbxSipCvi2QU/6wZ1AvEPrjv6xt6Mr2L/Tz")}
          />
          <NavigationLink 
            label={t(I18.app_footer_privacy_notice)}
            onSelectedEvent={() => OpenNewTab("https://new.abb.com/privacy-policy")}
          />
          <NavigationLink
            label={t(I18.app_footer_privacy_cookie)}
            onSelectedEvent={onPrivacyBannerClick}
          />
           <NavigationLink
            label={t(I18.app_footer_legal)}
            onSelectedEvent={legalHandler}
          />
        </Navigation>
        <Navigation size="small" type="discreet" className="brand-name">
           <NavigationLink 
            label={t(I18.app_footer_version, "1.0.2")}
            onSelectedEvent={versionHistoryHandler}
          />
        </Navigation>
      </div>
    </footer>
     {!showPrivacybanner && (
      <PrivacyCookieBanner
          modalOpen={isModalOpen}
          onClose={()=> onModalClose}
          setPrivacyBannerValue={setPrivacyBannerValue}
          handleAnalyticcheckvalue={(event, type) => handleAnalyticcheckvalue(event, type)}
          analytics={analyticCheckbox}
          preferences={preferencesCheckbox}
          setAllTheCheckBox={setAllTheCheckBox}
          refuseAllClick={refuseAllClick}
          acceptSelectedClick={acceptSelectedClick}
          processData={processData}
          thirdParty={thirParty}
          />
      )}
    </>
  );
}

export default Footer;
