import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { hideEditLvGensetModal, updateComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import { getSelectedComponent, getSelectOptionArray } from "../../../../utils/Common";
import { SystemComponent } from '../../models/SystemComponent';
import { cloneDeep } from 'lodash';
import { LVGenset, LvGensetFrequencyOptions } from '../../models/PowerSource';
import CommonModalFooter from './CommonModalFooter';
import LvGensetFormFields from '../../models/FormFields/LvGensetFormFields';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { UnitSymbols } from '../../models/UnitSymbols';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import Icon from '../../../../components/Icon';
import ModalDialog from '../../../../components/ModalDialog';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

function LVGensetModalPopup() {
    const [formState, setFormState] = useState<any>({});
    const [selectedComponent, setSelectedComponent] = useState<SystemComponent>();
    const { configurationTree, isShowEditLvGensetModal, selectedComponentNodeId } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const idRef = useRef<any>();
    const ratedVoltageRef = useRef<any>();
    const ratedPowerRef = useRef<any>();
    const ratedPfRef = useRef<any>();
    const ratedXdRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
    }

    const { t } = useTranslate();

    const handleCloseLvGensetModal = () => {
        clearData();
        dispatch(hideEditLvGensetModal());
    }

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };

        //added this because select is trigerring change event before it is fully rendered
        if (!fields.hasOwnProperty(name))
            return;

        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(LvGensetFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    useEffect(() => {
        if (isShowEditLvGensetModal) {
            let selectedLvGenset = getSelectedComponent(configurationTree, selectedComponentNodeId);
            if (selectedLvGenset) {
                setSelectedComponent(selectedLvGenset);
                let lvGensetData = selectedLvGenset.componentData as LVGenset;
                let fields = { ...formState };

                fields[LvGensetFormFields.id] = lvGensetData.id;
                fields[LvGensetFormFields.frequency] = lvGensetData.frequency.toString();
                fields[LvGensetFormFields.ratedVoltage] = lvGensetData.ratedVoltage.toString();
                fields[LvGensetFormFields.ratedPower] = lvGensetData.ratedPower.toString();
                fields[LvGensetFormFields.ratedPf] = lvGensetData.ratedPf.toString();
                fields[LvGensetFormFields.ratedXd] = lvGensetData.ratedXd.toString();

                if (lvGensetData) {
                    setFormState({
                        ...fields
                    })
                }
            }
        }
        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowEditLvGensetModal, configurationTree, selectedComponentNodeId])

    const handleUpdate = () => {
        if (!validateForm())
            return;
        if (selectedComponent) {
            let component = cloneDeep(selectedComponent);
            let lvGensetData = component.componentData as LVGenset;
            if (lvGensetData) {
                lvGensetData.id = formState[LvGensetFormFields.id];
                lvGensetData.frequency = parseInt(formState[LvGensetFormFields.frequency]);
                lvGensetData.ratedVoltage = parseFloat(formState[LvGensetFormFields.ratedVoltage]);
                lvGensetData.ratedPower = parseFloat(formState[LvGensetFormFields.ratedPower]);
                lvGensetData.ratedPf = parseFloat(formState[LvGensetFormFields.ratedPf]);
                lvGensetData.ratedXd = parseFloat(formState[LvGensetFormFields.ratedXd]);
                dispatch(updateComponent(component));
            }
        }
        clearData();
        dispatch(hideEditLvGensetModal());
    }

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = idRef.current.validate()
            & ratedVoltageRef.current.validate()
            & ratedPowerRef.current.validate()
            & ratedPfRef.current.validate()
            & ratedXdRef.current.validate()
        return !!isValid;
    }

    return (
        <ModalDialog
            open={isShowEditLvGensetModal}
            onModalClose={handleCloseLvGensetModal}
            showBackButton={true}
            onBack={handleCloseLvGensetModal}
            modalTitle={t(I18.configuration_page_lv_genset_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
        >
            <Icon slot="title-icon" name="generator" size="medium" ></Icon>
            <div className="modal-content" >
                <Input
                    className="input-style"
                    size="medium"
                    labelText={t(I18.configuration_page_label_id_tag)}
                    optionalText={t(I18.configuration_page_optional_text_id_tag)}
                    value={formState[LvGensetFormFields.id]}
                    onInputChange={(e) => { handleChange(e, "id"); }}
                    heMaxLength={10}
                    ref={idRef}>
                </Input>
                <SelectContainer
                    className="select-style first-child"
                    size="medium"
                    required
                    labelText={t(I18.configuration_page_label_frequency)}
                    optionalText={t(I18.configuration_page_optional_text_frequency, UnitSymbols.Frequency)}
                    suffixText={UnitSymbols.Frequency}
                >
                    <Select
                        placeholder="Type text"
                        slot='main'
                        value={formState[LvGensetFormFields.frequency]}
                        optionText={formState[LvGensetFormFields.frequency]}
                        onSelectChange={(e) => { handleChange(e, LvGensetFormFields.frequency); }}>
                        <div slot="options-main">
                            {getSelectOptionArray(LvGensetFrequencyOptions).map((item) =>
                                <SelectOption
                                    key={item.value.key}
                                    data-value={item.value.key}
                                    selected={formState[LvGensetFormFields.frequency] && item.value.key.toString() === formState[LvGensetFormFields.frequency].toString()}
                                    optionText={item.value.value} >
                                </SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_voltage)}
                    optionalText={t(I18.configuration_page_optional_text_voltage, UnitSymbols.VoltageV)}
                    suffixText={UnitSymbols.VoltageV}
                    size="medium"
                    value={formState[LvGensetFormFields.ratedVoltage]}
                    onInputChange={(e) => { handleChange(e, LvGensetFormFields.ratedVoltage); }}
                    heMinValue={100}
                    heMaxValue={1000}
                    heDecimalPrecision={0}
                    ref={ratedVoltageRef} >
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_rated_power)}
                    optionalText={t(I18.configuration_page_optional_text_rated_power, UnitSymbols.PowerkW)}
                    suffixText={UnitSymbols.PowerkW}
                    size="medium"
                    value={formState[LvGensetFormFields.ratedPower]}
                    onInputChange={(e) => { handleChange(e, LvGensetFormFields.ratedPower); }}
                    heMinValue={1}
                    heMaxValue={100000}
                    heDecimalPrecision={0}
                    ref={ratedPowerRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_rated_power_factor)}
                    optionalText={t(I18.configuration_page_optional_text_rated_power_factor, UnitSymbols.PowerFactor)}
                    suffixText={UnitSymbols.PowerFactor}
                    size="medium"
                    value={formState[LvGensetFormFields.ratedPf]}
                    onInputChange={(e) => { handleChange(e, LvGensetFormFields.ratedPf); }}
                    heMinValue={0.1}
                    heMaxValue={1}
                    heDecimalPrecision={2}
                    ref={ratedPfRef} >
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_sub_transient_reactance_xd)}
                    optionalText={t(I18.configuration_page_optional_text_sub_transient_reactance_xd, UnitSymbols.PerUnit)}
                    suffixText={UnitSymbols.PerUnit}
                    size="medium"
                    value={formState[LvGensetFormFields.ratedXd]}
                    onInputChange={(e) => { handleChange(e, LvGensetFormFields.ratedXd); }}
                    heMinValue={0.0001}
                    heMaxValue={1}
                    heDecimalPrecision={4}
                    ref={ratedXdRef}>
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={true}
                    onCancelClick={handleCloseLvGensetModal}
                    onUpdateClick={handleUpdate}
                />
            </div>
        </ModalDialog>
    )
}
export default LVGensetModalPopup;
