import ModalDialog from "./ModalDialog";
import Button from "./Form/Button";
import Checkbox from "./Form/Checkbox";
import { useTranslate } from "../i18translate/Hooks";
import { I18 } from "../languages/I18";
import "../styles/privacyCookieBanner.scss";
import { GetCurrentCountry } from "../utils/Common";

interface PrivacyCookieProps {
  modalOpen: boolean;
  onClose: () => void;
  setPrivacyBannerValue: () => void;
  handleAnalyticcheckvalue: (event: CustomEvent<any>, type:string) => void;
  preferences: boolean;
  analytics: boolean;
  setAllTheCheckBox: () => void;
  refuseAllClick: () => void;
  acceptSelectedClick: () => void;
  processData:boolean;
  thirdParty:boolean;
} 

const PrivacyCookieBanner = (props:PrivacyCookieProps) =>{

    const { t } = useTranslate();
    const currentCountryId = GetCurrentCountry().id;

    return(
    <ModalDialog
      open={props.modalOpen}
      showBackButton={false}
      modalTitle={t(I18.privacy_cookie_modal_title)}
      showFooterSeparator={true}
      onModalClose={props.onClose}
      showCloseButton={false}
      type="discreet"
      backdropClick={false}
      show-footer-separator="true"
      className="privacy-banner-container"
    >
    <div
        className="privacy-banner-body"
        data-rf="privacybanner-modal-content"
        id="privacybanner-modal-content"
      >
        <p className="privacy-banner-text">
          {t(I18.privacy_cookie_modal_body)}
          {'  '}
          <a
            href="https://new.abb.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            {' '}
            {t(I18.privacy_cookie_modal_body_link)}
          </a>
        </p>
        <div className="privacy-banner-analytic">
          <Checkbox
            onCheckboxChange={(event) => props.handleAnalyticcheckvalue(event,"analytics")}
            type="primary-red"
            size="medium"
            id="analytics"
            // className="privacy-banner-checkbox"
            checked={props.analytics}
          >
            {t(I18.privacy_cookie_analytics_title)}
            <p slot="content">{t(I18.privacy_cookie_analytics_text)}</p>
          </Checkbox>
        </div>
        <div className="sepratorLine" />
        <div className="privacy-banner-preferences">
          <Checkbox
            onCheckboxChange={(event) => props.handleAnalyticcheckvalue(event, "preferences")}
            checked={props.preferences}
            type="primary-red"
            size="medium"
            id="preferences"
            // className="privacy-banner-checkbox"
          >
            {t(I18.privacy_cookie_preference_title)}
            <div slot="content">
              <p>{t(I18.privacy_cookie_perferences_body)}</p>
            </div>
          </Checkbox>
        </div>
        {/* //display these two policies only if App is being used in China */}
        {
          currentCountryId === 'CN' &&
          <>
           <div className="sepratorLine" />
           <div className="privacy-banner-china-concent">
              <Checkbox
                onCheckboxChange={(event) => props.handleAnalyticcheckvalue(event,"processData")}
                type="primary-red"
                size="medium"
                id="process-data"
                className="privacy-banner-checkbox"
                checked={props.processData}
              >
                <div slot="content">
                  <p>{t(I18.privacy_cookie_chinese_concent)}</p>
                </div>
              </Checkbox>
            </div>      
            <div className="sepratorLine" />
            <div className="privacy-banner-china-concent">
              <Checkbox
                onCheckboxChange={(event) => props.handleAnalyticcheckvalue(event, "thirdParty")}
                type="primary-red"
                size="medium"
                id="third-party"
                className="privacy-banner-checkbox"
                checked={props.thirdParty}
              >
                <div slot="content">
                  <p>{t(I18.privacy_cookie_chinese_data_transfer)}</p>
                </div>
              </Checkbox>
            </div>
          </>
        }
      </div>
      <div className="privacy-banner-button-container" slot="footer">
          <Button
            type="tertiary"
            size="medium"
            onClick={() => props.acceptSelectedClick()}
          >
            {t(I18.privacy_cookie_accept_selected_button)}
          </Button>
        <section>
          <Button
            type="tertiary"
            size="medium"
            onClick={() => props.refuseAllClick()}
          >
            {t(I18.privacy_cookie_refuse_all_button)}
          </Button>
          <Button
            type="primary-red"
            size="medium"
            onClick={() => props.setAllTheCheckBox()}
          >
           {t(I18.privacy_cookie_accept_all_button)}
          </Button>
        </section>
      </div>
    </ModalDialog>);
}

export default PrivacyCookieBanner;