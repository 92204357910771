import React from 'react';
import { useGetSoftwareLicensesQuery, useGetDisclaimerQuery } from '../services/VersionLicenseAndDiscliamerService';
import ReactMarkdown from 'react-markdown';

const LeagalCard = () => {
  const { data: disclaimerData, isLoading: disclaimerDataIsLoading, isSuccess: disclaimerDataIsSuccess } = useGetDisclaimerQuery();
  const { data: legalData, isLoading: legalDataIsLoading, isSuccess: legalDataIsSuccess } = useGetSoftwareLicensesQuery();
  return (
    <div className={`${disclaimerDataIsLoading || legalDataIsLoading ? 'skeleton' : "info-card"}`}>
      {
        disclaimerDataIsSuccess &&
        <ReactMarkdown children={disclaimerData.value} />
      }
      {
        legalDataIsSuccess &&
        <ReactMarkdown children={legalData.value} />
      }
    </div>
  );
};

export default LeagalCard;
