const PowerGridFormFields = {
    powergridRadioSelection: "powergridRadioSelection",
    mvasc: "mvasc",
    frequency: "frequency",
    ratedVoltage: "ratedVoltage",
    isc: "isc",
    resistance: "resistance",
    inductance: "inductance"
}

export const powergridRadioOptions = {
    mvasc: "mvasc",
    isc: "isc",
    feederrandx: "feederrandx"
}

export default PowerGridFormFields;