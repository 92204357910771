import { memo, useEffect, useRef, useState } from "react";
import Input from "../../../../components/Form/Input";
import Select from '../../../../components/Form/Select';
import { Transformer, TransformerConfigurationOptions } from "../../models/Transfomer";
import ComponentValue from "../ComponentValue";
import TransformerFormFields from '../../models/FormFields/TransformerFormFields';
import { clone } from "lodash";
import { getSelectOptionArray } from '../../../../utils/Common';
import { UnitSymbols } from "../../models/UnitSymbols";
import SelectContainer from "../../../../components/Form/SelectContainer";
import SelectOption from "../../../../components/Form/SelectOption";
import Icon from "../../../../components/Icon";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";

interface CardStripTransformerPorps {
    data: Transformer;
    onUpdate?: (data: Transformer) => void;
}

function CardStripTransformer(props: CardStripTransformerPorps) {
    const { data, onUpdate } = props;
    const [cardStripState, setCardStripState] = useState<any>({ ...data });
    const idRef = useRef<any>();
    const ratedKvaRef = useRef<any>();
    const secondaryVoltageRef = useRef<any>();
    const impedenceRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
    }

    useEffect(() => {
        setCardStripState({ ...data });
    }, [data]);

    const handleOnUpdate = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name] = detail.value;
        setCardStripState({ ...state });
    }

    const handleCancel = () => {
        setCardStripState({ ...data });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === TransformerFormFields.id && !idRef.current.validate())
            return false;

        if (editFiledId === TransformerFormFields.ratedKva && !ratedKvaRef.current.validate())
            return false;

        if (editFiledId === TransformerFormFields.secondaryVoltage && !secondaryVoltageRef.current.validate())
            return false;

        if (editFiledId === TransformerFormFields.impedence && !impedenceRef.current.validate())
            return false;

        let updatedData = clone(data);
        if (editFiledId !== 'id') {
            (updatedData as any)[editFiledId] = parseFloat(cardStripState[editFiledId]);
        } else {
            (updatedData as any)[editFiledId] = cardStripState[editFiledId];
        }
        onUpdate(updatedData);

        return true;
    }

    const getConfigurationShortCode = (selectedConfiguration: number = 0) => {
        const configuration = getSelectOptionArray(TransformerConfigurationOptions).find(item => item.value.key === selectedConfiguration)?.value.value;
        if (configuration)
            return configuration.substring(configuration.length - 2); //last 2 character contains the short code for configuration

        return "";
    }

    const { t } = useTranslate();

    return (
        <>
            <Icon className="component-icon" size="small" name="transformer" />
            <span className="component-name body-1-regular">
                {t(I18.configuration_page_card_strip_transformer_title)}
            </span>
            <ComponentValue
                labelText={t(I18.configuration_page_label_id_tag)}
                value={data.id}
                editable
                fieldId={'id'}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}>
                <Input
                    placeholder="0"
                    inputType='text'
                    size="small"
                    optionalText={t(I18.configuration_page_optional_text_id_tag_inline_edit_popup)}
                    value={cardStripState.id}
                    onInputChange={(e) => { handleOnUpdate(e, TransformerFormFields.id); }}
                    heMaxLength={10}
                    ref={idRef}>
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_kva)}
                value={`${data.ratedKva} ${UnitSymbols.kVA}`}
                editable
                fieldId={TransformerFormFields.ratedKva}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}>
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_rated_kva_inline_edit_popup, UnitSymbols.kVA)}
                    suffixText={UnitSymbols.kVA}
                    required
                    value={cardStripState.ratedKva}
                    onInputChange={(e) => { handleOnUpdate(e, TransformerFormFields.ratedKva); }}
                    heMinValue={1}
                    heMaxValue={500000}
                    heDecimalPrecision={0}
                    ref={ratedKvaRef}>
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_secondary_voltage)}
                value={`${data.secondaryVoltage} ${UnitSymbols.VoltageV} `}
                editable
                fieldId={TransformerFormFields.secondaryVoltage}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}>
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_secondary_voltage_inline_edit_popup, UnitSymbols.VoltageV)}
                    suffixText={UnitSymbols.VoltageV}
                    required
                    value={cardStripState.secondaryVoltage}
                    onInputChange={(e) => { handleOnUpdate(e, TransformerFormFields.secondaryVoltage); }}
                    heMinValue={100}
                    heMaxValue={1000}
                    heDecimalPrecision={0}
                    ref={secondaryVoltageRef}>
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_impedence_short_form)}
                value={`${data.impedence} ${UnitSymbols.Percentage}`}
                editable
                fieldId={TransformerFormFields.impedence}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}>
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_rated_impedence, UnitSymbols.Percentage)}
                    suffixText={UnitSymbols.Percentage}
                    required
                    value={cardStripState.impedence}
                    onInputChange={(e) => { handleOnUpdate(e, TransformerFormFields.impedence); }}
                    heMinValue={0.001}
                    heMaxValue={50}
                    heDecimalPrecision={3}
                    ref={impedenceRef}>
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_configuration)}
                value={getConfigurationShortCode(data.bindingConfiguration)}
                editable
                fieldId={TransformerFormFields.bindingConfiguration}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
                className="display-xl">
                <SelectContainer
                    className="block-edit"
                    size="small"
                    required
                    optionalText={t(I18.configuration_page_optional_text_configuration)}>
                    <Select slot="main" onSelectChange={(e) => handleOnUpdate(e, TransformerFormFields.bindingConfiguration)}>
                        <div slot="options-main">
                            {getSelectOptionArray(TransformerConfigurationOptions).map((item) =>
                                <SelectOption key={item.value.key} data-value={item.value.key} selected={item.value.key.toString() === cardStripState.bindingConfiguration.toString()} optionText={item.value.value} ></SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
            </ComponentValue>
        </>
    )
}
export default memo(CardStripTransformer)