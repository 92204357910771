import SetTitle from "../../components/SetTitle";
import TabContent from "../../components/TabContent";
import TabGroup from "../../components/TabGroup";
import TabItem from "../../components/TabItem";
import VersionHistorycard from "./components/VersionHistoryCard";
import "./styles/style.scss";
import { useTranslate } from './../../i18translate/Hooks';
import { I18 } from "../../languages/I18";
import { NavigationPath } from "../../utils/Navigation";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import LeagalCard from "./components/LegalCard";

const Info = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const versionHistoryPath: string = `/${NavigationPath.Info}/${NavigationPath.VersionHistory}`
  const legalPath: string = `/${NavigationPath.Info}/${NavigationPath.Legal}`

  const [selectedType, setSelectedType] = useState<string>(NavigationPath.VersionHistory)
  const location = window.location;

  const onTabClick = (tabName: string) => {
    switch (tabName) {
      case versionHistoryPath:
        setSelectedType(NavigationPath.VersionHistory)
        navigate(NavigationPath.VersionHistory)
        break;
      case legalPath:
        setSelectedType(NavigationPath.Legal)
        navigate(NavigationPath.Legal)
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    onTabClick(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <SetTitle title="info" />
      <div className="container info-container">
        <TabGroup type={"default"} variant={"panel-tab"} selectedTabId={selectedType}>
          <TabItem
            closable={false}
            label={t(I18.info_page_version_history_header)}
            tabId={NavigationPath.VersionHistory}
            onTabClick={() => onTabClick(versionHistoryPath)}
          ></TabItem>
          <TabItem
            closable={false}
            label={t(I18.info_page_legal_header)}
            tabId={NavigationPath.Legal}
            onTabClick={() => onTabClick(legalPath)}
          ></TabItem>
          <div slot="container">
            <TabContent containerId={NavigationPath.VersionHistory}>
              <VersionHistorycard />
            </TabContent>
            <TabContent containerId={NavigationPath.Legal}>
              <LeagalCard />
            </TabContent>
          </div>
        </TabGroup>
      </div>
    </>
  );
};

export default Info;
