import { CommonuxRadioGroup } from '@abb-ux/commonux-web-components-react'
import { JSX } from '@abb-ux/commonux-web-components/dist/types'
import { PropsWithChildren } from 'react';

interface RadioGroupProps extends JSX.CommonuxRadioGroup {
    className?: string;
    id?: string
}

function RadioGroup(props: PropsWithChildren<RadioGroupProps>) {
    return (
        <CommonuxRadioGroup {...props}>
            {props.children}
        </CommonuxRadioGroup>
    )
}

export default RadioGroup