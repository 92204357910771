import ReportHeader from './ReportHeader';
import '../../Configuration/styles/commonstyle.scss';
import ReportPages from './ReportPages';
import { ResultSummaryRequestPropModel } from '../models/ResultSummaryRequestPropModel';

function ReportSection(props: ResultSummaryRequestPropModel) {
    return (
        <section id='configuration' className='configuration' >
            <ReportHeader />
            <ReportPages resultSummaryRequest={props.resultSummaryRequest} />
        </section>
    )
}
export default ReportSection