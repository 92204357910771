import { ReportConfigurationModel } from "../models/ReportHeaderModel";
import { ReportStoreModel } from "../models/ReportStoreModel";
import { ReportAction, ReportActionTypes } from "./actionTypes";

export const initialState: ReportStoreModel = {
    reportHeaderConfig: {
        projectName: "Untitled Project",
        configurationName: "Untitled Configuration",
        email: "contact.center@us.abb.com"
    } as ReportConfigurationModel,
    emailModalConfig: {
        modalState: false
    }
}

const reportReducer = (state: ReportStoreModel | undefined, action: ReportAction): ReportStoreModel => {
    state = state ? state : initialState;

    switch (action.type) {
        case ReportActionTypes.REPORT_UPDATE_HEADER:
            return {
                ...state,
                reportHeaderConfig: action.payload
            }

        case ReportActionTypes.EMAIL_MODAL:
            return {
                ...state,
                emailModalConfig: action.payload
            }

        default:
            return state;
    }
}

export default reportReducer;