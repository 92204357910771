import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import './styles/main.scss';
import '@abb-ux/commonux-web-components/dist/commonux-web-components/commonux-web-components.css';
import './styles/commonux.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
