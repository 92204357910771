import { PropsWithChildren } from 'react';
import { CommonuxNotification } from '@abb-ux/commonux-web-components-react';
import { JSX } from '@abb-ux/commonux-web-components/dist/types';

interface NotificationProps extends JSX.CommonuxNotification {
    className?: string;
    id?: string;
}

function Notification(props: PropsWithChildren<NotificationProps>) {
    return (
        <CommonuxNotification {...props} >
            {props.children}
        </CommonuxNotification>
    )
}

export default Notification