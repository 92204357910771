import { PropsWithChildren, useState } from "react";
import OutsideClickHandler from "../../../components/OutsideClickHandler";
import { isStringEmpty } from "../../../utils/Common";
import InlineEditPopup from "./InlineEditPopup";

interface ComponentValueProps {
    labelText: string;
    value: string;
    editable?: boolean;
    fieldId?: string;
    className?: string;
    onUpdateValue?: (fieldId: string) => boolean;
    onCancel?: () => void;
}

function ComponentValue(props: PropsWithChildren<ComponentValueProps>) {
    const { labelText, value, editable = false, fieldId = "", className = "", onUpdateValue, onCancel } = props
    const [active, setActive] = useState(false);

    const handleOnClickComponentValue = () => {
        setActive(!active);
    }

    const handleOnCancelClickInlineEdit = () => {
        if (onCancel && active) {
            onCancel();
        }
        setActive(false);
    }

    const handleOnUpdateClickInlineEdit = (editFieldId: string) => {
        if (onUpdateValue)
            setActive(!onUpdateValue(editFieldId));
    }

    return (
        <>
            <OutsideClickHandler onClickOutside={handleOnCancelClickInlineEdit}>
                <div className={`inline-edit ${className}`}>
                    <div className="component-value" onClick={handleOnClickComponentValue}>
                        <label className="label floating-label">{labelText}</label>
                        <span 
                        className={`description-regular value ${active ? 'active' : ''}`} 
                        // eslint-disable-next-line
                        role="inline-edit-value"
                        >{!isStringEmpty(value) ? value : '\u00A0'}</span>
                    </div>
                    {editable && active &&
                        <InlineEditPopup fieldId={fieldId} onCancelClick={handleOnCancelClickInlineEdit} onUpdateClick={handleOnUpdateClickInlineEdit} >
                            {props.children}
                        </InlineEditPopup>
                    }
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default ComponentValue