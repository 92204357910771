import { clone } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { getSelectOptionArray } from '../../../../utils/Common';
import { LVGenset, PowerGridFrequencyOptions } from '../../models/PowerSource';
import ComponentValue from '../ComponentValue';
import LvGensetFormFields from '../../models/FormFields/LvGensetFormFields';
import { UnitSymbols } from '../../models/UnitSymbols';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

interface CardStripLVGensetPorps {
    data: LVGenset;
    onUpdate?: (data: LVGenset) => void;
}

function CardStripLVGenset(props: CardStripLVGensetPorps) {
    const { data, onUpdate } = props;
    const [cardStripState, setCardStripState] = useState<any>({ ...data });
    const idRef = useRef<any>();
    const ratedVoltageRef = useRef<any>();
    const ratedPowerRef = useRef<any>();
    const ratedPfRef = useRef<any>();
    const ratedXdRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
        placeholder: "0"
    }

    useEffect(() => {
        setCardStripState({ ...data });
    }, [data]);

    const handleOnUpdate = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name] = detail.value;
        setCardStripState({ ...state });
    }

    const handleCancel = () => {
        setCardStripState({ ...data });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === LvGensetFormFields.id && !idRef.current.validate())
            return false;

        if (editFiledId === LvGensetFormFields.ratedVoltage && !ratedVoltageRef.current.validate())
            return false;

        if (editFiledId === LvGensetFormFields.ratedPower && !ratedPowerRef.current.validate())
            return false;

        if (editFiledId === LvGensetFormFields.ratedPf && !ratedPfRef.current.validate())
            return false;

        if (editFiledId === LvGensetFormFields.ratedXd && !ratedXdRef.current.validate())
            return false;

        let updatedData = clone(data);
        (updatedData as any)[editFiledId] = editFiledId === LvGensetFormFields.id ? cardStripState[editFiledId] : parseFloat(cardStripState[editFiledId]);
        onUpdate(updatedData);
        return true;
    }

    const { t } = useTranslate()

    return (
        <>
            <ComponentValue
                labelText={t(I18.configuration_page_label_id_tag)}
                value={`${data.id}`}
                editable
                fieldId={LvGensetFormFields.id}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
            >
                <Input
                    placeholder={t(I18.configuration_page_optional_text_id_tag)}
                    size="small"
                    optionalText={t(I18.configuration_page_optional_text_id_tag_inline_edit_popup)}
                    value={cardStripState.id.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, LvGensetFormFields.id); }}
                    heMaxLength={10}
                    ref={idRef}
                >
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_frequency)}
                value={`${data.frequency} ${UnitSymbols.Frequency}`}
                editable
                fieldId={LvGensetFormFields.frequency}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
            >
                <SelectContainer
                    className="block-edit"
                    size="small"
                    required
                    optionalText={t(I18.configuration_page_optional_text_frequency, UnitSymbols.Frequency)}
                    suffixText={UnitSymbols.Frequency}
                >
                    <Select slot="main" onSelectChange={(e) => handleOnUpdate(e, LvGensetFormFields.frequency)}>
                        <div slot="options-main">
                            {getSelectOptionArray(PowerGridFrequencyOptions).map((item) =>
                                <SelectOption key={item.value.key} data-value={item.value.key} selected={item.value.key.toString() === cardStripState.frequency.toString()} optionText={item.value.value} ></SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_voltage)}
                value={`${data.ratedVoltage} ${UnitSymbols.VoltageV}`}
                editable
                fieldId={LvGensetFormFields.ratedVoltage}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
            >
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_voltage, UnitSymbols.VoltageV)}
                    suffixText={UnitSymbols.VoltageV}
                    required
                    value={cardStripState.ratedVoltage.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, LvGensetFormFields.ratedVoltage); }}
                    heMinValue={100}
                    heMaxValue={1000}
                    heDecimalPrecision={0}
                    ref={ratedVoltageRef}
                >
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_power)}
                value={`${data.ratedPower} ${UnitSymbols.PowerkW}`}
                editable
                fieldId={LvGensetFormFields.ratedPower}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
                className="display-xl"
            >
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_rated_power, UnitSymbols.PowerkW)}
                    suffixText={UnitSymbols.PowerkW}
                    value={cardStripState.ratedPower.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, LvGensetFormFields.ratedPower); }}
                    heMinValue={1}
                    heMaxValue={100000}
                    heDecimalPrecision={0}
                    ref={ratedPowerRef}
                >
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_power_factor_short_form)}
                value={`${data.ratedPf}`}
                editable
                fieldId={LvGensetFormFields.ratedPf}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
                className="display-xl"
            >
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_rated_power_factor, UnitSymbols.PowerFactor)}
                    suffixText={UnitSymbols.PowerFactor}
                    value={cardStripState.ratedPf.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, LvGensetFormFields.ratedPf); }}
                    heMinValue={0.1}
                    heMaxValue={1}
                    heDecimalPrecision={2}
                    ref={ratedPfRef}
                >
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_sub_transient_reactance_xd_short_form)}
                value={`${data.ratedXd} ${UnitSymbols.PerUnit}`}
                editable
                fieldId={LvGensetFormFields.ratedXd}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
                className="display-xl"
            >
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_sub_transient_reactance_xd, UnitSymbols.PerUnit)}
                    suffixText={UnitSymbols.PerUnit}
                    value={cardStripState.ratedXd.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, LvGensetFormFields.ratedXd); }}
                    heMinValue={0.0001}
                    heMaxValue={1}
                    heDecimalPrecision={4}
                    ref={ratedXdRef}
                >
                </Input>
            </ComponentValue>
        </>
    )
}
export default CardStripLVGenset