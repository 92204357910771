import { orderBy } from "lodash";
import { ConfigurationModel } from "../model/ConfigurationModel";
import { ProjectListmodel } from "../model/ProjectListModel";
import { ProjectModel } from '../model/ProjectModel';
import { projectPageAPI } from "./APIService";

const baseUrl = "projects";

export const projectApi = projectPageAPI.injectEndpoints({
    endpoints: (builder) => ({
        getAllProjects: builder.query<ProjectListmodel[], void>({
            query: () => `${baseUrl}`,
            transformResponse: (response: ProjectListmodel[], meta, args) => {
                return response && response.length > 0 ? orderBy<ProjectListmodel>(response, [(project: ProjectListmodel) => project.updatedDateTime ? new Date(project.updatedDateTime) : new Date()], ['desc']) : response;
            },
            providesTags: (result) => result && result.length > 0 ? [...result.map(({ projectID }) => ({ type: "Projects", id: projectID } as const))] : ["Projects"]
        }),
        getProjectById: builder.query<ProjectListmodel, string>({
            query: (projectID) => `${baseUrl}/${projectID}`,
            transformResponse: (response: ProjectListmodel, meta, args) => {
                if(response && response.configurationCount){
                    const orderedConfigurations = orderBy<ConfigurationModel>(response.configuration, [(configuration: ConfigurationModel) => configuration.updatedDateTime ? new Date(configuration.updatedDateTime) : new Date()], ['desc']);
                    response.configuration = [...orderedConfigurations];
                }
                return response;
            },
            providesTags: (result) => result && result.configurationCount > 0 ?
                [...result.configuration.map(({ configurationID }) => ({ type: "Configurations", id: configurationID } as const)),
                { type: "Projects", id: result.projectID }] :
                ["Configurations", { type: "Projects", id: result?.projectID }],
        }),
        addProject: builder.mutation<ProjectModel, Partial<ProjectModel>>({
            query: (data) => (
                {
                    url: `${baseUrl}`,
                    method: 'POST',
                    body: { ...data }
                }
            ),
            invalidatesTags: ["Projects"]
        }),
        updateProject: builder.mutation<ProjectModel, Partial<ProjectModel>>({
            query: (data) => (
                {
                    url: `${baseUrl}?projectID=${data.projectID}`,
                    method: 'PUT',
                    body: { ...data }
                }
            ),
            invalidatesTags: (result, error, { projectID }) => [{ type: "Projects", id: projectID }]
        }),
        deleteProject: builder.mutation<ProjectModel, string>({
            query: (data) => (
                {
                    url: `${baseUrl}?projectID=${data}`,
                    method: 'DELETE'
                }
            ),
            invalidatesTags: ["Projects"]
        }),
    }),
});

export const {
    useGetAllProjectsQuery,
    useGetProjectByIdQuery,
    useAddProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = projectApi;