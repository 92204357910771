import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { addChildComponent, hideNonlinearLoadsModal, hideSystemComponentModal, updateComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import { getSelectOptionArray, getSelectedComponent, getSelectedComponentParent } from "../../../../utils/Common";
import { DriveLoadDriveOptions, DriveLoadFilterOptions, getFilterOptionsForSelectedDriveOption, NonLinearDriveLoad } from "../../models/NonLinearDriveLoad";
import '../../styles/nonlinearloadsmodalstyle.scss';
import { SystemComponent } from '../../models/SystemComponent';
import { getLengthConversionFromSI, getPowerConversionFromSI, getUnit, getUnitConversion } from '../../models/Units';
import { cloneDeep } from 'lodash';
import UnitOptions from '../../models/UnitOptions';
import CommonModalFooter from './CommonModalFooter';
import { ComponentType } from '../../models/ComponentType';
import NonLinearDriveLoadFormFields from '../../models/FormFields/NonLinearDriveLoadFormFields';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { UnitSymbols } from '../../models/UnitSymbols';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import Icon from '../../../../components/Icon';
import ModalDialog from '../../../../components/ModalDialog';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import { getNonLinearDriveLoadDefaultValues } from '../../models/DefaultValues';

function NonLinearModalPopup() {
    const [formState, setFormState] = useState<any>({
        toggleSelection: false,
        parentComponent: ComponentType.Transformer
    });

    const [selectedNonLinearLoad, setSelectedNonLinearLoad] = useState<SystemComponent>();
    const { configurationTree, isShowNonlinearLoadsModal, isNonLinearLoadsEdit, selectedComponentNodeId, selectedUnit, project } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const hearderText = formState[NonLinearDriveLoadFormFields.parentComponent] === ComponentType.Transformer ? "transformer" : "LV Genset";
    const idRef = useRef<any>();
    const quantityRef = useRef<any>();
    const powerRef = useRef<any>();
    const maximumLoadRef = useRef<any>();
    const applicationNameRef = useRef<any>();
    const lengthRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
    }

    const { t } = useTranslate();

    const handleCloseNonlinearloadsModal = () => {
        dispatch(hideNonlinearLoadsModal());
        dispatch(hideSystemComponentModal());
    }

    const handleCancelNonlinearLoadsModal = () => {
        dispatch(hideNonlinearLoadsModal());
    }

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };

        //added this because select is trigerring change event before it is fully rendered
        if (!fields.hasOwnProperty(name))
            return;

        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const handleDriveTypeChange = ({ detail }: any) => {
        let fields = { ...formState }

        //added this because select is trigerring change event before it is fully rendered
        if (!fields[NonLinearDriveLoadFormFields.drive])
            return;

        fields[NonLinearDriveLoadFormFields.drive] = detail.value;
        fields[NonLinearDriveLoadFormFields.filter] = DriveLoadFilterOptions.None.key.toString();
        setFormState({
            ...fields
        });
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(NonLinearDriveLoadFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    useEffect(() => {
        if (!isShowNonlinearLoadsModal)
            return;
        let selectedComponent = getSelectedComponent(configurationTree, selectedComponentNodeId);
        if (!selectedComponent)
            return;
        if (isNonLinearLoadsEdit) {
            setSelectedNonLinearLoad(selectedComponent);
            let nonLinearData = selectedComponent.componentData as NonLinearDriveLoad;
            let fields = { ...formState };
            let selectedParentComponent = getSelectedComponentParent(configurationTree, configurationTree, selectedComponentNodeId);
            fields[NonLinearDriveLoadFormFields.parentId] = selectedParentComponent?.componentData?.hasOwnProperty("id") ? (selectedParentComponent.componentData as any).id : "";
            const powerMagnitute = getUnitConversion(nonLinearData.power.magnitude, "Power", nonLinearData.power.unit, selectedUnit);
            const lengthMagnitute = getUnitConversion(nonLinearData.length.magnitude, "Length", nonLinearData.length.unit, selectedUnit, 1);
            fields[NonLinearDriveLoadFormFields.toggleSelection] = false;
            fields[NonLinearDriveLoadFormFields.parentComponent] = selectedParentComponent?.componentType;
            fields[NonLinearDriveLoadFormFields.applicationName] = nonLinearData.applicationName;
            fields[NonLinearDriveLoadFormFields.id] = nonLinearData.id;
            fields[NonLinearDriveLoadFormFields.quantity] = nonLinearData.quantity.toString();
            fields[NonLinearDriveLoadFormFields.drive] = nonLinearData.drive.toString();
            fields[NonLinearDriveLoadFormFields.filter] = nonLinearData.filter.toString();
            fields[NonLinearDriveLoadFormFields.power] = powerMagnitute.toString();
            fields[NonLinearDriveLoadFormFields.maximumLoad] = nonLinearData.maximumLoad.toString();
            fields[NonLinearDriveLoadFormFields.length] = lengthMagnitute.toString();
            if (nonLinearData) {
                setFormState({
                    ...fields,
                });
            }
        }
        else {
            let fields = { ...formState };
            const nonLinearLoadDefaults = getNonLinearDriveLoadDefaultValues(project.contact?.country);
            fields[NonLinearDriveLoadFormFields.parentId] = selectedComponent?.componentData?.hasOwnProperty("id") ? (selectedComponent.componentData as any).id : "";
            fields[NonLinearDriveLoadFormFields.toggleSelection] = false;
            fields[NonLinearDriveLoadFormFields.applicationName] = nonLinearLoadDefaults.applicationName;
            fields[NonLinearDriveLoadFormFields.id] = nonLinearLoadDefaults.id;
            fields[NonLinearDriveLoadFormFields.quantity] = nonLinearLoadDefaults.quantity;
            fields[NonLinearDriveLoadFormFields.power] = getUnitConversion(nonLinearLoadDefaults.power.magnitude, "Power", nonLinearLoadDefaults.power.unit, selectedUnit);
            fields[NonLinearDriveLoadFormFields.maximumLoad] = nonLinearLoadDefaults.maximumLoad;
            fields[NonLinearDriveLoadFormFields.length] = getUnitConversion(nonLinearLoadDefaults.length.magnitude, "Length", nonLinearLoadDefaults.length.unit, selectedUnit, 1);
            fields[NonLinearDriveLoadFormFields.drive] = nonLinearLoadDefaults.drive.toString();
            fields[NonLinearDriveLoadFormFields.filter] = nonLinearLoadDefaults.filter.toString();
            fields[NonLinearDriveLoadFormFields.parentComponent] = selectedComponent?.componentType;
            setFormState({
                ...fields
            });
        }

        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowNonlinearLoadsModal, isNonLinearLoadsEdit, configurationTree, selectedComponentNodeId])

    const handleUpdate = () => {
        if (!validateForm())
            return;

        if (!selectedNonLinearLoad) {
            dispatch(hideNonlinearLoadsModal());
            return;
        }
        let component = cloneDeep(selectedNonLinearLoad);
        let nonLinearData = component.componentData as NonLinearDriveLoad;
        if (nonLinearData) {
            nonLinearData.applicationName = formState[NonLinearDriveLoadFormFields.applicationName];
            nonLinearData.id = formState[NonLinearDriveLoadFormFields.id];
            nonLinearData.quantity = parseInt(formState[NonLinearDriveLoadFormFields.quantity]);
            nonLinearData.drive = parseInt(formState[NonLinearDriveLoadFormFields.drive]);
            nonLinearData.filter = parseInt(formState[NonLinearDriveLoadFormFields.filter]);
            nonLinearData.power = { magnitude: parseFloat(formState[NonLinearDriveLoadFormFields.power]), unit: selectedUnit };
            nonLinearData.maximumLoad = parseFloat(formState[NonLinearDriveLoadFormFields.maximumLoad]);
            nonLinearData.length = { magnitude: parseFloat(formState[NonLinearDriveLoadFormFields.length]), unit: selectedUnit };
            dispatch(updateComponent(component));
        }
        dispatch(hideNonlinearLoadsModal());
    }

    const handleAdd = () => {
        if (!validateForm())
            return;
        let nonLinearData: NonLinearDriveLoad = new NonLinearDriveLoad(
            formState[NonLinearDriveLoadFormFields.id],
            formState[NonLinearDriveLoadFormFields.applicationName],
            parseInt(formState[NonLinearDriveLoadFormFields.drive]),
            parseInt(formState[NonLinearDriveLoadFormFields.filter]),
            { magnitude: parseFloat(formState[NonLinearDriveLoadFormFields.power]), unit: selectedUnit },
            parseFloat(formState[NonLinearDriveLoadFormFields.maximumLoad]),
            parseInt(formState[NonLinearDriveLoadFormFields.quantity]),
            { magnitude: parseFloat(formState[NonLinearDriveLoadFormFields.length]), unit: selectedUnit }
        )
        let linearLoadComponent: SystemComponent = {
            componentType: ComponentType.NonLinearDriveLoad,
            componentData: nonLinearData
        };
        if (selectedComponentNodeId) {
            dispatch(addChildComponent(selectedComponentNodeId, linearLoadComponent));
        }
        dispatch(hideNonlinearLoadsModal());
        dispatch(hideSystemComponentModal());
    }

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = idRef.current.validate()
            & quantityRef.current.validate()
            & applicationNameRef.current.validate()
            & lengthRef.current.validate()
            & powerRef.current.validate()
            & maximumLoadRef.current.validate()

        return !!isValid;
    }

    return (
        <ModalDialog
            open={isShowNonlinearLoadsModal}
            onModalClose={handleCloseNonlinearloadsModal}
            showBackButton={true}
            onBack={handleCancelNonlinearLoadsModal}
            modalTitle={isNonLinearLoadsEdit ? t(I18.configuration_page_non_linear_load_modal_popup_edit_title) : t(I18.configuration_page_non_linear_load_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
        >
            <Icon slot="title-icon" name="drive" ></Icon>
            <div className="modal-content" >
                {!isNonLinearLoadsEdit &&
                    <div className='nonlinearloads-text'>{t(I18.configuration_page_non_linear_load_modal_popup_header)} {`${hearderText} ${t(I18.configuration_page_label_id_tag)} ${formState[NonLinearDriveLoadFormFields.parentId]}`} </div>
                }
                {isNonLinearLoadsEdit &&
                    <div className='nonlinearloads-text'>{t(I18.configuration_page_non_linear_load_modal_popup_edit_header)} {`${hearderText} ${t(I18.configuration_page_label_id_tag)} ${formState[NonLinearDriveLoadFormFields.parentId]}`} </div>
                }
                <Input
                    className="input-style first-child"
                    size="medium"
                    labelText={t(I18.configuration_page_label_application_name)}
                    optionalText={t(I18.configuration_page_optional_text_application_name)}
                    value={formState[NonLinearDriveLoadFormFields.applicationName]}
                    onInputChange={(e) => { handleChange(e, "applicationName"); }}
                    heMaxLength={30}
                    ref={applicationNameRef}>
                </Input>
                <Input
                    className="input-style"
                    size="medium"
                    labelText={t(I18.configuration_page_label_id_tag)}
                    optionalText={t(I18.configuration_page_optional_text_id_tag)}
                    value={formState[NonLinearDriveLoadFormFields.id]}
                    onInputChange={(e) => { handleChange(e, "id"); }}
                    heMaxLength={10}
                    ref={idRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_quantity_of_drives)}
                    optionalText={t(I18.configuration_page_optional_text_quantity_of_drives)}
                    value={formState[NonLinearDriveLoadFormFields.quantity]}
                    size="medium"
                    onInputChange={(e) => { handleChange(e, "quantity"); }}
                    heMaxValue={100}
                    heMinValue={1}
                    heDecimalPrecision={0}
                    ref={quantityRef}>
                </Input>
                <div className="hr-style" />
                {
                    /* // Hiding it for the alpha release
                    <div className="label-style">Drives</div>
                    <div className="toggle-text-align">
                        <span className='label-text'>NonABB</span>
                        <ToggleSwitch size="medium" text="ABB" disabled onToggleChange={(e) => { handleChange(e, "toggleSelection"); }}></ToggleSwitch>
                    </div> 
                {formState[NonLinearDriveLoadFormFields.toggleSelection] &&
                    <div>
                        <Select className="select-style" label-text="ABB Drive family" size="medium" optional-text="Choose a drive type"></Select>
                        <Select className="select-style" label-text="Main Voltage" size="medium" optional-text="Choose the main voltage" suffixText="V" required ></Select>
                        <Select className="select-style" label-text="Drive rating" size="medium" optional-text="Choose a drive rating" suffixText="A" required ></Select>
                    </div>
                }
                */
                }
                <SelectContainer
                    className="select-style"
                    labelText={t(I18.configuration_page_label_drive)}
                    size="medium"
                    optionalText={t(I18.configuration_page_optional_text_drive)}
                    required>
                    <Select slot="main"
                        value={formState[NonLinearDriveLoadFormFields.drive]}
                        optionText={formState[NonLinearDriveLoadFormFields.drive] && getSelectOptionArray(DriveLoadDriveOptions).find(item => item.value.key.toString() === formState[NonLinearDriveLoadFormFields.drive])?.value.value}
                        onSelectChange={handleDriveTypeChange}>
                        <div slot="options-main">
                            {getSelectOptionArray(DriveLoadDriveOptions).map((item) =>
                                <SelectOption
                                    key={item.value.key}
                                    data-value={item.value.key}
                                    selected={formState[NonLinearDriveLoadFormFields.drive] && item.value.key.toString() === formState[NonLinearDriveLoadFormFields.drive].toString()}
                                    optionText={item.value.value} >
                                </SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
                <SelectContainer
                    className="select-style"
                    labelText={t(I18.configuration_page_label_additional_filter)}
                    size="medium"
                    optionalText={t(I18.configuration_page_optional_text_additional_filter)}
                    required>
                    <Select
                        slot="main"
                        value={formState[NonLinearDriveLoadFormFields.filter]}
                        optionText={formState[NonLinearDriveLoadFormFields.filter] && getFilterOptionsForSelectedDriveOption(parseInt(formState[NonLinearDriveLoadFormFields.drive])).find(item => item.value.key.toString() === formState[NonLinearDriveLoadFormFields.filter].toString())?.value.value}
                    >
                        <div slot="options-main">
                            {getFilterOptionsForSelectedDriveOption(parseInt(formState[NonLinearDriveLoadFormFields.drive])).map((item) =>
                                <SelectOption
                                    key={item.value.key}
                                    data-value={item.value.key}
                                    selected={formState[NonLinearDriveLoadFormFields.filter] && item.value.key.toString() === formState[NonLinearDriveLoadFormFields.filter].toString()}
                                    optionText={item.value.value}
                                    onOptionClick={(e) => { handleChange({ detail: { value: item.value.key } }, NonLinearDriveLoadFormFields.filter); }}
                                >
                                </SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_rated_power)}
                    optionalText={t(I18.configuration_page_optional_text_rated_power, getUnit("Power", selectedUnit))}
                    suffixText={getUnit("Power", selectedUnit)}
                    size="medium"
                    value={formState[NonLinearDriveLoadFormFields.power]}
                    onInputChange={(e) => { handleChange(e, "power"); }}
                    heMaxValue={getPowerConversionFromSI(4500, selectedUnit)}
                    heMinValue={getPowerConversionFromSI(1, selectedUnit)}
                    heDecimalPrecision={2}
                    ref={powerRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_maximum_load)}
                    optionalText={t(I18.configuration_page_optional_text_maximum_load, UnitSymbols.Percentage)}
                    suffixText={UnitSymbols.Percentage}
                    size="medium"
                    heMaxValue={100}
                    heMinValue={1}
                    value={formState[NonLinearDriveLoadFormFields.maximumLoad]}
                    onInputChange={(e) => { handleChange(e, "maximumLoad"); }}
                    heDecimalPrecision={1}
                    ref={maximumLoadRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_cable_length)}
                    optionalText={t(I18.configuration_page_optional_text_cable_length, (selectedUnit === UnitOptions.SI.key) ? 'meter (m)' : 'feet (ft)')}
                    suffixText={getUnit("Length", selectedUnit)}
                    required
                    size="medium"
                    value={formState[NonLinearDriveLoadFormFields.length]}
                    onInputChange={(e) => { handleChange(e, "length"); }}
                    heMaxValue={getLengthConversionFromSI(1000, selectedUnit, 1)}
                    heMinValue={getLengthConversionFromSI(1, selectedUnit, 1)}
                    heDecimalPrecision={1}
                    ref={lengthRef}>
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={isNonLinearLoadsEdit}
                    onCancelClick={handleCancelNonlinearLoadsModal}
                    onActionButtonClick={handleAdd}
                    onUpdateClick={handleUpdate} />
            </div>
        </ModalDialog>
    )
}
export default NonLinearModalPopup;
