import '../styles/login.scss';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { NavigationPath } from '../utils/Navigation';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslate } from '../i18translate/Hooks';
import { I18 } from '../languages/I18';
import { AuthManager } from './AuthManager';

function Login() {
    const auth = useAuth();
    const { t } = useTranslate();

    useEffect(() => {
        //sign in silently in case already logged in. This is done as auth is not able to check it.
        if (AuthManager.isAuthenticated() && !hasAuthParams() && !auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
            auth.signinSilent();
            return;
        }

        if (!AuthManager.isAuthenticated() && !auth.isLoading)
            auth.signinRedirect();
    }, [auth.isAuthenticated, auth.isLoading, auth.activeNavigator, auth])

    if (auth.error) {
        console.error("Auth Error: ", auth.error?.message)
        return <Navigate to={NavigationPath.Home} />
    }

    if (auth.isAuthenticated && AuthManager.isAuthenticated())
        return <Navigate to={`/${NavigationPath.Project}`} />

    return (
        <div className='container'>
            <div className='login'>
                <h4 className='title abb-cursor'>{t(I18.login_redirect_auth_portal)}</h4>
                <span className='description'>{t(I18.login_waiting_message)}</span>
            </div>
        </div>
    )
}

export default Login