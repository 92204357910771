const LinearLoadFormFields = {
    totalMotorLoads: "totalMotorLoads",
    totalResistiveLoads: "totalResistiveLoads",
    totalSoftStarterLoads: "totalSoftStarterLoads",
    totalALoads: "totalALoads",
    totalKVALoads: "totalKVALoads",
    maxPercentageKVALoads: "maxPercentageKVALoads",

    totalMotorLoadsPowerFactor: "totalMotorLoadsPowerFactor",
    totalResistiveLoadsPowerFactor: "totalResistiveLoadsPowerFactor",
    totalSoftStarterLoadsPowerFactor: "totalSoftStarterLoadsPowerFactor",
    totalALoadsPowerFactor: "totalALoadsPowerFactor",
    totalKVALoadsPowerFactor: "totalKVALoadsPowerFactor",
    maxPercentageKVALoadsPowerFactor: "maxPercentageKVALoadsPowerFactor",
    parentComponent: "parentComponent",
    parentId: "parentId",
    linearLoadSelection: "linearLoadSelection",
}
export default LinearLoadFormFields;