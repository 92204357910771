import Button from '../../../../components/Form/Button';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from "../../../../languages/I18";


interface ModalProps {
    isEdit: boolean;
    onCancelClick?: () => void;
    onActionButtonClick?: () => void;
    onUpdateClick?: () => void;
    actionButtonText?: string;
    isActionButtonDisabled?: boolean;
    isUpdateButtonDisabled?: boolean;
    isLoading?: boolean;
}
function CommonModalFooter(props: ModalProps) {
    const { t } = useTranslate();
    const { isEdit, onCancelClick, onActionButtonClick, onUpdateClick, actionButtonText = t(I18.add), isUpdateButtonDisabled = false, isActionButtonDisabled = false, isLoading = false } = props

    return (
        <div className="commonux-modal-footer" >
            <Button className="add-button" size="medium" type="discreet" onClick={onCancelClick} disabled={isLoading}>{t(I18.cancel)}</Button>
            <span className="add-button">
                {isEdit &&
                    < Button size="medium" type="primary-black" onClick={onUpdateClick} disabled={isUpdateButtonDisabled || isLoading}>{isLoading ? t(I18.updating) : t(I18.update)}</Button>
                }
                {!isEdit && actionButtonText &&
                    < Button size="medium" type="primary-black" onClick={onActionButtonClick} disabled={isActionButtonDisabled || isLoading}>{actionButtonText}</Button>
                }
            </span>
        </div >
    );
}
export default CommonModalFooter;