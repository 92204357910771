import { ComponentType } from "../models/ComponentType";
import { NonLinearDriveLoad } from "../models/NonLinearDriveLoad";
import { SystemComponent } from "../models/SystemComponent";
import { getAllComponentsTransformerLevel } from "./SystemComponentUtils";

export class NonLinearLoadUtils {
    /**
     * Checks if the deletion or disabling of component is allowed or not
     * @param currentComponentNodeId Current component nodeId that needs to be deleted/disabled. 
     * @param configTree Complete config tree object
     * @returns true if the deletion/disabling is allowed otherwise false.
     * Currently this handles only deletion of NonLinearLoad but it can be used modified to handle other components as well.
     */
    static isAllowDeleteOrSwitchOff(currentComponentNodeId: string, configTree: SystemComponent): boolean {
        if (!configTree.childComponents)
            return false;

        let components = getAllComponentsTransformerLevel(currentComponentNodeId, configTree);

        if (!components || components.length <= 0)
            return false;

        return components.filter((item) => item.componentType === ComponentType.NonLinearDriveLoad
            && (!(item.componentData as NonLinearDriveLoad).isDisabled || item.nodeId === currentComponentNodeId)).length > 1; //allow delete or switchoff of the last NonLinearLoad
    }
}