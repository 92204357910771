import UnitOptions from "./UnitOptions";
import { getSelectOptionArray, roundOffByDecimalPrecision } from "../../../utils/Common";
import { UnitSymbols } from "./UnitSymbols";

export const Units = {
    Power: {
        HP: UnitSymbols.PowerHP.toString(),
        kW: UnitSymbols.PowerkW.toString(),
    },
    Length: {
        HP: UnitSymbols.LengthFeet.toString(),
        kW: UnitSymbols.LengthMeter.toString(),
    }
}

/**
 * Use this function to get the SI or US units
 * @param unit the unit type like "Power" or "Length" for which you want to get unit   
 * @param standardUnitType standard unit type like US or SI. Pass from UnitOptions
 * @returns Unit text. 
 */
export function getUnit(unit: "Power" | "Length", standardUnitType: number): string {
    const unitType = getSelectOptionArray(UnitOptions).find((item) => item.value.key === standardUnitType)?.value.value;
    const units = Units[unit];
    if (unitType)
        return units[unitType as keyof typeof units];

    return "";
}

/**
 * Returns converted unit value
 * @param valueToConvert value to be converted
 * @param unit Type of unit conversion Power or Lenght, etc.. 
 * @param fromUnit Unit to be converted from SI or US, etc..
 * @param toUnit Unit to be converted to SI or US, etc..
 * @returns converted value.
 */
export function getUnitConversion(valueToConvert: number, unit: "Power" | "Length", fromUnit: number, toUnit: number, decimalPrecision: number = 2): number {
    if (fromUnit === toUnit)
        return valueToConvert;

    switch (unit) {
        case "Power":
            return powerConversion(valueToConvert, fromUnit, toUnit, decimalPrecision);

        case "Length":
            return lengthConversion(valueToConvert, fromUnit, toUnit, decimalPrecision);

        default:
            return valueToConvert;
    }
}

/**
 * Use it for converting given power to specified unit. Considering SI as base unit
 * @param valueToConvert value to be converted
 * @param toUnit Unit to be converted to SI or US, etc..
 * @returns converted power value
 */
export function getPowerConversionFromSI(valueToConvert: number, toUnit: number) {
    return powerConversion(valueToConvert, UnitOptions.SI.key as number, toUnit)
}

/**
 * Use it for converting given length to specified unit. Considering SI as base unit
 * @param valueToConvert value to be converted
 * @param toUnit Unit to be converted to SI or US, etc..
 * @returns converted length value
 */
export function getLengthConversionFromSI(valueToConvert: number, toUnit: number, decimalPrecision: number = 2) {
    return lengthConversion(valueToConvert, UnitOptions.SI.key as number, toUnit)
}

//Power conversion from kW to HP or vice versa
function powerConversion(valueToConvert: number, fromUnit: number, toUnit: number, decimalPrecision: number = 2): number {
    const conversionConstant: number = 0.746;
    //more cases can be added here
    if (fromUnit === UnitOptions.SI.key && toUnit === UnitOptions.US.key)
        return roundOffByDecimalPrecision(valueToConvert / conversionConstant, decimalPrecision);

    if (fromUnit === UnitOptions.US.key && toUnit === UnitOptions.SI.key)
        return roundOffByDecimalPrecision(valueToConvert * conversionConstant, decimalPrecision);

    return valueToConvert;
}

//length conversion from meter to ft or vice versa 
function lengthConversion(valueToConvert: number, fromUnit: number, toUnit: number, decimalPrecision: number = 2): number {
    const conversionConstant: number = 3.28;
    //more cases can be added here
    if (fromUnit === UnitOptions.SI.key && toUnit === UnitOptions.US.key)
        return roundOffByDecimalPrecision(valueToConvert * conversionConstant, decimalPrecision);

    if (fromUnit === UnitOptions.US.key && toUnit === UnitOptions.SI.key)
        return roundOffByDecimalPrecision(valueToConvert / conversionConstant, decimalPrecision);

    return valueToConvert;
}

export class MagnitudeStandardUnit {
    magnitude: number = 0;
    unit: number = 0;

    public constructor(magnitude?: number, unit?: number) {
        this.magnitude = magnitude ? magnitude : 0;
        this.unit = unit ? unit : 0;
    }
}