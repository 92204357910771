import { MagnitudeStandardUnit } from "./Units";

export class LinearLoad {
    totalMotorLoads: StandardBasedLinearLoad = new StandardBasedLinearLoad();
    totalResistiveLoads: NonStandardBasedLinearLoad = { power: 0, powerFactor: 1 };
    totalSoftStarterLoads: StandardBasedLinearLoad = new StandardBasedLinearLoad();
    totalALoads: NonStandardBasedLinearLoad = new NonStandardBasedLinearLoad();
    totalKVALoads: NonStandardBasedLinearLoad = new NonStandardBasedLinearLoad();
    maxPercentageKVALoads: NonStandardBasedLinearLoad = new NonStandardBasedLinearLoad();
    selectedLoadType: "Transformer" | "LV Genset" | "specificLoad" = "specificLoad";
}

export class NonStandardBasedLinearLoad {
    power: number = 0;
    powerFactor: number = 0.90;
}

export class StandardBasedLinearLoad {
    power: MagnitudeStandardUnit = { magnitude: 0, unit: 0 };
    powerFactor: number = 0.85;
}