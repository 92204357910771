import '../styles/dashedbutton.scss';
import Icon from "./Icon";

export interface DashedButtonProps {
  label: string;
  iconName: string;
  iconSize?: "small" | "medium" | "large";
  onClick?: () => void;
  disabled: boolean;
}


function DashedButton(props: DashedButtonProps) {
  const { label, iconName, iconSize, onClick, disabled } = props;

  return (
    <div className={`card-strip  dashedbutton ${disabled ? 'disabled' : ''}`} onClick={disabled ? undefined : onClick} >
      <span className="dashedbutton-icon-style">
        <Icon size={iconSize} name={iconName} className="icon-gt"></Icon>
      </span>
      <span className={`dashedbutton-content-style ${disabled ? 'disabled' : ''}`}>{label}</span>
    </div>
  );
}
export default DashedButton;
