import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

var reactPlugin = new ReactPlugin();

 let iKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;
 let appInsights = new ApplicationInsights({
            config: {
            instrumentationKey: iKey,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin]
            }
 });
 appInsights.loadAppInsights();

 export { reactPlugin, appInsights };