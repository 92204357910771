import { clone } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import SelectContainer from "../../../../components/Form/SelectContainer";
import SelectOption from "../../../../components/Form/SelectOption";
import ToggleSwitch from "../../../../components/Form/ToggleSwitch";
import HarmonicIcon from "../../../../components/HarmonicIcon";
import Icon from "../../../../components/Icon";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import { useAppSelector } from "../../../../store/hooks";
import { getSelectOptionArray, isStringEmpty } from "../../../../utils/Common";
import NonLinearDriveLoadFormFields from "../../models/FormFields/NonLinearDriveLoadFormFields";
import { DriveLoadDriveOptions, DriveLoadFilterOptions, getFilterOptionsForSelectedDriveOption, NonLinearDriveLoad } from "../../models/NonLinearDriveLoad";
import { getPowerConversionFromSI, getUnit, getUnitConversion } from "../../models/Units";
import { NonLinearLoadUtils } from "../../utils/NonLinearLoadUtils";
import ComponentValue from "../ComponentValue";
interface CardStripNonLinearDriveLoadPorps {
    data: NonLinearDriveLoad;
    nodeId: string;
    onUpdate: (data: NonLinearDriveLoad) => void;
}

function CardStripNonLinearDriveLoad(props: CardStripNonLinearDriveLoadPorps) {
    const { selectedUnit, configurationTree } = useAppSelector(state => state.configuration);
    const { data, nodeId, onUpdate } = props;
    const [cardStripState, setCardStripState] = useState<any>({ ...data });
    const idRef = useRef<any>();
    const powerRef = useRef<any>();

    useEffect(() => {
        resetCardStripState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedUnit]);

    const resetCardStripState = () => {
        let updatedData = { ...data }
        updatedData.power = {
            magnitude: getUnitConversion(updatedData.power.magnitude, "Power", updatedData.power.unit, selectedUnit),
            unit: selectedUnit
        }
        setCardStripState({ ...updatedData });
    }

    const handleDriveTypeChange = ({ detail }: any) => {
        let updatedData = clone(data);
        updatedData.drive = parseInt(detail.value);
        updatedData.filter = DriveLoadFilterOptions.None.key as number;
        onUpdate(updatedData);
    }

    const handleFilterTypeChange = ({ detail }: any) => {
        let filter = parseInt(detail.value)
        if (data.filter === filter)
            return;
        let updatedData = clone(data);
        updatedData.filter = filter;
        onUpdate(updatedData);
    }

    const handleDriveSwitchToggleChange = (event: any) => {
        const { detail } = event;

        if (detail.value === undefined)
            return;

        if (!detail.value && !NonLinearLoadUtils.isAllowDeleteOrSwitchOff(nodeId, configurationTree)) {
            event.preventDefault();
            alert('Atleast one non-linear load must be active');
            return;
        }

        let updatedData = clone(data);
        updatedData.isDisabled = !detail.value;
        onUpdate(updatedData);
    }

    const handleOnUpdate = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        if (name === NonLinearDriveLoadFormFields.power) {
            state.power = { ...state.power, magnitude: detail.value, unit: selectedUnit };
        }
        else {
            state[name] = detail.value;
        }
        setCardStripState({ ...state });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === NonLinearDriveLoadFormFields.id && !idRef.current.validate())
            return false;

        if (editFiledId === NonLinearDriveLoadFormFields.power && !powerRef.current.validate())
            return false;

        let updatedData = clone(data);

        switch (editFiledId) {
            case NonLinearDriveLoadFormFields.id:
                (updatedData as any)[editFiledId] = cardStripState[editFiledId];
                break;

            case NonLinearDriveLoadFormFields.power:
                updatedData.power = { ...updatedData.power, magnitude: parseFloat(cardStripState.power.magnitude), unit: selectedUnit };
                break;

            default:
                (updatedData as any)[editFiledId] = parseFloat(cardStripState[editFiledId]);
                break;
        }
        onUpdate(updatedData)

        return true;
    }

    const handleCancel = () => {
        resetCardStripState();
    }

    const { t } = useTranslate();

    return (
        <>
            {data.quantity > 1
                ? <HarmonicIcon className="component-icon" size="small" name="multi-drive" />
                : <Icon className="component-icon" size="small" name="drive" />
            }
            {
                data.quantity > 0 &&
                <span className={`drive-quantity drive-quantity-${data.quantity.toString().length}`}>
                    {data.quantity}
                </span>
            }
            <ToggleSwitch className="drive-switch" size="medium" type="primary-black" showicon checked={!data.isDisabled} onToggleChange={handleDriveSwitchToggleChange} ></ToggleSwitch>
            {
                !isStringEmpty(data.applicationName) &&
                <span className="application-name body-1-regular display-xl">
                    {data.applicationName}
                </span>
            }
            {/* //*NOTE for below drop downs onOptionClick is used instead of onSelectChange to prevent multiple updates */}
            <SelectContainer
                className="drive-option"
                size="medium"
                required>
                <Select
                    slot="main"
                    placeholder={t(I18.configuration_page_label_drive)}
                    variant="floating"
                    value={data.drive.toString()}
                    optionText={getSelectOptionArray(DriveLoadDriveOptions).find(item => item.value.key === cardStripState.drive)?.value.value}
                >
                    <div slot="options-main">
                        {getSelectOptionArray(DriveLoadDriveOptions).map((item) =>
                            <SelectOption
                                key={item.value.key}
                                data-value={item.value.key}
                                selected={item.value.key.toString() === cardStripState.drive.toString()}
                                optionText={item.value.value}
                                onOptionClick={e => handleDriveTypeChange({ detail: { value: item.value.key } })}
                            >
                            </SelectOption>)
                        }
                    </div>
                </Select>
            </SelectContainer>
            <SelectContainer
                className="filter-option"
                size="medium"
                required>
                <Select
                    slot="main"
                    placeholder={t(I18.configuration_page_label_additional_filter)}
                    value={data.filter.toString()}
                    optionText={getFilterOptionsForSelectedDriveOption(cardStripState.drive).find(item => item.value.key === cardStripState.filter)?.value.value}
                    variant="floating"
                    max-option={5}
                >
                    <div slot="options-main">
                        {getFilterOptionsForSelectedDriveOption(cardStripState.drive).map((item) =>
                            <SelectOption
                                key={item.value.key}
                                data-value={item.value.key}
                                selected={item.value.key.toString() === cardStripState.filter.toString()}
                                optionText={item.value.value}
                                onOptionClick={e => handleFilterTypeChange({ detail: { value: item.value.key } })}
                            ></SelectOption>)
                        }
                    </div>
                </Select>
            </SelectContainer>
            <ComponentValue
                labelText={t(I18.configuration_page_label_id_tag)}
                value={data.id}
                editable
                fieldId={'id'}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
                className=" display-xl">
                <Input
                    placeholder="0"
                    inputType='text'
                    size="small"
                    optionalText={t(I18.configuration_page_optional_text_id_tag_inline_edit_popup)}
                    heMaxLength={10}
                    value={cardStripState.id}
                    onInputChange={(e) => { handleOnUpdate(e, NonLinearDriveLoadFormFields.id); }}
                    ref={idRef}>
                </Input>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_rated_power)}
                value={`${getUnitConversion(data.power.magnitude, "Power", data.power.unit, selectedUnit)} ${getUnit("Power", selectedUnit)}`}
                editable
                fieldId={NonLinearDriveLoadFormFields.power}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
                className=" display-xl">
                <Input
                    placeholder="0"
                    inputType='number'
                    size="small"
                    optionalText={t(I18.configuration_page_optional_text_rated_power_short_form, getUnit("Power", selectedUnit))}
                    suffixText={getUnit("Power", selectedUnit)}
                    heIsRequired={true}
                    heIsNumberOnly={true}
                    heMinValue={getPowerConversionFromSI(1, selectedUnit)}
                    heMaxValue={getPowerConversionFromSI(4500, selectedUnit)}
                    heDecimalPrecision={2}
                    value={cardStripState.power.magnitude.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, NonLinearDriveLoadFormFields.power); }}
                    ref={powerRef}>
                </Input>
            </ComponentValue>
        </>
    )
}
export default memo(CardStripNonLinearDriveLoad)