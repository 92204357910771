import { AuthManager } from './../../../authServices/AuthManager';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';


const baseUrlEnv = process.env.REACT_APP_API_URL;
const baseUrl = baseUrlEnv;

export const ImportValidationAPI = createApi({
    reducerPath: "ImportValidationAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${AuthManager.getUser()?.access_token}`);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        validateConfigurationJson: builder.mutation<any, any>(
            {
                query: (data) => (
                    {
                        url: 'Validator/HarmonicsConfiguration',
                        method: 'POST',
                        body: data
                    }
                )
            })
    })
})

export const { useValidateConfigurationJsonMutation } = ImportValidationAPI