import { CommonuxLeftPane } from "@abb-ux/commonux-web-components-react";
import { JSX } from '@abb-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from "react";
import "../pages/Project/styles/leftpaneitem.scss"

interface LeftPaneProps extends JSX.CommonuxLeftPane {
    allignment?: "row" | "column";
    className?: string;
    isLoading?: boolean
}

const LeftPane = (props: PropsWithChildren<LeftPaneProps>) => {
    const { allignment = "column", className, isLoading = false } = props
    return <CommonuxLeftPane {...props} className={`leftpan-wrapper allignment-${allignment} ${className ? className : ""} ${isLoading ? 'skeleton' : ''}`}>{props.children}</CommonuxLeftPane>
}

export default LeftPane