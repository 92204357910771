import { SelectOptionValues } from "../../../models/SelectOptionKeyValuePair";
import { getFormatedComponentData } from "../utils/SystemComponentUtils";
import { ComponentType } from "./ComponentType";
import { ConfigurationLoads } from "./ConfigurationLoads";
import { ConfigurationStrategy } from "./ConfigurationStrategy";
import { powergridRadioOptions } from "./FormFields/PowerGridFormFields";
import { Load } from "./Load";
import { SystemComponent } from "./SystemComponent";
import { Transformer } from "./Transfomer";

export interface PowerSource {
    frequency: number;
    ratedVoltage: number;
}

export class PowerGrid implements PowerSource, ConfigurationStrategy {
    mvasc?: number;
    transformers: Transformer[] = [];
    isc?: number;
    resistance?: number;
    inductance?: number;
    powergridRadioSelection?: string;
    frequency: number;
    ratedVoltage: number;

    public constructor(
        frequency?: number,
        ratedVoltage?: number,
        mvasc?: number,

    ) {
        this.mvasc = mvasc;
        this.powergridRadioSelection = powergridRadioOptions.mvasc;
        this.frequency = frequency ?? PowerGridFrequencyOptions.Hz50.key as number;
        this.ratedVoltage = ratedVoltage ?? 0;
    }

    addChildComponent(load: any): void {
        if (load.constructor.name === Transformer.name)
            this.transformers.push(load);
    }

    getChildComponents(): SystemComponent[] | undefined {
        if (!this.transformers || this.transformers.length === 0)
            return undefined;

        let childComponents: SystemComponent[] = [];
        this.transformers.forEach((transformer) => {
            let trans: Transformer = new Transformer();
            if (typeof transformer.getChildComponents !== 'function')
                Object.assign(trans, transformer);
            else
                trans = transformer;
            childComponents.push(getFormatedComponentData(ComponentType.Transformer, trans.getComponentData(), trans.getChildComponents()))
        })
        return childComponents;
    }

    getComponentData(): PowerGrid {
        const powerGrid = new PowerGrid();
        Object.assign(powerGrid, this);
        //*This will set the transformer as blank otherwise it will have repetative data both in Powergrid and transformer
        //*for component data we don't need child components data
        powerGrid.transformers = []
        return powerGrid;
    }
}
export class LVGenset extends Load implements PowerSource {
    id?: string;
    ratedPower: number = 0;
    ratedPf: number = 0;
    ratedXd: number = 0;
    frequency: number;
    ratedVoltage: number

    public constructor(
        frequency?: number,
        ratedVoltage?: number,
        id?: string,
        ratedPower?: number,
        ratedPf?: number,
        ratedXd?: number
    ) {
        super();
        this.id = id ? id : "";
        this.ratedPower = ratedPower ? ratedPower : 0;
        this.ratedPf = ratedPf ? ratedPf : 0;
        this.ratedXd = ratedXd ? ratedXd : 0;
        this.frequency = frequency ?? PowerGridFrequencyOptions.Hz50.key as number;
        this.ratedVoltage = ratedVoltage ?? 0;
    }

    getComponentData(): LVGenset {
        const lvGenset = new LVGenset();
        Object.assign(lvGenset, this);
        //*This will set the loads as blank 
        //*for component data we don't need child components data
        lvGenset.load = new ConfigurationLoads();
        return lvGenset;
    }
}
export class PowerSourceOptions {
    public static PowerGrid: SelectOptionValues = { key: 0, value: "Power Grid" }
    public static LVGenset: SelectOptionValues = { key: 1, value: "LV Genset" }
}
export class FrequencyOptions {
    public static Hz50: SelectOptionValues = { key: 50, value: "50" };
    public static Hz60: SelectOptionValues = { key: 60, value: "60" };
}
export class PowerGridFrequencyOptions extends FrequencyOptions {

}
export class LvGensetFrequencyOptions extends FrequencyOptions {

}