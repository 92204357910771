import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { ConfigurationAction } from '../../store/actionTypes';
import CommonModalFooter from './CommonModalFooter';
import { isStringEmpty } from "../../../../utils/Common";
import Input from '../../../../components/Form/Input';
import ModalDialog from '../../../../components/ModalDialog';
import { hideExportConfigurationModal } from '../../store/actions';
import { getAPIRequestModel } from '../../models/ConfigTreeToAPIObjectMapper';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

function ExportConfigurationModalPopup() {
    const { isShowExportConfigurationModal, configuration } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const [exportFileName, setExportFileName] = useState<string>("");
    const fileNameRef = useRef<any>();
    const { t } = useTranslate();

    const handleCloseExportConfigurationModal = () => {
        dispatch(hideExportConfigurationModal());
    }

    const handleFileNameChange = ({ detail }: any) => setExportFileName(detail.value);

    useEffect(() => {
        setExportFileName(configuration.configurationName)
        return () => setExportFileName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleExport = () => {
        if (!validateForm())
            return;

        const configRequest = getAPIRequestModel();
        const configJson = JSON.stringify(configRequest, null, "\t");
        const blob = new Blob([configJson], { type: "application/json" });
        const url = URL || webkitURL
        const href = url.createObjectURL(blob);

        //create anchor tag
        let anchor = document.createElement('a');
        anchor.href = href
        anchor.download = `${exportFileName}.json`;
        anchor.click();
        dispatch(hideExportConfigurationModal());
    }

    const validateForm = (): boolean => fileNameRef.current.validate();

    return (
        <ModalDialog
            open={isShowExportConfigurationModal}
            onModalClose={handleCloseExportConfigurationModal}
            modalTitle={t(I18.configuration_page_export_configuration_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup">
            <div className="modal-content">
                <Input
                    className="input-style first-child"
                    size="medium"
                    labelText={t(I18.configuration_page_export_configuration_modal_popup_label_file_name)}
                    optionalText={t(I18.configuration_page_export_configuration_modal_popup_optional_text_file_name)}
                    required
                    value={exportFileName}
                    onInputInput={handleFileNameChange}
                    heMaxLength={100}
                    ref={fileNameRef} >
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={false}
                    onCancelClick={handleCloseExportConfigurationModal}
                    onActionButtonClick={handleExport}
                    actionButtonText={t(I18.export)}
                    isActionButtonDisabled={isStringEmpty(exportFileName)} />
            </div>
        </ModalDialog>
    )
}
export default ExportConfigurationModalPopup;