import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ArticleModel } from "../models/ArticleModel";

const baseUrlEnv = process.env.REACT_APP_API_URL;
const baseUrl = `${baseUrlEnv}/`;

export const articalAndCaseStudyAPI = createApi({
    reducerPath: 'articalAndCaseStudy',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getArticals: builder.query<[ArticleModel], void>({
            query: () => `articles`
        }),
        getCaseStudy: builder.query<[ArticleModel], void>({
            query: () => `casestudies`
        })
    })
});

export const { useGetArticalsQuery, useGetCaseStudyQuery } = articalAndCaseStudyAPI;