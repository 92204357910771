import { UnitSymbols } from "../models/UnitSymbols";

export interface LoadDetailstemProps {
    title: string;
    Amps?: number;
    kVA?: number;
    kW?: number;
    PF?: number;
    loading?: boolean;
}
function LoadDetailsItem(props: LoadDetailstemProps) {
    const { title, Amps, kVA, kW, PF, loading = false } = props;
    const classSkeleton = loading ? 'skeleton' : ''
    const getLoadItem = (unitTitle: string, value?: number, className?: string) => {
        return (
            <div className={`load-item ${classSkeleton}`}>
                {
                    value !== undefined &&
                    <>
                        <label className={`load-item-label`}>{unitTitle}</label>
                        <span 
                        className={`body-1-medium`} 
                        // eslint-disable-next-line
                        role={"load-item-values"}
                        >{value}</span>
                    </>
                }
            </div>
        )
    }
    return (
        <>
            <li>
                <label className={`load-details-text`}>{title}</label>
            </li>
            <li>
                <div className="load-items">
                    {getLoadItem(UnitSymbols.CurrentAmps, Amps)}
                    {getLoadItem(UnitSymbols.kVA, kVA)}
                    {getLoadItem(UnitSymbols.PowerkW, kW)}
                    {getLoadItem(UnitSymbols.PowerFactorPF, PF, "load-item-value-lower-limit")}
                </div>
            </li>
        </>
    )
}

export default LoadDetailsItem