import Icon from '../../../components/Icon';
import "../styles/projectdashedbutton.scss";

interface ProjectDashedButtonProps {
    label: string;
    additionalText: string;
    iconName: string;
    iconSize?: "small" | "medium" | "large";
    onClick?: () => void;
    disabled?: boolean;
}

const ProjectDashedButton = (props: ProjectDashedButtonProps) => {
    const { label, additionalText, iconName, iconSize, onClick, disabled = false } = props;
    return (
        <div className={`projectdashedbutton ${disabled ? "disabled" : ""}`} onClick={disabled ? undefined : onClick}>
            <div className='projectdashedbutton-text-wrapper'>
                <span className='body-2-regular'>{label}</span>
                <span className='projectdashedbutton-text-additional description-regular'>{additionalText}</span>
            </div>
            <div className='projectdashedbutton-icon-style'>
                <Icon name={iconName} size={iconSize}></Icon>
            </div>
        </div>
    )
}

export default ProjectDashedButton