export enum UnitSymbols {
    Current = "A",
    PowerHP = "HP",
    PowerkW = "kW",
    kVA = "kVA",
    VoltageV = "V",
    VoltagekV = "kV",
    LengthFeet = "ft",
    LengthMeter = "m",
    Frequency = "Hz",
    Percentage = "%",
    PowerFactor = "cos(Φ)",
    Xd = "Xd”",
    CurrentAmps = "Amps",
    PowerFactorPF = "PF",
    ResistanceOhm = "Ω",
    PerUnit = "pu"
}