import NarrowMenu from "../../../components/NarrowMenu";
import { NarrowMenuItemProps } from "../../../components/NarrowMenuItem";
import { SystemComponent } from "../models/SystemComponent";
import { ComponentType } from "../models/ComponentType";
import { getSelectedComponentParent, isStringEmpty } from "../../../utils/Common";
import { useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import { ConfigurationAction } from "../store/actionTypes";
import { deleteChildComponent, addChildComponent, selectComponent, showActiveHarmonicFilterModal, showEditLvGensetModal, showEditPowerGridModal, showLinearLoadModal, showMotorControlCenterModal, showNonlinearLoadsModal, showSystemComponentModal, showUserTransformerModal, updateComponent } from "../store/actions";
import CardStripPowerSource from "./CardStripComponents/CardStripPowerSource";
import CardStripTransformer from "./CardStripComponents/CardStripTransformer";
import { LVGenset, PowerGrid } from "../models/PowerSource";
import { Transformer } from "../models/Transfomer";
import CardStripNonLinearDriveLoad from "./CardStripComponents/CardStripNonLinearDriveLoad";
import { NonLinearDriveLoad } from "../models/NonLinearDriveLoad";
import { cloneDeep } from "lodash";
import ToolTip from "../../../components/ToolTip";
import CardStripActiveHarmonicFilter from "./CardStripComponents/CardStripActiveHarmonicFilter";
import { ActiveHarmonicFilter } from "../models/ActiveHarmonicFilter";
import { MotorControlCenter } from "../models/MotorControlCenter";
import CardStripMotorControlCenter from "./CardStripComponents/CardStripMotorControlCenter";
import "../styles/cardstrip.scss";
import CardStripLinearLoad from "./CardStripComponents/CardStripLinearLoad";
import { LinearLoad } from "../models/LinearLoad";
import { NonLinearLoadUtils } from "../utils/NonLinearLoadUtils";
import MinAHFRating from "./CardStripComponents/MinAHFRating";
import PCCSelectComponent from "./CardStripComponents/PCCSelectComponent";
import Button from "../../../components/Form/Button";
import Icon from "../../../components/Icon";
import NotificationManager from "../../../utils/NotificationManager";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";

interface CardStripProps {
  id?: string;
  size?: "large" | "small";
  systemComponent: SystemComponent;
}

function CardStrip(props: React.PropsWithChildren<CardStripProps>) {

  const { size = "small", id, systemComponent } = props;
  const componentType = systemComponent.componentType;
  const { selectedComponentNodeId, selectedPCCNodeId, configurationTree } = useAppSelector(state => state.configuration);
  const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
  const isShowPCC = componentType === ComponentType.PowerGrid ||
    componentType === ComponentType.LVGenset ||
    componentType === ComponentType.Transformer;
  const isShowMinAHFRating = componentType === ComponentType.ActiveHarmonicFilter;
  const isSelected = !isStringEmpty(id) && selectedComponentNodeId === id;
  const isPCCSelected = isShowPCC && !isStringEmpty(id) && selectedPCCNodeId === id;
  const selectedTransfomer = { isEdit: true, selectedTransformer: "" };
  const selectedNonLinearLoads = { isEdit: true, selectedNonLinearLoads: "" };
  const selectedActiveHarmonicFilter = { isEdit: true, selectedActiveHarmonicFilter: "" };
  const selectedMotorControlCenter = { isEdit: true, selectedMotorControlCenter: "" };
  const selectedLinearLoad = { isEdit: true };

  const { t } = useTranslate();

  const handleDuplicateComponent = () => {
    let systemParentComponent = getSelectedComponentParent(configurationTree, configurationTree, systemComponent?.nodeId)
    if (systemParentComponent?.nodeId != null) {
      let clonedSystemComponent = cloneDeep(systemComponent);
      dispatch(addChildComponent(systemParentComponent.nodeId, clonedSystemComponent));
    }
  }

  const handleOnClickMenuItem = (value: string) => {
    switch (value) {
      case 'Add':
        dispatch(showSystemComponentModal());
        break;

      case 'Delete':
        handleDeleteComponent();
        break;

      case 'Duplicate':
        handleDuplicateComponent();
        break;
    }
  }

  const handleDeleteComponent = () => {
    if (componentType === ComponentType.NonLinearDriveLoad && !NonLinearLoadUtils.isAllowDeleteOrSwitchOff(systemComponent.nodeId || "", configurationTree)) {
      alert("Configuration must have atleast one active non-linear load");
      return;
    }
    dispatch(deleteChildComponent(systemComponent));
    NotificationManager.info("Component has been deleted successfully!");
  }

  const handleCardSelect = () => {
    const selectedNodeId: string = id ? id : "";
    if (selectedComponentNodeId !== selectedNodeId)
      dispatch(selectComponent(selectedNodeId));
  }

  const onClickEdit = () => {
    switch (componentType) {

      case ComponentType.LVGenset:
        dispatch(showEditLvGensetModal());
        break;

      case ComponentType.PowerGrid:
        dispatch(showEditPowerGridModal());
        break;

      case ComponentType.Transformer:
        dispatch(showUserTransformerModal(selectedTransfomer));
        break;

      case ComponentType.NonLinearDriveLoad:
        dispatch(showNonlinearLoadsModal(selectedNonLinearLoads));
        break;

      case ComponentType.ActiveHarmonicFilter:
        dispatch(showActiveHarmonicFilterModal(selectedActiveHarmonicFilter));
        break;

      case ComponentType.MCC:
        dispatch(showMotorControlCenterModal(selectedMotorControlCenter));
        break;

      case ComponentType.LinearLoad:
        dispatch(showLinearLoadModal(selectedLinearLoad));
        break;

    }
  }

  const getNarrowMenuItem = (): NarrowMenuItemProps[] => {
    let menuItems: NarrowMenuItemProps[] = [];
    switch (componentType) {
      case ComponentType.PowerGrid:
        menuItems.push({ label: t(I18.add), value: "Add" });
        break;

      case ComponentType.LVGenset:
      case ComponentType.Transformer:
        menuItems.push({ label: t(I18.add), value: "Add", onClick: handleOnClickMenuItem });
        break;

      case ComponentType.NonLinearDriveLoad:
        menuItems.push({ label: t(I18.duplicate), value: "Duplicate", onClick: handleOnClickMenuItem });
        menuItems.push({ label: t(I18.delete), value: "Delete", onClick: handleOnClickMenuItem });
        break;
      case ComponentType.ActiveHarmonicFilter:
      case ComponentType.LinearLoad:
        menuItems.push({ label: t(I18.delete), value: "Delete", onClick: handleOnClickMenuItem });
        break;

      case ComponentType.MCC:
        menuItems.push({ label: t(I18.add), value: "Add", onClick: handleOnClickMenuItem });
        menuItems.push({ label: t(I18.delete), value: "Delete", onClick: handleOnClickMenuItem });
        break;
    }
    return menuItems;
  }

  const handleOnComponentUpdate = (data: any) => {
    let sysComponent = cloneDeep(systemComponent);
    sysComponent.componentData = data;
    dispatch(updateComponent(sysComponent));
  }

  const getCompentValues = () => {
    switch (componentType) {
      case ComponentType.PowerGrid:
      case ComponentType.LVGenset:
        return <CardStripPowerSource data={systemComponent.componentData as PowerGrid | LVGenset} onUpdate={handleOnComponentUpdate} />;

      case ComponentType.Transformer:
        return <CardStripTransformer data={systemComponent.componentData as Transformer} onUpdate={handleOnComponentUpdate} />;

      case ComponentType.NonLinearDriveLoad:
        return <CardStripNonLinearDriveLoad
          data={systemComponent.componentData as NonLinearDriveLoad}
          nodeId={systemComponent.nodeId || ""}
          onUpdate={handleOnComponentUpdate}
        />;

      case ComponentType.ActiveHarmonicFilter:
        return <CardStripActiveHarmonicFilter data={systemComponent.componentData as ActiveHarmonicFilter} onUpdate={handleOnComponentUpdate} />;

      case ComponentType.MCC:
        return <CardStripMotorControlCenter data={systemComponent.componentData as MotorControlCenter} onUpdate={handleOnComponentUpdate} />;

      case ComponentType.LinearLoad:
        return <CardStripLinearLoad data={systemComponent.componentData as LinearLoad} onUpdate={handleOnComponentUpdate} />;

      default:
        break;
    }
  }

  return (
    <div id={id} className={`card-strip ${size} ${isSelected ? 'selected' : ''} ${isPCCSelected ? 'pcc-selected' : ''}`} onClick={handleCardSelect} data-test={componentType} >
      <section className="left">
        {/* Selected component indicator  */}
        <span className="selected-pipe"></span>

        {/* Components */}
        {
          getCompentValues()
        }
      </section>

      <section className="right">
        {isShowPCC &&
          <ToolTip label={t(I18.configuration_page_label_point_of_common_coupling)} position="top">
            <PCCSelectComponent id={id} isPCCSelected={isPCCSelected} systemComponent={systemComponent} />
          </ToolTip>
        }
        {isShowMinAHFRating &&
          <MinAHFRating />
        }
        <Button type='discreet' size='small' className={`edit-button ${isSelected ? 'selected' : ''}`} onClick={onClickEdit}>
          <Icon slot="icon" size="small" name="edit"></Icon>
        </Button>
        <section id="action-controls">
          <NarrowMenu disabled={componentType === ComponentType.PowerGrid} menuItems={getNarrowMenuItem()} />
        </section>
      </section>
    </div>
  )
}
export default CardStrip