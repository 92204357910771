import ReportSummarySection from './ReportSummarySection';
import Button from '../../../components/Form/Button';
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useGetReportPdfMutation } from '../services/APIService';
import { useAppSelector } from '../../../store/hooks';
import { ReportRequestModel } from '../models/ReportRequestModel';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../utils/Navigation';
import Icon from '../../../components/Icon';
import { showEmailModal } from '../store/actions';
import { ResultSummaryRequestPropModel } from '../models/ResultSummaryRequestPropModel';

function GenerateReport(props: ResultSummaryRequestPropModel) {
    const { resultSummaryRequest } = props;
    const { reportHeaderConfig } = useAppSelector(store => store.report);
    const [isDownloading, setIsDownloading] = useState(false);
    const [getReportPdf] = useGetReportPdfMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDownload = () => {
        if (!resultSummaryRequest) {
            console.error("Download Report. Missing request JSON.");
            return;
        }

        setIsDownloading(true);
        const reportRequest = new ReportRequestModel(reportHeaderConfig, resultSummaryRequest);
        if (reportRequest) {
            getReportPdf(reportRequest).unwrap()
                .then(data => {
                    setIsDownloading(false);
                })
                .catch(error => {
                    console.error("Error in downloading report. ", error);
                    setIsDownloading(false);
                })
        }
    }

    // const handlePrint = () => {
    //     navigate(`${NavigationPath.ReportPrint}`);
    // }

    const handleEmail = () => {
        dispatch(showEmailModal());
    }

    return (
        <section id='summary' className='summary print-hidden'>
            <div className='report-functions'>
                <Button size='medium' type='discreet' disabled={isDownloading} onClick={handleDownload}>
                    <Icon name="download" size="small" slot='icon' ></Icon>
                    {isDownloading ? "Downloading..." : "Download"}
                </Button>
                {/* Removing Print button for release
                <Button size='medium' type='discreet' onClick={handlePrint}>
                    <Icon name="print" size="small" slot='icon' ></Icon>
                    Print
                </Button> */}
                <Button size='medium' type='discreet' onClick={handleEmail}>
                    <Icon name="envelope" size="small" slot='icon' ></Icon>
                    Email
                </Button>

            </div>
            {
                <ReportSummarySection />
            }
        </section>
    )
}
export default GenerateReport;

