import { CommonuxNavigationLink } from "@abb-ux/commonux-web-components-react";
import { JSX } from "@abb-ux/commonux-web-components/dist/types";
import { PropsWithChildren } from "react";
import "../styles/commonux/navigationLink.scss"

interface NavigationProps extends JSX.CommonuxNavigationLink {
  className?: string;
  id?: string;
  blackFont?: boolean;
}

const NavigationLink = (props: PropsWithChildren<NavigationProps>) => {
  const { className, blackFont = true } = props
  return (
    <CommonuxNavigationLink {...props}
      className={`CommonuxNavigationLink ${className ?? ""} pointer ${blackFont ? "black-font" : ""}`}>
      {props.children}
    </CommonuxNavigationLink>
  );
};

export default NavigationLink;
