const NonLinearDriveLoadFormFields = {
    toggleSelection: "toggleSelection",
    parentId: "parentId",
    parentComponent: "parentComponent",
    applicationName: "applicationName",   
    id: "id",
    quantity: "quantity",
    drive: "drive",
    filter: "filter",
    power: "power",
    maximumLoad: "maximumLoad",
    length: "length"
}
export default NonLinearDriveLoadFormFields;