import Button from "../../../components/Form/Button"
import Icon from "../../../components/Icon"
import NarrowMenu from "../../../components/NarrowMenu"
import { NarrowMenuItemProps } from "../../../components/NarrowMenuItem";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";
import { NavigationPath } from "../../../utils/Navigation";
import { ConfigurationModel } from "../model/ConfigurationModel";
import { getLocalDateFromUTC } from "../../../utils/Common";

interface ConfigurationListItemProps {
    configuration: ConfigurationModel;
    onMenuAction?: (action: string) => void;
    isLoading?: boolean;
}

function ConfigurationListItem(props: ConfigurationListItemProps) {
    const { configuration, onMenuAction, isLoading = false } = props;
    const { configurationID, projectID, configurationName = "Untitled Configuration", updatedDateTime = Date.now() } = configuration
    const navigate = useNavigate();
    const { t } = useTranslate();

    const handleEdit = () => {
        navigate(`/${NavigationPath.Configuration}/${projectID}/${configurationID}`);
    }

    const handleNarrowMenuItemClick = (actionType: string) => {
        if (!onMenuAction)
            return;

        onMenuAction(actionType);
    }

    const getNarrowMenuItem = (): NarrowMenuItemProps[] => [
        { label: t(I18.rename), value: "rename", onClick: handleNarrowMenuItemClick },
        { label: t(I18.duplicate), value: "duplicate", onClick: handleNarrowMenuItemClick },
        { label: t(I18.delete), value: "delete", onClick: handleNarrowMenuItemClick },
    ];

    return (
        <div className={`configuration-list-item ${isLoading ? "skeleton" : ""}`}>
            <article className="configuration-detail">
                <div className="body-1-medium configuration-name">
                    {configurationName}
                </div>
                <span className="description-regular configuration-description">
                    <Moment fromNow>{getLocalDateFromUTC(new Date(updatedDateTime))}</Moment>
                </span>
            </article>
            <Button type='discreet' className="edit-button" size='small' onClick={handleEdit} disabled={isLoading}>
                <Icon slot="icon" size="small" name="edit"></Icon>
            </Button>
            <NarrowMenu menuItems={getNarrowMenuItem()} disabled={isLoading} className="narrow-menu" />
        </div >
    )
}

export default ConfigurationListItem