import { ConfigurationAction, ConfigurationActionTypes } from "./actionTypes";
import { SystemComponent } from "../models/SystemComponent";
import { ActiveHarmonicFilterResultModel } from "../models/ActiveHarmonicFilterResultModel";
import { ProjectSharedModel } from '../../../models/ProjectSharedModel';
import { ConfigurationSharedModel } from '../../../models/ConfigurationSharedModel';

//This will reset the whole configuration to initial state. use wisely
export const resetConfiguration = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.RESET_CONFIGURATION
    }
}

export const addChildComponent = (parentNodeId: string, component: SystemComponent): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_TREE_ADD_CHILD,
        parentNodeId,
        payload: component
    }
}

export const updateComponent = (component: SystemComponent): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_TREE_UPDATE,
        payload: component
    }
}

export const deleteChildComponent = (component: SystemComponent): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_TREE_DELETE_CHILD,
        payload: component
    }
}

export const createConfigTree = (component: SystemComponent): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_TREE_CREATE_TREE,
        payload: component
    }
}

export const projectUpdate = (project: ProjectSharedModel): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.PROJECT_UPDATE,
        payload: project
    }
}

export const configurationUpdate = (configuration: ConfigurationSharedModel): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_UPDATE,
        payload: configuration
    }
}

export const showSystemComponentModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.SYSTEM_COMPONENT_MODAL_SHOW
    }
}

export const hideSystemComponentModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.SYSTEM_COMPONENT_MODAL_HIDE
    }
}

export const selectComponent = (selectedNodeId: string): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_SELECT_COMPONENT,
        payload: selectedNodeId
    }
}

export const selectPCC = (selectedNodeId: string): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_SELECT_PCC,
        payload: selectedNodeId
    }
}

export const selectPowerSource = (selectedPowerSource: number): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_SELECT_POWERSOURCE,
        payload: selectedPowerSource
    }
}

export const selectUnit = (selectedUnit: number): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_SELECT_UNIT,
        payload: selectedUnit
    }
}

export const selectStandard = (selectedStandard: number): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.CONFIGURATION_SELECT_STANDARD,
        payload: selectedStandard
    }
}

export const showUserTransformerModal = (transformerPayload: { isEdit: boolean }): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.USER_TRANSFORMER_MODAL_SHOW,
        payload: transformerPayload
    }
}

export const hideUserTransformerModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.USER_TRANSFORMER_MODAL_HIDE
    }
}

export const showNonlinearLoadsModal = (NonLinearPayload: { isEdit: boolean }): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.NON_LINEAR_LOADS_MODAL_SHOW,
        payload: NonLinearPayload
    }
}

export const hideNonlinearLoadsModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.NON_LINEAR_LOADS_MODAL_HIDE
    }
}
export const showEditPowerGridModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.EDIT_POWER_GRID_MODAL_SHOW
    }
}

export const hideEditPowerGridModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.EDIT_POWER_GRID_MODAL_HIDE
    }
}

export const showEditLvGensetModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.EDIT_LV_GENSET_MODAL_SHOW
    }
}

export const hideEditLvGensetModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.EDIT_LV_GENSET_MODAL_HIDE
    }
}

export const showActiveHarmonicFilterModal = (ActiveHarmonicFilterPayload: { isEdit: boolean }): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.ACTIVE_HARMONIC_FILTER_MODAL_SHOW,
        payload: ActiveHarmonicFilterPayload
    }
}

export const hideActiveHarmonicFilterModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.ACTIVE_HARMONIC_FILTER_MODAL_HIDE
    }
}

export const showMotorControlCenterModal = (MotorControlCenterPayload: { isEdit: boolean }): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.MOTOR_CONTROL_CENTER_MODAL_SHOW,
        payload: MotorControlCenterPayload
    }
}

export const hideMotorControlCenterModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.MOTOR_CONTROL_CENTER_MODAL_HIDE
    }
}

export const showLinearLoadModal = (LinearLoadPayload: { isEdit: boolean }): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.LINEAR_LOAD_MODAL_SHOW,
        payload: LinearLoadPayload
    }
}

export const hideLinearLoadModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.LINEAR_LOAD_MODAL_HIDE
    }
}

export const disableCreateReportButton = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.DISABLE_CREATE_REPORT_BUTTON
    }
}

export const enableCreateReportButton = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.ENABLE_CREATE_REPORT_BUTTON
    }
}

export const updateAHFResult = (ahfResult: ActiveHarmonicFilterResultModel): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.UPDATE_ACTIVE_HARMONIC_FILTER_RESULT,
        payload: ahfResult
    }
}

export const showAddAssumptionnoteslModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.ADD_ASSUMPTIONNOTES_MODEL_SHOW
    }
}

export const hideAddAssumptionnoteslModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.ADD_ASSUMPTIONNOTES_MODEL_HIDE
    }
}

export const addAssumptionNote = (assumptionNotes: string[]): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.ADD_ASSUMPTIONNOTE,
        payload: assumptionNotes
    }
}

export const removeAssumptionNote = (assumptionNote: string): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.REMOVE_ASSUMPTIONNOTE,
        payload: assumptionNote
    }
}

export const updateAssumptionNote = (assumptionNote: string): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.UPDATE_ASSUMPTIONNOTE,
        payload: assumptionNote
    }
}

export const showExportConfigurationModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.EXPORT_CONFIGURATION_MODAL_SHOW
    }
}

export const hideExportConfigurationModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.EXPORT_CONFIGURATION_MODAL_HIDE
    }
}

export const showImportConfigurationModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.IMPORT_CONFIGURATION_MODAL_SHOW
    }
}

export const hideImportConfigurationModal = (): ConfigurationAction => {
    return {
        type: ConfigurationActionTypes.IMPORT_CONFIGURATION_MODAL_HIDE
    }
}
