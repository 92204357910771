import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthManager } from "../../../authServices/AuthManager";
import { ResultSummaryRequestModel } from "../models/ResultSummaryRequestModel";
import { ResultSumarryResonseModel } from "../models/ResultSummaryResonseModel";

const baseUrlEnv = process.env.REACT_APP_API_URL;
const baseUrl = `${baseUrlEnv}harmonics/`;

export const configAPI = createApi({
    reducerPath: 'configAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${AuthManager.getUser()?.access_token}`);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getResultSummary: builder.mutation<ResultSumarryResonseModel, Partial<ResultSummaryRequestModel>>({
            query: (data: ResultSummaryRequestModel) => (
                {
                    url: 'summary',
                    method: 'POST',
                    body: data
                }
            )
        })
    })
});

export const { useGetResultSummaryMutation } = configAPI;