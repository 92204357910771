import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import Select from '../../../components/Form/Select';
import SelectContainer from '../../../components/Form/SelectContainer';
import SelectOption from '../../../components/Form/SelectOption';
import NarrowMenu from '../../../components/NarrowMenu';
import { NarrowMenuItemProps } from '../../../components/NarrowMenuItem';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { useAppSelector } from '../../../store/hooks';
import { getSelectOptionArray, getShortForm } from '../../../utils/Common';
import UnitOptions from '../models/UnitOptions';
import { selectStandard, selectUnit, showExportConfigurationModal, showImportConfigurationModal } from '../store/actions';
import { ConfigurationAction } from '../store/actionTypes';
import WindowBackNavigation from '../../../components/WindowBackNavigation';
import { useParams } from 'react-router-dom';
import { NavigationPath } from '../../../utils/Navigation';
import { StandardOptions } from '../models/Standard';

interface ConfigurationHeaderProps {
    isLoading?: boolean;
    configurationName?: string;
    projectId?: string;
}

function ConfigurationHeader(props: ConfigurationHeaderProps) {
    const { isLoading = false } = props
    const { t } = useTranslate();
    const { selectedUnit, selectedStandard, project, configuration } = useAppSelector(state => state.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const { projectId } = useParams();

    const handleOnClickConfigurationMenuItem = (value: string) => {
        if (value === "Export") {
            dispatch(showExportConfigurationModal())
            return;
        }
        else if (value === "Import") {
            dispatch(showImportConfigurationModal())
            return;
        }
    }

    const configurationMenuItems: NarrowMenuItemProps[] = [
        { label: "Import", value: "Import", onClick: handleOnClickConfigurationMenuItem },
        { label: t(I18.export), value: "Export", onClick: handleOnClickConfigurationMenuItem },
        // { label: "Delete", value: "Delete", onClick: handleOnClickConfigurationMenuItem },
    ];

    const handleUnitChange = ({ detail }: any) => {
        const unit = parseInt(detail.value);
        if (selectedUnit === unit)
            return;
        dispatch(selectUnit(unit));
    }
    const handleStandardChange = (e: any) => {
        const standard = parseInt(e.detail.value)
        dispatch(selectStandard(standard));
    }

    return (
        <div className='page-title'>
            <div className='title'>
                <WindowBackNavigation navigateTo={`/${NavigationPath.Project}/${projectId}`} />
                <h4 className={`configuration-name ${isLoading ? 'skeleton' : ''}`}>
                    {isLoading ? "Loading Configuration..." : getShortForm(30, configuration.configurationName ?? "")}
                </h4>
            </div>
            {!isLoading &&
                <>
                    <div className='unit-selector'>
                        <SelectContainer key={selectedStandard} class='ieee' size='small'>
                            <Select slot="main" onSelectChange={handleStandardChange}>
                                <div slot="options-main">
                                    {getSelectOptionArray(StandardOptions).map((item) =>
                                        <SelectOption data-value={item.value.key} selected={item.value.key.toString() === selectedStandard.toString()} optionText={item.value.value} key={item.value.key} />
                                    )}
                                </div>
                            </Select>
                        </SelectContainer>
                        <SelectContainer key={selectedUnit} class='unit' size='small'>
                            <Select slot="main" onSelectChange={handleUnitChange}>
                                <div slot="options-main">
                                    {
                                        getSelectOptionArray(UnitOptions).map((item) =>
                                            <SelectOption key={item.value.key} data-value={item.value.key} selected={item.value.key.toString() === selectedUnit.toString()} optionText={item.value.value} ></SelectOption>)
                                    }
                                </div>
                            </Select>
                        </SelectContainer>
                    </div>
                    <NarrowMenu menuItems={configurationMenuItems} />
                </>
            }
            <h6 className='project-title'>
                {getShortForm(70, project.projectName ?? "")}
            </h6>
        </div>
    )
}

export default ConfigurationHeader