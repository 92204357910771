import { SelectOptionValues } from "../../../models/SelectOptionKeyValuePair";
import { ConfigurationLoads } from "./ConfigurationLoads";
import { Load } from "./Load";

export class Transformer extends Load {
    id: string;
    ratedKva: number;
    secondaryVoltage: number;
    impedence: number;
    bindingConfiguration?: number;

    public constructor(
        id?: string,
        ratedKva?: number,
        secondaryVoltage?: number,
        impedence?: number,
        configuration?: number,
    ) {
        super();
        this.id = id ? id : "";
        this.ratedKva = ratedKva ? ratedKva : 0;
        this.secondaryVoltage = secondaryVoltage ? secondaryVoltage : 0;
        this.impedence = impedence ? impedence : 0;
        this.bindingConfiguration = configuration ? configuration : TransformerConfigurationOptions.DeltaStar.key as number;
    }

    getComponentData(): Transformer {
        const transformer = new Transformer();
        Object.assign(transformer, this);
        //*This will set the loads as blank 
        //*for component data we don't need child components data
        transformer.load = new ConfigurationLoads();
        return transformer;
    }
}

export class TransformerConfigurationOptions {
    public static DeltaDelta: SelectOptionValues = { key: 0, value: "Delta delta Dd" };
    public static StarStar: SelectOptionValues = { key: 1, value: "Star star Yy" };
    public static DeltaStar: SelectOptionValues = { key: 2, value: "Delta star Dy" };
    public static StarDelta: SelectOptionValues = { key: 3, value: "Star delta Yd" };
}
