import { PropsWithChildren } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom'
import { NavigationPath } from '../utils/Navigation';
import { AuthManager } from './AuthManager';

//This is a route function component which will check for authentication and will redirect to home page if not authenticated.
function PrivateRoute(props: PropsWithChildren<any>) {
    const auth = useAuth();

    if (auth.isLoading)
        return <div>Loading...</div>

    if (auth.isAuthenticated && AuthManager.isAuthenticated())
        return props.children

    if (auth.error)
        console.error("Authentication Error: ", auth.error.message)

    return <Navigate to={`/${NavigationPath.Login}`} />
}

export default PrivateRoute