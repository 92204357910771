import SetTitle from "../../components/SetTitle"
import GenerateReport from "./components/GenerateReport";
import ReportSection from "./components/ReportSection";
import './styles/reportstyle.scss';
import '../Configuration/styles/style.scss';
import { Navigate, useLocation, useParams } from "react-router-dom";
import { NavigationPath } from "../../utils/Navigation";
import PrintReport from "./components/PrintReport";
import { useGetConfigurationJSONByIdQuery } from "../Configuration/services/ConfigurationAPIService";
import { useEffect, useState } from "react";
import { ResultSummaryRequestModel } from "../Configuration/models/ResultSummaryRequestModel";
import { useGetProjectByIdQuery } from "../Project/services/ProjectAPIServices";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { updatetReportHeader } from "./store/actions";
import EmailModalPopup from "./components/ModalPopup/EmailModalPopup";

function Report() {
    const location = useLocation();
    const { reportHeaderConfig } = useAppSelector(store => store.report);
    const dispatch = useDispatch();
    const { emailModalConfig } = useAppSelector(store => store.report);
    const { projectId, configurationId } = useParams();
    //Get configuration json by projectID & ConfigurationID
    const { data: configurationJSONData, isSuccess: isConfigJSONSuccess, isError: isConfigJSONError, fulfilledTimeStamp } = useGetConfigurationJSONByIdQuery(configurationId && projectId ? { projectId, configurationId } : { projectId: "", configurationId: "" }, { skip: !configurationId || !projectId, refetchOnMountOrArgChange: true });
    const { data: projectData, isSuccess: isProjectSuccess, fulfilledTimeStamp: projectFulfilledTimeStamp } = useGetProjectByIdQuery(projectId ? projectId : "", { skip: !projectId, refetchOnMountOrArgChange: true });

    //using this to compare with existing timestamp as rtkquery already have oldData so multiple render happens
    const [initfulfilledTimeStamp, setInitfulfilledTimeStamp] = useState(fulfilledTimeStamp);
    const [resultSummaryRequest, setResultSummaryRequest] = useState<ResultSummaryRequestModel | undefined>(undefined);

    useEffect(() => {
        if (initfulfilledTimeStamp !== fulfilledTimeStamp)
            setConfigurationStateFromJSON();

        return () => {
            setInitfulfilledTimeStamp(fulfilledTimeStamp)
            setResultSummaryRequest(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fulfilledTimeStamp])

    //this is for right side project detail in report
    useEffect(() => {
        if (isProjectSuccess && isConfigJSONSuccess)
            setReportHeader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectFulfilledTimeStamp])

    function setConfigurationStateFromJSON() {
        if (!isConfigJSONSuccess || !configurationJSONData || !configurationJSONData.configuration?.configuration?.configurationInfo)
            return;
        const configurationJson: ResultSummaryRequestModel = JSON.parse(configurationJSONData.configuration.configuration?.configurationInfo)
        setResultSummaryRequest(configurationJson);
    }

    function setReportHeader() {
        if (!isProjectSuccess || !projectData || !configurationJSONData)
            return;

        let reportHeaderData = cloneDeep(reportHeaderConfig);
        reportHeaderData.projectName = projectData.projectName;
        reportHeaderData.configurationName = configurationJSONData?.configuration.configurationName ?? "";
        reportHeaderData.revisionHistory = configurationJSONData?.configuration.revision ?? "";
        reportHeaderData.contactName = projectData.contact?.contactPerson ?? "";
        dispatch(updatetReportHeader(reportHeaderData));
    }

    if (isConfigJSONError)
        return <Navigate to={`/${NavigationPath.NotFound}?${window.location.pathname}`} ></Navigate>

    return (
        <>
            <SetTitle title='Report' />
            {
                !location.pathname.includes(NavigationPath.ReportPrint) &&
                <div id="report-page-root-container" className="container config-container">
                    <ReportSection resultSummaryRequest={resultSummaryRequest} />
                    <GenerateReport resultSummaryRequest={resultSummaryRequest} />
                </div>
            }
            {
                location.pathname.includes(NavigationPath.ReportPrint) &&
                <PrintReport resultSummaryRequest={resultSummaryRequest} />
            }

            {
                emailModalConfig.modalState &&
                <EmailModalPopup resultSummaryRequest={resultSummaryRequest} />
            }
        </>
    )
}
export default Report
