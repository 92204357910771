import { Dispatch, useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { getAPIRequestModel } from '../models/ConfigTreeToAPIObjectMapper';
import { useGetResultSummaryMutation } from '../services/APIService';
import SummaryCard from './SummaryCard';
import { getComponentByNodeId } from '../utils/SystemComponentUtils';
import { Transformer } from '../models/Transfomer';
import { PCCPointOptions } from '../models/PCCPointOptions';
import { useDispatch } from 'react-redux';
import { ConfigurationAction } from '../store/actionTypes';
import { disableCreateReportButton, enableCreateReportButton, updateAHFResult } from '../store/actions';
import { ResultSumarryResonseModel } from '../models/ResultSummaryResonseModel';
import LoadDetailsCard from './LoadDetailsCard';
import Button from '../../../components/Form/Button';
import Icon from '../../../components/Icon';
import AssumptionNotesCard from './AssumptionNotesCard';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationPath } from '../../../utils/Navigation';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';

function ResultSummarySection() {
    const { configurationTree, selectedPCCNodeId, isDisableCreateReportButton, selectedStandard } = useAppSelector(state => state.configuration);
    const [summaryResult, setSummaryResult] = useState<ResultSumarryResonseModel>();
    const [selectedPCCPoint, setSelectedPCCPoint] = useState<number>(PCCPointOptions.PowerGrid.key as number);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();
    const { projectId, configurationId } = useParams();

    const handleCreateReport = () => {
        navigate(`/${NavigationPath.Report}/${projectId}/${configurationId}`);
    }

    const [getResultSummary] = useGetResultSummaryMutation();
    const { t } = useTranslate();

    useEffect(() => {
        const resultSummaryRequest = getAPIRequestModel();
        setIsLoading(true);
        if (resultSummaryRequest) {
            setSelectedPCCPoint(resultSummaryRequest.setting.pccPoint);
            setSummaryResult(undefined);
            dispatch(disableCreateReportButton());
            getResultSummary(resultSummaryRequest).unwrap()
                .then((data) => {
                    setSummaryResult(data)
                    setIsLoading(false);
                    dispatch(enableCreateReportButton());

                    //Update AHF Result for min AHF
                    if (data.loadDetails.ahfResult)
                        dispatch(updateAHFResult(data.loadDetails.ahfResult));
                })
                .catch((error) => console.log("Caught error: ", error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationTree, selectedPCCNodeId, selectedStandard]);

    const getSummaryCard = () => {
        const blankSummaryCard = <SummaryCard title='Loading...' loading={true} />

        if (!selectedPCCNodeId)
            return blankSummaryCard

        switch (selectedPCCPoint) {
            case PCCPointOptions.PowerGrid.key:
                return <SummaryCard title={t(I18.configuration_page_result_summary_title_power_grid)} resultSummary={summaryResult?.summaryResult} loading={isLoading} />

            case PCCPointOptions.Transformer.key:
                {
                    let pccComponent = getComponentByNodeId(selectedPCCNodeId, configurationTree);

                    if (!pccComponent)
                        return blankSummaryCard;

                    const subTitle = `${t(I18.configuration_page_result_summary_sub_title_transformer_id_tag)}: ${(pccComponent.componentData as Transformer).id}`

                    return <>
                        <SummaryCard title={t(I18.configuration_page_result_summary_title_primary_side_of_transformer)} resultSummary={summaryResult?.primaryResult} loading={isLoading} subTitle={subTitle} />
                        <SummaryCard title={t(I18.configuration_page_result_summary_title_secoundary_side_of_transformer)} resultSummary={summaryResult?.secondaryResult} loading={isLoading} subTitle={subTitle} />
                    </>
                }

            case PCCPointOptions.LVGenset.key:
                return <SummaryCard title={t(I18.configuration_page_result_summary_title_lv_genset)} resultSummary={summaryResult?.summaryResult} loading={isLoading} />
        }
    }

    return (
        <section id='summary' className='summary' >
            <div className='create-report'>
                <Button size='medium' type='primary-red' disabled={isDisableCreateReportButton} onClick={handleCreateReport}>
                    <Icon name='reports' size='small' slot='icon'></Icon>
                    {t(I18.configuration_page_create_report_button_label)}
                </Button>
            </div>
            <div className='result-summary'>
                <h6>{t(I18.configuration_page_harmonic_result_summary_title)}</h6>
                {
                    getSummaryCard()
                }
            </div>
            <div className='result-summary'>
                <h6>{t(I18.configuration_page_load_details_title)}</h6>
                <LoadDetailsCard loadDetailsResult={summaryResult?.loadDetails} loading={isLoading} />
            </div>
            <div className='result-summary'>
                <h6>{t(I18.configuration_page_assumption_notes_title)}</h6>
                <AssumptionNotesCard></AssumptionNotesCard>
            </div>
        </section>
    )
}
export default ResultSummarySection