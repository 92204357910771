import { CommonuxInput } from '@abb-ux/commonux-web-components-react';
import { JSX } from '@abb-ux/commonux-web-components/dist/types';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isStringEmpty, roundOffByDecimalPrecision } from '../../utils/Common';

interface InputValidationMessages {
    showErrorMessage?: boolean;
    errors?: {
        required?: string;
        numberOnly?: string;
        minValue?: string;
        maxValue?: string
        minLength?: string;
        maxLength?: string;
        regExp?: string;
    }
}

interface InputProps extends JSX.CommonuxInput {
    className?: string;
    heIsRequired?: boolean;
    heIsNumberOnly?: boolean;
    heMinValue?: number;
    heMaxValue?: number;
    heMinLength?: number;
    heMaxLength?: number;
    heRegExp?: RegExp; //!Note: Don't use \g in regular expressions. it doesn't reset properly. refer - https://blog.stevenlevithan.com/archives/es3-regexes-broken
    heDecimalPrecision?: number;
    heErrorMessages?: InputValidationMessages;
    onInputChange?: (event: CustomEvent<any>) => void;
    children?: React.ReactNode;
}

const Input = React.forwardRef((props: InputProps, ref) => {
    const { required, heIsRequired, heIsNumberOnly, heMinValue, heMaxValue, heMinLength, heMaxLength,
        value, optionalText, heErrorMessages, heRegExp, heDecimalPrecision,
        onInputChange } = props;
    const [isValid, setIsValid] = useState(true);
    const [isAutoValidate, setIsAutoValidate] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");
    const inputRef = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            validate(): boolean {
                return validateInput();
            }
        })
    )

    useEffect(() => {
        if (!isValid) {
            inputRef.current.querySelector("div.inputWrapper > .inputLabel > input").focus();
        }
    }, [isValid])

    const getValidationMessage = (defaultMessage: string, customMessage?: string): string => {
        if (!heErrorMessages || (heErrorMessages.showErrorMessage && isStringEmpty(customMessage)))
            return defaultMessage;

        return !heErrorMessages?.showErrorMessage || !customMessage ? "" : customMessage;
    }

    const validateInput = (): boolean => {
        setIsValid(true);
        setValidationMessage("");
        setIsAutoValidate(true);

        //adding multiple ifs in case we need to add separate error for each validation
        if (heIsRequired && isStringEmpty(value?.toString().trim())) {
            return invalidate("This field is required.", heErrorMessages?.errors?.required);
        }

        //adding this condition here because further validations require some value, 
        //If value not present no need to perform validation.
        if (!value)
            return true;

        if (heIsNumberOnly && isNaN(value as any))
            return invalidate("Enter numeric only.", heErrorMessages?.errors?.numberOnly);

        if (heMaxLength && value.length > heMaxLength)
            return invalidate(`Exceeds max length of ${heMaxLength} character(s) limit.`, heErrorMessages?.errors?.maxLength);

        if (heMinLength && value.length < heMinLength)
            return invalidate(`Must contain minimum ${heMinLength} character(s).`, heErrorMessages?.errors?.minLength);

        if (heMaxValue && !isNaN(value as any) && parseFloat(value) > heMaxValue)
            return invalidate(`Value must be less than or equal to ${heMaxValue}.`, heErrorMessages?.errors?.maxValue);

        if (heMinValue !== undefined && !isNaN(value as any) && parseFloat(value) < heMinValue)
            return invalidate(`Value must be greater than or equal to ${heMinValue}.`, heErrorMessages?.errors?.minValue);

        if (heRegExp !== undefined && !heRegExp.test(value.trim()))
            return invalidate(`Failed to validate value against given expression`, heErrorMessages?.errors?.regExp);

        return true;
    }

    const validateOnBlur = () => {
        if (!isAutoValidate)
            return;
        validateInput();
    }

    function invalidate(defaultMessage: string, customMessage?: string) {
        setIsValid(false);
        setValidationMessage(getValidationMessage(defaultMessage, customMessage));
        return false;
    }

    const getRoundedValue = (inputValue: string) => {
        if (heDecimalPrecision === undefined ||
            inputValue === undefined || isStringEmpty(inputValue) ||
            (heIsNumberOnly && isNaN(inputValue as any)))
            return inputValue;

        return roundOffByDecimalPrecision(parseFloat(inputValue), heDecimalPrecision).toString();
    }

    const handleInputChange = (event: CustomEvent<any>) => {
        if (!onInputChange)
            return;

        const { detail } = event;
        detail.value = getRoundedValue(detail.value);
        onInputChange(event);
    }

    return (
        <CommonuxInput
            {...props}
            optionalText={isStringEmpty(validationMessage) ? optionalText : validationMessage}
            valid={isValid}
            required={required || heIsRequired}
            onBlur={validateOnBlur}
            onInputChange={handleInputChange}
            ref={inputRef}
        >
            {props.children}
        </CommonuxInput>
    )
})

export default Input