import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { hideSystemComponentModal, showUserTransformerModal, showNonlinearLoadsModal, showActiveHarmonicFilterModal, showLinearLoadModal } from '../store/actions';
import { ConfigurationAction } from '../store/actionTypes';
import SystemComponentModalItem from "./SystemComponentModalItem";
import '../styles/systemcomponentModalstyle.scss';
import { getSelectedComponent } from '../../../utils/Common';
import { ComponentType } from '../models/ComponentType';
import { getAllComponentsTransformerLevel } from '../utils/SystemComponentUtils';
import ModalDialog from '../../../components/ModalDialog';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';

function SystemComponentModal() {
    const { isShowSystemComponentModal, selectedComponentNodeId, configurationTree } = useAppSelector(state => state.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const selectedTransfomer = { isEdit: false };
    const selectedNonLinearLoads = { isEdit: false };
    const selectedActiveHarmonicFilter = { isEdit: false };
    const { t } = useTranslate();

    //Commenting it for alpha release (MCC not required in alpha release)
    //const selectedMotorControlCenter = { isEdit: false };
    const selectedLinearLoad = { isEdit: false };

    const handleCloseSystemComponentModal = () => {
        dispatch(hideSystemComponentModal());
    }

    const onClickUserTransformer = () => {
        dispatch(showUserTransformerModal(selectedTransfomer));
    }

    const onClickLinearLoads = () => {
        dispatch(showLinearLoadModal(selectedLinearLoad));
    }

    const onClickNonLinearLoads = () => {
        dispatch(showNonlinearLoadsModal(selectedNonLinearLoads));
    }

    const onClickActiveHarmonicFilter = () => {
        dispatch(showActiveHarmonicFilterModal(selectedActiveHarmonicFilter));
    }

    //Commenting it for alpha release (MCC not required in alpha release)
    // const onClickMotorControlCenter = () => {
    //     dispatch(showMotorControlCenterModal(selectedMotorControlCenter));
    // }

    const getComponentItems = () => {
        let selectedComponent = getSelectedComponent(configurationTree, selectedComponentNodeId);
        const componentType = selectedComponent?.componentType;
        switch (componentType) {
            case ComponentType.PowerGrid:
                return (
                    <ul>
                        <SystemComponentModalItem icon="transformer" text={t(I18.configuration_page_system_component_modal_popup_user_tranformer_item_title)} onClick={onClickUserTransformer} />
                    </ul>
                );
            case ComponentType.Transformer:
            case ComponentType.LVGenset:
                return (
                    <ul>
                        {
                            isAllowAddComponent(ComponentType.LinearLoad) &&
                            <SystemComponentModalItem icon="linear-load" iconType='Harmonic' text={t(I18.configuration_page_system_component_modal_popup_linear_loads_item_title)} onClick={onClickLinearLoads} />
                        }
                        <SystemComponentModalItem icon="drive" text={t(I18.configuration_page_system_component_modal_popup_non_linear_loads_item_title)} onClick={onClickNonLinearLoads} />
                        {/* //Commenting it for alpha release (MCC not required for alpha release)
                        <SystemComponentModalItem icon="custom-non-linear" iconType='Harmonic' text="Custom non-linear load" /> 
                        <SystemComponentModalItem icon="sld-1" text="MCC(Motor Control Center)" onClick={onClickMotorControlCenter} /> */}
                        {
                            isAllowAddComponent(ComponentType.ActiveHarmonicFilter) &&
                            <SystemComponentModalItem icon="ahf" iconType='Harmonic' text={t(I18.configuration_page_system_component_modal_popup_active_harmonic_filter_item_title)} onClick={onClickActiveHarmonicFilter} />
                        }
                    </ul>
                );
            case ComponentType.MCC:
                return (
                    <ul>
                        {
                            isAllowAddComponent(ComponentType.LinearLoad) &&
                            <SystemComponentModalItem icon="linear-load" iconType='Harmonic' text={t(I18.configuration_page_system_component_modal_popup_linear_loads_item_title)} onClick={onClickLinearLoads} />
                        }
                        <SystemComponentModalItem icon="drive" text={t(I18.configuration_page_system_component_modal_popup_non_linear_loads_item_title)} onClick={onClickNonLinearLoads} />
                        {/* //Commenting it for alpha release 
                        <SystemComponentModalItem icon="custom-non-linear" iconType='Harmonic' text="Custom non-linear load" /> */}
                        {
                            isAllowAddComponent(ComponentType.ActiveHarmonicFilter) &&
                            <SystemComponentModalItem icon="ahf" iconType='Harmonic' text={t(I18.configuration_page_system_component_modal_popup_active_harmonic_filter_item_title)} onClick={onClickActiveHarmonicFilter} />
                        }
                    </ul>
                );
            default:
                return (<ul></ul>);
        }
    }

    const isAllowAddComponent = (componentType: ComponentType) => {
        if (!selectedComponentNodeId)
            return false

        const allComponentsTransformerLevel = getAllComponentsTransformerLevel(selectedComponentNodeId, configurationTree);

        //allow adding if no child component exists
        if (!allComponentsTransformerLevel || allComponentsTransformerLevel.length === 0)
            return true;

        return allComponentsTransformerLevel.filter((component) => component.componentType === componentType).length === 0;
    }

    return (
        <ModalDialog
            open={isShowSystemComponentModal}
            onModalClose={handleCloseSystemComponentModal}
            modalTitle={t(I18.configuration_page_system_component_modal_popup_title)}
            className="commonux-modal-popup">
            <div className="systemComponent-modal">
                <div className="systemComponentText">{t(I18.configuration_page_system_component_modal_popup_header)}</div>
                {getComponentItems()}
            </div>
        </ModalDialog>
    )
}
export default SystemComponentModal;
