import { CommonuxNavigation } from "@abb-ux/commonux-web-components-react";
import { JSX } from "@abb-ux/commonux-web-components/dist/types";
import { PropsWithChildren } from "react";

interface NavigationProps extends JSX.CommonuxNavigation {
  className?: string;
  id?: string;
}

const Navigation = (props: PropsWithChildren<NavigationProps>) => {
  return <CommonuxNavigation {...props}>{props.children}</CommonuxNavigation>;
};

export default Navigation;
