import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { hideSystemComponentModal, hideLinearLoadModal, addChildComponent, updateComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import '../../styles/commonstyle.scss';
import '../../styles/linearloadsmodalstyle.scss';
import CommonModalFooter from './CommonModalFooter';
import HarmonicIcon from '../../../../components/HarmonicIcon';
import { ComponentType } from '../../models/ComponentType';
import { SystemComponent } from '../../models/SystemComponent';
import LinearLoadFormFields from '../../models/FormFields/LinearLoadFormFields';
import { getSelectedComponent, getSelectedComponentParent } from '../../../../utils/Common';
import { LinearLoad } from '../../models/LinearLoad';
import Input from '../../../../components/Form/Input';
import { cloneDeep } from 'lodash';
import { getPowerConversionFromSI, getUnit, getUnitConversion, MagnitudeStandardUnit } from '../../models/Units';
import { PowerSourceOptions } from '../../models/PowerSource';
import { UnitSymbols } from '../../models/UnitSymbols';
import ToolTip from '../../../../components/ToolTip';
import Radio from '../../../../components/Form/Radio';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Icon from '../../../../components/Icon';
import ModalDialog from '../../../../components/ModalDialog';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

const toolTipSpecificLoad = "Add linear loads to the non-linear loads (drives) already listed by specifying the key parameter (HP, kW, kVA, A) and the average Power Factor (cos φ) for each type of linear load.";
const toolTipMaxPercentageLoad = "Add linear loads to the non-linear loads (drives) already listed by specifying the max % load on this system and the average Power Factor (cos φ) of the linear loads. Typical values would be 70% for transformers and 100% for gensets.";
function LinearLoadModalPopup() {
    const [formState, setFormState] = useState<any>({});
    const [selectedLinearLoad, setSelectedLinearLoad] = useState<SystemComponent>();
    const { configurationTree, isLinearLoadEdit, isShowLinearLoadModal, selectedComponentNodeId, selectedPowerSource, selectedUnit } = useAppSelector(state => state.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const loadTypeSpecificLoad = "specificLoad";
    const loadTypeMaxPercentageLoad = selectedPowerSource === PowerSourceOptions.PowerGrid.key ? ComponentType.Transformer : ComponentType.LVGenset;
    const powerFactorRegExp: RegExp = new RegExp(/^-$|[1-9]$|[1-9]$/);
    const powerFactorError: string = "Enter valid number between -1 to 1 except 0.";
    const decimalPrecisionPowerFactor: number = 2;
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
        required: true
    }

    const { t } = useTranslate();

    const commonInputPropsPowerFactor: any = {
        ...commonInputProps,
        labelText: t(I18.configuration_page_label_total_average_power_factor),
        optionalText: t(I18.configuration_page_optional_text_average_power_factor, UnitSymbols.PowerFactor),
        suffixText: UnitSymbols.PowerFactor,
        heMaxValue: 1,
        heMinValue: -1,
        heRegExp: powerFactorRegExp,
        heErrorMessages: { showErrorMessage: true, errors: { regExp: powerFactorError } },
        heDecimalPrecision: decimalPrecisionPowerFactor
    }

    //Form field refs
    const totalMotorLoadsRef = useRef<any>();
    const totalResistiveLoadsRef = useRef<any>();
    const totalSoftStarterLoadsRef = useRef<any>();
    const totalAloadsRef = useRef<any>();
    const totalKVALoadsRef = useRef<any>();
    const maxPercentageofLoadingKVARef = useRef<any>();
    const totalMotorLoadsAvgPFRef = useRef<any>();
    const totalKWResistiveLoadsPFRef = useRef<any>();
    const totalSoftStarterLoadsAvgPFRef = useRef<any>();
    const totalAloadsAvgPFRef = useRef<any>();
    const totalKVALoadsAvgPFRef = useRef<any>();
    const maxPercentageofLoadingKVAAvgPFRef = useRef<any>();

    const handleCloseLinearLoadModal = () => {
        dispatch(hideLinearLoadModal());
        dispatch(hideSystemComponentModal());
    }

    const handleCancelLinearLoadModal = () => {
        dispatch(hideLinearLoadModal());
    }

    useEffect(() => {
        if (!isShowLinearLoadModal)
            return;

        let selectedComponent = getSelectedComponent(configurationTree, selectedComponentNodeId);
        if (!selectedComponent)
            return;

        const setFormFields = (linearLoadData: LinearLoad, fields: any) => {
            Object.keys(linearLoadData).forEach((item) => {
                if (!LinearLoadFormFields.hasOwnProperty(item))
                    return;

                let totalLoad = linearLoadData[item as keyof LinearLoad];
                if (typeof totalLoad == "object") {
                    //Assigning Loads Power
                    fields[item] = typeof totalLoad.power === "object" ?
                        getUnitConversion((totalLoad.power as MagnitudeStandardUnit).magnitude, "Power", (totalLoad.power as MagnitudeStandardUnit).unit, selectedUnit).toString() :
                        totalLoad.power.toString();

                    //Assigning Loads Power Factor
                    fields[`${item}PowerFactor`] = totalLoad.powerFactor.toString();
                }
            });
        }

        if (isLinearLoadEdit) {
            setSelectedLinearLoad(selectedComponent);
            let linearLoadData = selectedComponent.componentData as LinearLoad;
            let fields = { ...formState };
            setFormFields(linearLoadData, fields);

            let selectedParentComponent = getSelectedComponentParent(configurationTree, configurationTree, selectedComponentNodeId);

            fields[LinearLoadFormFields.linearLoadSelection] = linearLoadData.selectedLoadType !== loadTypeSpecificLoad ? loadTypeMaxPercentageLoad : loadTypeSpecificLoad;
            fields[LinearLoadFormFields.parentComponent] = selectedParentComponent?.componentType;
            fields[LinearLoadFormFields.parentId] = selectedParentComponent?.componentData?.hasOwnProperty("id") ? (selectedParentComponent.componentData as any).id : "";

            if (linearLoadData) {
                setFormState({
                    ...fields
                });
            }
        }
        else {
            let fields = { ...formState };
            let linearLoadData = new LinearLoad();
            setFormFields(linearLoadData, fields);

            fields[LinearLoadFormFields.linearLoadSelection] = loadTypeMaxPercentageLoad;
            fields[LinearLoadFormFields.parentComponent] = selectedComponent?.componentType;
            fields[LinearLoadFormFields.parentId] = selectedComponent?.componentData?.hasOwnProperty("id") ? (selectedComponent.componentData as any).id : "";
            fields[LinearLoadFormFields.maxPercentageKVALoads] = 70;
            setFormState({
                ...fields
            });
        }

        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowLinearLoadModal, isLinearLoadEdit, configurationTree, selectedComponentNodeId])

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };
        if (!fields.hasOwnProperty(name))
            return;

        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const handleUpdateLinearLoad = () => {
        if (!validateForm())
            return;

        if (!selectedLinearLoad) {
            dispatch(hideLinearLoadModal());
            return;
        }

        let component = cloneDeep(selectedLinearLoad);
        component.componentData = getLinearLoadData();

        dispatch(updateComponent(component));
        dispatch(hideLinearLoadModal());
    }

    const handleAddLinearLoad = () => {
        if (!validateForm())
            return;

        let linearLoadData: LinearLoad = getLinearLoadData();

        let linearLoadComponent: SystemComponent = {
            componentType: ComponentType.LinearLoad,
            componentData: linearLoadData
        }

        if (selectedComponentNodeId)
            dispatch(addChildComponent(selectedComponentNodeId, linearLoadComponent));

        dispatch(hideLinearLoadModal());
        dispatch(hideSystemComponentModal());
    }

    const getLinearLoadData = (): LinearLoad => {
        let linearLoadData = new LinearLoad();
        if (formState[LinearLoadFormFields.linearLoadSelection] === loadTypeSpecificLoad) {
            linearLoadData.totalMotorLoads = {
                power: { magnitude: parseFloat(formState[LinearLoadFormFields.totalMotorLoads]), unit: selectedUnit },
                powerFactor: parseFloat(formState[LinearLoadFormFields.totalMotorLoadsPowerFactor])
            }

            linearLoadData.totalResistiveLoads = {
                power: parseFloat(formState[LinearLoadFormFields.totalResistiveLoads]),
                powerFactor: parseFloat(formState[LinearLoadFormFields.totalResistiveLoadsPowerFactor])
            }

            linearLoadData.totalSoftStarterLoads = {
                power: { magnitude: parseFloat(formState[LinearLoadFormFields.totalSoftStarterLoads]), unit: selectedUnit },
                powerFactor: parseFloat(formState[LinearLoadFormFields.totalSoftStarterLoadsPowerFactor])
            }

            linearLoadData.totalALoads = {
                power: parseFloat(formState[LinearLoadFormFields.totalALoads]),
                powerFactor: parseFloat(formState[LinearLoadFormFields.totalALoadsPowerFactor])
            }

            linearLoadData.totalKVALoads = {
                power: parseFloat(formState[LinearLoadFormFields.totalKVALoads]),
                powerFactor: parseFloat(formState[LinearLoadFormFields.totalKVALoadsPowerFactor])
            }
            linearLoadData.selectedLoadType = loadTypeSpecificLoad;

        } else {
            linearLoadData.selectedLoadType = loadTypeMaxPercentageLoad;
            linearLoadData.maxPercentageKVALoads = {
                power: parseFloat(formState[LinearLoadFormFields.maxPercentageKVALoads]),
                powerFactor: parseFloat(formState[LinearLoadFormFields.maxPercentageKVALoadsPowerFactor])
            }
        }

        return linearLoadData;
    }

    const validateForm = (): boolean => {
        let isValid = 1; //using this for bitwise operation, 1 = true;

        if ((formState[LinearLoadFormFields.linearLoadSelection] === ComponentType.Transformer
            || formState[LinearLoadFormFields.linearLoadSelection] === ComponentType.LVGenset))
            isValid = maxPercentageofLoadingKVARef.current.validate() & maxPercentageofLoadingKVAAvgPFRef.current.validate()

        if (formState[LinearLoadFormFields.linearLoadSelection] === loadTypeSpecificLoad)
            //using bitwise & operator this will check all the validation and if any one is false it return 0.
            isValid = totalMotorLoadsRef.current.validate()
                & totalMotorLoadsAvgPFRef.current.validate()
                & totalSoftStarterLoadsRef.current.validate()
                & totalSoftStarterLoadsAvgPFRef.current.validate()
                & totalKVALoadsRef.current.validate()
                & totalKVALoadsAvgPFRef.current.validate()
                & totalResistiveLoadsRef.current.validate()
                & totalKWResistiveLoadsPFRef.current.validate()
                & totalAloadsRef.current.validate()
                & totalAloadsAvgPFRef.current.validate()

        return !!isValid;
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(LinearLoadFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    return (
        <ModalDialog
            open={isShowLinearLoadModal}
            onModalClose={handleCloseLinearLoadModal}
            showBackButton={true}
            onBack={handleCancelLinearLoadModal}
            modalTitle={isLinearLoadEdit ? t(I18.configuration_page_linear_load_modal_popup_edit_title) : t(I18.configuration_page_linear_load_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
        >
            <div slot="title-icon">
                <HarmonicIcon size="small" name="linear-load" />
            </div>
            <div className="modal-content linear-load-modal-popup">
                <RadioGroup onRadioChange={(e) => { handleChange(e, LinearLoadFormFields.linearLoadSelection) }} allowEmptySelection={false} className='radio-group' value={formState[LinearLoadFormFields.linearLoadSelection]} >
                    {loadTypeMaxPercentageLoad === ComponentType.Transformer &&
                        <>
                            <div className="linear-loads-text">{`${isLinearLoadEdit ? t(I18.configuration_page_linear_load_modal_popup_text_transformer_edit) : t(I18.configuration_page_linear_load_modal_popup_text_transformer)} ${t(I18.configuration_page_label_id_tag)} ${formState[LinearLoadFormFields.parentId]}`}</div>
                            <h6 className='linear-loads-sub-text'>{t(I18.configuration_page_linear_load_modal_popup_header)}</h6>
                            <div className="select-radio-option">
                                <Radio size="small" name="radioLoadType" id="transformer" value={ComponentType.Transformer} >
                                    <span slot='label'>
                                        {t(I18.configuration_page_linear_load_modal_popup_section_header_max_percentage_load_transformer)}
                                    </span>
                                </Radio>
                                <ToolTip label={toolTipMaxPercentageLoad} position="left">
                                    <Icon size="small" name="information-circle-2" />
                                </ToolTip>
                            </div>
                        </>}
                    {loadTypeMaxPercentageLoad === ComponentType.LVGenset &&
                        <>
                            <div className="linear-loads-text">{`${isLinearLoadEdit ? t(I18.configuration_page_linear_load_modal_popup_text_genset_edit) : t(I18.configuration_page_linear_load_modal_popup_text_genset)} ${t(I18.configuration_page_label_id_tag)} ${formState[LinearLoadFormFields.parentId]}`}</div>
                            <h6 className='linear-loads-sub-text'>{t(I18.configuration_page_linear_load_modal_popup_header)}</h6>
                            <div className="select-radio-option">
                                <Radio size="small" name="radioLoadType" id="lvGenset" value={ComponentType.LVGenset} >
                                    <span slot='label'>
                                        {t(I18.configuration_page_linear_load_modal_popup_radio_max_percentage_load_genset)}
                                    </span>
                                </Radio>
                                <ToolTip label={toolTipMaxPercentageLoad} position="left">
                                    <Icon size="small" name="information-circle-2" />
                                </ToolTip>
                            </div>
                        </>
                    }
                    <div className="select-radio-option">
                        <Radio size="small" name="radioLoadType" id="specificLoad" value={loadTypeSpecificLoad} >
                            <span slot='label'>
                                {t(I18.configuration_page_linear_load_modal_popup_radio_specific)}
                            </span>
                        </Radio>
                        <ToolTip label={toolTipSpecificLoad} position="left">
                            <Icon size="small" name="information-circle-2" />
                        </ToolTip>
                    </div>
                </RadioGroup>
                <div className="horizontal-line-style"></div>
                {formState.linearLoadSelection === ComponentType.LVGenset &&
                    <div>
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_max_percentage_load_genset)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_kva_loads)}
                                optionalText={t(I18.configuration_page_optional_text_total_kva_loads, UnitSymbols.Percentage)}
                                suffixText={UnitSymbols.Percentage}
                                required
                                size="medium"
                                value={formState[LinearLoadFormFields.maxPercentageKVALoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.maxPercentageKVALoads)}
                                heMaxValue={150}
                                heMinValue={0}
                                heDecimalPrecision={1}
                                ref={maxPercentageofLoadingKVARef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.maxPercentageKVALoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.maxPercentageKVALoadsPowerFactor)}
                                ref={maxPercentageofLoadingKVAAvgPFRef}
                            >
                            </Input>
                        </div>
                    </div>
                }
                {formState.linearLoadSelection === ComponentType.Transformer &&
                    <div>
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_max_percentage_load_transformer)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_percentage_kva_loads)}
                                optionalText={t(I18.configuration_page_optional_text_total_percentage_kva_loads, UnitSymbols.Percentage)}
                                suffixText={UnitSymbols.Percentage}
                                size="medium"
                                value={formState[LinearLoadFormFields.maxPercentageKVALoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.maxPercentageKVALoads)}
                                heMaxValue={150}
                                heMinValue={0}
                                heDecimalPrecision={1}
                                ref={maxPercentageofLoadingKVARef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.maxPercentageKVALoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.maxPercentageKVALoadsPowerFactor)}
                                ref={maxPercentageofLoadingKVAAvgPFRef}
                            >
                            </Input>
                        </div>
                    </div>
                }
                {formState.linearLoadSelection === "specificLoad" &&
                    //Motor Load
                    <div>
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_motor_loads)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_motor_loads)}
                                optionalText={t(I18.configuration_page_optional_text_total_motor_loads, getUnit("Power", selectedUnit))}
                                suffixText={getUnit("Power", selectedUnit)}
                                size="medium"
                                value={formState[LinearLoadFormFields.totalMotorLoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalMotorLoads)}
                                heMaxValue={getPowerConversionFromSI(10000, selectedUnit)}
                                heMinValue={0}
                                heDecimalPrecision={2}
                                ref={totalMotorLoadsRef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.totalMotorLoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalMotorLoadsPowerFactor)}
                                ref={totalMotorLoadsAvgPFRef}
                            >
                            </Input>
                        </div>
                        <div className="horizontal-line-style"></div>

                        {/* Soft Starter Load */}
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_soft_starters_loads)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_soft_starterloads)}
                                optionalText={t(I18.configuration_page_optional_text_totalsoft_starterloads, getUnit("Power", selectedUnit))}
                                suffixText={getUnit("Power", selectedUnit)}
                                size="medium"
                                value={formState[LinearLoadFormFields.totalSoftStarterLoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalSoftStarterLoads)}
                                heMaxValue={getPowerConversionFromSI(10000, selectedUnit)}
                                heMinValue={0}
                                heDecimalPrecision={2}
                                ref={totalSoftStarterLoadsRef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.totalSoftStarterLoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalSoftStarterLoadsPowerFactor)}
                                ref={totalSoftStarterLoadsAvgPFRef}
                            >
                            </Input>
                        </div>
                        <div className="horizontal-line-style"></div>
                        {/* kVA Loads */}
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_kva_loads)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_kva_loads)}
                                optionalText={t(I18.configuration_page_optional_text_total_kva_loads_of_other_loads, UnitSymbols.kVA)}
                                suffixText={UnitSymbols.kVA}
                                size="medium"
                                value={formState[LinearLoadFormFields.totalKVALoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalKVALoads)}
                                heMaxValue={5000}
                                heMinValue={0}
                                heDecimalPrecision={1}
                                ref={totalKVALoadsRef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.totalKVALoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalKVALoadsPowerFactor)}
                                ref={totalKVALoadsAvgPFRef}
                            >
                            </Input>
                        </div>
                        <div className="horizontal-line-style"></div>

                        {/* kW Resistive Loads */}
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_resistive_loads)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_resistive_loads)}
                                optionalText={t(I18.configuration_page_optional_text_total_resistive_loads, UnitSymbols.PowerkW)}
                                suffixText={UnitSymbols.PowerkW}
                                size="medium"
                                value={formState[LinearLoadFormFields.totalResistiveLoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalResistiveLoads)}
                                heMaxValue={7500}
                                heMinValue={0}
                                heDecimalPrecision={1}
                                ref={totalResistiveLoadsRef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.totalResistiveLoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalResistiveLoadsPowerFactor)}
                                ref={totalKWResistiveLoadsPFRef}
                            >
                            </Input>
                        </div>
                        <div className="horizontal-line-style"></div>

                        {/* Current Loads */}
                        <h6 className='linear-loads-heading-text'>{t(I18.configuration_page_linear_load_modal_popup_section_header_current_loads)}</h6>
                        <div className="input-box-style">
                            <Input
                                {...commonInputProps}
                                labelText={t(I18.configuration_page_label_total_current_loads)}
                                optionalText={t(I18.configuration_page_optional_text_total_current_loads, UnitSymbols.Current)}
                                suffixText={UnitSymbols.Current}
                                size="medium"
                                value={formState[LinearLoadFormFields.totalALoads]}
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalALoads)}
                                heMaxValue={1000}
                                heMinValue={0}
                                heDecimalPrecision={1}
                                ref={totalAloadsRef}
                            >
                            </Input>
                            <Input
                                {...commonInputPropsPowerFactor}
                                value={formState[LinearLoadFormFields.totalALoadsPowerFactor]}
                                size="medium"
                                onInputChange={(e) => handleChange(e, LinearLoadFormFields.totalALoadsPowerFactor)}
                                ref={totalAloadsAvgPFRef}
                            >
                            </Input>
                        </div>
                    </div>
                }
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={isLinearLoadEdit}
                    onCancelClick={handleCancelLinearLoadModal}
                    onUpdateClick={handleUpdateLinearLoad}
                    onActionButtonClick={handleAddLinearLoad}
                />
            </div>
        </ModalDialog>
    )
}
export default LinearLoadModalPopup;
