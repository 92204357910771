import { ActiveHarmonicFilter } from "./ActiveHarmonicFilter";
import { SystemComponent } from "./SystemComponent";
import { ComponentType } from "./ComponentType"
import { MotorControlCenter } from "./MotorControlCenter";
import { NonLinearDriveLoad } from "./NonLinearDriveLoad";
import { LVGenset, PowerGrid } from "./PowerSource";
import { Transformer } from "./Transfomer";
import { CountryConfigMapping } from "../../../models/CountryConfigMapping";
import configMapping from '../../../defaultConfigurations/countryConfigMapping.json';
import defaultConfig from '../../../defaultConfigurations/defaultConfig.json';
import { isStringEmpty } from "../../../utils/Common";
import { ResultSummaryRequestModel } from "./ResultSummaryRequestModel";

export function getPowerGridDefaultValues(country?: string): PowerGrid {
    let countryDefault = getCountrySpecificDefaultConfiguration(country);

    if (countryDefault)
        return new PowerGrid(countryDefault.powergrid?.frequency, countryDefault.powergrid?.ratedVoltage, countryDefault.powergrid?.mvasc);

    return new PowerGrid(defaultConfig.powergrid?.frequency, defaultConfig.powergrid?.ratedVoltage, defaultConfig.powergrid?.mvasc);
}

export function getLVGensetDefaultValues(country?: string): LVGenset {
    let countryDefault = getCountrySpecificDefaultConfiguration(country);

    if (countryDefault)
        return new LVGenset(
            countryDefault.generator?.frequency,
            countryDefault.generator?.ratedVoltage,
            countryDefault.generator?.id,
            countryDefault.generator?.ratedPower,
            countryDefault.generator?.ratedPf,
            countryDefault.generator?.ratedXd);

    return new LVGenset(
        defaultConfig.generator?.frequency,
        defaultConfig.generator?.ratedVoltage,
        defaultConfig.generator?.id,
        defaultConfig.generator?.ratedPower,
        defaultConfig.generator?.ratedPf,
        defaultConfig.generator?.ratedXd);
}

export function getTransformerDefaultValues(country?: string): Transformer {
    let countryDefault = getCountrySpecificDefaultConfiguration(country);
    let transformer;

    if (countryDefault)
        transformer = countryDefault.powergrid?.transformers[0]
    else
        transformer = defaultConfig.powergrid?.transformers[0];

    return new Transformer(transformer?.id, transformer?.ratedKva, transformer?.secondaryVoltage, transformer?.impedence);
}

export function getActiveHarmonicsFilterDefaultValues(): ActiveHarmonicFilter {
    return new ActiveHarmonicFilter("AF300.1", 11);
}

export function getMotorControlCenterDefaultValues(): MotorControlCenter {
    return new MotorControlCenter("M300.1");
}

export function getNonLinearDriveLoadDefaultValues(country?: string): NonLinearDriveLoad {
    let countryDefault = getCountrySpecificDefaultConfiguration(country);
    let nonLinearLoad;
    if (countryDefault && countryDefault.powergrid?.transformers[0]?.load?.driveLoadList)
        nonLinearLoad = countryDefault.powergrid?.transformers[0].load?.driveLoadList[0];
    else
        nonLinearLoad = defaultConfig.powergrid?.transformers[0].load?.driveLoadList[0];

    return new NonLinearDriveLoad(
        nonLinearLoad.id,
        nonLinearLoad.applicationName,
        nonLinearLoad.drive,
        nonLinearLoad.filter,
        { magnitude: nonLinearLoad.power.magnitude, unit: nonLinearLoad.power.unit },
        nonLinearLoad.maximumLoad,
        nonLinearLoad.quantity,
        { magnitude: nonLinearLoad.length.magnitude, unit: nonLinearLoad.length.unit }
    )
}

export function getConfigTreePowerGridDefaultValue(transformerChildComponents?: SystemComponent[], country?: string): SystemComponent {
    return {
        nodeId: "0",
        componentType: ComponentType.PowerGrid,
        componentData: getPowerGridDefaultValues(country),
        childComponents: [
            {
                nodeId: "0_0",
                componentType: ComponentType.Transformer,
                componentData: getTransformerDefaultValues(country),
                childComponents: transformerChildComponents ? transformerChildComponents : [
                    {
                        nodeId: "0_0_0",
                        componentType: ComponentType.NonLinearDriveLoad,
                        componentData: getNonLinearDriveLoadDefaultValues(country),
                    }
                ]
            }
        ]
    };
}

export function getConfigTreeLVGensetDefaultValue(childComponents?: SystemComponent[], country?: string): SystemComponent {

    return {
        nodeId: "0",
        componentType: ComponentType.LVGenset,
        componentData: getLVGensetDefaultValues(country),
        childComponents: childComponents && childComponents.length > 0 ? childComponents : [
            {
                nodeId: "0_0",
                componentType: ComponentType.NonLinearDriveLoad,
                componentData: getNonLinearDriveLoadDefaultValues(country),
            }
        ]
    };
}

/**
 * Get default values for the specified country
 * @param country Name of the country for which default values are required. 
 * @param useDefault If true it will return default country json if no country specific json is found otherwise return undefined. Be default false 
 * @returns ResultSummaryRequestModel | undefined
 */
export function getCountrySpecificDefaultConfiguration(country?: string, useDefault: boolean = false): ResultSummaryRequestModel | undefined {
    if (!country || isStringEmpty(country))
        return useDefault ? (defaultConfig as unknown) as ResultSummaryRequestModel : undefined;

    const mapping: CountryConfigMapping[] = configMapping;
    let countryConfig: CountryConfigMapping | undefined = mapping.find(item => item.countries.includes(country));
    let config: ResultSummaryRequestModel = countryConfig ? require(`../../../defaultConfigurations/${countryConfig.configurationJson}`) : defaultConfig;

    return config;
}