import RadioGroup from '../../../components/Form/RadioGroup';
import Tree from '../../../components/Tree/Tree';
import TreeItem from '../../../components/Tree/TreeItem';
import TreeNode from '../../../components/Tree/TreeNode';
import { useAppSelector } from '../../../store/hooks';
import { SystemComponent } from '../models/SystemComponent';
import CardStrip from './CardStrip';

function ConfigurationTree() {
    const { configurationTree, selectedPCCNodeId } = useAppSelector(state => state.configuration);

    const getChildItems = (component: SystemComponent) => {
        const { nodeId, childComponents } = component
        const hasChild = childComponents && childComponents.length > 0;
        return (
            <TreeNode
                key={component.nodeId}
                nodeId={nodeId}
                hasChild={hasChild}
                componentType={component.componentType}
            >
                <CardStrip
                    id={component.nodeId}
                    systemComponent={component}
                >
                    {component.componentType}
                </CardStrip>
                {(component.childComponents) &&
                    <TreeItem>
                        {component.childComponents.map((item) => { return getChildItems(item) }
                        )}
                    </TreeItem>
                }
            </TreeNode>
        )
    }

    return (
        <div className="system-configuration">
            {configurationTree &&
                <RadioGroup name="radioPCC" value={selectedPCCNodeId}>
                    <Tree
                        parentNode={
                            <CardStrip
                                id={configurationTree.nodeId}
                                size='large'
                                systemComponent={configurationTree}>
                                {configurationTree.componentType}
                            </CardStrip>
                        }>
                        {configurationTree.childComponents &&
                            <TreeItem>
                                {
                                    configurationTree.childComponents.map(
                                        (item) => {
                                            return getChildItems(item);
                                        }
                                    )
                                }
                            </TreeItem>
                        }
                    </Tree>
                </RadioGroup>
            }
        </div>
    )
}

export default ConfigurationTree