import "../styles/login.scss";
import { useAuth } from "react-oidc-context";
import { NavigationPath } from "../utils/Navigation";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslate } from "../i18translate/Hooks";
import { I18 } from "../languages/I18";
import Button from "../components/Form/Button";
import Icon from "../components/Icon";
import { downloadExcelTool } from "../models/ExcelToolDownloadModel";

function UnAuthorisedOrExcelDownloadPage() {
  const auth = useAuth();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const isUnauthorizedUser: boolean = location.pathname.toLowerCase().includes(NavigationPath.Unauthorized);

  const homeButtonHandler = (): void => navigate(NavigationPath.Home);

  const downloadButtonHandler = (): void => downloadExcelTool();

  useEffect(() => {
    if (auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator)
      auth.removeUser();
  }, [auth.isAuthenticated, auth.isLoading, auth.activeNavigator, auth]);

  if (auth.error) {
    console.error("Auth Error: ", auth.error?.message);
    return <Navigate to={NavigationPath.Home} />;
  }

  if (auth.isAuthenticated && !auth.isLoading)
    return <Navigate to={`/${NavigationPath.Logout}`} />;

  return (
    <div className="container un-authorized">
      <Icon name="document-excel" size="large" />
      <h3>{t(I18.login_unauthorized_title)}</h3>
      <span
        className="body-2-regular"
        dangerouslySetInnerHTML={isUnauthorizedUser ? { __html: t(I18.login_unauthorized_message) } : {__html: t(I18.download_excel_page_message) } }
      />
      <div className="buttton-container">
        <Button type="secondary" size="large" onClick={homeButtonHandler}>
          {t(I18.home)}
          <Icon slot="icon" name="left-arrow" size="medium" />
        </Button>
        <Button
          type="primary-black"
          size="large"
          className="download-button"
          onClick={downloadButtonHandler}
        >
          {t(I18.download)}
          <Icon slot="icon" name="download" size="medium" />
        </Button>
      </div>
    </div>
  );
}

export default UnAuthorisedOrExcelDownloadPage;
