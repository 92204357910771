import { ReportRequestModel } from "./ReportRequestModel";

export class EmailRequestModel {
    report?: ReportRequestModel;
    emailAddresses?: string;

    constructor(report?: ReportRequestModel, emailAddresses?: string) {
        this.report = report;
        this.emailAddresses = emailAddresses;
    }
}