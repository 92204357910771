import { CommonuxToggleswitch } from '@abb-ux/commonux-web-components-react'
import { JSX } from '@abb-ux/commonux-web-components/dist/types'
import { PropsWithChildren } from 'react';

interface ToggleSwitchProps extends JSX.CommonuxToggleswitch {
    className?: string;
}

function ToggleSwitch(props: PropsWithChildren<ToggleSwitchProps>) {
    return (
        <CommonuxToggleswitch {...props}>
            {props.children}
        </CommonuxToggleswitch>
    )
}

export default ToggleSwitch