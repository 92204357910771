import SetTitle from "../../components/SetTitle";
import ConfigurationModalPopup from "./components/ModalPopup/ConfigurationModalPopup";
import DeleteProjectModalPopup from "./components/ModalPopup/DeleteProjectModalPopup";
import ProjectAndConfigurationModalPopup from "./components/ModalPopup/ProjectAndCustomerInformationModalPopup";
import ProjectDetailSection from "./components/ProjectDetailSection";
import ProjectListSection from "./components/ProjectListSection";
import "./styles/style.scss";

function Project() {
    return (
        <>
            <SetTitle title="Project" />
            <div id="project-root" className="container project-container">
                <ProjectListSection></ProjectListSection>
                <ProjectDetailSection></ProjectDetailSection>
            </div>
            <ProjectAndConfigurationModalPopup />
            <ConfigurationModalPopup />
            <DeleteProjectModalPopup />
        </>
    )
}

export default Project
