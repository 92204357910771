import { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { addChildComponent, hideSystemComponentModal, hideUserTransformerModal, updateComponent } from '../../store/actions';
import { ConfigurationAction } from '../../store/actionTypes';
import { getSelectedComponent, getSelectOptionArray } from "../../../../utils/Common";
import { TransformerConfigurationOptions, Transformer } from "../../models/Transfomer";
import { SystemComponent } from '../../models/SystemComponent';
import { cloneDeep } from 'lodash';
import CommonModalFooter from './CommonModalFooter';
import { ComponentType } from '../../models/ComponentType';
import TransformerFormFields from '../../models/FormFields/TransformerFormFields';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { UnitSymbols } from '../../models/UnitSymbols';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import Icon from '../../../../components/Icon';
import ModalDialog from '../../../../components/ModalDialog';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';

function UserTransformerModalPopup() {
    const [formState, setFormState] = useState<any>({

    });
    const [selectedComponent, setSelectedComponent] = useState<SystemComponent>();
    const { configurationTree, selectedComponentNodeId, isTransformerEdit, isShowUserTransformerModal } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const idRef = useRef<any>();
    const ratedKvaRef = useRef<any>();
    const secondaryVoltageRef = useRef<any>();
    const impedenceRef = useRef<any>();
    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
    }

    const { t } = useTranslate();

    const handleCloseUserTransformerModal = () => {
        dispatch(hideUserTransformerModal());
        dispatch(hideSystemComponentModal());
    }

    const handleCancelUserTransformerModal = () => {
        dispatch(hideUserTransformerModal());
    }

    const handleChange = ({ detail }: any, name: string) => {
        let fields = { ...formState };

        //added this because select is trigerring change event before it is fully rendered
        if (!fields.hasOwnProperty(name))
            return;

        fields[name] = detail.value;
        setFormState({
            ...fields
        });
    }

    const clearData = () => {
        let fields = { ...formState };
        Object.keys(TransformerFormFields).forEach((item) => {
            fields[item] = '';
        });
        setFormState({
            ...fields
        });
    }

    useEffect(() => {
        if (isShowUserTransformerModal) {
            if (isTransformerEdit) {
                let selectedTransformer = getSelectedComponent(configurationTree, selectedComponentNodeId);
                if (selectedTransformer) {
                    setSelectedComponent(selectedTransformer);
                    let transformerData = selectedTransformer.componentData as Transformer;
                    let fields = { ...formState };
                    fields[TransformerFormFields.id] = transformerData.id;
                    fields[TransformerFormFields.ratedKva] = transformerData.ratedKva.toString();
                    fields[TransformerFormFields.secondaryVoltage] = transformerData.secondaryVoltage.toString();
                    fields[TransformerFormFields.impedence] = transformerData.impedence.toString();
                    fields[TransformerFormFields.bindingConfiguration] = transformerData.bindingConfiguration !== undefined ? transformerData.bindingConfiguration.toString() : TransformerConfigurationOptions.DeltaDelta.key.toString();
                    if (transformerData) {
                        setFormState({
                            ...fields
                        });
                    }
                }
            }
            else {
                let fields = { ...formState }
                fields[TransformerFormFields.id] = "";
                fields[TransformerFormFields.ratedKva] = "2000";
                fields[TransformerFormFields.secondaryVoltage] = "480";
                fields[TransformerFormFields.impedence] = "5.75";
                fields[TransformerFormFields.bindingConfiguration] = TransformerConfigurationOptions.DeltaDelta.key.toString();
                setFormState({
                    ...fields
                });
            }
        }
        return () => {
            clearData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowUserTransformerModal, isTransformerEdit, configurationTree, selectedComponentNodeId])

    const handleUpdate = () => {
        if (!validateForm())
            return;
        if (selectedComponent) {
            let component = cloneDeep(selectedComponent);
            let transformerData = component.componentData as Transformer;
            if (transformerData) {
                transformerData.id = formState[TransformerFormFields.id];
                transformerData.ratedKva = parseFloat(formState[TransformerFormFields.ratedKva]);
                transformerData.secondaryVoltage = parseFloat(formState[TransformerFormFields.secondaryVoltage]);
                transformerData.impedence = parseFloat(formState[TransformerFormFields.impedence]);
                transformerData.bindingConfiguration = parseInt(formState[TransformerFormFields.bindingConfiguration]);
                dispatch(updateComponent(component));
            }
        }
        dispatch(hideUserTransformerModal());
    }

    const handleAdd = () => {
        if (!validateForm())
            return;
        let transformerData: Transformer = new Transformer(
            formState[TransformerFormFields.id],
            parseFloat(formState[TransformerFormFields.ratedKva]),
            parseFloat(formState[TransformerFormFields.secondaryVoltage]),
            parseFloat(formState[TransformerFormFields.impedence]),
            parseInt(formState[TransformerFormFields.bindingConfiguration])
        );
        let transformerComponent: SystemComponent = {
            componentType: ComponentType.Transformer,
            componentData: transformerData
        };
        if (selectedComponentNodeId) {
            dispatch(addChildComponent(selectedComponentNodeId, transformerComponent));
        }
        dispatch(hideUserTransformerModal());
        dispatch(hideSystemComponentModal());
    }

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = idRef.current.validate()
            & ratedKvaRef.current.validate()
            & secondaryVoltageRef.current.validate()
            & impedenceRef.current.validate()
        return !!isValid;
    }

    return (
        <ModalDialog
            open={isShowUserTransformerModal}
            onModalClose={handleCloseUserTransformerModal}
            showBackButton={true}
            onBack={handleCancelUserTransformerModal}
            modalTitle={isTransformerEdit ? t(I18.configuration_page_user_transformer_modal_popup_edit_title) : t(I18.configuration_page_user_transformer_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
        >
            <Icon slot='title-icon' name="transformer" ></Icon>
            <div className="modal-content">
                <Input
                    className="input-style first-child"
                    size="medium"
                    labelText={t(I18.configuration_page_label_id_tag)}
                    optionalText={t(I18.configuration_page_optional_text_id_tag)}
                    value={formState[TransformerFormFields.id]}
                    onInputChange={(e) => { handleChange(e, "id"); }}
                    heMaxLength={10}
                    ref={idRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_rated_kva)}
                    optionalText={t(I18.configuration_page_optional_text_rated_kva, UnitSymbols.kVA)}
                    suffixText={UnitSymbols.kVA}
                    size="medium"
                    value={formState[TransformerFormFields.ratedKva]}
                    onInputChange={(e) => { handleChange(e, "ratedKva"); }}
                    heMinValue={1}
                    heMaxValue={500000}
                    heDecimalPrecision={0}
                    ref={ratedKvaRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_secondary_voltage)}
                    optionalText={t(I18.configuration_page_optional_text_secondary_voltage, UnitSymbols.VoltageV)}
                    suffixText={UnitSymbols.VoltageV}
                    size="medium"
                    value={formState[TransformerFormFields.secondaryVoltage]}
                    onInputChange={(e) => { handleChange(e, "secondaryVoltage"); }}
                    heMinValue={100}
                    heMaxValue={1000}
                    heDecimalPrecision={0}
                    ref={secondaryVoltageRef}>
                </Input>
                <Input
                    {...commonInputProps}
                    labelText={t(I18.configuration_page_label_rated_impedence)}
                    optionalText={t(I18.configuration_page_optional_text_rated_impedence, UnitSymbols.Percentage)}
                    suffixText={UnitSymbols.Percentage}
                    size="medium"
                    value={formState[TransformerFormFields.impedence]}
                    onInputChange={(e) => { handleChange(e, "impedence"); }}
                    heMinValue={0.001}
                    heMaxValue={50}
                    heDecimalPrecision={3}
                    ref={impedenceRef}>
                </Input>
                <SelectContainer
                    className='select-style' labelText={t(I18.configuration_page_label_configuration)} size="medium" optionalText={t(I18.configuration_page_optional_text_configuration_choose)} required>
                    <Select slot="main"
                        value={formState[TransformerFormFields.bindingConfiguration]}
                        optionText={formState[TransformerFormFields.bindingConfiguration] && getSelectOptionArray(TransformerConfigurationOptions).find(item => item.value.key.toString() === formState[TransformerFormFields.bindingConfiguration])?.value.value}
                        onSelectChange={(e) => { handleChange(e, "bindingConfiguration"); }}>
                        <div slot="options-main">
                            {getSelectOptionArray(TransformerConfigurationOptions).map((item) =>
                                <SelectOption
                                    key={item.value.key}
                                    data-value={item.value.key}
                                    selected={formState[TransformerFormFields.bindingConfiguration] && item.value.key.toString() === formState[TransformerFormFields.bindingConfiguration].toString()}
                                    optionText={item.value.value} >
                                </SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    isEdit={isTransformerEdit}
                    onCancelClick={handleCancelUserTransformerModal}
                    onActionButtonClick={handleAdd}
                    onUpdateClick={handleUpdate} />
            </div>
        </ModalDialog>
    )
}
export default UserTransformerModalPopup;
