import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthManager } from "../../../authServices/AuthManager";

const baseUrlEnv = process.env.REACT_APP_API_URL;

export const projectPageAPI = createApi({
    reducerPath: "projectpageApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrlEnv,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('accept', 'application/json');
            headers.set('Authorization', `Bearer ${AuthManager.getUser()?.access_token}`);
            return headers;
        }
    }),
    tagTypes: ['Projects', 'Configurations'],
    endpoints: () => ({})
});
