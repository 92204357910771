import React, { useState } from 'react'
import '../../styles/tree.scss';
import Icon from '../Icon';

interface TreeNodeProps {
    nodeId?: string;
    hasChild?: boolean;
    componentType?: string;
}

function TreeNode(props: React.PropsWithChildren<TreeNodeProps>) {
    const { hasChild, children, componentType = "" } = props;

    const [collapsed, setCollapsed] = useState(false);
    const handleOnClickExpandCollapse = () => {
        setCollapsed(!collapsed);
    }

    return (
        <div className={`tree-node ${collapsed ? 'collapse' : ''}`} data-test={componentType}>
            {hasChild &&
                <span className='expand-collapse' onClick={handleOnClickExpandCollapse} >
                    {collapsed ?
                        <Icon size='small' name='down' /> :
                        <Icon size='small' name='up' />}
                </span>
            }
            {children}
        </div>
    )
}

export default TreeNode