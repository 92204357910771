import { CommonuxSelectContainer } from '@abb-ux/commonux-web-components-react'
import { JSX } from '@abb-ux/commonux-web-components/dist/types'
import { PropsWithChildren } from 'react';

interface SelectContainerProps extends JSX.CommonuxSelectContainer {
    className?: string;
    class?: string;
}

function SelectContainer(props: PropsWithChildren<SelectContainerProps>) {
    return (
        <CommonuxSelectContainer {...props}>
            {props.children}
        </CommonuxSelectContainer>
    )
}

export default SelectContainer