import { cloneDeep } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import Input from "../../../../components/Form/Input";
import HarmonicIcon from "../../../../components/HarmonicIcon";
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import { useAppSelector } from "../../../../store/hooks";
import LinearLoadFormFields from "../../models/FormFields/LinearLoadFormFields";
import { LinearLoad } from "../../models/LinearLoad";
import { getPowerConversionFromSI, getUnit, getUnitConversion } from "../../models/Units";
import { UnitSymbols } from "../../models/UnitSymbols";
import ComponentValue from "../ComponentValue";

interface CardStripLinearLoadPorps {
    data: LinearLoad;
    onUpdate?: (data: LinearLoad) => void;
}

function CardStripLinearLoad(props: CardStripLinearLoadPorps) {
    const { data, onUpdate } = props;
    const { selectedUnit } = useAppSelector(state => state.configuration);
    const [cardStripState, setCardStripState] = useState<any>({ ...cloneDeep(data) });
    const maxPercentageKVALoadsRef = useRef<any>();
    const totalMotorLoadsRef = useRef<any>();
    const totalSoftStarterLoadsRef = useRef<any>();
    const totalKVALoadsRef = useRef<any>();
    const totalKWResistiveLoadsRef = useRef<any>();
    const totalALoadsRef = useRef<any>();
    const loadTypeSpecificLoad = "specificLoad";

    useEffect(() => {
        resetCardStripState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedUnit]);

    const resetCardStripState = () => {
        let updatedData = { ...cloneDeep(data) }
        updatedData.totalMotorLoads.power = {
            magnitude: getUnitConversion(updatedData.totalMotorLoads.power.magnitude, "Power", updatedData.totalMotorLoads.power.unit, selectedUnit),
            unit: selectedUnit
        }
        updatedData.totalSoftStarterLoads.power = {
            magnitude: getUnitConversion(updatedData.totalSoftStarterLoads.power.magnitude, "Power", updatedData.totalSoftStarterLoads.power.unit, selectedUnit),
            unit: selectedUnit
        }
        setCardStripState(updatedData);
    }

    const handleOnUpdateNonStandard = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name].power = detail.value;
        setCardStripState({ ...state });
    }

    const handleOnUpdateStandard = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name].power.magnitude = detail.value;
        setCardStripState({ ...state });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === LinearLoadFormFields.maxPercentageKVALoads && !maxPercentageKVALoadsRef.current.validate())
            return false;

        if (editFiledId === LinearLoadFormFields.totalMotorLoads && !totalMotorLoadsRef.current.validate())
            return false;

        if (editFiledId === LinearLoadFormFields.totalSoftStarterLoads && !totalSoftStarterLoadsRef.current.validate())
            return false;

        if (editFiledId === LinearLoadFormFields.totalKVALoads && !totalKVALoadsRef.current.validate())
            return false;

        if (editFiledId === LinearLoadFormFields.totalResistiveLoads && !totalKWResistiveLoadsRef.current.validate())
            return false;

        if (editFiledId === LinearLoadFormFields.totalALoads && !totalALoadsRef.current.validate())
            return false;

        let updatedData = cloneDeep(data);
        (updatedData as any)[editFiledId] = cardStripState[editFiledId];
        onUpdate(updatedData);
        return true;
    }

    const handleCancel = () => {
        resetCardStripState();
    }

    const { t } = useTranslate();

    return (
        <>
            <HarmonicIcon className="component-icon" size="small" name="linear-load" />
            <span className="component-name body-1-regular">
                {t(I18.configuration_page_card_strip_linear_loads_title)}
            </span>
            {data.selectedLoadType !== loadTypeSpecificLoad &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_kva_loads)}
                    value={`${data.maxPercentageKVALoads?.power} %`}
                    editable
                    fieldId={LinearLoadFormFields.maxPercentageKVALoads}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}>
                    <Input
                        placeholder="0"
                        inputType='number'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_kva_loads, UnitSymbols.Percentage)}
                        suffixText={UnitSymbols.Percentage}
                        required
                        value={cardStripState.maxPercentageKVALoads?.power}
                        onInputChange={(e) => { handleOnUpdateNonStandard(e, LinearLoadFormFields.maxPercentageKVALoads); }}
                        heIsRequired={true}
                        heIsNumberOnly={true}
                        heMinValue={0}
                        heMaxValue={150}
                        heDecimalPrecision={1}
                        ref={maxPercentageKVALoadsRef}>
                    </Input>
                </ComponentValue>}
            {data.selectedLoadType === loadTypeSpecificLoad && <>
                <ComponentValue
                    labelText={t(I18.configuration_page_label_motor_loads)}
                    value={`${getUnitConversion(data.totalMotorLoads.power.magnitude, "Power", data.totalMotorLoads.power.unit, selectedUnit)} ${getUnit("Power", selectedUnit)}`}
                    editable
                    fieldId={LinearLoadFormFields.totalMotorLoads}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}
                >
                    <Input
                        placeholder="0"
                        inputType='number'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_motor_loads, getUnit("Power", selectedUnit))}
                        suffixText={getUnit("Power", selectedUnit)}
                        required
                        value={cardStripState.totalMotorLoads?.power?.magnitude}
                        onInputChange={(e) => { handleOnUpdateStandard(e, LinearLoadFormFields.totalMotorLoads); }}
                        heIsRequired={true}
                        heDecimalPrecision={2}
                        heIsNumberOnly={true}
                        heMinValue={0}
                        heMaxValue={getPowerConversionFromSI(10000, selectedUnit)}
                        ref={totalMotorLoadsRef}
                    >
                    </Input>
                </ComponentValue>
                <ComponentValue
                    labelText={t(I18.configuration_page_label_soft_starter_loads)}
                    value={`${getUnitConversion(data.totalSoftStarterLoads.power.magnitude, "Power", data.totalSoftStarterLoads.power.unit, selectedUnit)} ${getUnit("Power", selectedUnit)}`}
                    editable
                    fieldId={LinearLoadFormFields.totalSoftStarterLoads}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel} >
                    <Input
                        placeholder="0"
                        inputType='number'
                        size="small"
                        suffixText={getUnit("Power", selectedUnit)}
                        optionalText={t(I18.configuration_page_optional_text_soft_starter_loads, getUnit("Power", selectedUnit))}
                        required
                        value={cardStripState.totalSoftStarterLoads?.power?.magnitude}
                        onInputChange={(e) => { handleOnUpdateStandard(e, LinearLoadFormFields.totalSoftStarterLoads); }}
                        heIsRequired={true}
                        heIsNumberOnly={true}
                        heDecimalPrecision={2}
                        heMinValue={0}
                        heMaxValue={getPowerConversionFromSI(10000, selectedUnit)}
                        ref={totalSoftStarterLoadsRef}>
                    </Input>
                </ComponentValue>
                <ComponentValue
                    labelText={t(I18.configuration_page_label_kva_loads)}
                    value={`${data.totalKVALoads?.power} kVA`}
                    editable
                    fieldId={LinearLoadFormFields.totalKVALoads}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel} >
                    <Input
                        placeholder="0"
                        inputType='number'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_kva_loads, UnitSymbols.kVA)}
                        suffixText={UnitSymbols.kVA}
                        required
                        value={cardStripState.totalKVALoads?.power}
                        onInputChange={(e) => { handleOnUpdateNonStandard(e, LinearLoadFormFields.totalKVALoads); }}
                        heIsRequired={true}
                        heIsNumberOnly={true}
                        heMinValue={0}
                        heMaxValue={5000}
                        heDecimalPrecision={1}
                        ref={totalKVALoadsRef}>
                    </Input>
                </ComponentValue>

                <ComponentValue
                    labelText={t(I18.configuration_page_label_soft_resistive_loads)}
                    value={`${data.totalResistiveLoads.power} kW`}
                    editable
                    fieldId={LinearLoadFormFields.totalResistiveLoads}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}>
                    <Input
                        placeholder="0"
                        inputType='number'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_resistive_loads, UnitSymbols.PowerkW)}
                        suffixText={UnitSymbols.PowerkW}
                        required
                        value={cardStripState.totalResistiveLoads?.power}
                        onInputChange={(e) => { handleOnUpdateNonStandard(e, LinearLoadFormFields.totalResistiveLoads); }}
                        heIsRequired={true}
                        heIsNumberOnly={true}
                        heMinValue={0}
                        heMaxValue={7500}
                        heDecimalPrecision={1}
                        ref={totalKWResistiveLoadsRef}>
                    </Input>
                </ComponentValue>
                <ComponentValue
                    labelText={t(I18.configuration_page_label_soft_current_loads)}
                    value={`${data.totalALoads?.power} A`}
                    editable
                    fieldId={LinearLoadFormFields.totalALoads}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}
                    className="display-xl">
                    <Input
                        placeholder="0"
                        inputType='number'
                        size="small"
                        optionalText={t(I18.configuration_page_optional_text_current_loads, UnitSymbols.Current)}
                        suffixText={UnitSymbols.Current}
                        required
                        value={cardStripState.totalALoads?.power}
                        onInputChange={(e) => { handleOnUpdateNonStandard(e, LinearLoadFormFields.totalALoads); }}
                        heIsRequired={true}
                        heIsNumberOnly={true}
                        heMinValue={0}
                        heMaxValue={1000}
                        heDecimalPrecision={1}
                        ref={totalALoadsRef}>
                    </Input>
                </ComponentValue>
            </>
            }
        </>
    )
}
export default memo(CardStripLinearLoad)