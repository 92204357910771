import ConfigurationSectionHeader from "./ConfigurationSectionHeader";
import "../styles/configurationlistitem.scss";
import ConfigurationListItem from "./ConfigurationListItem";
import { useParams } from "react-router-dom";
import { useAddConfigurationMutation, useDeleteconfigurationMutation, useGetConfigurationByProjectIdAndConfigurationIdMutation, useUpdateConfigurationJsonMutation } from "../services/ConfigurationAPIServices";
import { useDispatch } from "react-redux";
import { showConfigurationModal } from "../store/actions";
import { ConfigurationModel } from "../model/ConfigurationModel";
import NotificationManager from "../../../utils/NotificationManager";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import { ConfigurationJSONModel } from "../model/ConfigurationJSONModel";

interface ConfigurationSectionProps {
    configurations?: ConfigurationModel[];
    isLoading?: boolean;
}

function ConfigurationSection(props: ConfigurationSectionProps) {
    const { configurations = [], isLoading = true } = props
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { projectId = "" } = useParams();
    const [addConfiguration] = useAddConfigurationMutation();
    const [deleteConfiguration] = useDeleteconfigurationMutation();
    const [getConfigByProjectIdAndConfigurationId] = useGetConfigurationByProjectIdAndConfigurationIdMutation();
    const [updateConfigurationJson] = useUpdateConfigurationJsonMutation();

    const handleListItemClick = (action: string, configuration: ConfigurationModel) => {
        switch (action) {
            case "rename":
                dispatch(showConfigurationModal(projectId, true, configuration))
                break;

            case "duplicate":
                duplicateConfiguration(configuration);
                break;

            case "delete":
                deleteConfiguration(configuration).unwrap()
                    .then((data) => {
                        NotificationManager.success(
                            t(I18.project_page_delete_configuration_success_notification_text));
                    })
                    .catch((error) => NotificationManager.error(error))
                break;

            default:
                break;
        }
    }

    function duplicateConfiguration(configuration: ConfigurationModel) {
        let { configurationID, updatedDateTime, ...newConfig } = configuration;
        newConfig.configuration = { projectID: configuration.projectID };
        //create new configuration without json
        addConfiguration(newConfig).unwrap()
            .then((configData: ConfigurationModel) => {
                //After create configuration get source configJson and update to new Configuration 
                if (configData && configData.configurationID)
                    updateConfigJson(configData.configurationID, configuration);

                NotificationManager.success(
                    t(I18.project_page_create_new_configuration_modalpopup_success_notification_text));
            })
            .catch((error) => NotificationManager.error(error));
    }

    const updateConfigJson = (newConfigurationId: string, configuration: ConfigurationModel) => {
        getConfigByProjectIdAndConfigurationId({ projectId: configuration.projectID, configurationId: configuration.configurationID ?? "" }).unwrap()
            .then((configData: ConfigurationModel) => {
                const configJsonRequest: ConfigurationJSONModel = {
                    projectID: projectId,
                    configurationID: newConfigurationId,
                    configurationInfo: configData.configuration?.configurationInfo
                }
                updateConfigurationJson({ projectId: configuration.projectID, configurationId: newConfigurationId, data: configJsonRequest })
            })
            .catch((error) => NotificationManager.error("Error in updating configuration json."));
    }

    return (
        <section id="section-configuration" className="configuration-section">
            <ConfigurationSectionHeader projectId={projectId} />
            <div className="configuration-section-list">
                {isLoading &&
                    <ConfigurationListItem isLoading={isLoading} configuration={{
                        configurationName: "loading",
                        projectID: "",
                        revision: "loading",
                        configurationID: ""
                    }} ></ConfigurationListItem>
                }
                {
                    !isLoading && configurations &&
                    configurations.map((config: ConfigurationModel, index: number) =>
                        config &&
                        <ConfigurationListItem key={index} configuration={config} onMenuAction={(action) => handleListItemClick(action, config)}></ConfigurationListItem>
                    )
                }
            </div>
        </section>
    )
}

export default ConfigurationSection