import Input from '../../../../components/Form/Input'
import Select from '../../../../components/Form/Select'
import SelectContainer from '../../../../components/Form/SelectContainer'
import SelectOption from '../../../../components/Form/SelectOption'
import ModalDialog from '../../../../components/ModalDialog'
import { useEffect, useState, useRef } from 'react';
import CommonModalFooter from '../../../Configuration/components/AddNewComponentModalPopup/CommonModalFooter';
import { useAddProjectMutation, useUpdateProjectMutation } from '../../services/ProjectAPIServices'
import { useTranslate } from '../../../../i18translate/Hooks'
import { I18 } from '../../../../languages/I18'
import NotificationManager from '../../../../utils/NotificationManager'
import { useAppSelector } from '../../../../store/hooks'
import { useDispatch } from 'react-redux'
import { hideProjectModal } from '../../store/actions'
import { ProjectModel } from '../../model/ProjectModel'
import { useGetAllCoutriesQuery, useGetAllIndustriesQuery } from '../../../../services/CommonAPIServices'

const ProjectAndConfigurationModalPopup = () => {
    const { projectModal } = useAppSelector(store => store.project);
    const dispatch = useDispatch();
    const { data: countries, isFetching: isCountriesFetching } = useGetAllCoutriesQuery();
    const { data: industries, isFetching: isIndustriesFetching } = useGetAllIndustriesQuery();
    const [addProject, { isLoading: isProjectCreating }] = useAddProjectMutation();
    const [updateProject, { isLoading: isProjectUpdating }] = useUpdateProjectMutation();
    const isEdit: boolean = !!projectModal.isEdit && !!projectModal.project;

    interface InputValueType {
        projectName: string;
        contactPerson: string;
        country: string;
        industry: string;
        company: string;
    }

    const initialInputValues: InputValueType = {
        projectName: "Untitled Project",
        contactPerson: "",
        country: "United States",
        industry: "HVACR",
        company: "",
    }

    const { t } = useTranslate();
    const [inputValues, setInputValues] = useState<InputValueType>(initialInputValues);
    const projectNameRef = useRef<any>();
    const contactref = useRef<any>();
    const companyRef = useRef<any>();

    useEffect(() => {
        if (isEdit) {
            let project = { ...projectModal.project }
            setInputValues({
                projectName: project.projectName ?? "",
                company: project.contact?.company ?? "",
                contactPerson: project.contact?.contactPerson ?? "",
                country: project.contact?.country ?? "",
                industry: project.contact?.industry ?? ""
            })
        }
        //**Validating here as a temporary fix for commonux bug in validation (Validation error is still showing after the component is re-rendered)*/
        validateForm();
        return () => {
            setInputValues({ ...initialInputValues });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, projectModal.project, projectModal.modalState])

    const inputHandler = (e: any, name: string) => {
        setInputValues((previousInputValue: any) => ({
            ...previousInputValue,
            [name]: e.detail.value
        }))
    }

    const validateForm = (): boolean => {
        let isValid = true
        isValid = projectNameRef.current.validate()
            && contactref.current.validate()
            && companyRef.current.validate()
        return isValid
    }

    const actionButtonHandler = () => {
        if (!validateForm()) return;

        let project: ProjectModel = {
            projectName: inputValues.projectName,
            contact: {
                company: inputValues.company,
                country: inputValues.country,
                industry: inputValues.industry,
                contactPerson: inputValues.contactPerson
            },
            projectID: isEdit && projectModal.project?.projectID ? projectModal.project.projectID : undefined
        }
        console.log(project)

        if (isEdit)
            updateProject(project).unwrap()
                .then((data) => {
                    NotificationManager.success(t(I18.project_page_edit_project_modalpopup_success_notification_text));
                    dispatch(hideProjectModal());
                })
                .catch((error) => NotificationManager.error(error))
        else
            addProject(project).unwrap()
                .then((data) => {
                    NotificationManager.success(t(I18.project_page_new_project_modalpopup_success_notification_text));
                    dispatch(hideProjectModal());
                })
                .catch((error) => NotificationManager.error(error))
    }

    const handleBackButtonClick = () => dispatch(hideProjectModal())

    return (
        <ModalDialog
            open={projectModal.modalState}
            showBackButton={false}
            modalTitle={isEdit ? t(I18.project_page_edit_customer_information_modalpopup_title) : t(I18.project_page_new_project_modalpopup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
            onModalClose={handleBackButtonClick}
        >
            <div className="modal-content">
                <Input
                    labelText={t(I18.project_name)}
                    heIsRequired
                    optionalText={t(I18.optioal_text_project_name)}
                    size="medium"
                    ref={projectNameRef}
                    heMaxLength={100}
                    value={inputValues.projectName}
                    onInputInput={(e) => inputHandler(e, "projectName")}>
                </Input>
                <Input
                    labelText={t(I18.project_page_modalpopup_label_text_contact_person)}
                    optionalText={t(I18.project_page_modalpopup_optioal_text_contact_person)}
                    size="medium"
                    heMaxLength={100}
                    ref={contactref}
                    value={inputValues.contactPerson}
                    onInputInput={(e) => inputHandler(e, "contactPerson")}>
                </Input>
                <SelectContainer
                    labelText={t(I18.project_page_modalpopup_label_text_country)}
                    size="medium"
                    optionalText={t(I18.project_page_modalpopup_optioal_text_country)}>
                    <Select
                        slot="main"
                        value={inputValues.country}
                        optionText={inputValues.country}
                        maxOption={5}
                        onSelectChange={(e) => inputHandler(e, "country")}
                        filter={true}>
                        <div slot="options-main">
                            {!isCountriesFetching && countries &&
                                countries.map((item, index) => (
                                    <SelectOption
                                        key={item.name}
                                        data-value={item.name}
                                        selected={item.name === inputValues.country}>
                                    </SelectOption>
                                ))}
                        </div>
                    </Select>
                </SelectContainer>
                <SelectContainer
                    labelText={t(I18.project_page_modalpopup_label_text_Industry)}
                    size="medium"
                    optionalText={t(I18.project_page_modalpopup_optioal_text_Industry)}>
                    <Select
                        slot="main"
                        value={inputValues.industry}
                        optionText={inputValues.industry}
                        maxOption={5}
                        onSelectChange={(e) => inputHandler(e, "industry")}>
                        <div slot="options-main">
                            {!isIndustriesFetching && industries &&
                                industries.map((item) => (
                                    <SelectOption
                                        key={item.industry}
                                        data-value={item.industry}
                                        selected={item.industry === inputValues.industry}>
                                    </SelectOption>
                                ))}
                        </div>
                    </Select>
                </SelectContainer>
                <Input
                    labelText={t(I18.project_page_modalpopup_label_text_company)}
                    optionalText={t(I18.project_page_modalpopup_optioal_text_company)}
                    size="medium"
                    ref={companyRef}
                    value={inputValues.company}
                    heMaxLength={100}
                    onInputInput={(e) => inputHandler(e, "company")}>
                </Input>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    key={isProjectCreating || isProjectUpdating ? "loading" : "defualt"}
                    isEdit={isEdit}
                    isLoading={isProjectCreating || isProjectUpdating}
                    actionButtonText={isProjectCreating ? t(I18.creating) : t(I18.create)}
                    onActionButtonClick={actionButtonHandler}
                    onUpdateClick={actionButtonHandler}
                    onCancelClick={handleBackButtonClick}
                />
            </div>
        </ModalDialog >
    )
}

export default ProjectAndConfigurationModalPopup;