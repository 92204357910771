import Icon from "../../../components/Icon";
import ToolTip from "../../../components/ToolTip";
import { useTranslate } from "../../../i18translate/Hooks";
import { I18 } from "../../../languages/I18";
import { useAppSelector } from "../../../store/hooks";
import { roundOff } from "../../../utils/Common";
import { PowerSourceOptions } from "../models/PowerSource";
import "../styles/commonstyle.scss";

export interface SummaryCardItemProps {
    title: string;
    value?: number;
    limit?: number;
    toolTip?: string;
    unitSymbol?: string;
    loading: boolean;
}

function SummaryCardItem(props: SummaryCardItemProps) {
    const { title, value, limit, toolTip, loading} = props;
    const hasLimit = limit !== undefined; 
    const isSuccess = hasLimit && value !== undefined && value <= limit;
    let unitSymbol = props.unitSymbol ? props.unitSymbol : '';
    const {selectedPowerSource} = useAppSelector(state => state.configuration);
    const {t} = useTranslate();

    return (
        <li>
            <div className="summary-value">
                <div className="name-label body-1-medium">
                    <label className={`${loading ? 'skeleton' : 'name-label body-1-medium'}`}>{title}</label>
                    {toolTip && !loading && <ToolTip label={toolTip} position="top">
                        <Icon size="small" name="information-circle-2" />
                    </ToolTip>
                    }
                </div>
                <div className={`value-label ${hasLimit && (isSuccess ? 'success' : 'fail')}`} >
                    {!loading && hasLimit && <Icon size="small" name={isSuccess ? 'check-mark-circle-1' : 'error-circle-1'} />}
                    <label className={`${loading ? 'skeleton' : 'name-label body-1-medium'}`}>
                        {`${value !== undefined ? roundOff(value, 2) : '--'}${unitSymbol}`}
                    </label>
                </div>
            </div>
            {
                hasLimit &&
                <div className='summary-limit description-regular'>
                    <label className={`${loading ? 'skeleton skeleton-text-limit' : ' '}`}> {selectedPowerSource == PowerSourceOptions.PowerGrid.key ? t(I18.configuration_page_summary_card_label_limit) : t(I18.configuration_page_summary_card_label_Recommendation) }</label>
                    <label className={`${loading ? 'skeleton skeleton-text-limit' : 'summary-limit description-regular'}`}>
                        {`${roundOff(limit, 2)}${unitSymbol}`}
                    </label>
                </div>
            }
        </li>
    )
}

export default SummaryCardItem