
import { useState, useRef } from "react"
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import Input from "../../../../components/Form/Input";
import ModalDialog from "../../../../components/ModalDialog";
import { hideEmailModal } from "../../store/actions";
import { I18 } from "../../../../languages/I18";
import { useTranslate } from "../../../../i18translate/Hooks";
import { useSendEmailMutation } from "../../services/APIService";
import { ResultSummaryRequestPropModel } from "../../models/ResultSummaryRequestPropModel";
import { EmailRequestModel } from "../../models/EmailRequestModel";
import { ReportRequestModel } from "../../models/ReportRequestModel";
import NotificationManager from "../../../../utils/NotificationManager";
import CommonModalFooter from "../../../Configuration/components/AddNewComponentModalPopup/CommonModalFooter";

const EmailModalPopup = (props: ResultSummaryRequestPropModel) => {
    const { resultSummaryRequest } = props;
    const { reportHeaderConfig } = useAppSelector(store => store.report);
    const { t } = useTranslate();
    const { emailModalConfig } = useAppSelector(store => store.report);
    const [emailAddress, setEmailAddress] = useState(undefined);
    const [sendEmail, { isLoading: isEmailSending }] = useSendEmailMutation();
    const dispatch = useDispatch();
    const emailRef = useRef<any>();
    const emailRegExp: RegExp = new RegExp(/^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}( ?, )*)+$/);
    const emailError: string = "Enter a valid email ID"

    const validateForm = (): boolean => {
        let isValid = 1;
        isValid = emailRef.current.validate();
        return !!isValid;
    }

    const handleCloseEmailModal = () => {
        dispatch(hideEmailModal());
    }

    const handleSendEmail = () => {

        if (!validateForm() || !emailAddress)
            return;

        else {
            const requestModel = new ReportRequestModel(reportHeaderConfig, resultSummaryRequest)
            const emailRequest = new EmailRequestModel(requestModel, emailAddress);

            if (emailRequest) {
                sendEmail(emailRequest).unwrap()
                    .then(data => {
                        console.log(data);
                        handleCloseEmailModal();
                        NotificationManager.success("Email sent successfully!");

                    })
                    .catch(error => {
                        console.log("error in sending email", error);
                        NotificationManager.error("Error sending email");

                    })
            }
        }
    }


    return (
        <ModalDialog
            open={emailModalConfig.modalState}
            onModalClose={handleCloseEmailModal}
            showBackButton={true}
            onBack={handleCloseEmailModal}
            modalTitle={t(I18.report_page_email_modal_popup_title)}
            showFooterSeparator={true}
            className="commonux-modal-popup"
        >
            <div className="modal-content">
                <Input
                    className='input-generate-report input-email-style'
                    labelText={t(I18.email_address)}
                    optionalText={t(I18.email_optional_text)}
                    size="medium"
                    placeholder={t(I18.enter_email_id)}
                    value={emailAddress}
                    heRegExp={emailRegExp}
                    ref={emailRef}
                    heIsRequired={true}
                    heErrorMessages={{ showErrorMessage: true, errors: { regExp: emailError } }}
                    onInputInput={(e) => setEmailAddress(e.detail.value)}>
                </Input>
            </div>

            <div slot="footer">
                <CommonModalFooter
                    key={isEmailSending ? "loading" : "defualt"}
                    isEdit={false}
                    isLoading={isEmailSending}
                    actionButtonText={isEmailSending ? t(I18.sending) : t(I18.send)}
                    onActionButtonClick={handleSendEmail}
                    onCancelClick={handleCloseEmailModal}
                />
            </div>
        </ModalDialog >
    )
}

export default EmailModalPopup;