import { useParams } from 'react-router-dom';
import WindowBackNavigation from '../../../components/WindowBackNavigation';
import { NavigationPath } from '../../../utils/Navigation';

function ReportHeader() {
    const { projectId, configurationId } = useParams();

    return (
        <div className='page-title print-hidden'>
            <div className='title'>
                <WindowBackNavigation navigateTo={`/${NavigationPath.Configuration}/${projectId}/${configurationId}`} />
                <h4>Report</h4>
            </div>
        </div>
    )
}
export default ReportHeader