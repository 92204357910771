
const NotFound = () => {
    return (
        <>
            <h1>
                We can't find that page for you.
            </h1>
        </>
    )
}

export default NotFound