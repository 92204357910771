import { clone } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { getSelectOptionArray } from '../../../../utils/Common';
import { PowerGrid, PowerGridFrequencyOptions } from '../../models/PowerSource';
import ComponentValue from '../ComponentValue';
import PowerGridFormFields, { powergridRadioOptions } from '../../models/FormFields/PowerGridFormFields';
import { UnitSymbols } from '../../models/UnitSymbols';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
interface CardStripPowerGridPorps {
    data: PowerGrid;
    onUpdate?: (data: PowerGrid) => void;
}

function CardStripPowerGrid(props: CardStripPowerGridPorps) {
    const { data, onUpdate } = props;
    const [cardStripState, setCardStripState] = useState<any>({ ...data });
    const ratedVoltageRef = useRef<any>();
    const mvascRef = useRef<any>();
    const iscRef = useRef<any>();
    const feederRRef = useRef<any>();
    const feederXRef = useRef<any>();

    const commonInputProps: any = {
        className: "input-style",
        inputType: 'number',
        placeholder: "0",
        size: "small",
        heIsNumberOnly: true,
        heIsRequired: true,
    }

    useEffect(() => {
        setCardStripState({ ...data });
    }, [data]);

    const handleOnUpdate = ({ detail }: any, name: string) => {
        let state = { ...cardStripState };
        state[name] = detail.value;
        setCardStripState({ ...state });
    }

    const handleOnUpdateComponentValue = (editFiledId: string): boolean => {
        if (!onUpdate)
            return true;

        if (editFiledId === PowerGridFormFields.ratedVoltage && !ratedVoltageRef.current.validate())
            return false;

        if (editFiledId === PowerGridFormFields.mvasc && !mvascRef.current.validate())
            return false;

        if (editFiledId === PowerGridFormFields.isc && !iscRef.current.validate())
            return false;

        if (editFiledId === PowerGridFormFields.resistance && !feederRRef.current.validate())
            return false;

        if (editFiledId === PowerGridFormFields.inductance && !feederXRef.current.validate())
            return false;

        let updatedData = clone(data);
        (updatedData as any)[editFiledId] = parseFloat(cardStripState[editFiledId]);
        onUpdate(updatedData);

        return true;
    }

    const handleCancel = () => {
        setCardStripState({ ...data });
    }

    const { t } = useTranslate();

    return (
        <>
            <ComponentValue
                labelText={t(I18.configuration_page_label_frequency)}
                value={`${data.frequency} ${UnitSymbols.Frequency}`}
                editable
                fieldId={PowerGridFormFields.frequency}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
            >
                <SelectContainer
                    size="small"
                    className="block-edit"
                    required
                    optionalText={t(I18.configuration_page_optional_text_frequency_short_form, UnitSymbols.Frequency)}
                    suffixText={UnitSymbols.Frequency}
                >
                    <Select slot="main" onSelectChange={(e) => handleOnUpdate(e, PowerGridFormFields.frequency)}>
                        <div slot="options-main">
                            {getSelectOptionArray(PowerGridFrequencyOptions).map((item) =>
                                <SelectOption key={item.value.key} data-value={item.value.key} selected={item.value.key.toString() === cardStripState.frequency.toString()} optionText={item.value.value} ></SelectOption>)
                            }
                        </div>
                    </Select>
                </SelectContainer>
            </ComponentValue>
            <ComponentValue
                labelText={t(I18.configuration_page_label_voltage)}
                value={`${data.ratedVoltage} ${UnitSymbols.VoltagekV}`}
                editable
                fieldId={PowerGridFormFields.ratedVoltage}
                onUpdateValue={handleOnUpdateComponentValue}
                onCancel={handleCancel}
            >
                <Input
                    {...commonInputProps}
                    optionalText={t(I18.configuration_page_optional_text_voltage, UnitSymbols.VoltagekV)}
                    suffixText={UnitSymbols.VoltagekV}
                    value={cardStripState.ratedVoltage.toString()}
                    onInputChange={(e) => { handleOnUpdate(e, PowerGridFormFields.ratedVoltage); }}
                    heMinValue={0.1}
                    heMaxValue={500}
                    heDecimalPrecision={2}
                    ref={ratedVoltageRef}
                >
                </Input>
            </ComponentValue>
            {
                data.powergridRadioSelection === powergridRadioOptions.mvasc &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_mvasc)}
                    value={`${data.mvasc}`}
                    editable
                    fieldId={PowerGridFormFields.mvasc}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}
                    className="display-xl"
                >
                    <Input
                        {...commonInputProps}
                        optionalText={t(I18.configuration_page_optional_text_mvasc)}
                        required
                        value={cardStripState.mvasc?.toString()}
                        onInputChange={(e) => { handleOnUpdate(e, PowerGridFormFields.mvasc); }}
                        heMinValue={0.001}
                        heMaxValue={1000}
                        heDecimalPrecision={3}
                        ref={mvascRef}
                    >
                    </Input>
                </ComponentValue>
            }
            {
                data.powergridRadioSelection === powergridRadioOptions.isc &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_isc)}
                    value={`${data.isc} ${UnitSymbols.Current}`}
                    editable
                    fieldId={PowerGridFormFields.isc}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}
                    className="display-xl"
                >
                    <Input
                        {...commonInputProps}
                        optionalText={t(I18.configuration_page_optional_text_isc, UnitSymbols.Current)}
                        required
                        value={cardStripState.isc?.toString()}
                        onInputChange={(e) => { handleOnUpdate(e, PowerGridFormFields.isc); }}
                        suffixText='A'
                        heDecimalPrecision={0}
                        heMinValue={10}
                        heMaxValue={100000}
                        ref={iscRef}
                    >
                    </Input>
                </ComponentValue>
            }
            {
                data.powergridRadioSelection === powergridRadioOptions.feederrandx &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_feeder_r)}
                    value={`${data.resistance} ${UnitSymbols.ResistanceOhm}`}
                    editable
                    fieldId={PowerGridFormFields.resistance}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}
                    className="display-xl"
                >
                    <Input
                        {...commonInputProps}
                        optionalText={t(I18.configuration_page_optional_text_feeder_r, UnitSymbols.ResistanceOhm)}
                        required
                        value={cardStripState.resistance?.toString()}
                        onInputChange={(e) => { handleOnUpdate(e, PowerGridFormFields.resistance); }}
                        suffixText='Ω'
                        heDecimalPrecision={4}
                        heMinValue={0.0001}
                        heMaxValue={10000}
                        ref={feederRRef}
                    >
                    </Input>
                </ComponentValue>
            }
            {
                data.powergridRadioSelection === powergridRadioOptions.feederrandx &&
                <ComponentValue
                    labelText={t(I18.configuration_page_label_feeder_x)}
                    value={`${data.inductance} ${UnitSymbols.ResistanceOhm}`}
                    editable
                    fieldId={PowerGridFormFields.inductance}
                    onUpdateValue={handleOnUpdateComponentValue}
                    onCancel={handleCancel}
                    className="display-xl"
                >
                    <Input
                        {...commonInputProps}
                        optionalText={t(I18.configuration_page_optional_text_feeder_x, UnitSymbols.ResistanceOhm)}
                        required
                        value={cardStripState.inductance?.toString()}
                        onInputChange={(e) => { handleOnUpdate(e, PowerGridFormFields.inductance); }}
                        suffixText={UnitSymbols.ResistanceOhm}
                        heDecimalPrecision={4}
                        heMinValue={0.0001}
                        heMaxValue={10000}
                        ref={feederXRef}
                    >
                    </Input>
                </ComponentValue>
            }
        </>
    )
}
export default CardStripPowerGrid