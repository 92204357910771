import { ImportValidationAPI } from './../pages/Configuration/services/ImportApiServices';
import { combineReducers } from 'redux';
import { configAPI } from '../pages/Configuration/services/APIService';
import { configurationJSONAPI } from '../pages/Configuration/services/ConfigurationAPIService';
import configurationReducer from '../pages/Configuration/store/reducers';
import { articalAndCaseStudyAPI } from '../pages/Home/services/ArticalAndCaseStudyService';
import homeReducer from '../pages/Home/store/reducers';
import projectReducer from '../pages/Project/store/reducers';
import { reportAPI } from '../pages/Report/services/APIService';
import reportReducer from '../pages/Report/store/reducers';
import { projectPageAPI } from '../pages/Project/services/APIService';
import { commonApi } from '../services/CommonAPIServices';
import { VersionLicenseAndDiscliamerAPI } from '../pages/Info/services/VersionLicenseAndDiscliamerService';
import { assumptionAPI } from '../pages/Configuration/services/ContentfulAPIService';
import mainLayoutReducer from '../layout/store/reducer';

const rootReducer = combineReducers({
    home: homeReducer,
    configuration: configurationReducer,
    report: reportReducer,
    project: projectReducer,
    main: mainLayoutReducer,
    [configAPI.reducerPath]: configAPI.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    [projectPageAPI.reducerPath]: projectPageAPI.reducer,
    [configurationJSONAPI.reducerPath]: configurationJSONAPI.reducer,
    [articalAndCaseStudyAPI.reducerPath]: articalAndCaseStudyAPI.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [VersionLicenseAndDiscliamerAPI.reducerPath]: VersionLicenseAndDiscliamerAPI.reducer,
    [ImportValidationAPI.reducerPath]: ImportValidationAPI.reducer,
    [assumptionAPI.reducerPath]: assumptionAPI.reducer

});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
