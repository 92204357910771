import { User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import { NavigationPath } from "../utils/Navigation";

const isDisableAuthGroupCheck = process.env.REACT_APP_DISABLE_AUTH_GROUP_CHECK ? process.env.REACT_APP_DISABLE_AUTH_GROUP_CHECK : "false";

const AuthConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_AUTH_AUTHORITY_URL ?? "",
    client_id: process.env.REACT_APP_AUTH_CLIENTID ?? "",
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? "",
    scope: process.env.REACT_APP_AUTH_SCOPE,
    response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
    post_logout_redirect_uri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
    response_mode: "query",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    onSigninCallback: (user: User | void) => {
        //adde environment check because group 1_GLB-ALL-ABB-Employees only available for ABB internal Users.
        if (isDisableAuthGroupCheck === "true")
            window.location.assign(`/${NavigationPath.Project}`);
        else {
            if (!user?.profile?.groups) {
                window.location.assign(`/${NavigationPath.Logout}`);
                return;
            }
            const isUserInABBInternalsGroup: boolean = ((user?.profile?.groups as string[]).includes("1_GLB-ALL-ABB-Employees"))
            const isUserInHEExternalsGroup: boolean = ((user?.profile?.groups as string[]).includes("1_HarmonicEstimator_Externals"))

            //User part of group 1_GLB-ALL-ABB-Employees and 1_HarmonicEstimator_Externals are allowed to login are logged out automatically.
            if (!(isUserInABBInternalsGroup || isUserInHEExternalsGroup)) {
                window.location.assign(`/${NavigationPath.Logout}/${NavigationPath.Unauthorized}`);
                return;
            }

            window.location.assign(`/${NavigationPath.Project}`);
        }
    }
}

export default AuthConfig;