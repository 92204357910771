import '../styles/login.scss';
import { useAuth } from 'react-oidc-context';
import { NavigationPath } from '../utils/Navigation';
import { Navigate } from 'react-router-dom';
import { useTranslate } from '../i18translate/Hooks';
import { I18 } from '../languages/I18';

//This is a Login callback component. After successfull login this will be called to.
function LoginCallback() {
    const auth = useAuth();
    const {t} = useTranslate();

    if (auth.error) {
        console.error("Auth Error: ", auth.error?.message)
        return <Navigate to={NavigationPath.Home} />
    }

    if (!auth.isLoading && !auth.isAuthenticated)
        return <Navigate to={NavigationPath.Home} />

    return (
        <div className='container'>
            <div className='login'>
                <h4 className='title abb-cursor'>{t(I18.login_redirect_harmonic_portal)}</h4>
                <span className='description'>{t(I18.login_waiting_message)}</span>
            </div>
        </div>
    )
}

export default LoginCallback