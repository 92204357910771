import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import ModalDialog from "../../../../components/ModalDialog"
import { useTranslate } from "../../../../i18translate/Hooks"
import { I18 } from "../../../../languages/I18"
import { useAppSelector } from "../../../../store/hooks"
import { NavigationPath } from "../../../../utils/Navigation"
import NotificationManager from "../../../../utils/NotificationManager"
import CommonModalFooter from "../../../Configuration/components/AddNewComponentModalPopup/CommonModalFooter"
import { useDeleteProjectMutation } from "../../services/ProjectAPIServices"
import { hideDeleteProjectModal } from "../../store/actions"

const DeleteProjectModalPopup = () => {

    const { t } = useTranslate();
    const { deleteProjectModal } = useAppSelector(store => store.project);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteProject, { isLoading: isProjectDeleting }] = useDeleteProjectMutation();

    const deleteHandler = () => {
        const projectId = deleteProjectModal.projectId ? deleteProjectModal.projectId : ""
        deleteProject(projectId).unwrap()
            .then((data) => {
                dispatch(hideDeleteProjectModal())
                NotificationManager.success(t(I18.project_page_delete_project_success_notification_text))
                //redirecting to project so the list get refreshed
                navigate(`/${NavigationPath.Project}`);
            })
            .catch((error) => NotificationManager.error(error))
    }
    const cancelHandler = () => dispatch(hideDeleteProjectModal());

    return (
        <ModalDialog
            open={deleteProjectModal.modalState}
            showBackButton={false}
            modalTitle={t(I18.project_page_delete_project_modalpopup_title)}
            onModalClose={cancelHandler}
            showFooterSeparator={true}
            className="commonux-modal-popup"
        >
            <div className="modal-content">
                <div className="body-2-regular">{t(I18.project_page_delete_project_modalpopup_content_text)}</div>
                <div className="body-2-regular">{t(I18.project_page_delete_project_modalpopup_confirmationt_text)}.</div>
            </div>
            <div slot="footer">
                <CommonModalFooter
                    key={isProjectDeleting ? "loading" : "defualt"}
                    isEdit={false}
                    isLoading={isProjectDeleting}
                    actionButtonText={isProjectDeleting ? "Deleting project" : t(I18.project_page_delete_project_modalpopup_action_button)}
                    onActionButtonClick={deleteHandler}
                    onCancelClick={cancelHandler} />
            </div>
        </ModalDialog>
    )
}

export default DeleteProjectModalPopup