import { isEmpty } from 'lodash';
import React, { useEffect } from 'react'

interface SetTitleProps {
    title?: string;
}

function SetTitle({ title }: SetTitleProps) {

    useEffect(() => {
        let titleSuffix = !isEmpty(title) ? `| ${title}` : '';
        document.title = `${process.env.REACT_APP_BRAND_NAME} ${titleSuffix}`;
    }, [title]);

    return (
        <>
        </>
    )
}

export default SetTitle