import { useContext } from "react"
import { TranslateContext, TranslateDispatchContext, TranslateStateContext } from "./Contexts"

export const useTranslate = () => {
    const context = useContext(TranslateContext);
    if(context === undefined) {
        throw new Error('useTranslate must be used within a TranslateProvider');
    }
    return context;
}

export const useTranslateState = () => {
    const context = useContext(TranslateStateContext);
    if(context === undefined) {
        throw new Error('useTranslateState must be used within a TranslateProvider');
    }
    return context;
}

export const useTranslateDispatch = () => {
    const context = useContext(TranslateDispatchContext);
    if(context === undefined) {
        throw new Error('useTranslateDispatch must be used within a TranslateProvider');
    }
    return context;
}