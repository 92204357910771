import { CookiePolicyModel } from "../models/CookiePolicyModel";

export enum CookiePolicyActionTypes {
    COOKIE_POLICY_UPDATE = "COOKIE_POLICY_UPDATE",
}

export interface UpdateCookiePolicy {
    type: CookiePolicyActionTypes.COOKIE_POLICY_UPDATE;
    payload: CookiePolicyModel;
}
export type CookiePolicyAction = UpdateCookiePolicy;