export interface NarrowMenuItemProps {
    type?: "large" | "medium"; 
    value: string;
    label: string;
    data?: any;
    onClick?: (value: string, data?: any) => void;
}

function NarrowMenuItem(props: NarrowMenuItemProps) {
    const { type = "medium", value, label, data, onClick } = props
    const handleClick = () => {
        if (onClick)
            onClick(value, data);
    }
    
    return (
        <li className={`body-1-regular menu-item ${type}`} onClick={handleClick}>
            {label}
        </li>
    )
}

export default NarrowMenuItem