import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { NavigationPath } from "../utils/Navigation";

export const apiQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.error("Something went wrong! API ERROR: ", action);
        //check for forbidden status
        if (action?.payload?.status === 403)
            window.location.assign(`/${NavigationPath.Logout}/${NavigationPath.SessionTimeOut}`);
        //Commenting this part as it will show errors many unnecessary places
        // else
        //     notificationManager.error("Something went wrong!");
    }
    return next(action);
}