import ConfigurationTree from './ConfigurationTree';
import DashedButton from '../../../components/DashedButton';
import SystemComponentModal from './SystemComponentModal';
import { useDispatch } from 'react-redux';
import { addAssumptionNote, configurationUpdate, createConfigTree, projectUpdate, resetConfiguration, selectPCC, selectPowerSource, selectStandard, selectUnit, showSystemComponentModal } from '../store/actions';
import { useAppSelector } from '../../../store/hooks';
import ConfigurationHeader from './ConfigurationHeader';
import UserTransformerModalpopup from './AddNewComponentModalPopup/UserTransformerModalpopup';
import PowerGridModalPopup from './AddNewComponentModalPopup/PowerGridModalPopup';
import { getSelectedComponent, isStringEmpty } from '../../../utils/Common';
import { ComponentType } from '../models/ComponentType';
import NonLinearModalPopup from './AddNewComponentModalPopup/NonLinearModalPopup';
import LVGensetModalPopup from './AddNewComponentModalPopup/LVGensetModalPopup';
import ActiveHarmonicFilterModalPopup from './AddNewComponentModalPopup/ActiveHarmonicFilterModalPopup';
import MotorControlCenterModalPopup from './AddNewComponentModalPopup/MotorControlCenterModalPopup';
import LinearLoadModalPopup from './AddNewComponentModalPopup/LinearLoadModalPopup';
import '../styles/commonstyle.scss';
import AssumptionNotesModalPopup from './AddNewComponentModalPopup/AssumptionNotesModalPopup';
import ExportConfigurationModalPopup from './AddNewComponentModalPopup/ExportConfigurationModalPopup';
import { Navigate, useParams } from 'react-router-dom';
import { useGetConfigurationJSONByIdQuery, useUpdateConfigurationJsonMutation } from '../services/ConfigurationAPIService';
import { useEffect, useState } from 'react';
import { getComponentNodeIdByPCCPoint, getConfigTreeModel } from '../models/APIObjectToConfigTreeMapper';
import { PowerSourceOptions } from '../models/PowerSource';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { ResultSummaryRequestModel } from '../models/ResultSummaryRequestModel';
import { NavigationPath } from '../../../utils/Navigation';
import { getAPIRequestModel, getPCCPoint } from '../models/ConfigTreeToAPIObjectMapper';
import { ConfigurationJSONModel } from '../models/ConfigurationJSONModel';
import { PCCPointOptions } from '../models/PCCPointOptions';
import ImportConfigurationModalPopup from './AddNewComponentModalPopup/ImportConfigurationModalPopup';

function ConfigurationSection() {
    const { selectedComponentNodeId, configurationTree, isShowEditLvGensetModal, isShowEditPowerGridModal, isShowUserTransformerModal,
        isShowNonlinearLoadsModal, isShowActiveHarmonicFilterModal, isShowMotorControlCenterModal, isShowLinearLoadModal,
        isShowAssumptionNoteModal, isShowExportConfigurationModal, selectedUnit, selectedPCCNodeId, selectedStandard, assumptionNotes } = useAppSelector(state => state.configuration);
    const dispatch = useDispatch();
    const { projectId, configurationId } = useParams();
    const { data, isError, isSuccess, isFetching, fulfilledTimeStamp } = useGetConfigurationJSONByIdQuery(configurationId && projectId ? { projectId, configurationId } : { projectId: "", configurationId: "" }, { skip: !configurationId || !projectId, refetchOnMountOrArgChange: true });
    const [updateConfigJson] = useUpdateConfigurationJsonMutation();
    const { t } = useTranslate();

    //this is to restrict calling update on configtree change for the first time load
    const [initialConfigJson, setInitialConfigJson] = useState("");

    //using this to compare with existing timestamp as rtkquery already have oldData so multiple render happens
    const [initfulfilledTimeStamp, setInitfulfilledTimeStamp] = useState(fulfilledTimeStamp);

    useEffect(() => {
        if (initfulfilledTimeStamp !== fulfilledTimeStamp)
            setConfigurationStateFromJSON();

        return () => {
            setInitfulfilledTimeStamp(fulfilledTimeStamp)
            dispatch(resetConfiguration());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fulfilledTimeStamp])

    useEffect(() => {
        //if data is not loaded then don't auto call auto update
        if (!isSuccess || !projectId || !configurationId || isStringEmpty(initialConfigJson))
            return;

        const resultConfigurationRequest = getAPIRequestModel(configurationTree, getPCCPoint(configurationTree, selectedPCCNodeId), selectedUnit, selectedStandard, assumptionNotes);
        if (resultConfigurationRequest) {
            const configJSON = JSON.stringify(resultConfigurationRequest);
            const configJsonRequest: ConfigurationJSONModel = {
                projectID: projectId,
                configurationID: configurationId,
                configurationInfo: configJSON
            }
            updateConfigJson({ projectId, configurationId, data: configJsonRequest })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationTree, selectedPCCNodeId, selectedUnit, selectedStandard, assumptionNotes])

    function setConfigurationStateFromJSON() {
        if (!isSuccess || !data || !data.configuration?.configuration?.configurationInfo)
            return;

        const configurationJson: ResultSummaryRequestModel = JSON.parse(data.configuration.configuration.configurationInfo)
        setInitialConfigJson(data.configuration.configuration.configurationInfo);
        const jsonSelectedUnit = configurationJson.setting.category;
        const jsonSelectedStandard = configurationJson.setting.standard;
        const jsonAssumptionNotes = configurationJson.assumptionNotes ?? [];

        //Set selected Unit from settings
        if (selectedUnit !== jsonSelectedUnit) {
            dispatch(selectUnit(jsonSelectedUnit));
        }

        //Set selected Standard from settings
        if (jsonSelectedStandard && selectedStandard !== jsonSelectedStandard) {
            dispatch(selectStandard(jsonSelectedStandard));
        }

        //Set Assumption notes
        if (jsonAssumptionNotes !== assumptionNotes) {
            dispatch(addAssumptionNote(jsonAssumptionNotes));
        }

        //get config tree object from the configurationJson resonse 
        const newConfigTree = getConfigTreeModel(configurationJson);
        const pccPoint = configurationJson.setting.pccPoint ?? PCCPointOptions.PowerGrid.key as number;

        //Recreate the configuration tree 
        if (newConfigTree) {
            dispatch(createConfigTree(newConfigTree));

            //Set the selected powersource configurationJson 
            dispatch(selectPowerSource(newConfigTree.componentType === ComponentType.PowerGrid ? PowerSourceOptions.PowerGrid.key as number : PowerSourceOptions.LVGenset.key as number));

            //Set the PCC point as per the configurationJson 
            const pccNodeId = getComponentNodeIdByPCCPoint(newConfigTree, pccPoint) ?? "0";
            dispatch(selectPCC(pccNodeId));
        }

        //Set project for selected configuration
        if (data.project) {
            dispatch(projectUpdate(data.project));
        }

        //Set configuration
        if (data.configuration) {
            dispatch(configurationUpdate(data.configuration));
        }
    }

    const onClickDashedButton = () => {
        dispatch(showSystemComponentModal());
    }

    const disableDashedButton = () => {
        if (isStringEmpty(selectedComponentNodeId))
            return true;

        let selectedComponent = getSelectedComponent(configurationTree, selectedComponentNodeId);
        return selectedComponent !== undefined &&
            (selectedComponent.componentType !== ComponentType.Transformer
                && selectedComponent.componentType !== ComponentType.MCC
                && selectedComponent.componentType !== ComponentType.LVGenset);
    }

    if (isError)
        return <Navigate to={`/${NavigationPath.NotFound}?${window.location.pathname}`} ></Navigate>

    return (
        <section id='configuration' className='configuration' >
            {isFetching &&
                <ConfigurationHeader isLoading={true} />
            }
            {!isFetching &&
                <>
                    <ConfigurationHeader configurationName={data?.configuration.configurationName} projectId={data?.configuration.projectID} isLoading={false} />
                    <ConfigurationTree />
                    <div className="systemconfiguration-button">
                        <DashedButton label={t(I18.configuration_page_system_component_button_label)} iconName='plus' iconSize='small' onClick={onClickDashedButton} disabled={disableDashedButton()} />
                    </div>
                </>
            }
            <div>
                <SystemComponentModal />
            </div>
            {
                isShowUserTransformerModal &&
                <div>
                    <UserTransformerModalpopup />
                </div>
            }
            {
                isShowEditPowerGridModal &&
                <div>
                    <PowerGridModalPopup />
                </div>
            }
            {
                isShowNonlinearLoadsModal &&
                <div>
                    <NonLinearModalPopup />
                </div>
            }
            {
                isShowEditLvGensetModal &&
                <div>
                    <LVGensetModalPopup />
                </div>
            }
            {
                isShowActiveHarmonicFilterModal &&
                <div>
                    <ActiveHarmonicFilterModalPopup />
                </div>
            }
            {
                isShowMotorControlCenterModal &&
                <div>
                    <MotorControlCenterModalPopup />
                </div>
            }
            {
                isShowLinearLoadModal &&
                <div>
                    <LinearLoadModalPopup />
                </div>
            }
            {
                isShowAssumptionNoteModal &&
                <div>
                    <AssumptionNotesModalPopup />
                </div>
            }
            {
                isShowExportConfigurationModal &&
                <div>
                    <ExportConfigurationModalPopup />
                </div>
            }
            {
                <div>
                    <ImportConfigurationModalPopup />
                </div>
            }
        </section>
    )
}
export default ConfigurationSection