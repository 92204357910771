import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthManager } from "../../../authServices/AuthManager";
import { isStringEmpty } from "../../../utils/Common";
import { getAPIRequestModel } from "../models/ConfigTreeToAPIObjectMapper";
import { ConfigurationJSONModel } from "../models/ConfigurationJSONModel";
import { ConfigurationModel } from "../models/ConfigurationModel";
import { getConfigTreePowerGridDefaultValue, getCountrySpecificDefaultConfiguration } from "../models/DefaultValues";
import { PCCPointOptions } from "../models/PCCPointOptions";
import { StandardOptions } from "../models/Standard";
import UnitOptions from "../models/UnitOptions";
import { ProjectSharedModel } from "../../../models/ProjectSharedModel";
import { ProjectConfigurationModel } from "../models/ProjectConfiguration";

const baseUrlEnv = process.env.REACT_APP_API_URL;
const baseUrl = `${baseUrlEnv}`;

export const configurationJSONAPI = createApi({
    reducerPath: "configurationJSONAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${AuthManager.getUser()?.access_token}`);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getConfigurationJSONById: builder.query<ProjectConfigurationModel, { projectId: string, configurationId: string }>({
            async queryFn({ projectId, configurationId }, queryApi, extraOptions, baseQuery) {
                //Get configuration information
                const configResult = await baseQuery(`configurations/${projectId}/${configurationId}`);
                //Get project information (required for projects country)
                const projectResult = await baseQuery(`projects/${projectId}`);

                let projectConfiguration: ProjectConfigurationModel = {} as ProjectConfigurationModel;
                if (configResult.data) {
                    const configData: ConfigurationModel = configResult?.data as ConfigurationModel
                    //extract config json from API response
                    let configJson: string = configData.configuration?.configurationInfo ?? "";
                    
                    const projectData = projectResult && projectResult.data ? projectResult.data as ProjectSharedModel : undefined;
                    
                    //Get default value for country specific if not found it will return default json
                    const defaultCountryJson = getCountrySpecificDefaultConfiguration(projectData?.contact?.country, true);
                    
                    //If no config json exist then create and return default config json string based on country.
                    if (isStringEmpty(configJson)) {
                        configJson = JSON.stringify(getAPIRequestModel(
                            getConfigTreePowerGridDefaultValue(undefined, projectData?.contact?.country),
                            PCCPointOptions.PowerGrid.key as number,
                            defaultCountryJson ? defaultCountryJson.setting.category : UnitOptions.US.key as number,
                            defaultCountryJson ? defaultCountryJson.setting.standard : StandardOptions.IEEE2022.key as number,
                            defaultCountryJson ? defaultCountryJson.assumptionNotes : []));
                        configData.configuration = {
                            projectID: configData.projectID,
                            configurationID: configData.configurationID,
                            configurationInfo: configJson,
                        }
                    }
                    //set config and project for response
                    projectConfiguration.configuration = configData;
                    if (projectData) {
                        const projectData = projectResult.data as ProjectSharedModel;
                        projectConfiguration.project = {
                            projectName: projectData.projectName,
                            contact: projectData.contact,
                            projectID: projectData.projectID,
                        }
                    }
                    return { data: projectConfiguration };
                } else if (configResult.error)
                    return { error: configResult.error };
                return { error: { status: 500, statusText: 'Something went wrong', data: undefined } };
            }
        }),
        updateConfigurationJson: builder.mutation<ConfigurationJSONModel, { projectId: string, configurationId: string, data: ConfigurationJSONModel }>({
            query: ({ projectId, configurationId, data }) => (
                {
                    url: `configurations?projectID=${projectId}&configurationID=${configurationId}`,
                    method: 'PUT',
                    body: data
                }
            )
        }),
    })
})

export const { useGetConfigurationJSONByIdQuery, useUpdateConfigurationJsonMutation } = configurationJSONAPI;