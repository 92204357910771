import { LVGenset, PowerGrid } from "./PowerSource";
import { ConfigurationSetting } from "./ConfigurationSettings";


export class ResultSummaryRequestModel {
    frequency: number;
    ratedVoltage: number;
    assumptionNotes: string[];
    setting: ConfigurationSetting;
    generator?: LVGenset;
    powergrid?: PowerGrid;

    public constructor(powerSource: PowerGrid | LVGenset, selectedUnit: number, selectedPccPoint: number = 0, selectedStandard: number, assumptionNotes: string[]) {
        this.frequency = powerSource.frequency;
        this.ratedVoltage = powerSource.ratedVoltage;
        this.assumptionNotes = assumptionNotes;
        this.setting = { category: selectedUnit, pccPoint: selectedPccPoint, standard: selectedStandard };

        if (powerSource.constructor.name === PowerGrid.name)
            this.powergrid = powerSource as PowerGrid;

        if (powerSource.constructor.name === LVGenset.name)
            this.generator = powerSource as LVGenset;
    }
}