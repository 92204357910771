import "../styles/labelseparator.scss";

interface LabelOrSeparatorProps {
  separatorText?: string;
}

const LabelSeparator = (props: LabelOrSeparatorProps) => {
  const { separatorText = "" } = props;
  return (
    <>
      {separatorText ? (
        <div className="label-separator withLabel">
          <hr />
          <label>{separatorText}</label>
          <hr />
        </div>
      ) : (
        <div className="separator">
          <hr />
        </div>
      )}
    </>
  );
};

export default LabelSeparator;
