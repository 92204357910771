import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DragAndDrop from "../../../../components/DragAndDrop";
import Button from "../../../../components/Form/Button";
import Icon from "../../../../components/Icon";
import ModalDialog from "../../../../components/ModalDialog"
import { useTranslate } from "../../../../i18translate/Hooks";
import { I18 } from "../../../../languages/I18";
import { useAppSelector } from "../../../../store/hooks";
import NotificationManager from "../../../../utils/NotificationManager";
import { ResultSummaryRequestModel } from "../../models/ResultSummaryRequestModel";
import { useUpdateConfigurationJsonMutation } from "../../services/ConfigurationAPIService";
import { useValidateConfigurationJsonMutation } from "../../services/ImportApiServices";
import { hideImportConfigurationModal } from "../../store/actions";
import { ConfigurationAction } from "../../store/actionTypes";
import "../../styles/importconfiguratioin.scss"
import CommonModalFooter from "./CommonModalFooter";
import { getShortForm } from "../../../../utils/Common";

const ImportConfigurationModalPopup = () => {

    const { isShowImportConfigurationModal } = useAppSelector(storeState => storeState.configuration);
    const dispatch = useDispatch<Dispatch<ConfigurationAction>>();
    const { t } = useTranslate();

    const { projectId, configurationId } = useParams();
    const [validateConfiguration, { isLoading: isValidationLoading }] = useValidateConfigurationJsonMutation();
    const [updateConfiguration, { isLoading: isUpdateConfigurationLoading }] = useUpdateConfigurationJsonMutation();

    const [file, setFile] = useState<any>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<any>();
    const [isDragAndDropDisabled, setIsDragAndDropDisabled] = useState<boolean>(false);

    const pickerOpts = {
        types: [
            {
                description: "JSON",
                accept: {
                    "application/json": [".json"],
                },
            },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
    };

    const fileInputHandler = async (e: any) => {
        try {

            const [fileHandle] = await window.showOpenFilePicker(pickerOpts);
            setFile(await fileHandle.getFile())
        }
        catch (error) {
            setFile(null);
        }
    }
    
    const fileDragedHandler = (files: any) => setFile(files[0]);

    const getContent = () => {
        let fileReader = new FileReader();
        fileReader.onload = (event: any) => validateConfigurationAPICall(event.target.result);
        fileReader.readAsText(file);
    }
    const validateConfigurationAPICall = (content: any) => {
        try {
            const configuration: ResultSummaryRequestModel = JSON.parse(content);
            (validateConfiguration(configuration).unwrap()
                .then(() => UpdateConfigurationTree(JSON.stringify(configuration)))
                .catch((response) => {
                    setIsError(true)
                    setErrorMessages(Object.values(response.data));
                }))
        } catch (error) {
            setIsError(true);
            setErrorMessages([[t(I18.configuration_page_import_configuration_modal_popup_invalid_json_format_message)]]);
        }
    }
    const UpdateConfigurationTree = (configuration: string) => {
        (updateConfiguration({ projectId: projectId ?? "", configurationId: configurationId ?? "", data: { projectID: projectId ?? "", configurationID: configurationId ?? "", configurationInfo: configuration } })).unwrap()
            .then(() => {
                NotificationManager.success(t(I18.configuration_page_import_configuration_modal_popup_success_notification_message));
                setTimeout(() => window.location.reload(), 1500);
            })
            .catch(() => {
                setIsError(true);
                setErrorMessages([[t(I18.configuration_page_import_configuration_modal_popup_updation_failed_error_message)]]);
            });
    }

    const uploadeHandler = () => {
        setIsDragAndDropDisabled(true);
        setErrorMessages([]);
        getContent();
    }
    const tryAgainHandler = () => {
        setIsError(false);
        setFile(null);
        setIsDragAndDropDisabled(false);
    }
    const handleCloseImportConfigurationModalPopup = () => dispatch(hideImportConfigurationModal());

    useEffect(() => {
        setFile(null);
        setIsDragAndDropDisabled(false);
        setIsError(false);
    }, [isShowImportConfigurationModal])

    return (
        <ModalDialog
            open={isShowImportConfigurationModal}
            onModalClose={handleCloseImportConfigurationModalPopup}
            modalTitle={t(I18.configuration_page_import_configuration_modal_popup_title)}
            className="commonux-modal-popup"
            showFooterSeparator={false}>
            <Icon slot="title-icon" name="import" ></Icon>
            <div className="modal-content">
                <div >
                    <DragAndDrop onfileDroped={(files) => fileDragedHandler(files)} isDisabled={isDragAndDropDisabled} className={`file-upload-section ${file ? "selected" : "selecting"} ${isError ? "error" : ""}`}>
                        {file && !isError &&
                            <div className="upload-button-container">
                                <div>{getShortForm(40, file.name, ".json")}</div>
                                <div >
                                    <Button size="medium" type="primary-black" disabled={isUpdateConfigurationLoading || isValidationLoading} onClick={uploadeHandler}>
                                        <Icon name="upload" slot="icon" />
                                        {(isUpdateConfigurationLoading || isValidationLoading) ? t(I18.uploading) : t(I18.upload)}
                                    </Button>
                                </div>
                            </div>
                        }

                        {!isError &&
                            <div className={`${file ? "bottom-section" : ""}`}>
                                {file &&
                                    <span className="description-regular">{t(I18.configuration_page_import_configuration_modal_popup_uploaded_the_wrong_file_text)}</span>
                                }
                                <div>
                                    <span className={`${file ? "description-regular gray" : "body-2-medium"}`}>{`${t(I18.configuration_page_import_configuration_modal_popup_drag_and_drop_or_text)} `}</span>
                                    <span className={`underline pointer ${file ? "description-regular" : "body-2-medium"}`} onClick={(e) => fileInputHandler(e)}>{t(I18.configuration_page_import_configuration_modal_popup_click_here_text)}</span>
                                    {file &&
                                        <span className="description-regular gray">{` ${t(I18.configuration_page_import_configuration_modal_popup_to_upload_file_text)}`}</span>
                                    }
                                </div>
                            </div>}
                        {!file && !isError &&
                            <span className="description-regular">{` ${t(I18.configuration_page_import_configuration_modal_popup_to_upload_file_text)}`}</span>
                        }

                        {isError &&
                            <>
                                <Icon name="error-circle-2" size="large" />
                                <div className="error-messages-section">
                                    {errorMessages.map((messages: any) => (
                                        <ul className="error-messages-list" key={messages}>
                                            {messages.map((message: any, index: number) => (
                                                <li key={message}>{message}</li>
                                            ))}
                                        </ul>
                                    ))}
                                </div>
                                <span className="description-regular gray">{t(I18.configuration_page_import_configuration_modal_popup_please_try_uploading_again_text)}</span>
                            </>
                        }
                    </DragAndDrop>
                </div>
                {isError &&
                    <CommonModalFooter
                        isEdit={false}
                        actionButtonText={"Try again"}
                        onCancelClick={handleCloseImportConfigurationModalPopup}
                        onActionButtonClick={tryAgainHandler} />
                }
            </div>
        </ModalDialog >
    )
}

export default ImportConfigurationModalPopup
