import { ConfigurationStoreModel } from "../models/ConfigurationStoreModel";
import { PowerSourceOptions } from "../models/PowerSource";
import { updateComponent, addChildComponent, deleteComponent, addSelectedAssumptionNotes, filterAssumptionNotes, updateAssumptionNotes } from "./actionMethods";
import { ConfigurationAction, ConfigurationActionTypes } from "./actionTypes";
import UnitOptions from '../models/UnitOptions';
import { ActiveHarmonicFilterResultModel } from "../models/ActiveHarmonicFilterResultModel";
import { SystemComponent } from "../models/SystemComponent";
import { StandardOptions } from "../models/Standard";
import { ProjectSharedModel } from "../../../models/ProjectSharedModel";
import { ConfigurationSharedModel } from "../../../models/ConfigurationSharedModel";
//? Do not remove it. It can be used in quick estimate.
//import { getConfigTreePowerGridDefaultValue } from "../models/DefaultValues";

export const initialState: ConfigurationStoreModel = {
    selectedPowerSource: PowerSourceOptions.PowerGrid.key as number,
    //? Do not remove it. It can be used in quick estimate.
    //configurationTree: getConfigTreePowerGridDefaultValue(),
    configurationTree: {} as SystemComponent,
    project: {} as ProjectSharedModel,
    configuration: {} as ConfigurationSharedModel,
    isShowSystemComponentModal: false,
    selectedPCCNodeId: "0",
    selectedUnit: UnitOptions.SI.key as number,
    selectedStandard: StandardOptions.IEEE2022.key as number,
    isShowUserTransformerModal: false,
    isShowNonlinearLoadsModal: false,
    isShowEditPowerGridModal: false,
    isTransformerEdit: false,
    isNonLinearLoadsEdit: false,
    isShowEditLvGensetModal: false,
    isShowActiveHarmonicFilterModal: false,
    isActiveHarmonicFilterEdit: false,
    isShowMotorControlCenterModal: false,
    isMotorControlCenterEdit: false,
    isShowLinearLoadModal: false,
    isLinearLoadEdit: false,
    isDisableCreateReportButton: true,
    activeHarmonicFilterResult: {} as ActiveHarmonicFilterResultModel,
    isShowAssumptionNoteModal: false,
    assumptionNotes: [],
    isShowExportConfigurationModal: false,
    isShowImportConfigurationModal: false,
}

const configurationReducer = (state: ConfigurationStoreModel | undefined, action: ConfigurationAction): ConfigurationStoreModel => {
    state = state ? state : initialState;

    switch (action.type) {
        //This will reset the whole configuration to initial state. use wisely
        case ConfigurationActionTypes.RESET_CONFIGURATION:
            return { ...initialState }

        case ConfigurationActionTypes.CONFIGURATION_TREE_ADD_CHILD:
            return {
                ...state,
                configurationTree: addChildComponent(state, action.parentNodeId, action.payload)
            };

        case ConfigurationActionTypes.CONFIGURATION_TREE_UPDATE:
            return {
                ...state,
                configurationTree: updateComponent(state, action.payload)
            };

        case ConfigurationActionTypes.CONFIGURATION_TREE_DELETE_CHILD:
            return {
                ...state,
                configurationTree: deleteComponent(state, action.payload),
                selectedComponentNodeId: action.payload.nodeId === state.selectedComponentNodeId ? undefined : state.selectedComponentNodeId,
                selectedPCCNodeId: action.payload.nodeId === state.selectedPCCNodeId ? undefined : state.selectedPCCNodeId,
            };

        case ConfigurationActionTypes.CONFIGURATION_TREE_CREATE_TREE:
            return {
                ...state,
                configurationTree: action.payload,
            };

        case ConfigurationActionTypes.PROJECT_UPDATE:
            return {
                ...state,
                project: action.payload,
            };

        case ConfigurationActionTypes.CONFIGURATION_UPDATE:
            return {
                ...state,
                configuration: action.payload,
            };

        case ConfigurationActionTypes.SYSTEM_COMPONENT_MODAL_SHOW:
            return {
                ...state,
                isShowSystemComponentModal: true
            };

        case ConfigurationActionTypes.SYSTEM_COMPONENT_MODAL_HIDE:
            return {
                ...state,
                isShowSystemComponentModal: false
            };

        case ConfigurationActionTypes.CONFIGURATION_SELECT_COMPONENT:
            return {
                ...state,
                selectedComponentNodeId: action.payload
            }

        case ConfigurationActionTypes.CONFIGURATION_SELECT_PCC:
            return {
                ...state,
                selectedPCCNodeId: action.payload
            }

        case ConfigurationActionTypes.CONFIGURATION_SELECT_POWERSOURCE:
            return {
                ...state,
                selectedPowerSource: action.payload
            }
        case ConfigurationActionTypes.USER_TRANSFORMER_MODAL_SHOW:
            return {
                ...state,
                isShowUserTransformerModal: true,
                isTransformerEdit: action.payload?.isEdit,

            };

        case ConfigurationActionTypes.USER_TRANSFORMER_MODAL_HIDE:
            return {
                ...state,
                isShowUserTransformerModal: false
            };
        case ConfigurationActionTypes.NON_LINEAR_LOADS_MODAL_SHOW:
            return {
                ...state,
                isShowNonlinearLoadsModal: true,
                isNonLinearLoadsEdit: action.payload?.isEdit,

            };

        case ConfigurationActionTypes.NON_LINEAR_LOADS_MODAL_HIDE:
            return {
                ...state,
                isShowNonlinearLoadsModal: false
            };
        case ConfigurationActionTypes.EDIT_POWER_GRID_MODAL_SHOW:
            return {
                ...state,
                isShowEditPowerGridModal: true
            };

        case ConfigurationActionTypes.EDIT_POWER_GRID_MODAL_HIDE:
            return {
                ...state,
                isShowEditPowerGridModal: false
            };
        case ConfigurationActionTypes.EDIT_LV_GENSET_MODAL_SHOW:
            return {
                ...state,
                isShowEditLvGensetModal: true
            };

        case ConfigurationActionTypes.EDIT_LV_GENSET_MODAL_HIDE:
            return {
                ...state,
                isShowEditLvGensetModal: false
            };

        case ConfigurationActionTypes.CONFIGURATION_SELECT_UNIT:
            return {
                ...state,
                selectedUnit: action.payload
            }

        case ConfigurationActionTypes.CONFIGURATION_SELECT_STANDARD:
            return {
                ...state,
                selectedStandard: action.payload
            }

        case ConfigurationActionTypes.ACTIVE_HARMONIC_FILTER_MODAL_SHOW:
            return {
                ...state,
                isShowActiveHarmonicFilterModal: true,
                isActiveHarmonicFilterEdit: action.payload?.isEdit
            }
        case ConfigurationActionTypes.ACTIVE_HARMONIC_FILTER_MODAL_HIDE:
            return {
                ...state,
                isShowActiveHarmonicFilterModal: false

            }
        case ConfigurationActionTypes.MOTOR_CONTROL_CENTER_MODAL_SHOW:
            return {
                ...state,
                isShowMotorControlCenterModal: true,
                isMotorControlCenterEdit: action.payload?.isEdit
            }
        case ConfigurationActionTypes.MOTOR_CONTROL_CENTER_MODAL_HIDE:
            return {
                ...state,
                isShowMotorControlCenterModal: false
            }
        case ConfigurationActionTypes.LINEAR_LOAD_MODAL_SHOW:
            return {
                ...state,
                isShowLinearLoadModal: true,
                isLinearLoadEdit: action.payload?.isEdit
            }
        case ConfigurationActionTypes.LINEAR_LOAD_MODAL_HIDE:
            return {
                ...state,
                isShowLinearLoadModal: false
            }

        case ConfigurationActionTypes.DISABLE_CREATE_REPORT_BUTTON:
            return {
                ...state,
                isDisableCreateReportButton: true
            }
        case ConfigurationActionTypes.ENABLE_CREATE_REPORT_BUTTON:
            return {
                ...state,
                isDisableCreateReportButton: false
            }
        case ConfigurationActionTypes.UPDATE_ACTIVE_HARMONIC_FILTER_RESULT:
            return {
                ...state,
                activeHarmonicFilterResult: action.payload
            }

        case ConfigurationActionTypes.ADD_ASSUMPTIONNOTES_MODEL_SHOW:
            return {
                ...state,
                isShowAssumptionNoteModal: true
            }

        case ConfigurationActionTypes.ADD_ASSUMPTIONNOTES_MODEL_HIDE:
            return {
                ...state,
                isShowAssumptionNoteModal: false
            }

        case ConfigurationActionTypes.ADD_ASSUMPTIONNOTE:
            return {
                ...state,
                assumptionNotes: addSelectedAssumptionNotes(state, action.payload)
            }

        case ConfigurationActionTypes.REMOVE_ASSUMPTIONNOTE:
            return {
                ...state,
                assumptionNotes: filterAssumptionNotes(state, action.payload)
            }

        case ConfigurationActionTypes.UPDATE_ASSUMPTIONNOTE:
            return {
                ...state,
                assumptionNotes: updateAssumptionNotes(state, action.payload)
            }

        case ConfigurationActionTypes.EXPORT_CONFIGURATION_MODAL_SHOW:
            return {
                ...state,
                isShowExportConfigurationModal: true
            }

        case ConfigurationActionTypes.EXPORT_CONFIGURATION_MODAL_HIDE:
            return {
                ...state,
                isShowExportConfigurationModal: false
            }

        case ConfigurationActionTypes.IMPORT_CONFIGURATION_MODAL_SHOW:
            return {
                ...state,
                isShowImportConfigurationModal: true
            }

        case ConfigurationActionTypes.IMPORT_CONFIGURATION_MODAL_HIDE:
            return {
                ...state,
                isShowImportConfigurationModal: false
            }

        default:
            return {
                ...state,
            }
    }
}
export default configurationReducer;

