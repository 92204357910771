import { useDispatch } from "react-redux";
import Button from "../../../components/Form/Button";
import Icon from "../../../components/Icon";
import { useTranslate } from "../../../i18translate/Hooks"
import { I18 } from "../../../languages/I18";
import { isStringEmpty } from "../../../utils/Common";
import { showConfigurationModal } from "../store/actions";

interface ConfigurationSectionHeaderProps {
    projectId?: string;
}

function ConfigurationSectionHeader(props: ConfigurationSectionHeaderProps) {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { projectId = "" } = props
    const handleClickNewConfiguration = () => dispatch(showConfigurationModal(projectId))

    return (
        <header id="header-configuration" className="configuration-section-header">
            <article>
                <h6>{t(I18.project_info_configurations_label)}</h6>
                <span className="description-regular">{t(I18.project_info_configurations_description)}</span>
            </article>
            {
                !isStringEmpty(projectId) &&
                <Button type="tertiary" size="medium" onBtnClick={handleClickNewConfiguration}>
                    <Icon slot="icon" name="plus" size="small"></Icon>
                    {t(I18.project_info_new_configuration_button_label)}
                </Button>
            }
        </header>
    )
}

export default ConfigurationSectionHeader