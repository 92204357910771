export enum HomeActionType {
    HOME_OPTION_MODAL_SHOW = "HOME_OPTION_MODAL_SHOW",
    HOME_OPTION_MODAL_HIDE = "HOME_OPTION_MODAL_HIDE",
    HOME_ARTICLE_SECTION = "HOME_ARTICLE_SECTION"
}

interface  ShowOptionModal {
    type: HomeActionType.HOME_OPTION_MODAL_SHOW;
}

interface HideOptionModal {
    type: HomeActionType.HOME_OPTION_MODAL_HIDE;
}

export type HomeAction = ShowOptionModal | HideOptionModal;