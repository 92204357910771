import SetTitle from '../../components/SetTitle';
import ConfigurationSection from './components/ConfigurationSection';
import ResultSummarySection from './components/ResultSummarySection';
import './styles/style.scss';

function Configuration() {

    return (
        <>
            <SetTitle title='Configuration' />
            <div className="container config-container">
                <ConfigurationSection />
                <ResultSummarySection />
            </div>
        </>

    )
}

export default Configuration
